import { useEffect, useRef, useCallback } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { Vector3, Euler, Quaternion } from 'three';
import { DEFAULT_CAMERA_POSITION, DEBUG_CAMERA_POSITION } from '../../utils/TunnelUtils';
import { useCameraInfo } from './hooks';

interface CameraResetProps {
  isResetting: boolean;
  debug: boolean;
}

/**
 * Component that handles camera reset animations
 */
export function CameraReset({ isResetting, debug }: CameraResetProps) {
  const { camera } = useThree();
  const initialPos = useRef(new Vector3(...(debug ? DEBUG_CAMERA_POSITION : DEFAULT_CAMERA_POSITION)));
  const initialRot = useRef(new Euler(debug ? -1.3 : 0, debug ? -1.46 : 0, debug ? -1.3 : 0));
  const startPos = useRef(new Vector3());
  const startRot = useRef(new Euler());
  const animationProgress = useRef(0);
  const isAnimating = useRef(false);
  const wasDebugMode = useRef(debug);

  // Update initial position and rotation when debug mode changes
  useEffect(() => {
    initialPos.current = new Vector3(...(debug ? DEBUG_CAMERA_POSITION : DEFAULT_CAMERA_POSITION));
    initialRot.current = new Euler(debug ? -1.3 : 0, debug ? -1.46 : 0, debug ? -1.3 : 0);
    
    // If debug mode changed, apply the new position and rotation immediately
    if (debug !== wasDebugMode.current) {
      camera.position.copy(initialPos.current);
      camera.rotation.copy(initialRot.current);
      wasDebugMode.current = debug;
    }
  }, [debug, camera]);

  useEffect(() => {
    if (isResetting && !isAnimating.current) {
      // Capture start position and rotation
      startPos.current.copy(camera.position);
      startRot.current.copy(camera.rotation);
      animationProgress.current = 0;
      isAnimating.current = true;
    }
  }, [isResetting, camera]);

  useFrame((_, delta) => {
    if (isAnimating.current) {
      // Faster, more pronounced easing
      const ease = (t: number) => 1 - Math.pow(1 - t, 3);
      
      // Use fixed time step for more consistent animation
      const ANIMATION_SPEED = 2.5; // Complete animation in 0.4s
      animationProgress.current = Math.min(animationProgress.current + delta * ANIMATION_SPEED, 1);
      const alpha = ease(animationProgress.current);

      // Position interpolation
      camera.position.lerpVectors(startPos.current, initialPos.current, alpha);
      
      // Quaternion-based rotation interpolation for smoother rotation
      const startQuat = new Quaternion().setFromEuler(startRot.current);
      const endQuat = new Quaternion().setFromEuler(initialRot.current);
      const currentQuat = new Quaternion();
      currentQuat.slerpQuaternions(startQuat, endQuat, alpha);
      camera.quaternion.copy(currentQuat);

      // End animation
      if (animationProgress.current >= 1) {
        isAnimating.current = false;
        camera.position.copy(initialPos.current);
        camera.rotation.copy(initialRot.current);
      }
    }
  });

  return null;
}

interface CameraControllerProps {
  controlsRef: React.RefObject<any>;
  debug: boolean;
  onReset?: () => void;
}

/**
 * Main camera controller component that combines controls and reset functionality
 */
export function CameraController({ controlsRef, debug, onReset }: CameraControllerProps) {
  const resetCamera = useCallback(() => {
    if (controlsRef.current) {
      controlsRef.current.reset(); // Use OrbitControls built-in reset
      onReset?.();
    }
  }, [controlsRef, onReset]);

  return null;
}

/**
 * Component that displays camera position and rotation information for debugging
 */
export function CameraInfo() {
  const { camera } = useThree();
  const updateCameraInfo = useCameraInfo(state => state.updateCameraInfo);
  
  useFrame(() => {
    // We're just using this to update camera info, no rendering needed
    const position = {
      x: parseFloat(camera.position.x.toFixed(2)),
      y: parseFloat(camera.position.y.toFixed(2)),
      z: parseFloat(camera.position.z.toFixed(2))
    };
    
    const rotation = {
      x: parseFloat(camera.rotation.x.toFixed(2)),
      y: parseFloat(camera.rotation.y.toFixed(2)),
      z: parseFloat(camera.rotation.z.toFixed(2))
    };
    
    // Update camera info in the store
    updateCameraInfo(position, rotation, (camera as any).fov);
    
    // Also update debug info in window if available
    if (window.debug) {
      window.debug.cameraInfo = {
        position,
        rotation,
        fov: (camera as any).fov
      };
    }
  });
  
  return null;
} 