import { useState, useEffect, useRef } from 'react';
import { getAllMandalas } from '../../services/mandalaService';
import { loadMandalaTextures } from '../../utils/textureLoader';
import * as THREE from 'three';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Badge } from '../ui/badge';
import { 
  Card, 
  CardContent, 
  Button,
  ScrollArea,
  Loader
} from '../ui';
import { useTunnelStore } from '../../store/tunnelStore';
import { cn } from '../../lib/utils';
import { X } from 'lucide-react';
import { useMotionControl } from '../Tunnel';
import { MandalaInfo } from '../../types/tunnel';
import { toast } from '../../components/ui/use-toast';

// Fallback image for mandalas without previews
const FALLBACK_IMAGE = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGNpcmNsZSBjeD0iNTAiIGN5PSI1MCIgcj0iNDUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1vcGFjaXR5PSIwLjIiIHN0cm9rZS13aWR0aD0iMiIvPgogIDxwYXRoIGQ9Ik01MCAyNUw2Mi41IDQ1TDc1IDY1SDUwSDI1TDM3LjUgNDVMNTAgMjVaIiBzdHJva2U9IndoaXRlIiBzdHJva2Utb3BhY2l0eT0iMC4yIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==';

// Helper function to render mandala preview image
function MandalaPreviewImage({ mandala, size = 'small', className = '' }: { 
  mandala: MandalaInfo, 
  size?: 'small' | 'large',
  className?: string 
}) {
  const [imgSrc, setImgSrc] = useState<string | null>(mandala.preview_url || mandala.previewUrl || null);
  const [imgError, setImgError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 2;
  
  useEffect(() => {
    // Reset state when mandala changes
    setImgSrc(mandala.preview_url || mandala.previewUrl || null);
    setImgError(false);
    setRetryCount(0);
  }, [mandala.preview_url, mandala.previewUrl]);
  
  const handleError = () => {
    if (retryCount < maxRetries && imgSrc) {
      setRetryCount(prev => prev + 1);
      const timestamp = new Date().getTime();
      const newSrc = imgSrc.includes('?') 
        ? `${imgSrc.split('?')[0]}?t=${timestamp}` 
        : `${imgSrc}?t=${timestamp}`;
      
      setImgSrc(newSrc);
    } else {
      setImgError(true);
      setImgSrc(FALLBACK_IMAGE);
    }
  };
  
  const handleLoad = () => {
    // Image loaded successfully
  };
  
  if (!imgSrc || imgError) {
    return (
      <div className="w-full h-full flex items-center justify-center text-muted-foreground">
        <img src={FALLBACK_IMAGE} alt="No preview" className="w-full h-full object-contain opacity-50" />
      </div>
    );
  }
  
  return (
    <img 
      src={imgSrc} 
      alt={`Preview of ${mandala.name}`} 
      className={cn(
        "w-full h-full", 
        size === 'small' ? 'object-cover' : 'object-contain',
        className
      )}
      onLoad={handleLoad}
      onError={handleError}
    />
  );
}

// Sortable item component
function SortableItem({ 
  mandala, 
  index, 
  onRemove
}: { 
  mandala: MandalaInfo; 
  index: number; 
  onRemove: () => void;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: `${mandala.name}-${index}` });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 10 : 1,
    opacity: isDragging ? 0.8 : 1
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={cn(
        "flex items-center justify-between p-3 rounded-lg",
        "bg-card/50 hover:bg-card/80 transition-colors",
        "border border-border group cursor-grab active:cursor-grabbing"
      )}
    >
      <div className="flex items-center gap-3">
        <Badge variant="outline" className="flex-shrink-0 w-5 h-5 p-0 flex items-center justify-center">
          {index + 1}
        </Badge>
        
        <div className="w-8 h-8 rounded-md overflow-hidden border border-border flex-shrink-0 bg-muted">
          <MandalaPreviewImage mandala={mandala} size="small" />
        </div>
        <span className="text-md">{mandala.name}</span>
      </div>
      <Button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onRemove();
        }}
        type="button"
        variant="ghost"
        size="sm"
        className="text-destructive hover:text-destructive hover:bg-destructive/10 flex-shrink-0 h-6 w-6 p-0"
      >
        <X className="h-4 w-4" />
      </Button>
    </div>
  );
}

// Mandala Card component for the available mandalas section
function MandalaCard({ 
  mandala, 
  isSelected, 
  onSelect
}: { 
  mandala: MandalaInfo; 
  isSelected: boolean;
  onSelect: (mandala: MandalaInfo) => void;
}) {
  // Get the layer count properly
  const layerCount = mandala.layerCount || (mandala as any).layer_count || 0;
  
  return (
    <Button
      variant="ghost"
      className={cn(
        "relative aspect-square p-0 overflow-hidden rounded-md w-full h-full",
        "transition-all duration-200",
        isSelected 
          ? "border border-green-500 bg-primary/10" 
          : "opacity-80 hover:opacity-100 border border-border"
      )}
      onClick={() => onSelect(mandala)}
    >
      <MandalaPreviewImage
        mandala={mandala}
        size="large"
        className="w-full h-full object-cover"
      />
      <div className={cn(
        "absolute inset-0 flex flex-col items-center justify-center bg-black/60 opacity-0 transition-opacity",
        "hover:opacity-100"
      )}>
        <span className="text-sm font-doto hover:font-doto-hover">{mandala.name}</span>
        <span className="text-xs text-muted-foreground">{layerCount} layers</span>
      </div>
    </Button>
  );
}

interface TunnelMandalaOverlayProps {
  isOpen: boolean;
  onClose: () => void;
  setLocalTunnelTextures?: (textures: THREE.Texture[]) => void;
  setLocalTunnelMandalas?: (mandalas: MandalaInfo[]) => void;
}

export function TunnelMandalaOverlay({ 
  isOpen, 
  onClose,
  setLocalTunnelTextures,
  setLocalTunnelMandalas
}: TunnelMandalaOverlayProps) {
  const [availableMandalas, setAvailableMandalas] = useState<MandalaInfo[]>([]);
  const [selectedMandalas, setSelectedMandalas] = useState<MandalaInfo[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { isPlaying, setIsPlaying } = useMotionControl();
  const wasPlaying = useState<boolean>(isPlaying)[0];
  
  // Track if changes have been made to the selected mandalas
  const [hasChanges, setHasChanges] = useState(false);
  
  // Current tunnel from store
  const { 
    currentTunnel, 
    setCurrentTunnel, 
    setTextures: setTunnelStoreTextures,
    textures: currentTextures
  } = useTunnelStore();

  // Store original mandalas for change detection
  const originalMandalasRef = useRef<MandalaInfo[]>([]);

  // Set up sensors for drag and drop
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5, // Minimum drag distance for activation
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [animationState, setAnimationState] = useState<'entering' | 'entered' | 'exiting' | 'exited'>('exited');

  useEffect(() => {
    if (isOpen && animationState === 'exited') {
      // Start entering animation
      setAnimationState('entering');
      setTimeout(() => setAnimationState('entered'), 300); // Duration of animation
    } else if (!isOpen && (animationState === 'entered' || animationState === 'entering')) {
      // Start exiting animation
      setAnimationState('exiting');
      // Wait for animation to complete before hiding
      setTimeout(() => setAnimationState('exited'), 300); // Duration of animation
    }
  }, [isOpen, animationState]);

  // Load mandalas and pause tunnel when overlay opens
  useEffect(() => {
    if (isOpen) {
      // Pause the tunnel animation
      setIsPlaying(false);
      
      // Load mandalas
      loadMandalas();
      
      // Initialize selected mandalas from tunnel store
      if (currentTunnel) {
        const mandalasFromTunnel = currentTunnel.mandalas.map((mandala: any) => ({
          id: mandala.id || `mandala-${mandala.name}-${Date.now()}`,
          name: mandala.name || '',
          layerCount: mandala.layerCount || mandala.layers?.length || (mandala as any).layer_count || 0,
          preview_url: mandala.preview_url || '',
          previewUrl: mandala.previewUrl || mandala.preview_url || ''
        }));
        
        setSelectedMandalas(mandalasFromTunnel);
        // Store original mandalas for change detection
        originalMandalasRef.current = [...mandalasFromTunnel];
      }
      
      // Reset changes state
      setHasChanges(false);
    }
    
    // Cleanup function - resume tunnel if it was playing
    return () => {
      if (isOpen && wasPlaying) {
        setIsPlaying(true);
      }
    };
  }, [isOpen, setIsPlaying, currentTunnel, wasPlaying]);
  
  // Check for changes when selected mandalas change
  useEffect(() => {
    if (!isOpen || selectedMandalas.length === 0 || originalMandalasRef.current.length === 0) return;
    
    // Check if the arrays have different lengths
    if (selectedMandalas.length !== originalMandalasRef.current.length) {
      setHasChanges(true);
      return;
    }
    
    // Check if any mandala has been reordered or changed
    const hasOrderChanged = selectedMandalas.some((mandala, index) => {
      const originalMandala = originalMandalasRef.current[index];
      return mandala.name !== originalMandala.name;
    });
    
    setHasChanges(hasOrderChanged);
  }, [selectedMandalas, isOpen]);

  async function loadMandalas() {
    try {
      setIsLoading(true);
      
      const mandalas = await getAllMandalas();
      
      const formattedMandalas = mandalas.map(mandala => ({
        ...mandala,
        id: mandala.id,
        layerCount: mandala.layer_count,
        previewUrl: mandala.preview_url
      }));
      
      setAvailableMandalas(formattedMandalas);
      setError(null);
    } catch (err) {
      console.error('Error loading mandalas:', err);
      setError(err instanceof Error ? err.message : 'Failed to load mandalas');
    } finally {
      setIsLoading(false);
    }
  }

  const handleMandalaSelect = (mandala: MandalaInfo) => {
    const newSelectedMandalas = [...selectedMandalas, mandala];
    setSelectedMandalas(newSelectedMandalas);
    setHasChanges(true);
  };

  const handleMandalaRemove = (index: number) => {
    // Create a new array without the mandala at the specified index
    const updatedMandalas = [...selectedMandalas];
    updatedMandalas.splice(index, 1);
    
    setSelectedMandalas(updatedMandalas);
    setHasChanges(true);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    
    if (over && active.id !== over.id) {
      setSelectedMandalas((items) => {
        const oldIndex = items.findIndex((_, i) => `${items[i].name}-${i}` === active.id);
        const newIndex = items.findIndex((_, i) => `${items[i].name}-${i}` === over.id);
        
        const newOrder = arrayMove(items, oldIndex, newIndex);
        setHasChanges(true);
        return newOrder;
      });
    }
  };
  
  const handleSaveAndClose = async () => {
    if (selectedMandalas.length === 0) {
      setError('Please select at least one mandala');
      return;
    }

    try {
      // Set loading state
      setIsLoading(true);
      
      // Update the current tunnel with the new mandalas
      if (currentTunnel) {
        // Convert to proper Mandala type for the tunnel store
        const tunnelMandalas = selectedMandalas.map(info => ({
          id: info.id || `mandala-${info.name}-${Date.now()}`,
          name: info.name,
          layerCount: info.layerCount || (info as any).layer_count || 0,
          preview_url: info.preview_url,
          previewUrl: info.previewUrl || info.preview_url,
          layers: [], // Still need empty layers for type compatibility
          separation: 1.0
        }));
        
        // Load all the necessary textures for the selected mandalas
        const newTextures = await Promise.all(
          selectedMandalas.map(mandala => loadMandalaTextures(mandala.name))
        );
        
        // Flatten the array of texture arrays
        const flattenedTextures = newTextures.flat();
        
        // Update the textures in the store
        setTunnelStoreTextures(flattenedTextures);
        
        // Also update local textures if a setter was provided
        if (setLocalTunnelTextures) {
          setLocalTunnelTextures(flattenedTextures);
        }
        
        // Also update local mandalas if a setter was provided
        if (setLocalTunnelMandalas) {
          setLocalTunnelMandalas(selectedMandalas);
        }
        
        // Create updated tunnel object
        const updatedTunnel = {
          ...currentTunnel,
          mandalas: tunnelMandalas
        };
        
        // Update the store
        setCurrentTunnel(updatedTunnel);
        
        // Show toast notification
        toast({
          title: "Success",
          description: "Tunnel configuration updated",
          variant: "default"
        });
      }
    } catch (error) {
      console.error("Error updating tunnel:", error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to update tunnel",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
      
      // Resume the tunnel if it was playing
      if (wasPlaying) {
        setIsPlaying(true);
      }
      
      // Close the overlay
      onClose();
    }
  };
  
  const isSelectedMandala = (mandala: MandalaInfo) => {
    return selectedMandalas.some(selected => selected.name === mandala.name);
  };
  
  const handleToggleSelect = (mandala: MandalaInfo) => {
    if (isSelectedMandala(mandala)) {
      // Remove mandala
      const index = selectedMandalas.findIndex(selected => selected.name === mandala.name);
      if (index !== -1) {
        handleMandalaRemove(index);
      }
    } else {
      // Add mandala
      handleMandalaSelect(mandala);
    }
  };

  // Don't render anything if completely exited
  if (animationState === 'exited') return null;

  return (
    <div 
      className={`fixed inset-0 z-50 flex items-center justify-center transition-all duration-300 ease-in-out ${
        animationState === 'entered' || animationState === 'entering' 
          ? 'bg-background/90 backdrop-blur-sm opacity-100'
          : 'bg-background/0 backdrop-blur-none opacity-0'
      }`}
    >
      {/* Return/Apply and Cancel buttons */}
      <div className={`fixed top-4 right-4 z-[100] flex gap-4 transition-transform duration-300 ${
        animationState === 'entered' ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'
      }`}>
        {/* Cancel button - only shown when changes have been made */}
        {hasChanges && (
          <Button
            className="font-doto text-white bg-transparent hover:bg-background/50"
            onClick={() => {
              // Resume the tunnel if it was playing
              if (wasPlaying) {
                setIsPlaying(true);
              }
              // Close the overlay without saving changes
              onClose();
            }}
            style={{ fontSize: '1.2rem' }}
          >
            cancel
          </Button>
        )}
        <Button
          className="font-doto text-white bg-transparent hover:bg-background/50"
          onClick={handleSaveAndClose}
          style={{ fontSize: '1.2rem' }}
        >
          {hasChanges ? "apply" : "return"}
        </Button>
      </div>
      
      <div className={`flex flex-col md:flex-row gap-6 w-[90vw] h-[80vh] transition-all duration-300 ease-in-out ${
        animationState === 'entered' ? 'scale-100 opacity-100' : 'scale-95 opacity-0'
      }`}>
        {/* Left: Mandala selection */}
        <div className="w-full md:w-3/5 h-full overflow-hidden">
          <Card className="h-full bg-card/80 backdrop-blur border-border">
            <div className="p-4 font-doto" style={{ fontSize: "1.2rem" }}>Select</div>
            
            <CardContent className="p-4 h-[calc(100%-3rem)] overflow-hidden">
              {isLoading ? (
                <div className="flex justify-center items-center h-full">
                  <Loader className="text-primary" text="loading..." />
                </div>
              ) : error ? (
                <div className="text-destructive p-4 bg-destructive/10 rounded-md border border-destructive/30">
                  <p className="font-medium">Error loading mandalas:</p>
                  <p>{error}</p>
                  <Button 
                    onClick={() => loadMandalas()} 
                    variant="outline" 
                    className="mt-4"
                  >
                    Try Again
                  </Button>
                </div>
              ) : (
                <ScrollArea className="h-full pr-4 pb-6">
                  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 pb-8">
                    {availableMandalas.length === 0 ? (
                      <div className="text-center p-4 bg-muted rounded-md border border-border col-span-full">
                        <p className="text-muted-foreground mb-2">No mandalas found</p>
                      </div>
                    ) : (
                      availableMandalas.map((mandala) => (
                        <MandalaCard
                          key={mandala.name}
                          mandala={mandala}
                          isSelected={isSelectedMandala(mandala)}
                          onSelect={() => handleToggleSelect(mandala)}
                        />
                      ))
                    )}
                  </div>
                </ScrollArea>
              )}
            </CardContent>
          </Card>
        </div>
        
        {/* Right: Selected mandalas */}
        <div className="w-full md:w-2/5 h-full overflow-hidden">
          <Card className="h-full bg-card/80 backdrop-blur border-border">
            <div className="p-4 font-doto" style={{ fontSize: "1.2rem" }}>Sort</div>
            
            <CardContent className="p-4 h-[calc(100%-3rem)]">
              <ScrollArea className="h-full pr-4">
                <DndContext 
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext 
                    items={selectedMandalas.map((mandala, index) => `${mandala.name}-${index}`)}
                    strategy={verticalListSortingStrategy}
                  >
                    <div className="space-y-3 pb-6">
                      {selectedMandalas.map((mandala, index) => (
                        <SortableItem
                          key={`${mandala.name}-${index}`}
                          mandala={mandala}
                          index={index}
                          onRemove={() => handleMandalaRemove(index)}
                        />
                      ))}
                    </div>
                  </SortableContext>
                </DndContext>
                {selectedMandalas.length === 0 && (
                  <div className="flex flex-col items-center justify-center h-48 text-center p-4 bg-muted rounded-lg border border-border">
                    <p className="text-muted-foreground mb-2">No mandalas selected</p>
                  </div>
                )}
              </ScrollArea>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
} 