import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAllMandalas, clearCache } from '../services/mandalaService';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
// Import individual components directly if needed for TypeScript resolution
import { Badge } from './ui/badge';
import { 
  Card, 
  CardContent, 
  CardHeader, 
  CardTitle,
  CardDescription,
  CardFooter,
  Button,
  ScrollArea,
  Loader
} from './ui';
import { DEFAULT_SPEED, DEFAULT_ROTATION_SPEED } from '../store/tunnelStore';
import { cn } from '../lib/utils';
import { X, Settings, RefreshCw } from 'lucide-react';

// Custom styles for dark scrollbars
import './scrollbar-dark.css';

interface MandalaInfo {
  id: string;
  name: string;
  layerCount: number;
  previewUrl?: string | null;
  layer_count: number;
  preview_url: string;
}

// Fallback image for mandalas without previews
const FALLBACK_IMAGE = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGNpcmNsZSBjeD0iNTAiIGN5PSI1MCIgcj0iNDUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1vcGFjaXR5PSIwLjIiIHN0cm9rZS13aWR0aD0iMiIvPgogIDxwYXRoIGQ9Ik01MCAyNUw2Mi41IDQ1TDc1IDY1SDUwSDI1TDM3LjUgNDVMNTAgMjVaIiBzdHJva2U9IndoaXRlIiBzdHJva2Utb3BhY2l0eT0iMC4yIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==';

// Helper function to render mandala preview image
function MandalaPreviewImage({ mandala, size = 'small', className = '' }: { 
  mandala: MandalaInfo, 
  size?: 'small' | 'large',
  className?: string 
}) {
  const [imgSrc, setImgSrc] = useState<string | null>(mandala.preview_url || mandala.previewUrl || null);
  const [imgError, setImgError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 2;
  
  useEffect(() => {
    // Reset state when mandala changes
    setImgSrc(mandala.preview_url || mandala.previewUrl || null);
    setImgError(false);
    setRetryCount(0);
  }, [mandala.preview_url, mandala.previewUrl]);
  
  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    console.error(`Error loading ${size} preview for ${mandala.name}:`, e);
    
    // Try to reload the image with a cache-busting parameter
    if (retryCount < maxRetries && imgSrc) {
      setRetryCount(prev => prev + 1);
      const timestamp = new Date().getTime();
      const newSrc = imgSrc.includes('?') 
        ? `${imgSrc.split('?')[0]}?t=${timestamp}` 
        : `${imgSrc}?t=${timestamp}`;
      
      console.log(`Retrying with new URL (attempt ${retryCount + 1}/${maxRetries}): ${newSrc}`);
      setImgSrc(newSrc);
    } else {
      setImgError(true);
      setImgSrc(FALLBACK_IMAGE);
    }
  };
  
  const handleLoad = () => {
    console.log(`${size} preview loaded for ${mandala.name}`);
  };
  
  if (!imgSrc || imgError) {
    return (
      <div className="w-full h-full flex items-center justify-center text-muted-foreground">
        <img src={FALLBACK_IMAGE} alt="No preview" className="w-full h-full object-contain opacity-50" />
      </div>
    );
  }
  
  return (
    <img 
      src={imgSrc} 
      alt={`Preview of ${mandala.name}`} 
      className={cn(
        "w-full h-full", 
        size === 'small' ? 'object-cover' : 'object-contain',
        className
      )}
      onLoad={handleLoad}
      onError={handleError}
    />
  );
}

// Sortable item component
function SortableItem({ 
  mandala, 
  index, 
  onRemove
}: { 
  mandala: MandalaInfo; 
  index: number; 
  onRemove: () => void;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: `${mandala.name}-${index}` });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 10 : 1,
    opacity: isDragging ? 0.8 : 1
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={cn(
        "flex items-center justify-between p-3 rounded-lg",
        "bg-card/50 hover:bg-card/80 transition-colors",
        "border border-border group cursor-grab active:cursor-grabbing"
      )}
    >
      <div className="flex items-center gap-3">
        <Badge variant="outline" className="flex-shrink-0 w-5 h-5 p-0 flex items-center justify-center">
          {index + 1}
        </Badge>
        
        <div className="w-8 h-8 rounded-md overflow-hidden border border-border flex-shrink-0 bg-muted">
          <MandalaPreviewImage mandala={mandala} size="small" />
        </div>
        <span className="text-md">{mandala.name}</span>
      </div>
      <Button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onRemove();
        }}
        type="button"
        variant="ghost"
        size="sm"
        className="text-destructive hover:text-destructive hover:bg-destructive/10 flex-shrink-0 h-6 w-6 p-0"
      >
        <X className="h-4 w-4" />
      </Button>
    </div>
  );
}

// Mandala Card component for the available mandalas section
function MandalaCard({ 
  mandala, 
  isSelected, 
  onSelect
}: { 
  mandala: MandalaInfo; 
  isSelected: boolean;
  onSelect: (mandala: MandalaInfo) => void;
}) {
  const navigate = useNavigate();

  const viewMandala = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering the select function
    navigate(`/${mandala.name}`);
  };

  return (
    <Button
      variant="ghost"
      className={cn(
        "relative aspect-square p-0 overflow-hidden rounded-md w-full h-full",
        "transition-all duration-200",
        isSelected 
          ? "border border-green-500 bg-primary/10" 
          : "opacity-80 hover:opacity-100 border border-border"
      )}
      onClick={() => onSelect(mandala)}
    >
      <MandalaPreviewImage
        mandala={mandala}
        size="large"
        className="w-full h-full object-cover"
      />
      <div className={cn(
        "absolute inset-0 flex flex-col items-center justify-center bg-black/60 opacity-0 transition-opacity",
        "hover:opacity-100"
      )}>
        <span className="text-sm font-doto hover:font-doto-hover">{mandala.name}</span>
        <span className="text-xs text-muted-foreground">{mandala.layer_count} layers</span>
        
        <div className="absolute bottom-2 right-2">
          <Button 
            size="sm" 
            variant="outline" 
            className="h-7 bg-black/40 text-xs px-2 border-white/20 hover:bg-black/80"
            onClick={viewMandala}
          >
            View
          </Button>
        </div>
      </div>
    </Button>
  );
}

export function MandalaSelector() {
  const [availableMandalas, setAvailableMandalas] = useState<MandalaInfo[]>([]);
  const [selectedMandalas, setSelectedMandalas] = useState<MandalaInfo[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Set up sensors for drag and drop
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5, // Minimum drag distance for activation
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // Check for existing selected mandalas (when returning from tunnel)
  useEffect(() => {
    if (location.state?.selectedMandalas) {
      console.log("Restoring mandalas from tunnel:", location.state.selectedMandalas);
      
      // Ensure all mandala objects have the required properties
      // This is needed when coming from a random tunnel in MandalaPage
      const normalizedMandalas = location.state.selectedMandalas.map((mandala: any) => ({
        id: mandala.id || `mandala-${mandala.name}-${Date.now()}`, // Generate ID if missing
        name: mandala.name,
        layerCount: mandala.layerCount || mandala.layer_count || 0,
        previewUrl: mandala.previewUrl || mandala.preview_url || null,
        layer_count: mandala.layer_count || mandala.layerCount || 0,
        preview_url: mandala.preview_url || mandala.previewUrl || null
      }));
      
      setSelectedMandalas(normalizedMandalas);
    }
  }, [location.state]);

  async function loadMandalas(forceRefresh = false) {
    try {
      setIsLoading(true);
      
      const mandalas = await getAllMandalas(forceRefresh);
      console.log('Loaded mandalas from database:', mandalas);
      
      const formattedMandalas = mandalas.map(mandala => ({
        ...mandala,
        id: mandala.id,
        layerCount: mandala.layer_count,
        previewUrl: mandala.preview_url
      }));
      
      setAvailableMandalas(formattedMandalas);
      setError(null);
    } catch (err) {
      console.error('Error loading mandalas:', err);
      setError(err instanceof Error ? err.message : 'Failed to load mandalas');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    loadMandalas();
  }, []);

  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);
      await clearCache();
      await loadMandalas(true);
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleMandalaSelect = (mandala: MandalaInfo) => {
    setSelectedMandalas([...selectedMandalas, mandala]);
  };

  const handleMandalaRemove = (index: number) => {
    console.log('Removing mandala at index:', index);
    
    // Create a new array without the mandala at the specified index
    const updatedMandalas = [...selectedMandalas];
    updatedMandalas.splice(index, 1);
    
    setSelectedMandalas(updatedMandalas);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    
    if (over && active.id !== over.id) {
      setSelectedMandalas((items) => {
        const oldIndex = items.findIndex((_, i) => `${items[i].name}-${i}` === active.id);
        const newIndex = items.findIndex((_, i) => `${items[i].name}-${i}` === over.id);
        
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const handleStartTunnel = () => {
    if (selectedMandalas.length === 0) {
      setError('Please select at least one mandala');
      return;
    }

    // Navigate to tunnel view with selected mandalas and initial parameters
    navigate('/tunnel', { 
      state: { 
        mandalas: selectedMandalas,
        initialConfig: {
          speed: DEFAULT_SPEED,
          rotationSpeed: 0
        }
      } 
    });
  };
  
  const isSelectedMandala = (mandala: MandalaInfo) => {
    return selectedMandalas.some(selected => selected.name === mandala.name);
  };
  
  const handleToggleSelect = (mandala: MandalaInfo) => {
    if (isSelectedMandala(mandala)) {
      // Remove mandala
      const index = selectedMandalas.findIndex(selected => selected.name === mandala.name);
      if (index !== -1) {
        handleMandalaRemove(index);
      }
    } else {
      // Add mandala
      handleMandalaSelect(mandala);
    }
  };

  if (isLoading && !isRefreshing) {
    return (
      <div className="flex items-center justify-center h-screen bg-background">
        <Loader className="text-primary" text="loading..." />
      </div>
    );
  }

  return (
    <div className="h-screen bg-background text-foreground dark-scrollbar overflow-hidden flex flex-col">
      {/* Header with buttons */}
      <header className="w-full flex items-center p-4 border-b border-border bg-card/40 relative">
        {/* Buttons on the right */}
        <div className="flex items-center gap-2 absolute right-4">
          <Button
            size="icon" 
            variant="outline"
            className={cn(
              "h-8 w-8 text-muted-foreground",
              isRefreshing && "animate-spin"
            )}
            onClick={handleRefresh}
            disabled={isRefreshing}
            title="Refresh Mandalas"
          >
            <RefreshCw className="h-4 w-4" />
          </Button>
          
          <Button
            size="icon" 
            variant="outline"
            className="h-8 w-8 text-muted-foreground"
            onClick={() => navigate('/admin')}
            title="Admin Settings"
          >
            <Settings className="h-4 w-4" />
          </Button>
          
          <Button
            onClick={handleStartTunnel}
            disabled={selectedMandalas.length === 0}
            variant="default"
          >
            {location.state?.returnFromTunnel ? "Return to Tunnel" : "Start"}
          </Button>
        </div>
      </header>
      
      {/* Main content */}
      <div className="flex-1 container mx-auto p-4 flex flex-col md:flex-row gap-6 overflow-hidden">
        {/* Main area - Mandala selection grid */}
        <div className="w-full md:w-3/4 h-full overflow-hidden">
          <Card className="h-full border-border">
            <CardContent className="p-4 h-full overflow-hidden">
              {isLoading && isRefreshing ? (
                <div className="flex justify-center items-center h-full">
                  <Loader className="text-primary" text="refreshing..." />
                </div>
              ) : error ? (
                <div className="text-destructive p-4 bg-destructive/10 rounded-md border border-destructive/30">
                  <p className="font-medium">Error loading mandalas:</p>
                  <p>{error}</p>
                  <Button 
                    onClick={() => loadMandalas(true)} 
                    variant="outline" 
                    className="mt-4"
                  >
                    Try Again
                  </Button>
                </div>
              ) : (
                <ScrollArea className="h-full dark-scrollbar pr-4 pb-6">
                  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 pb-8">
                    {availableMandalas.length === 0 ? (
                      <div className="text-center p-4 bg-muted rounded-md border border-border col-span-full">
                        <p className="text-muted-foreground mb-2">No mandalas found</p>
                        <p className="text-sm text-muted-foreground/70">
                          Please check your Supabase storage to ensure mandalas are properly uploaded.
                        </p>
                      </div>
                    ) : (
                      availableMandalas.map((mandala) => (
                        <MandalaCard
                          key={mandala.name}
                          mandala={mandala}
                          isSelected={isSelectedMandala(mandala)}
                          onSelect={() => handleToggleSelect(mandala)}
                        />
                      ))
                    )}
                  </div>
                </ScrollArea>
              )}
            </CardContent>
          </Card>
        </div>
        
        {/* Sidebar - Selected mandalas */}
        <div className="w-full md:w-1/4 h-full overflow-hidden">
          <Card className="h-full border-border">
            <CardContent className="p-4 h-full">
              <ScrollArea className="h-full dark-scrollbar pr-4">
                <DndContext 
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext 
                    items={selectedMandalas.map((mandala, index) => `${mandala.name}-${index}`)}
                    strategy={verticalListSortingStrategy}
                  >
                    <div className="space-y-3 pb-6">
                      {selectedMandalas.map((mandala, index) => (
                        <SortableItem
                          key={`${mandala.name}-${index}`}
                          mandala={mandala}
                          index={index}
                          onRemove={() => handleMandalaRemove(index)}
                        />
                      ))}
                    </div>
                  </SortableContext>
                </DndContext>
                {selectedMandalas.length === 0 && (
                  <div className="flex flex-col items-center justify-center h-48 text-center">
                    {/* Empty state - visible but no text */}
                  </div>
                )}
              </ScrollArea>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
} 