// This file has been refactored to use a modular approach
// The implementation is now in the Bird directory
import { Bird as BirdComponent } from './Bird/index';
export { useBirdStore } from './Bird/BirdStore';

// Create the component
function BirdWrapper(props: React.ComponentProps<typeof BirdComponent>) {
  return <BirdComponent {...props} />;
}

// Export both as named and default export
export { BirdWrapper as Bird };
export default BirdWrapper; 