import { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import * as Collapsible from '@radix-ui/react-collapsible';
import { Switch, Slider, Tabs, TabsList, TabsTrigger, TabsContent } from './ui';
import { useTunnelStore } from '../store/tunnelStore';
import { Card, CardContent } from './ui/card';
import { DeformationSettings } from './ControlPanel/DeformationControls';

// Add a CSS class for section containers and sliders
const sectionContainerClass = "bg-muted/30 rounded-md p-4 border border-border";
const sliderActiveClass = "[&_[role=slider]]:h-4 [&_[role=slider]]:w-4 [&_.bg-primary]:bg-primary";

// Helper type for update functions
type DeformationUpdateFn = (property: string, value: number | boolean) => void;

// Helper component for deformation controls
interface DeformationControlProps {
  title: string;
  effect: any;
  updateEffect: DeformationUpdateFn;
  controls: {
    name: string;
    label: string;
    min: number;
    max: number;
    step: number;
    defaultValue?: number;
  }[];
}

function DeformationControl({ title, effect, updateEffect, controls }: DeformationControlProps) {
  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between pt-2">
        <label className="text-sm font-medium">{title}</label>
        <Switch 
          checked={effect.enabled}
          onCheckedChange={(checked) => {
            // Call updateEffect with the enabled property change
            updateEffect('enabled', checked);
          }}
        />
      </div>
      
      {effect.enabled && (
        <>
          {controls.map((control) => (
            <div className="space-y-2" key={control.name}>
              <div className="flex items-center justify-between">
                <label className="text-xs text-muted-foreground">{control.label}</label>
                <span className="text-xs text-muted-foreground">
                  {typeof effect[control.name] === 'number' 
                    ? effect[control.name].toFixed(2) 
                    : control.defaultValue?.toFixed(2) || '0.00'}
                </span>
              </div>
              <Slider
                value={[typeof effect[control.name] === 'number' ? effect[control.name] : (control.defaultValue || 0)]}
                min={control.min}
                max={control.max}
                step={control.step}
                onValueChange={(values) => updateEffect(control.name, values[0])}
                className={sliderActiveClass}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
}

interface MandalaDeformationCardProps {
  isVisible: boolean;
  onClose: () => void;
}

export function MandalaDeformationCard({ onClose }: MandalaDeformationCardProps) {
  const [activeTab, setActiveTab] = useState("basic");
  
  // Get deformation settings from store
  const { 
    deformation,
    setDeformation
  } = useTunnelStore();

  // Generic update function for any deformation effect
  const updateDeformationSettings = (
    effectName: keyof DeformationSettings, 
    property: string, 
    value: number | boolean
  ) => {
    setDeformation({
      ...deformation,
      [effectName]: {
        ...deformation[effectName],
        [property]: value
      }
    });
  };

  // Effect update functions
  const updateWaveSettings = (property: string, value: number | boolean) => {
    updateDeformationSettings('wave', property, value);
  };

  const updateRippleSettings = (property: string, value: number | boolean) => {
    updateDeformationSettings('ripple', property, value);
  };

  const updateKaleidoscopeSettings = (property: string, value: number | boolean) => {
    updateDeformationSettings('kaleidoscope', property, value);
  };

  const updateTrailSettings = (property: string, value: number | boolean) => {
    updateDeformationSettings('trail', property, value);
  };

  const updateTwistSettings = (property: string, value: number | boolean) => {
    updateDeformationSettings('twist', property, value);
  };

  const updatePulseSettings = (property: string, value: number | boolean) => {
    updateDeformationSettings('pulse', property, value);
  };
  
  // New effect update functions
  const updateFractalSettings = (property: string, value: number | boolean) => {
    updateDeformationSettings('fractal', property, value);
  };
  
  const updateLiquidSettings = (property: string, value: number | boolean) => {
    updateDeformationSettings('liquid', property, value);
  };
  
  const updatePrismSettings = (property: string, value: number | boolean) => {
    updateDeformationSettings('prism', property, value);
  };
  
  const updateSpiralSettings = (property: string, value: number | boolean) => {
    updateDeformationSettings('spiral', property, value);
  };
  
  const updateVortexSettings = (property: string, value: number | boolean) => {
    updateDeformationSettings('vortex', property, value);
  };

  const updateBloomSettings = (property: string, value: number | boolean) => {
    // Update deformation settings
    updateDeformationSettings('bloom', property, value);
    
    // Special handling for bloom effect - sync with post-processing
    const { postProcessing, setPostProcessing } = useTunnelStore.getState();
    const newPostProcessing = { ...postProcessing };
    
    // Only sync these specific properties that affect post-processing
    if (property === 'enabled') {
      // When toggling enabled state, preserve all existing post-processing bloom settings
      // but override the enabled state
      newPostProcessing.bloom = {
        ...newPostProcessing.bloom,
        enabled: value as boolean,
        // Only set strength to default when enabling, otherwise preserve it
        strength: value ? (newPostProcessing.bloom?.strength || 3.0) : 0.0,
      };
      setPostProcessing(newPostProcessing);
    } else if (property === 'intensity') {
      newPostProcessing.bloom = {
        ...newPostProcessing.bloom,
        strength: value as number
      };
      setPostProcessing(newPostProcessing);
    } else if (property === 'radius') {
      newPostProcessing.bloom = {
        ...newPostProcessing.bloom,
        radius: value as number
      };
      setPostProcessing(newPostProcessing);
    } else if (property === 'threshold') {
      newPostProcessing.bloom = {
        ...newPostProcessing.bloom,
        threshold: value as number
      };
      setPostProcessing(newPostProcessing);
    }
  };

  const updateToneMappingSettings = (property: string, value: number | boolean) => {
    updateDeformationSettings('toneMapping', property, value);
  };

  return (
    <Card className="border border-border/50 bg-black/50 backdrop-blur-md shadow-xl z-30">
      <CardContent className="px-5 pb-5 pt-7 rounded-md">
        <div className="space-y-7">
          <DeformationControl
            title="Ripple"
            effect={deformation.ripple}
            updateEffect={updateRippleSettings}
            controls={[
              { name: 'strength', label: 'Strength', min: 0, max: 1, step: 0.01 },
              { name: 'frequency', label: 'Frequency', min: 0, max: 10, step: 0.1 },
              { name: 'speed', label: 'Speed', min: 0, max: 5, step: 0.1 }
            ]}
          />
          
          <DeformationControl
            title="Liquid"
            effect={deformation.liquid}
            updateEffect={updateLiquidSettings}
            controls={[
              { name: 'viscosity', label: 'Viscosity', min: 0.1, max: 2, step: 0.1 },
              { name: 'turbulence', label: 'Turbulence', min: 0, max: 1, step: 0.01 },
              { name: 'speed', label: 'Speed', min: 0, max: 1, step: 0.01 }
            ]}
          />
          
          <DeformationControl
            title="Bloom"
            effect={deformation.bloom}
            updateEffect={updateBloomSettings}
            controls={[
              { name: 'intensity', label: 'Intensity', min: 0, max: 10, step: 0.1 },
              { name: 'threshold', label: 'Threshold', min: 0, max: 1, step: 0.01 },
              { name: 'radius', label: 'Radius', min: 0.1, max: 2, step: 0.1 },
              { name: 'smoothing', label: 'Smoothing', min: 0, max: 1, step: 0.01 },
              { name: 'kernelSize', label: 'Kernel Size', min: 1, max: 8, step: 1 },
              { name: 'blend', label: 'Blend', min: 0, max: 1, step: 0.01 },
              { name: 'saturation', label: 'Saturation', min: 0, max: 2, step: 0.05 },
              { name: 'highlight', label: 'Highlight', min: 0, max: 2, step: 0.05 }
            ]}
          />
        </div>
      </CardContent>
    </Card>
  );
} 