import * as THREE from 'three';
import { DeformationSettings } from '../components/ControlPanel/DeformationControls';

// Shader constants
const vertexShader = `
  varying vec2 vUv;

  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

// Basic shader with time uniform for animations
const baseFragmentShader = `
  uniform sampler2D baseTexture;
  uniform float time;
  varying vec2 vUv;

  void main() {
    vec4 color = texture2D(baseTexture, vUv);
    gl_FragColor = color;
  }
`;

// Wave effect shader
const waveFragmentShader = `
  uniform sampler2D baseTexture;
  uniform float time;
  uniform float amplitude;
  uniform float frequency;
  uniform float speed;
  varying vec2 vUv;

  void main() {
    // Calculate distorted UVs
    vec2 uv = vUv;
    uv.y += sin(uv.x * frequency + time * speed) * amplitude;
    
    // Sample texture with distorted UVs
    vec4 color = texture2D(baseTexture, uv);
    gl_FragColor = color;
  }
`;

// Ripple effect shader
const rippleFragmentShader = `
  uniform sampler2D baseTexture;
  uniform float time;
  uniform float strength;
  uniform float frequency;
  uniform float speed;
  varying vec2 vUv;

  void main() {
    // Calculate center point
    vec2 center = vec2(0.5, 0.5);
    float dist = distance(vUv, center);
    
    // Calculate multi-layered ripple effect for more complex and interesting visuals
    float primaryRipple = sin(dist * frequency - time * speed) * strength;
    float secondaryRipple = sin(dist * frequency * 1.5 - time * speed * 0.8) * strength * 0.4;
    float combinedRipple = primaryRipple + secondaryRipple;
    
    // Add smooth falloff for ripple effect
    float falloff = smoothstep(1.0, 0.0, dist * 2.0);
    
    // Add distortion to UVs
    vec2 uv = vUv + normalize(vUv - center) * combinedRipple * falloff;
    
    // Sample texture with distorted UVs
    vec4 color = texture2D(baseTexture, uv);
    
    // Boost brightness to prevent darkening (1.2 = 20% boost)
    color.rgb *= 1.2;
    
    // Keep within proper range
    color.rgb = clamp(color.rgb, 0.0, 1.0);
    
    gl_FragColor = color;
  }
`;

// Kaleidoscope effect shader
const kaleidoscopeFragmentShader = `
  uniform sampler2D baseTexture;
  uniform float time;
  uniform float segments;
  uniform float rotation;
  varying vec2 vUv;

  void main() {
    // Move to center coordinates for easier rotation
    vec2 uv = vUv - 0.5;
    float r = length(uv);
    float theta = atan(uv.y, uv.x) + rotation * time;
    
    // Apply kaleidoscope by repeating across segments
    float segmentAngle = 2.0 * 3.14159 / segments;
    theta = mod(theta, segmentAngle);
    if (mod(floor(theta / segmentAngle), 2.0) >= 1.0) {
      theta = segmentAngle - theta;
    }
    
    // Convert back to Cartesian coordinates
    vec2 newUv = vec2(r * cos(theta), r * sin(theta)) + 0.5;
    
    // Sample texture with kaleidoscope UVs
    vec4 color = texture2D(baseTexture, newUv);
    
    // Boost brightness to prevent darkening (1.2 = 20% boost)
    color.rgb *= 1.2;
    
    // Keep within proper range
    color.rgb = clamp(color.rgb, 0.0, 1.0);
    
    gl_FragColor = color;
  }
`;

// Trail effect shader
const trailFragmentShader = `
  uniform sampler2D baseTexture;
  uniform sampler2D prevTexture;
  uniform float time;
  uniform float length;
  uniform float opacity;
  uniform float decay;
  varying vec2 vUv;

  void main() {
    // Get current texture
    vec4 currentColor = texture2D(baseTexture, vUv);
    
    // Get previous frame with slight offset (creates trail)
    vec2 trailOffset = vec2(length * 0.01, 0.0);
    vec4 prevColor = texture2D(prevTexture, vUv - trailOffset);
    
    // Blend current with previous frame
    vec4 finalColor = currentColor + prevColor * decay * opacity;
    
    gl_FragColor = finalColor;
  }
`;

// Twist effect shader
const twistFragmentShader = `
  uniform sampler2D baseTexture;
  uniform float time;
  uniform float strength;
  uniform float radius;
  varying vec2 vUv;

  void main() {
    // Calculate center point (centered on texture)
    vec2 center = vec2(0.5, 0.5);
    
    // Get vector from center to current pixel
    vec2 toCenter = center - vUv;
    float dist = length(toCenter);
    
    // Calculate twist angle based on distance from center
    float angle = strength * (1.0 - smoothstep(0.0, radius, dist));
    
    // Rotate UVs
    float s = sin(angle);
    float c = cos(angle);
    vec2 rotated = vec2(
      c * toCenter.x - s * toCenter.y,
      s * toCenter.x + c * toCenter.y
    );
    
    // Sample texture with rotated UVs
    vec2 uv = center - rotated;
    vec4 color = texture2D(baseTexture, uv);
    
    // Boost brightness to prevent darkening (1.2 = 20% boost)
    color.rgb *= 1.2;
    
    // Keep within proper range
    color.rgb = clamp(color.rgb, 0.0, 1.0);
    
    gl_FragColor = color;
  }
`;

// Pulse effect shader
const pulseFragmentShader = `
  uniform sampler2D baseTexture;
  uniform float time;
  uniform float frequency;
  uniform float amplitude;
  varying vec2 vUv;

  void main() {
    // Center UVs
    vec2 uv = vUv - 0.5;
    
    // Calculate distance from center
    float dist = length(uv);
    
    // Calculate pulse scaling factor
    float scale = 1.0 + sin(time * frequency) * amplitude;
    
    // Apply scaling to UVs
    uv = uv / scale;
    
    // Move back to 0-1 range
    uv += 0.5;
    
    // Sample texture with pulsed UVs
    vec4 color = texture2D(baseTexture, uv);
    gl_FragColor = color;
  }
`;

// Fractal effect shader
const fractalFragmentShader = `
  uniform sampler2D baseTexture;
  uniform float time;
  uniform float iterations;
  uniform float scale;
  uniform float rotation;
  varying vec2 vUv;

  void main() {
    // Center UVs
    vec2 uv = vUv - 0.5;
    vec2 originalUV = uv;
    
    // Add time-based rotation
    float angle = rotation * time * 0.2;
    float c = cos(angle);
    float s = sin(angle);
    
    // Color accumulation
    vec4 color = vec4(0.0);
    float totalWeight = 0.0;
    
    // Apply fractal iterations
    for (float i = 0.0; i < 10.0; i++) {
      if (i >= iterations) break;
      
      // Rotate and scale
      vec2 rotatedUV = vec2(
        uv.x * c - uv.y * s,
        uv.x * s + uv.y * c
      );
      
      // Scale for next iteration
      uv = rotatedUV * scale;
      
      // Calculate weight for this iteration
      float weight = 1.0 / (i + 1.0);
      totalWeight += weight;
      
      // Sample with transformed UVs
      vec2 sampleUV = uv + 0.5;
      if (sampleUV.x >= 0.0 && sampleUV.x <= 1.0 && sampleUV.y >= 0.0 && sampleUV.y <= 1.0) {
        color += texture2D(baseTexture, sampleUV) * weight;
      }
    }
    
    // Normalize by total weight
    if (totalWeight > 0.0) {
      color /= totalWeight;
    } else {
      color = texture2D(baseTexture, vUv);
    }
    
    gl_FragColor = color;
  }
`;

// Liquid effect shader
const liquidFragmentShader = `
  uniform sampler2D baseTexture;
  uniform float time;
  uniform float viscosity;
  uniform float turbulence;
  uniform float speed;
  varying vec2 vUv;

  // Simplex noise function
  vec3 mod289(vec3 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
  vec2 mod289(vec2 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
  vec3 permute(vec3 x) { return mod289(((x*34.0)+1.0)*x); }

  float snoise(vec2 v) {
    const vec4 C = vec4(0.211324865405187, 0.366025403784439,
             -0.577350269189626, 0.024390243902439);
    vec2 i  = floor(v + dot(v, C.yy));
    vec2 x0 = v -   i + dot(i, C.xx);
    vec2 i1;
    i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
    vec4 x12 = x0.xyxy + C.xxzz;
    x12.xy -= i1;
    i = mod289(i);
    vec3 p = permute(permute(i.y + vec3(0.0, i1.y, 1.0))
    + i.x + vec3(0.0, i1.x, 1.0));
    vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy), dot(x12.zw,x12.zw)), 0.0);
    m = m*m;
    m = m*m;
    vec3 x = 2.0 * fract(p * C.www) - 1.0;
    vec3 h = abs(x) - 0.5;
    vec3 ox = floor(x + 0.5);
    vec3 a0 = x - ox;
    m *= 1.79284291400159 - 0.85373472095314 * (a0*a0 + h*h);
    vec3 g;
    g.x  = a0.x  * x0.x  + h.x  * x0.y;
    g.yz = a0.yz * x12.xz + h.yz * x12.yw;
    return 130.0 * dot(m, g);
  }

  void main() {
    // Create fluid distortion with enhanced effect
    float t = time * speed * 1.2; // Increased speed
    
    // Layered noise for more complex movement
    float noise1 = snoise(vUv * viscosity + vec2(t * 0.1, t * 0.3)) * turbulence * 1.3;
    float noise2 = snoise(vUv * viscosity * 2.0 + vec2(t * -0.1, t * 0.2)) * turbulence * 1.3;
    float noise3 = snoise(vUv * viscosity * 0.5 + vec2(t * 0.2, t * -0.1)) * turbulence * 0.7;
    
    // Combine noise layers for more complex distortion
    vec2 uv = vUv + vec2(noise1 + noise3 * 0.5, noise2 + noise3 * 0.3) * (1.0 - viscosity * 0.7);
    
    // Sample texture with distorted UVs
    vec4 color = texture2D(baseTexture, uv);
    
    // Boost contrast slightly to make distortion more visible
    color.rgb = mix(color.rgb, color.rgb * color.rgb, 0.15);
    
    // Boost brightness to prevent darkening (1.2 = 20% boost)
    color.rgb *= 1.25;
    
    // Keep within proper range
    color.rgb = clamp(color.rgb, 0.0, 1.0);
    
    gl_FragColor = color;
  }
`;

// Prism effect shader
const prismFragmentShader = `
  uniform sampler2D baseTexture;
  uniform float time;
  uniform float refraction;
  uniform float dispersion;
  uniform float rotation;
  varying vec2 vUv;

  void main() {
    // Center UVs
    vec2 uv = vUv - 0.5;
    
    // Apply rotation based on time
    float angle = rotation * time;
    mat2 rotationMatrix = mat2(
      cos(angle), -sin(angle),
      sin(angle), cos(angle)
    );
    uv = rotationMatrix * uv;
    
    // Calculate distance from center
    float dist = length(uv);
    
    // Apply refraction based on distance
    float refractionFactor = refraction * dist;
    
    // Apply color dispersion
    vec2 redOffset = normalize(uv) * refractionFactor * 0.9;
    vec2 greenOffset = normalize(uv) * refractionFactor * 1.0;
    vec2 blueOffset = normalize(uv) * refractionFactor * (1.0 + dispersion);
    
    // Sample with color separation
    float r = texture2D(baseTexture, vUv + redOffset).r;
    float g = texture2D(baseTexture, vUv + greenOffset).g;
    float b = texture2D(baseTexture, vUv + blueOffset).b;
    float a = texture2D(baseTexture, vUv).a;
    
    // Combine color channels
    gl_FragColor = vec4(r, g, b, a);
  }
`;

// Spiral effect shader
const spiralFragmentShader = `
  uniform sampler2D baseTexture;
  uniform float time;
  uniform float tightness;
  uniform float direction;
  uniform float speed;
  varying vec2 vUv;

  void main() {
    // Center UVs
    vec2 uv = vUv - 0.5;
    
    // Calculate polar coordinates
    float dist = length(uv);
    float angle = atan(uv.y, uv.x);
    
    // Apply spiral distortion
    float spiralFactor = dist * tightness;
    angle += spiralFactor * direction + time * speed;
    
    // Convert back to Cartesian coordinates
    vec2 spiralUV = vec2(
      cos(angle) * dist,
      sin(angle) * dist
    ) + 0.5;
    
    // Sample texture with distorted UVs
    vec4 color = texture2D(baseTexture, spiralUV);
    gl_FragColor = color;
  }
`;

// Vortex effect shader
const vortexFragmentShader = `
  uniform sampler2D baseTexture;
  uniform float time;
  uniform float strength;
  uniform float radius;
  uniform float speed;
  varying vec2 vUv;

  void main() {
    // Center UVs
    vec2 uv = vUv - 0.5;
    
    // Calculate distance from center
    float dist = length(uv);
    
    // Calculate swirl angle based on distance
    float swirl = strength * (1.0 - smoothstep(0.0, radius, dist));
    
    // Apply time-based rotation
    float angle = swirl * sin(time * speed * 2.0);
    
    // Apply rotation transformation
    float s = sin(angle);
    float c = cos(angle);
    vec2 swirlUV = vec2(
      uv.x * c - uv.y * s,
      uv.x * s + uv.y * c
    ) + 0.5;
    
    // Sample texture with distorted UVs
    vec4 color = texture2D(baseTexture, swirlUV);
    gl_FragColor = color;
  }
`;

// Bloom effect shader
const bloomFragmentShader = `
  uniform sampler2D baseTexture;
  uniform float time;
  uniform float intensity;
  uniform float threshold;
  uniform float radius;
  varying vec2 vUv;

  // Helper function for Gaussian blur
  vec3 gaussianBlur(sampler2D tex, vec2 uv, float r) {
    vec3 color = vec3(0.0);
    float total = 0.0;
    
    // Sample multiple points around the current pixel
    for (float x = -4.0; x <= 4.0; x += 1.0) {
      for (float y = -4.0; y <= 4.0; y += 1.0) {
        // Calculate Gaussian weight based on distance
        float weight = exp(-(x * x + y * y) / (2.0 * r * r));
        
        // Sample texture and add weighted color
        vec2 offset = vec2(x, y) * 0.005 * radius;
        color += texture2D(tex, uv + offset).rgb * weight;
        total += weight;
      }
    }
    
    // Normalize the result
    return color / total;
  }

  void main() {
    // Get original color
    vec4 originalColor = texture2D(baseTexture, vUv);
    
    // Apply brightness boost to the entire image (reduced boost)
    vec3 boostedColor = originalColor.rgb * 1.2; // 20% boost instead of 30%
    
    // Calculate brightness
    float brightness = dot(boostedColor, vec3(0.299, 0.587, 0.114));
    
    // Apply threshold
    vec3 brightPass = brightness > threshold ? boostedColor : vec3(0.0);
    
    // Apply blur to bright areas
    vec3 blurredBright = gaussianBlur(baseTexture, vUv, radius);
    
    // Mix original with blurred based on threshold
    vec3 bloomColor = mix(vec3(0.0), blurredBright, step(threshold, brightness));
    
    // Add bloom to original color based on intensity - with softer blending
    vec3 finalColor = mix(boostedColor, boostedColor + bloomColor * intensity, 0.85);
    
    // Keep within proper range
    finalColor = clamp(finalColor, 0.0, 1.0);
    
    // Apply result
    gl_FragColor = vec4(finalColor, originalColor.a);
  }
`;

// Create a shader material for a specific deformation effect
export function createDeformationMaterial(
  texture: THREE.Texture, 
  deformationType: keyof DeformationSettings, 
  deformationSettings: DeformationSettings
): THREE.ShaderMaterial {
  // Set up base uniforms
  const uniforms: { [key: string]: THREE.IUniform } = {
    baseTexture: { value: texture },
    time: { value: 0.0 },
  };
  
  // Select shader and add specific uniforms based on effect type
  let fragmentShader: string;
  
  switch (deformationType) {
    case 'wave':
      fragmentShader = waveFragmentShader;
      uniforms.amplitude = { value: deformationSettings.wave.amplitude };
      uniforms.frequency = { value: deformationSettings.wave.frequency };
      uniforms.speed = { value: deformationSettings.wave.speed };
      break;
      
    case 'ripple':
      fragmentShader = rippleFragmentShader;
      uniforms.strength = { value: deformationSettings.ripple.strength };
      uniforms.frequency = { value: deformationSettings.ripple.frequency };
      uniforms.speed = { value: deformationSettings.ripple.speed };
      break;
      
    case 'kaleidoscope':
      fragmentShader = kaleidoscopeFragmentShader;
      uniforms.segments = { value: deformationSettings.kaleidoscope.segments };
      uniforms.rotation = { value: deformationSettings.kaleidoscope.rotation };
      break;
      
    case 'trail':
      fragmentShader = trailFragmentShader;
      uniforms.prevTexture = { value: texture }; // Will be updated each frame
      uniforms.length = { value: deformationSettings.trail.length };
      uniforms.opacity = { value: deformationSettings.trail.opacity };
      uniforms.decay = { value: deformationSettings.trail.decay };
      break;
      
    case 'twist':
      fragmentShader = twistFragmentShader;
      uniforms.strength = { value: deformationSettings.twist.strength };
      uniforms.radius = { value: deformationSettings.twist.radius };
      break;
      
    case 'pulse':
      fragmentShader = pulseFragmentShader;
      uniforms.frequency = { value: deformationSettings.pulse.frequency };
      uniforms.amplitude = { value: deformationSettings.pulse.amplitude };
      break;
      
    case 'fractal':
      fragmentShader = fractalFragmentShader;
      uniforms.iterations = { value: deformationSettings.fractal.iterations };
      uniforms.scale = { value: deformationSettings.fractal.scale };
      uniforms.rotation = { value: deformationSettings.fractal.rotation };
      break;
      
    case 'liquid':
      fragmentShader = liquidFragmentShader;
      uniforms.viscosity = { value: deformationSettings.liquid.viscosity };
      uniforms.turbulence = { value: deformationSettings.liquid.turbulence };
      uniforms.speed = { value: deformationSettings.liquid.speed };
      break;
      
    case 'prism':
      fragmentShader = prismFragmentShader;
      uniforms.refraction = { value: deformationSettings.prism.refraction };
      uniforms.dispersion = { value: deformationSettings.prism.dispersion };
      uniforms.rotation = { value: deformationSettings.prism.rotation };
      break;
      
    case 'spiral':
      fragmentShader = spiralFragmentShader;
      uniforms.tightness = { value: deformationSettings.spiral.tightness };
      uniforms.direction = { value: deformationSettings.spiral.direction };
      uniforms.speed = { value: deformationSettings.spiral.speed };
      break;
      
    case 'vortex':
      fragmentShader = vortexFragmentShader;
      uniforms.strength = { value: deformationSettings.vortex.strength };
      uniforms.radius = { value: deformationSettings.vortex.radius };
      uniforms.speed = { value: deformationSettings.vortex.speed };
      break;
      
    case 'bloom':
      fragmentShader = bloomFragmentShader;
      uniforms.intensity = { value: deformationSettings.bloom.intensity };
      uniforms.threshold = { value: deformationSettings.bloom.threshold };
      uniforms.radius = { value: deformationSettings.bloom.radius };
      break;
      
    default:
      fragmentShader = baseFragmentShader;
  }
  
  // Create and return shader material
  const material = new THREE.ShaderMaterial({
    uniforms,
    vertexShader,
    fragmentShader,
    transparent: true,
    blending: THREE.CustomBlending,
    blendSrc: THREE.SrcAlphaFactor,
    blendDst: THREE.OneMinusSrcAlphaFactor,
    blendEquation: THREE.AddEquation,
    depthWrite: false,
    depthTest: true
  });
  
  // Store the deformation type in userData for later reference
  material.userData.deformationType = deformationType;
  
  return material;
}

// Function to update shader uniforms based on settings
export function updateDeformationUniforms(
  material: THREE.ShaderMaterial, 
  deformationType: keyof DeformationSettings, 
  deformationSettings: DeformationSettings,
  time: number = 0
): void {
  if (!material.uniforms) return;
  
  // Update time uniform
  material.uniforms.time.value = time;
  
  // Update effect-specific uniforms
  switch (deformationType) {
    case 'wave':
      material.uniforms.amplitude.value = deformationSettings.wave.amplitude;
      material.uniforms.frequency.value = deformationSettings.wave.frequency;
      material.uniforms.speed.value = deformationSettings.wave.speed;
      break;
      
    case 'ripple':
      material.uniforms.strength.value = deformationSettings.ripple.strength;
      material.uniforms.frequency.value = deformationSettings.ripple.frequency;
      material.uniforms.speed.value = deformationSettings.ripple.speed;
      break;
      
    case 'kaleidoscope':
      material.uniforms.segments.value = deformationSettings.kaleidoscope.segments;
      material.uniforms.rotation.value = deformationSettings.kaleidoscope.rotation;
      break;
      
    case 'trail':
      material.uniforms.length.value = deformationSettings.trail.length;
      material.uniforms.opacity.value = deformationSettings.trail.opacity;
      material.uniforms.decay.value = deformationSettings.trail.decay;
      break;
      
    case 'twist':
      material.uniforms.strength.value = deformationSettings.twist.strength;
      material.uniforms.radius.value = deformationSettings.twist.radius;
      break;
      
    case 'pulse':
      material.uniforms.frequency.value = deformationSettings.pulse.frequency;
      material.uniforms.amplitude.value = deformationSettings.pulse.amplitude;
      break;
      
    case 'fractal':
      material.uniforms.iterations.value = deformationSettings.fractal.iterations;
      material.uniforms.scale.value = deformationSettings.fractal.scale;
      material.uniforms.rotation.value = deformationSettings.fractal.rotation;
      break;
      
    case 'liquid':
      material.uniforms.viscosity.value = deformationSettings.liquid.viscosity;
      material.uniforms.turbulence.value = deformationSettings.liquid.turbulence;
      material.uniforms.speed.value = deformationSettings.liquid.speed;
      break;
      
    case 'prism':
      material.uniforms.refraction.value = deformationSettings.prism.refraction;
      material.uniforms.dispersion.value = deformationSettings.prism.dispersion;
      material.uniforms.rotation.value = deformationSettings.prism.rotation;
      break;
      
    case 'spiral':
      material.uniforms.tightness.value = deformationSettings.spiral.tightness;
      material.uniforms.direction.value = deformationSettings.spiral.direction;
      material.uniforms.speed.value = deformationSettings.spiral.speed;
      break;
      
    case 'vortex':
      material.uniforms.strength.value = deformationSettings.vortex.strength;
      material.uniforms.radius.value = deformationSettings.vortex.radius;
      material.uniforms.speed.value = deformationSettings.vortex.speed;
      break;
      
    case 'bloom':
      material.uniforms.intensity.value = deformationSettings.bloom.intensity;
      material.uniforms.threshold.value = deformationSettings.bloom.threshold;
      material.uniforms.radius.value = deformationSettings.bloom.radius;
      break;
  }
}

// Function to apply deformation effects to a mandala layer
export function applyDeformationEffects(
  layerMesh: THREE.Mesh,
  texture: THREE.Texture,
  deformationSettings: DeformationSettings
): void {
  // Create a list of enabled effects
  const enabledEffects = Object.entries(deformationSettings)
    .filter(([_, settings]) => settings.enabled)
    .map(([name]) => name as keyof DeformationSettings);
  
  if (enabledEffects.length === 0) {
    // If no effects enabled, use basic texture material
    layerMesh.material = new THREE.MeshBasicMaterial({
      map: texture, 
      transparent: true,
      side: THREE.DoubleSide,
      depthWrite: false,
      depthTest: true,
      blending: THREE.CustomBlending,
      blendSrc: THREE.SrcAlphaFactor,
      blendDst: THREE.OneMinusSrcAlphaFactor,
      blendEquation: THREE.AddEquation
    });
    return;
  }

  // Define a priority order for effects (UV transformations should come before color effects)
  const effectPriority: Record<keyof DeformationSettings, number> = {
    liquid: 150, // Significantly increased for liquid to ensure it's always prioritized
    ripple: 145, // Increased priority for ripple to be right after liquid
    fractal: 100,
    prism: 90,
    vortex: 85,
    spiral: 80,
    kaleidoscope: 75,
    twist: 70,
    trail: 65,
    wave: 55,
    pulse: 50,
    // Color effects should be applied after all UV transformations
    bloom: 25, // Reduced for bloom to be less dominant
    toneMapping: 20
  };

  // Sort enabled effects by priority (highest first)
  const prioritizedEffects = [...enabledEffects].sort(
    (a, b) => effectPriority[b] - effectPriority[a]
  );

  // Check for special combinations
  const hasLiquid = prioritizedEffects.includes('liquid');
  const hasRipple = prioritizedEffects.includes('ripple');
  const hasBloom = prioritizedEffects.includes('bloom');
  
  // Special case: when both liquid and ripple are enabled, make sure they're applied in correct order
  if (hasLiquid && hasRipple) {
    console.log("Special case: both liquid and ripple are enabled");
    // Make temporary copies of the settings
    const tempSettings = JSON.parse(JSON.stringify(deformationSettings));
    
    // Enhance ripple when used with liquid
    if (tempSettings.ripple && tempSettings.liquid) {
      tempSettings.ripple.strength *= 1.2; // Boost ripple strength
      tempSettings.liquid.turbulence *= 1.3; // Increase liquid turbulence
    }
    
    // Apply with enhanced settings
    const material = createCompositeDeformationMaterial(texture, prioritizedEffects, tempSettings);
    layerMesh.material = material;
    return;
  }
  
  if (prioritizedEffects.length === 1) {
    // If only one effect is enabled, use the standard approach
    const effectName = prioritizedEffects[0];
    const material = createDeformationMaterial(texture, effectName, deformationSettings);
    layerMesh.material = material;
    console.log(`Applied single effect: ${effectName}`);
  } else {
    // If multiple effects are enabled, create a composite material
    const material = createCompositeDeformationMaterial(texture, prioritizedEffects, deformationSettings);
    layerMesh.material = material;
    console.log(`Applied multiple effects: ${prioritizedEffects.join(', ')}`);
  }
}

// Helper function to create a composite material that combines multiple effects
function createCompositeDeformationMaterial(
  texture: THREE.Texture,
  effectNames: Array<keyof DeformationSettings>,
  deformationSettings: DeformationSettings
): THREE.ShaderMaterial {
  // Set up base uniforms
  const uniforms: { [key: string]: THREE.IUniform } = {
    baseTexture: { value: texture },
    time: { value: 0.0 },
  };

  // Categorize effects
  const uvEffects: Array<keyof DeformationSettings> = [];
  const colorEffects: Array<keyof DeformationSettings> = [];
  
  // Categorize each effect based on whether it modifies UVs or colors
  effectNames.forEach(effect => {
    if (effect === 'bloom' || effect === 'toneMapping') {
      colorEffects.push(effect);
    } else {
      uvEffects.push(effect);
    }
  });
  
  // Generate custom uniforms for each effect
  [...uvEffects, ...colorEffects].forEach(effectName => {
    switch (effectName) {
      case 'wave':
        uniforms.waveAmplitude = { value: deformationSettings.wave.amplitude };
        uniforms.waveFrequency = { value: deformationSettings.wave.frequency };
        uniforms.waveSpeed = { value: deformationSettings.wave.speed };
        break;
      case 'ripple':
        uniforms.rippleStrength = { value: deformationSettings.ripple.strength };
        uniforms.rippleFrequency = { value: deformationSettings.ripple.frequency };
        uniforms.rippleSpeed = { value: deformationSettings.ripple.speed };
        break;
      case 'kaleidoscope':
        uniforms.kaleidoscopeSegments = { value: deformationSettings.kaleidoscope.segments };
        uniforms.kaleidoscopeRotation = { value: deformationSettings.kaleidoscope.rotation };
        break;
      case 'twist':
        uniforms.twistStrength = { value: deformationSettings.twist.strength };
        uniforms.twistRadius = { value: deformationSettings.twist.radius };
        break;
      case 'bloom':
        uniforms.bloomIntensity = { value: deformationSettings.bloom.intensity };
        uniforms.bloomThreshold = { value: deformationSettings.bloom.threshold };
        uniforms.bloomRadius = { value: deformationSettings.bloom.radius };
        break;
      case 'liquid':
        uniforms.liquidViscosity = { value: deformationSettings.liquid.viscosity };
        uniforms.liquidTurbulence = { value: deformationSettings.liquid.turbulence };
        uniforms.liquidSpeed = { value: deformationSettings.liquid.speed };
        break;
    }
  });

  // Create shader functions for each effect
  const shaderFunctions: string[] = [];
  const uvTransformations: string[] = [];
  const colorTransformations: string[] = [];

  // Keep track of which kinds of effects we have
  let hasUVTransformation = uvEffects.length > 0;
  let hasColorEffect = colorEffects.length > 0;

  // Generate shader code for UV effects first
  uvEffects.forEach(effectName => {
    switch (effectName) {
      case 'liquid':
        // Prioritize liquid effect by making it more prominent in the shader
        shaderFunctions.push(`
          vec2 applyLiquidEffect(vec2 uv, float time) {
            // Use stronger noise functions for more pronounced effect
            float noiseX = sin(uv.y * 10.0 + time * liquidSpeed) * cos(uv.x * 8.0 - time * liquidSpeed * 0.7) * liquidTurbulence * 1.5;
            float noiseY = cos(uv.x * 9.0 - time * liquidSpeed * 1.1) * sin(uv.y * 9.0 + time * liquidSpeed * 0.8) * liquidTurbulence * 1.5;
            return uv + vec2(noiseX, noiseY) * (1.0 - liquidViscosity * 0.8); // Reduce viscosity influence
          }
        `);
        uvTransformations.push(`uv = applyLiquidEffect(uv, time);`);
        break;
      case 'ripple':
        // Enhanced ripple effect for better compatibility with liquid
        shaderFunctions.push(`
          vec2 applyRippleEffect(vec2 uv, float time) {
            vec2 center = vec2(0.5, 0.5);
            float dist = distance(uv, center);
            
            // Add multi-layered ripples for more complex effect
            float ripple1 = sin(dist * rippleFrequency - time * rippleSpeed) * rippleStrength;
            float ripple2 = sin(dist * rippleFrequency * 1.5 - time * rippleSpeed * 0.8) * rippleStrength * 0.5;
            float combinedRipple = ripple1 + ripple2;
            
            // Apply smooth falloff from center
            vec2 dir = normalize(uv - center);
            return uv + dir * combinedRipple * smoothstep(1.0, 0.0, dist * 2.0);
          }
        `);
        uvTransformations.push(`uv = applyRippleEffect(uv, time);`);
        break;
      case 'wave':
        shaderFunctions.push(`
          vec2 applyWaveEffect(vec2 uv, float time) {
            float waveX = sin(uv.y * waveFrequency + time * waveSpeed) * waveAmplitude;
            float waveY = sin(uv.x * waveFrequency + time * waveSpeed) * waveAmplitude;
            return vec2(uv.x + waveX, uv.y + waveY);
          }
        `);
        uvTransformations.push(`uv = applyWaveEffect(uv, time);`);
        break;
      case 'ripple':
        shaderFunctions.push(`
          vec2 applyRippleEffect(vec2 uv, float time) {
            vec2 center = vec2(0.5, 0.5);
            float dist = distance(uv, center);
            float ripple = sin(dist * rippleFrequency - time * rippleSpeed) * rippleStrength;
            vec2 dir = normalize(uv - center);
            return uv + dir * ripple * smoothstep(1.0, 0.0, dist * 2.0);
          }
        `);
        uvTransformations.push(`uv = applyRippleEffect(uv, time);`);
        break;
      case 'kaleidoscope':
        shaderFunctions.push(`
          vec2 applyKaleidoscopeEffect(vec2 uv, float time) {
            vec2 center = vec2(0.5, 0.5);
            vec2 centered = uv - center;
            float angle = atan(centered.y, centered.x);
            float radius = length(centered);
            
            float segmentAngle = 3.14159 * 2.0 / kaleidoscopeSegments;
            angle = mod(angle + kaleidoscopeRotation + time * 0.2, segmentAngle);
            if (mod(floor((angle + kaleidoscopeRotation) / segmentAngle), 2.0) == 0.0) {
              angle = segmentAngle - angle;
            }
            
            return center + vec2(cos(angle), sin(angle)) * radius;
          }
        `);
        uvTransformations.push(`uv = applyKaleidoscopeEffect(uv, time);`);
        break;
      case 'twist':
        shaderFunctions.push(`
          vec2 applyTwistEffect(vec2 uv, float time) {
            vec2 center = vec2(0.5, 0.5);
            vec2 centered = uv - center;
            float dist = length(centered);
            float angle = atan(centered.y, centered.x);
            float twist = twistStrength * smoothstep(1.0, 0.0, dist / twistRadius);
            float newAngle = angle + twist * time;
            return center + vec2(cos(newAngle), sin(newAngle)) * dist;
          }
        `);
        uvTransformations.push(`uv = applyTwistEffect(uv, time);`);
        break;
    }
  });
  
  // Generate shader code for color effects second
  colorEffects.forEach(effectName => {
    switch (effectName) {
      case 'bloom':
        // Modify bloom to be less overpowering
        shaderFunctions.push(`
          vec4 applyBloomEffect(vec4 color, vec2 uv) {
            // Use a more gentle bloom that preserves original colors better
            float brightness = dot(color.rgb, vec3(0.2126, 0.7152, 0.0722));
            float contribution = max(0.0, brightness - bloomThreshold) * bloomIntensity * 0.8;
            vec3 bloomColor = color.rgb * contribution;
            
            // Preserve more of the original color
            return vec4(mix(color.rgb, color.rgb + bloomColor, 0.8), color.a);
          }
        `);
        colorTransformations.push(`color = applyBloomEffect(color, vUv);`);
        break;
    }
  });

  // Combine shader functions and transformations into a complete fragment shader
  const fragmentShader = `
    uniform sampler2D baseTexture;
    uniform float time;
    varying vec2 vUv;
    
    // Effect-specific uniforms
    ${uvEffects.includes('wave') ? 'uniform float waveAmplitude, waveFrequency, waveSpeed;' : ''}
    ${uvEffects.includes('ripple') ? 'uniform float rippleStrength, rippleFrequency, rippleSpeed;' : ''}
    ${uvEffects.includes('kaleidoscope') ? 'uniform float kaleidoscopeSegments, kaleidoscopeRotation;' : ''}
    ${uvEffects.includes('twist') ? 'uniform float twistStrength, twistRadius;' : ''}
    ${colorEffects.includes('bloom') ? 'uniform float bloomIntensity, bloomThreshold, bloomRadius;' : ''}
    ${uvEffects.includes('liquid') ? 'uniform float liquidViscosity, liquidTurbulence, liquidSpeed;' : ''}
    
    // Effect functions
    ${shaderFunctions.join('\n')}
    
    void main() {
      // Start with original UVs
      vec2 uv = vUv;
      
      // Apply UV transformations in order (ensure they apply even when bloom is active)
      ${hasUVTransformation ? uvTransformations.join('\n      ') : ''}
      
      // Sample texture with final UVs
      vec4 color = texture2D(baseTexture, uv);
      
      // Apply color effects after UV transformations
      ${hasColorEffect ? colorTransformations.join('\n      ') : ''}
      
      gl_FragColor = color;
    }
  `;

  // Create the composite material
  const material = new THREE.ShaderMaterial({
    uniforms: uniforms,
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
    transparent: true,
    side: THREE.DoubleSide,
    depthWrite: false
  });

  return material;
}

// Modified updateDeformationEffects function to handle both single and multiple effects
export function updateDeformationEffects(
  layerMesh: THREE.Mesh,
  time: number,
  deformationSettings: DeformationSettings
): void {
  if (!layerMesh.material || !(layerMesh.material instanceof THREE.ShaderMaterial)) {
    return;
  }
  
  const material = layerMesh.material as THREE.ShaderMaterial;
  
  // Check if this is a basic or single effect material (has deformationType)
  if (material.userData.deformationType) {
    // Update the simple shader material
    updateDeformationUniforms(
      material,
      material.userData.deformationType as keyof DeformationSettings,
      deformationSettings,
      time
    );
  } else {
    // This is likely a composite material, update all relevant uniforms
    updateCompositeDeformationUniforms(material, deformationSettings, time);
  }
}

// Function to update composite material uniforms
function updateCompositeDeformationUniforms(
  material: THREE.ShaderMaterial,
  deformationSettings: DeformationSettings,
  time: number
): void {
  if (!material.uniforms) return;
  
  // Update time uniform
  material.uniforms.time.value = time;
  
  // Check for special combinations to apply special handling
  const hasLiquid = deformationSettings.liquid.enabled;
  const hasBloom = deformationSettings.bloom.enabled;
  const hasRipple = deformationSettings.ripple.enabled;
  
  // Update all relevant effect uniforms
  if (material.uniforms.waveAmplitude && deformationSettings.wave.enabled) {
    material.uniforms.waveAmplitude.value = deformationSettings.wave.amplitude;
    material.uniforms.waveFrequency.value = deformationSettings.wave.frequency;
    material.uniforms.waveSpeed.value = deformationSettings.wave.speed;
  }
  
  if (material.uniforms.rippleStrength && deformationSettings.ripple.enabled) {
    // Special handling for ripple when combined with liquid
    const strength = hasLiquid 
      ? deformationSettings.ripple.strength * 1.5 // Boost strength when combined with liquid
      : deformationSettings.ripple.strength;
    
    const frequency = hasLiquid 
      ? deformationSettings.ripple.frequency * 0.8 // Reduce frequency to prevent interference
      : deformationSettings.ripple.frequency;
    
    material.uniforms.rippleStrength.value = strength;
    material.uniforms.rippleFrequency.value = frequency;
    material.uniforms.rippleSpeed.value = deformationSettings.ripple.speed;
  }
  
  if (material.uniforms.kaleidoscopeSegments && deformationSettings.kaleidoscope.enabled) {
    material.uniforms.kaleidoscopeSegments.value = deformationSettings.kaleidoscope.segments;
    material.uniforms.kaleidoscopeRotation.value = deformationSettings.kaleidoscope.rotation;
  }
  
  if (material.uniforms.twistStrength && deformationSettings.twist.enabled) {
    material.uniforms.twistStrength.value = deformationSettings.twist.strength;
    material.uniforms.twistRadius.value = deformationSettings.twist.radius;
  }
  
  if (material.uniforms.bloomIntensity && deformationSettings.bloom.enabled) {
    // Special handling for bloom - make it less intense when combined with liquid
    const intensity = hasLiquid 
      ? deformationSettings.bloom.intensity * 0.8 
      : deformationSettings.bloom.intensity;
    
    material.uniforms.bloomIntensity.value = intensity;
    material.uniforms.bloomThreshold.value = deformationSettings.bloom.threshold;
    material.uniforms.bloomRadius.value = deformationSettings.bloom.radius;
  }
  
  if (material.uniforms.liquidViscosity && deformationSettings.liquid.enabled) {
    // Special handling for liquid
    let turbulence = deformationSettings.liquid.turbulence;
    let viscosity = deformationSettings.liquid.viscosity;
    let speed = deformationSettings.liquid.speed;
    
    // Adjustments when combined with bloom
    if (hasBloom) {
      turbulence *= 1.5;
      viscosity *= 0.8; // Less viscosity = more movement
      speed *= 1.2;
    }
    
    // Adjustments when combined with ripple
    if (hasRipple) {
      turbulence *= 1.3; // Increase turbulence
      viscosity *= 0.7; // Further reduce viscosity for more movement
      speed *= 1.4; // Speed up the effect to complement ripples
    }
    
    material.uniforms.liquidViscosity.value = viscosity;
    material.uniforms.liquidTurbulence.value = turbulence;
    material.uniforms.liquidSpeed.value = speed;
  }
} 