import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ChevronDown, 
  Play, 
  Pause, 
  RefreshCw, 
  Box,
  Bug,
  Layers,
  Camera,
  Save,
  Upload,
  Crosshair,
  Palette,
  Lightbulb,
  EyeOff
} from 'lucide-react';
import { 
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Switch,
  Input,
  Label,
  Loader,
  ScrollArea,
  toast,
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  Slider,
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogHeader,
  DialogFooter
} from '../ui';
import { TunnelConfiguration } from './TunnelConfiguration';
import { MotionControls } from './MotionControls';
import { FogControls } from './FogControls';
import { EffectsControls } from './EffectsControls';
import { 
  useTunnelStore, 
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_SPEED,
  DEFAULT_ROTATION_SPEED,
  DEFAULT_LAYER_SEPARATION,
  DEFAULT_MANDALA_SEPARATION
} from '../../store/tunnelStore';
import { useMotionControl } from '../Tunnel';
import { useFPS } from '../../hooks/useFPS';
import * as Collapsible from '@radix-ui/react-collapsible';
import { useBirdStore, ModelType } from '../../components/Bird/BirdStore';
import { Tunnel } from '../../types/scene';
import { saveTunnelConfig, loadTunnelConfigs } from '../../services/supabase';
import { TunnelMandalaOverlay } from '../Tunnel/TunnelMandalaOverlay';

// Import the Magic icon SVG
import Magic from '/public/Icons/Magic.svg';
// Import the Capture icon SVG
import Capture from '/public/Icons/Capture.svg';

// Add a CSS class for section containers
const sectionContainerClass = "bg-muted/30 rounded-md p-3 border border-border";
const sliderActiveClass = "[&_[role=slider]]:h-4 [&_[role=slider]]:w-4 [&_.bg-primary]:bg-primary";

interface MainControlPanelProps {
  onResetCamera: () => void;
  externalSetTunnelMandalaOverlay?: (isOpen: boolean) => void;
  initialIsOpen?: boolean;
}

export function MainControlPanel({ 
  onResetCamera, 
  externalSetTunnelMandalaOverlay,
  initialIsOpen = true
}: MainControlPanelProps) {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [is3DModelOpen, setIs3DModelOpen] = useState(false);
  const [isMandalaOverlayOpen, setIsMandalaOverlayOpen] = useState(false);
  const { isPlaying, setIsPlaying } = useMotionControl();
  const fps = useFPS();
  const navigate = useNavigate();

  // Bird store state and actions
  const selectedModel = useBirdStore(state => state.modelType);
  const setModelType = useBirdStore(state => state.setModelType);
  const modelAdjustments = useBirdStore(state => state.modelAdjustments);
  const setModelScale = useBirdStore(state => state.setModelScale);
  const setModelPosition = useBirdStore(state => state.setModelPosition);
  const resetModelAdjustments = useBirdStore(state => state.resetModelAdjustments);
  
  // NEW: Get material and lighting settings from BirdStore
  const materialSettings = useBirdStore(state => state.materialSettings);
  const lightingSettings = useBirdStore(state => state.lightingSettings);
  
  // NEW: Get material and lighting actions from BirdStore
  const setEmissiveColor = useBirdStore(state => state.setEmissiveColor);
  const setEmissiveIntensity = useBirdStore(state => state.setEmissiveIntensity);
  const setMetalness = useBirdStore(state => state.setMetalness);
  const setRoughness = useBirdStore(state => state.setRoughness);
  const setDepthTest = useBirdStore(state => state.setDepthTest);
  const setTransparent = useBirdStore(state => state.setTransparent);
  const setRenderPriority = useBirdStore(state => state.setRenderPriority);
  const setLightIntensity = useBirdStore(state => state.setLightIntensity);
  const setLightColor = useBirdStore(state => state.setLightColor);
  const setAmbientIntensity = useBirdStore(state => state.setAmbientIntensity);
  const resetMaterialSettings = useBirdStore(state => state.resetMaterialSettings);
  const resetLightingSettings = useBirdStore(state => state.resetLightingSettings);

  // NEW: States for material and lighting sections
  const [isMaterialOpen, setIsMaterialOpen] = useState(false);
  const [isLightingOpen, setIsLightingOpen] = useState(false);

  const togglePlayPause = () => setIsPlaying(!isPlaying);

  // Handle model change
  const handleModelChange = (value: string) => {
    setModelType(value as ModelType);
  };

  // Get store state
  const {
    currentTunnel,
    speed,
    rotationSpeed,
    layerSeparation,
    mandalaSeparation,
    debug,
    fogEnabled,
    fogNear,
    fogFar,
    postProcessing,
    backgroundColor,
    setSpeed,
    setRotationSpeed,
    setLayerSeparation,
    setMandalaSeparation,
    setDebug,
    setFogEnabled,
    setFogNear,
    setFogFar,
    setPostProcessing,
    setBackgroundColor,
    setCurrentTunnel
  } = useTunnelStore();

  // Handle screenshot capture
  const captureScreenshot = () => {
    // Dispatch the take-screenshot event that the TunnelViewer listens for
    window.dispatchEvent(new CustomEvent('take-screenshot'));
    
    // Only show toast if not on MandalaPage (when externalSetTunnelMandalaOverlay is not provided)
    if (!externalSetTunnelMandalaOverlay) {
      // Show a toast notification
      toast({
        title: "Screenshot captured",
        description: "Your screenshot has been saved",
        variant: "default"
      });
    }
  };

  // Handle saving the current tunnel configuration
  const saveTunnelConfiguration = async () => {
    if (!currentTunnel) {
      toast({
        title: "Error",
        description: "No tunnel configuration to save",
        variant: "destructive"
      });
      return;
    }

    try {
      // Extract just mandala names - that's all we need
      const mandalaNames = currentTunnel.mandalas.map((mandala: any) => 
        typeof mandala.name === 'string' ? mandala.name : null
      ).filter(Boolean);
      
      if (mandalaNames.length === 0) {
        throw new Error("No valid mandalas to save");
      }

      // Create a minimal configuration with just what's needed
      // Ensure numerical values like 0 are saved properly
      const configToSave = {
        name: currentTunnel.name,
        mandalas: mandalaNames, // Just save the array of names
        speed: typeof speed === 'number' ? speed : DEFAULT_SPEED,
        rotationSpeed: typeof rotationSpeed === 'number' ? rotationSpeed : DEFAULT_ROTATION_SPEED,
        layerSeparation: typeof layerSeparation === 'number' ? layerSeparation : DEFAULT_LAYER_SEPARATION,
        mandalaSeparation: typeof mandalaSeparation === 'number' ? mandalaSeparation : DEFAULT_MANDALA_SEPARATION,
        backgroundColor
      };

      console.log("Saving config with rotation speed:", configToSave.rotationSpeed);
      await saveTunnelConfig(configToSave);

      toast({
        title: "Success",
        description: "Tunnel configuration saved",
        variant: "default"
      });
    } catch (error) {
      console.error("Error saving tunnel configuration:", error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to save tunnel configuration",
        variant: "destructive"
      });
    }
  };

  // State for saved tunnel configurations
  const [savedConfigs, setSavedConfigs] = useState<any[]>([]);
  const [isLoadDialogOpen, setIsLoadDialogOpen] = useState(false);

  // Load saved tunnel configurations
  const loadSavedConfigs = async () => {
    try {
      const configs = await loadTunnelConfigs();
      setSavedConfigs(configs);
      setIsLoadDialogOpen(true);
    } catch (error) {
      console.error("Error loading tunnel configurations:", error);
      toast({
        title: "Error",
        description: "Failed to load saved configurations",
        variant: "destructive"
      });
    }
  };

  // Load a specific tunnel configuration
  const loadTunnelConfiguration = (config: any) => {
    try {
      // Basic validation
      if (!config) {
        throw new Error("Invalid tunnel configuration");
      }

      // Parse mandala names - handle both string arrays and serialized JSON strings
      let mandalaNames: string[] = [];
      
      if (Array.isArray(config.mandalas)) {
        mandalaNames = config.mandalas.map((m: any) => {
          // If it's already a string, use it directly
          if (typeof m === 'string') {
            // Try to parse if it looks like JSON
            if (m.startsWith('{') && m.includes('name')) {
              try {
                const parsed = JSON.parse(m);
                return parsed.name;
              } catch {
                return m; // If parsing fails, use the string as is
              }
            }
            return m;
          }
          // If it's an object with a name property, use that
          else if (m && typeof m.name === 'string') {
            return m.name;
          }
          return null;
        }).filter(Boolean);
      }
      
      if (mandalaNames.length === 0) {
        throw new Error("No valid mandala names found in configuration");
      }

      // Create MandalaInfo objects from the names
      const mandalaInfos = mandalaNames.map(name => ({
        name,
        layerCount: 5 // Default layer count, will be updated when textures load
      }));
      
      // Use nullish coalescing operator (??) or explicit check for undefined/null
      // to ensure 0 is treated as a valid value and not replaced with the default
      const tunnelSpeed = typeof config.speed === 'number' ? config.speed : DEFAULT_SPEED;
      const tunnelRotation = typeof config.rotationSpeed === 'number' ? config.rotationSpeed : DEFAULT_ROTATION_SPEED;
      
      console.log("Loading config rotation speed:", config.rotationSpeed, "Using value:", tunnelRotation);
      
      // Create the tunnel object with proper typing
      const tunnelObject = {
        id: config.id || `tunnel-${Date.now()}`,
        name: config.name || mandalaNames.join(' '),
        mandalas: mandalaInfos,
        defaultSpeed: tunnelSpeed,
        defaultRotation: tunnelRotation
      } as any; // Use type assertion to bypass TypeScript checking temporarily
      
      // Update the store with the properly checked values
      setCurrentTunnel(tunnelObject);
      setSpeed(tunnelSpeed);
      setRotationSpeed(tunnelRotation);
      
      if (typeof config.layerSeparation === 'number') {
        setLayerSeparation(config.layerSeparation);
      }
      
      if (typeof config.mandalaSeparation === 'number') {
        setMandalaSeparation(config.mandalaSeparation);
      }
      
      if (config.backgroundColor) {
        setBackgroundColor(config.backgroundColor);
      }
      
      setIsLoadDialogOpen(false);
      
      toast({
        title: "Success",
        description: `Loaded "${config.name || 'configuration'}"`,
        variant: "default"
      });
    } catch (error) {
      console.error("Error loading tunnel configuration:", error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to load tunnel configuration",
        variant: "destructive"
      });
    }
  };

  // Handle opening the overlay, using either internal or external state
  const handleOpenMandalaOverlay = () => {
    setIsMandalaOverlayOpen(true);
    if (externalSetTunnelMandalaOverlay) {
      externalSetTunnelMandalaOverlay(true);
    }
  };

  // Handle closing the overlay
  const handleCloseMandalaOverlay = () => {
    setIsMandalaOverlayOpen(false);
    if (externalSetTunnelMandalaOverlay) {
      externalSetTunnelMandalaOverlay(false);
    }
  };

  return (
    <>
      {/* Only render the overlay if there's no external control */}
      {!externalSetTunnelMandalaOverlay && (
        <TunnelMandalaOverlay 
          isOpen={isMandalaOverlayOpen} 
          onClose={handleCloseMandalaOverlay}
        />
      )}

      {/* Controls title as toggle button - hide in mandala page */}
      {!externalSetTunnelMandalaOverlay && (
        <button
          className="fixed top-10 right-10 z-50 cursor-pointer transition-all hover:opacity-80 pointer-events-auto"
          onClick={() => setIsOpen(!isOpen)}
          title="Toggle controls"
        >
          <img src={Magic} alt="Controls" className="w-6 h-6 opacity-30" />
        </button>
      )}

      {/* Control card below the button */}
      <Card 
        className={`absolute top-20 right-10 w-72 sm:w-80 bg-background/80 backdrop-blur-md shadow-md text-foreground z-40 transition-all duration-300 ease-in-out origin-top-right ${isOpen ? 'opacity-100 scale-100 pointer-events-auto' : 'opacity-0 scale-90 pointer-events-none'} border-0`}
      >
        <CardContent className="p-3 sm:p-4 space-y-2 sm:space-y-3">
          {/* Main toolbar with rearranged buttons */}
          <div className="flex items-center justify-between mb-3 sm:mb-5">
            {/* Left side: Layers and Play/Pause buttons */}
            <div className="flex items-center space-x-2">
              {/* Back to Selection Button (Layers icon) */}
              <Button
                size="icon"
                variant="ghost"
                className="h-8 w-8"
                onClick={handleOpenMandalaOverlay}
              >
                <Layers className="h-4 w-4" />
              </Button>

              {/* Play/Pause Button */}
              <Button 
                size="icon" 
                variant="ghost" 
                className="h-8 w-8"
                onClick={togglePlayPause}
              >
                {isPlaying ? <Pause className="h-4 w-4" /> : <Play className="h-4 w-4" />}
              </Button>

              {/* Save Button */}
              <Button 
                size="icon" 
                variant="ghost" 
                className="h-8 w-8"
                onClick={saveTunnelConfiguration}
                title="Save tunnel configuration"
              >
                <Save className="h-4 w-4" />
              </Button>

              {/* Load Button - Only show in tunnel control panel (not mandala page) */}
              {!externalSetTunnelMandalaOverlay && (
                <Button 
                  size="icon" 
                  variant="ghost" 
                  className="h-8 w-8"
                  onClick={loadSavedConfigs}
                  title="Load tunnel configuration"
                >
                  <Upload className="h-4 w-4" />
                </Button>
              )}
            </div>
            
            {/* Right side: Reset Camera, Screenshot, and Debug Buttons */}
            <div className="flex items-center space-x-2">
              {/* Reset Camera Button - Now with Crosshair icon */}
              <Button 
                size="icon" 
                variant="ghost" 
                className="h-8 w-8"
                onClick={onResetCamera}
                title="Reset camera"
              >
                <Crosshair className="h-4 w-4" />
              </Button>
              
              {/* Screenshot Button - Now with Capture icon */}
              <Button 
                size="icon" 
                variant="ghost" 
                className="h-8 w-8"
                onClick={captureScreenshot}
                title="Take screenshot"
              >
                <img src="/Icons/Capture.svg" alt="Screenshot" width={16} height={16} className="filter invert" />
              </Button>
              
              {/* Debug Button */}
              <Button 
                size="icon" 
                variant={debug ? "default" : "ghost"} 
                className={`h-8 w-8 ${debug ? 'bg-primary' : ''}`}
                onClick={() => setDebug(!debug)}
                title="Toggle debug mode"
              >
                <Bug className="h-4 w-4" />
              </Button>
            </div>
          </div>
          
          {/* 3D Model Section */}
          <div>
            <div 
              className="flex items-center justify-between cursor-pointer py-2 px-1 hover:bg-muted/30 rounded text-foreground"
              onClick={() => setIs3DModelOpen(!is3DModelOpen)}
            >
              <div className="flex items-center gap-2">
                <Box className="h-4 w-4 text-muted-foreground" />
                <h3 className="text-sm font-medium">3D Model</h3>
              </div>
              <ChevronDown className={`w-4 h-4 transition-transform ${is3DModelOpen ? 'rotate-180' : ''}`} />
            </div>
            
            <Collapsible.Root open={is3DModelOpen}>
              <Collapsible.Content>
                <div className={`mt-2 space-y-6 ${sectionContainerClass}`}>
                  {/* 3D Model Selection Dropdown */}
                  <div className="mb-2">
                    <Select
                      value={selectedModel}
                      onValueChange={(value) => setModelType(value as ModelType)}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select model" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="none">No model</SelectItem>
                        <SelectItem value="phoenix">Phoenix</SelectItem>
                        <SelectItem value="bird">Bird</SelectItem>
                        <SelectItem value="anatomy">Anatomy</SelectItem>
                        <SelectItem value="shark">Shark</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>

                  {/* Model Adjustment Sliders - only shown when a model is selected (not "none") */}
                  {selectedModel !== 'none' && (
                    <div className="space-y-4 mt-2 border-t border-border pt-4">
                      <div className="flex items-center justify-between">
                        <Label className="text-sm font-medium">Model Adjustments</Label>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => resetModelAdjustments()}
                          className="h-7 text-xs"
                        >
                          Reset
                        </Button>
                      </div>
                      
                      {/* Scale Slider */}
                      <div className="space-y-1">
                        <div className="flex items-center justify-between">
                          <Label className="text-xs text-muted-foreground">Scale</Label>
                          <span className="text-xs text-muted-foreground">
                            {modelAdjustments.scale.toFixed(2)}
                          </span>
                        </div>
                        <Slider
                          value={[modelAdjustments.scale]}
                          min={0.1}
                          max={100}
                          step={0.05}
                          onValueChange={(values) => setModelScale(values[0])}
                          className={sliderActiveClass}
                        />
                      </div>
                      
                      {/* X Position Slider */}
                      <div className="space-y-1">
                        <div className="flex items-center justify-between">
                          <Label className="text-xs text-muted-foreground">X Position</Label>
                          <span className="text-xs text-muted-foreground">
                            {modelAdjustments.positionX.toFixed(1)}
                          </span>
                        </div>
                        <Slider
                          value={[modelAdjustments.positionX]}
                          min={-5}
                          max={5}
                          step={0.1}
                          onValueChange={(values) => setModelPosition('x', values[0])}
                          className={sliderActiveClass}
                        />
                      </div>
                      
                      {/* Y Position Slider */}
                      <div className="space-y-1">
                        <div className="flex items-center justify-between">
                          <Label className="text-xs text-muted-foreground">Y Position</Label>
                          <span className="text-xs text-muted-foreground">
                            {modelAdjustments.positionY.toFixed(1)}
                          </span>
                        </div>
                        <Slider
                          value={[modelAdjustments.positionY]}
                          min={-5}
                          max={5}
                          step={0.1}
                          onValueChange={(values) => setModelPosition('y', values[0])}
                          className={sliderActiveClass}
                        />
                      </div>
                      
                      {/* Z Position Slider */}
                      <div className="space-y-1">
                        <div className="flex items-center justify-between">
                          <Label className="text-xs text-muted-foreground">Z Position</Label>
                          <span className="text-xs text-muted-foreground">
                            {modelAdjustments.positionZ.toFixed(1)}
                          </span>
                        </div>
                        <Slider
                          value={[modelAdjustments.positionZ]}
                          min={-5}
                          max={5}
                          step={0.1}
                          onValueChange={(values) => setModelPosition('z', values[0])}
                          className={sliderActiveClass}
                        />
                      </div>
                    </div>
                  )}
                  
                  {/* NEW: Material Settings Section - only shown when a model is selected */}
                  {selectedModel !== 'none' && (
                    <div className="space-y-4 mt-4 border-t border-border pt-4">
                      <div 
                        className="flex items-center justify-between cursor-pointer"
                        onClick={() => setIsMaterialOpen(!isMaterialOpen)}
                      >
                        <div className="flex items-center gap-2">
                          <Palette className="h-4 w-4 text-muted-foreground" />
                          <Label className="text-sm font-medium">Material Settings</Label>
                        </div>
                        <div className="flex gap-1">
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={(e) => {
                              e.stopPropagation();
                              resetMaterialSettings();
                            }}
                            className="h-7 text-xs"
                          >
                            Reset
                          </Button>
                          <ChevronDown className={`w-4 h-4 transition-transform ${isMaterialOpen ? 'rotate-180' : ''}`} />
                        </div>
                      </div>
                      
                      <Collapsible.Root open={isMaterialOpen}>
                        <Collapsible.Content>
                          <div className="space-y-4 mt-2">
                            {/* Emissive Color Picker */}
                            <div className="space-y-1">
                              <Label className="text-xs text-muted-foreground">Emissive Color</Label>
                              <div className="flex items-center gap-2">
                                <input 
                                  type="color" 
                                  value={materialSettings.emissiveColor} 
                                  onChange={(e) => setEmissiveColor(e.target.value)}
                                  className="w-8 h-8 rounded-md border border-border cursor-pointer" 
                                />
                                <span className="text-xs text-muted-foreground">
                                  {materialSettings.emissiveColor}
                                </span>
                              </div>
                            </div>
                            
                            {/* Emissive Intensity Slider */}
                            <div className="space-y-1">
                              <div className="flex items-center justify-between">
                                <Label className="text-xs text-muted-foreground">Emissive Intensity</Label>
                                <span className="text-xs text-muted-foreground">
                                  {materialSettings.emissiveIntensity.toFixed(2)}
                                </span>
                              </div>
                              <Slider
                                value={[materialSettings.emissiveIntensity]}
                                min={0}
                                max={2}
                                step={0.05}
                                onValueChange={(values) => setEmissiveIntensity(values[0])}
                                className={sliderActiveClass}
                              />
                            </div>
                            
                            {/* Metalness Slider */}
                            <div className="space-y-1">
                              <div className="flex items-center justify-between">
                                <Label className="text-xs text-muted-foreground">Metalness</Label>
                                <span className="text-xs text-muted-foreground">
                                  {materialSettings.metalness.toFixed(2)}
                                </span>
                              </div>
                              <Slider
                                value={[materialSettings.metalness]}
                                min={0}
                                max={1}
                                step={0.05}
                                onValueChange={(values) => setMetalness(values[0])}
                                className={sliderActiveClass}
                              />
                            </div>
                            
                            {/* Roughness Slider */}
                            <div className="space-y-1">
                              <div className="flex items-center justify-between">
                                <Label className="text-xs text-muted-foreground">Roughness</Label>
                                <span className="text-xs text-muted-foreground">
                                  {materialSettings.roughness.toFixed(2)}
                                </span>
                              </div>
                              <Slider
                                value={[materialSettings.roughness]}
                                min={0}
                                max={1}
                                step={0.05}
                                onValueChange={(values) => setRoughness(values[0])}
                                className={sliderActiveClass}
                              />
                            </div>
                            
                            {/* Rendering Controls */}
                            <div className="space-y-1 mt-2">
                              <Label className="text-xs text-muted-foreground">Rendering Settings</Label>
                              <div className="flex items-center justify-between mt-2">
                                <div className="flex items-center gap-2">
                                  <EyeOff className="h-3 w-3 text-muted-foreground" />
                                  <span className="text-xs">Depth Test</span>
                                </div>
                                <Switch
                                  checked={materialSettings.depthTest}
                                  onCheckedChange={setDepthTest}
                                />
                              </div>
                              
                              <div className="flex items-center justify-between mt-2">
                                <div className="flex items-center gap-2">
                                  <EyeOff className="h-3 w-3 text-muted-foreground" />
                                  <span className="text-xs">Transparent</span>
                                </div>
                                <Switch
                                  checked={materialSettings.transparent}
                                  onCheckedChange={setTransparent}
                                />
                              </div>
                              
                              {/* Render Priority */}
                              <div className="flex items-center justify-between mt-2">
                                <span className="text-xs">Render Priority</span>
                                <Input
                                  type="number"
                                  value={materialSettings.renderPriority}
                                  onChange={(e) => setRenderPriority(parseInt(e.target.value))}
                                  className="w-16 h-7 text-xs"
                                  min={0}
                                  max={100}
                                />
                              </div>
                            </div>
                          </div>
                        </Collapsible.Content>
                      </Collapsible.Root>
                    </div>
                  )}
                  
                  {/* NEW: Lighting Settings Section - only shown when a model is selected */}
                  {selectedModel !== 'none' && (
                    <div className="space-y-4 mt-4 border-t border-border pt-4">
                      <div 
                        className="flex items-center justify-between cursor-pointer"
                        onClick={() => setIsLightingOpen(!isLightingOpen)}
                      >
                        <div className="flex items-center gap-2">
                          <Lightbulb className="h-4 w-4 text-muted-foreground" />
                          <Label className="text-sm font-medium">Lighting Settings</Label>
                        </div>
                        <div className="flex gap-1">
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={(e) => {
                              e.stopPropagation();
                              resetLightingSettings();
                            }}
                            className="h-7 text-xs"
                          >
                            Reset
                          </Button>
                          <ChevronDown className={`w-4 h-4 transition-transform ${isLightingOpen ? 'rotate-180' : ''}`} />
                        </div>
                      </div>
                      
                      <Collapsible.Root open={isLightingOpen}>
                        <Collapsible.Content>
                          <div className="space-y-4 mt-2">
                            {/* Light Color Picker */}
                            <div className="space-y-1">
                              <Label className="text-xs text-muted-foreground">Light Color</Label>
                              <div className="flex items-center gap-2">
                                <input 
                                  type="color" 
                                  value={lightingSettings.lightColor} 
                                  onChange={(e) => setLightColor(e.target.value)}
                                  className="w-8 h-8 rounded-md border border-border cursor-pointer" 
                                />
                                <span className="text-xs text-muted-foreground">
                                  {lightingSettings.lightColor}
                                </span>
                              </div>
                            </div>
                            
                            {/* Light Intensity Slider */}
                            <div className="space-y-1">
                              <div className="flex items-center justify-between">
                                <Label className="text-xs text-muted-foreground">Light Intensity</Label>
                                <span className="text-xs text-muted-foreground">
                                  {lightingSettings.lightIntensity.toFixed(2)}
                                </span>
                              </div>
                              <Slider
                                value={[lightingSettings.lightIntensity]}
                                min={0}
                                max={3}
                                step={0.1}
                                onValueChange={(values) => setLightIntensity(values[0])}
                                className={sliderActiveClass}
                              />
                            </div>
                            
                            {/* Ambient Intensity Slider */}
                            <div className="space-y-1">
                              <div className="flex items-center justify-between">
                                <Label className="text-xs text-muted-foreground">Ambient Intensity</Label>
                                <span className="text-xs text-muted-foreground">
                                  {lightingSettings.ambientIntensity.toFixed(2)}
                                </span>
                              </div>
                              <Slider
                                value={[lightingSettings.ambientIntensity]}
                                min={0}
                                max={2}
                                step={0.1}
                                onValueChange={(values) => setAmbientIntensity(values[0])}
                                className={sliderActiveClass}
                              />
                            </div>
                          </div>
                        </Collapsible.Content>
                      </Collapsible.Root>
                    </div>
                  )}
                </div>
              </Collapsible.Content>
            </Collapsible.Root>
          </div>
          
          {/* Motion Controls Section */}
          <MotionControls />
          
          {/* Effects Controls */}
          <EffectsControls />
          
          {/* Tunnel Configuration */}
          <TunnelConfiguration defaultBackgroundColor={DEFAULT_BACKGROUND_COLOR} />
          
          {/* Fog Controls */}
          <FogControls />
          
          {/* FPS counter moved to bottom */}
          <div className="flex justify-end pt-3 border-t border-border/30">
            <div className="text-sm text-muted-foreground">FPS: {fps}</div>
          </div>
        </CardContent>
      </Card>
      
      {/* Load Configurations Dialog */}
      <Dialog open={isLoadDialogOpen} onOpenChange={setIsLoadDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Load Tunnel Configuration</DialogTitle>
            <DialogDescription>
              Select a saved tunnel configuration to load.
            </DialogDescription>
          </DialogHeader>
          
          <ScrollArea className="h-[300px] mt-4">
            {savedConfigs.length === 0 ? (
              <p className="text-center text-muted-foreground py-4">No saved configurations found.</p>
            ) : (
              <div className="space-y-2 pr-4">
                {savedConfigs.map((config) => (
                  <div 
                    key={config.id} 
                    className="p-3 rounded-md border border-border hover:bg-muted/50 cursor-pointer"
                    onClick={() => loadTunnelConfiguration(config)}
                  >
                    <h3 className="font-medium">{config.name}</h3>
                    <p className="text-sm text-muted-foreground">
                      Mandalas: {Array.isArray(config.mandalas) ? config.mandalas.length : 'Unknown'}
                    </p>
                    <div className="flex items-center gap-4 mt-1">
                      <p className="text-xs text-muted-foreground">Speed: {config.speed.toFixed(1)}</p>
                      <p className="text-xs text-muted-foreground">Rotation: {config.rotationSpeed.toFixed(2)}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ScrollArea>
          
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsLoadDialogOpen(false)}>
              Cancel
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
} 