import { useState } from 'react';
import { Move, ChevronDown } from 'lucide-react';
import * as Collapsible from '@radix-ui/react-collapsible';
import { Slider } from '../ui';
import { useTunnelStore } from '../../store/tunnelStore';
import { useMotionControl } from '../Tunnel';

// Add a CSS class for section containers and sliders
const sectionContainerClass = "bg-muted/30 rounded-md p-3 border border-border";
const sliderActiveClass = "[&_[role=slider]]:h-4 [&_[role=slider]]:w-4 [&_.bg-primary]:bg-primary";

/**
 * Component that controls motion settings like animation speed and rotation
 */
export function MotionControls() {
  const [isMotionOpen, setIsMotionOpen] = useState(false);
  
  // Use selective subscriptions from useMotionControl
  const { isPlaying } = useMotionControl();
  
  // Use selective subscriptions with Zustand selectors
  const speed = useTunnelStore(state => state.speed);
  const rotationSpeed = useTunnelStore(state => state.rotationSpeed);
  
  // Get actions separately to avoid re-renders when other state changes
  const setSpeed = useTunnelStore(state => state.setSpeed);
  const setRotationSpeed = useTunnelStore(state => state.setRotationSpeed);

  // Simple handlers that only update the store values without side effects
  const handleSpeedChange = (values: number[]) => {
    setSpeed(values[0]);
  };

  const handleRotationSpeedChange = (values: number[]) => {
    setRotationSpeed(values[0]);
  };

  return (
    <div>
      <div 
        className="flex items-center justify-between cursor-pointer py-2 px-1 hover:bg-muted/30 rounded text-foreground"
        onClick={() => setIsMotionOpen(!isMotionOpen)}
      >
        <div className="flex items-center gap-2">
          <Move className="h-4 w-4 text-muted-foreground" />
          <h3 className="text-sm font-medium">Motion</h3>
        </div>
        <ChevronDown className={`w-4 h-4 transition-transform ${isMotionOpen ? 'rotate-180' : ''}`} />
      </div>
      
      <Collapsible.Root open={isMotionOpen}>
        <Collapsible.Content>
          <div className={`mt-2 space-y-6 ${sectionContainerClass}`}>
            {/* Animation Speed Slider */}
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <label className="text-sm font-medium">Animation Speed</label>
                <span className="text-sm text-muted-foreground/70">{speed.toFixed(1)}</span>
              </div>
              <Slider
                value={[speed]}
                min={0}
                max={5.0}
                step={0.1}
                onValueChange={handleSpeedChange}
                className={sliderActiveClass}
              />
            </div>
            
            {/* Rotation Speed Slider */}
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <label className="text-sm font-medium">Rotation Speed</label>
                <span className="text-sm text-muted-foreground/70">{rotationSpeed.toFixed(2)}</span>
              </div>
              <Slider
                value={[rotationSpeed]}
                min={-0.5}
                max={0.5}
                step={0.01}
                onValueChange={handleRotationSpeedChange}
                className={sliderActiveClass}
              />
            </div>
          </div>
        </Collapsible.Content>
      </Collapsible.Root>
    </div>
  );
} 