import { create } from 'zustand';
import { Vector3 } from 'three';

// Define available model types
export type ModelType = 'none' | 'phoenix' | 'bird' | 'anatomy' | 'shark';

export interface BirdState {
  // Model type
  modelType: ModelType;
  
  // Visibility state
  isBirdVisible: boolean;
  
  // Model adjustment settings
  modelAdjustments: {
    scale: number;
    positionX: number;
    positionY: number;
    positionZ: number;
  };
  
  // NEW: Material settings
  materialSettings: {
    emissiveColor: string;
    emissiveIntensity: number;
    metalness: number;
    roughness: number;
    depthTest: boolean;
    transparent: boolean;
    renderPriority: number;
  };
  
  // NEW: Lighting settings
  lightingSettings: {
    lightIntensity: number;
    lightColor: string;
    ambientIntensity: number;
  };
  
  // Wind effects
  windEffects: {
    active: boolean;
    intensity: number;
  };
  
  // Fog settings
  fogSettings: {
    enabled: boolean;
    nearDistance: number;
    farDistance: number;
  };
  
  // Debug mode
  debugMode: boolean;
  
  // Tunnel speed
  tunnelSpeed: number;
  defaultTunnelSpeed: number;
  
  // Flight parameters
  flightParams: {
    speed: number;
    energy: number;
    altitude: number;
    bankAngle: number;
    pitchAngle: number;
  };
  
  // Environment factors
  environment: {
    windDirection: Vector3;
    windStrength: number;
    turbulence: number;
  };
  
  // Actions
  setModelType: (modelType: ModelType) => void;
  setBirdVisibility: (isVisible: boolean) => void;
  setModelScale: (scale: number) => void;
  setModelPosition: (axis: 'x' | 'y' | 'z', value: number) => void;
  resetModelAdjustments: () => void;
  initModelAdjustments: () => void;
  
  // NEW: Material and lighting actions
  setEmissiveColor: (color: string) => void;
  setEmissiveIntensity: (intensity: number) => void;
  setMetalness: (value: number) => void;
  setRoughness: (value: number) => void;
  setDepthTest: (enabled: boolean) => void;
  setTransparent: (enabled: boolean) => void;
  setRenderPriority: (value: number) => void;
  setLightIntensity: (value: number) => void;
  setLightColor: (color: string) => void;
  setAmbientIntensity: (value: number) => void;
  resetMaterialSettings: () => void;
  resetLightingSettings: () => void;
  
  setWindEffects: (active: boolean, intensity?: number) => void;
  setFogSettings: (enabled: boolean, nearDistance?: number, farDistance?: number) => void;
  setDebugMode: (enabled: boolean) => void;
  setTunnelSpeed: (speed: number) => void;
  setDefaultTunnelSpeed: (speed: number) => void;
  setFlightParams: (params: Partial<BirdState['flightParams']>) => void;
  setEnvironment: (params: Partial<BirdState['environment']>) => void;
}

// Initialize with default values
export const useBirdStore = create<BirdState>((set, get) => ({
  // Default model type
  modelType: 'bird',
  
  // Default visibility
  isBirdVisible: true,
  
  // Default model adjustments - will be set based on model type
  modelAdjustments: {
    scale: 31.20,
    positionX: 0,
    positionY: 0,
    positionZ: -3.1
  },
  
  // NEW: Default material settings
  materialSettings: {
    emissiveColor: "#b8b8ff", // Light blue emissive color
    emissiveIntensity: 0.00,
    metalness: 0.00,
    roughness: 0.80,
    depthTest: false, // Disable depth testing by default
    transparent: true, // Enable transparency
    renderPriority: 10
  },
  
  // NEW: Default lighting settings
  lightingSettings: {
    lightIntensity: 1.00,
    lightColor: "#ffffff",
    ambientIntensity: 0.50
  },
  
  // Initialize adjustment values for specific model type
  initModelAdjustments: () => set((state) => {
    // For anatomy model, set specific default adjustments
    if (state.modelType === 'anatomy') {
      return {
        modelAdjustments: {
          scale: 1.10,
          positionX: 0.3,
          positionY: 0.4,
          positionZ: 3.0
        }
      };
    }
    // For bird model, set specific default adjustments
    else if (state.modelType === 'bird') {
      return {
        modelAdjustments: {
          scale: 31.20,
          positionX: 0.0,
          positionY: 0.0,
          positionZ: -3.1
        }
      };
    }
    // For shark model, set specific default adjustments
    else if (state.modelType === 'shark') {
      return {
        modelAdjustments: {
          scale: 18.45,
          positionX: 1.8,
          positionY: -0.1,
          positionZ: 1
        }
      };
    }
    return {}; // No changes for other models
  }),
  
  // Default wind effects
  windEffects: {
    active: true,
    intensity: 0.3
  },
  
  // Default fog settings
  fogSettings: {
    enabled: true,
    nearDistance: -0.2, // -20%
    farDistance: -0.75, // -75%
  },
  
  // Default debug mode
  debugMode: false,
  
  // Default tunnel speed
  tunnelSpeed: 1.0,
  defaultTunnelSpeed: 1.0,
  
  // Default flight parameters
  flightParams: {
    speed: 0.3,        // Reduced from 1.0 to minimize trails when static
    energy: 1.0,
    altitude: 0,
    bankAngle: 0,
    pitchAngle: 0
  },
  
  // Default environment
  environment: {
    windDirection: new Vector3(1, 0, 0),
    windStrength: 0.2,
    turbulence: 0.0
  },
  
  // Actions
  setModelType: (modelType) => {
    // For "none" option, hide the bird
    if (modelType === 'none') {
      set({
        modelType,
        isBirdVisible: false,
        modelAdjustments: {
          scale: 1.0,
          positionX: 0,
          positionY: 0,
          positionZ: 0
        }
      });
    }
    // For anatomy model, set specific default adjustments
    else if (modelType === 'anatomy') {
      set({
        modelType,
        isBirdVisible: true,
        modelAdjustments: {
          scale: 1.10,
          positionX: 0.3,
          positionY: 0.4,
          positionZ: 3.0
        }
      });
    } 
    // For bird model, set specific default adjustments
    else if (modelType === 'bird') {
      set({
        modelType,
        isBirdVisible: true,
        modelAdjustments: {
          scale: 31.20,
          positionX: 0.0,
          positionY: 0.0,
          positionZ: -3.1
        }
      });
    }
    // For shark model, set specific default adjustments
    else if (modelType === 'shark') {
      set({
        modelType,
        isBirdVisible: true,
        modelAdjustments: {
          scale: 18.45,
          positionX: 1.8,
          positionY: -0.1,
          positionZ: 1
        }
      });
    }
    else {
      // For other models, reset to default values
      set({
        modelType,
        isBirdVisible: true,
        modelAdjustments: {
          scale: 1.0,
          positionX: 0,
          positionY: 0,
          positionZ: 0
        }
      });
    }
  },
  
  setBirdVisibility: (isVisible) => set({ isBirdVisible: isVisible }),
  
  setModelScale: (scale) => set((state) => ({
    modelAdjustments: { ...state.modelAdjustments, scale }
  })),
  
  setModelPosition: (axis, value) => set((state) => ({
    modelAdjustments: { 
      ...state.modelAdjustments, 
      [`position${axis.toUpperCase()}`]: value 
    }
  })),
  
  resetModelAdjustments: () => set((state) => {
    // Use different default values for anatomy model
    if (state.modelType === 'anatomy') {
      return {
        modelAdjustments: {
          scale: 1.10,
          positionX: 0.3,
          positionY: 0.4,
          positionZ: 3.0
        }
      };
    }
    // For bird model, set specific default adjustments
    else if (state.modelType === 'bird') {
      return {
        modelAdjustments: {
          scale: 31.20,
          positionX: 0.0,
          positionY: 0.0,
          positionZ: -3.1
        }
      };
    }
    // For shark model, set specific default adjustments
    else if (state.modelType === 'shark') {
      return {
        modelAdjustments: {
          scale: 18.45,
          positionX: 1.8,
          positionY: -0.1,
          positionZ: 1
        }
      };
    }
    
    // Default values for other models
    return {
      modelAdjustments: {
        scale: 1.0,
        positionX: 0,
        positionY: 0,
        positionZ: 0
      }
    };
  }),
  
  setWindEffects: (active, intensity) => set((state) => ({
    windEffects: {
      active,
      intensity: intensity !== undefined ? intensity : state.windEffects.intensity
    }
  })),
  
  setFogSettings: (enabled, nearDistance, farDistance) => set((state) => ({
    fogSettings: {
      enabled,
      nearDistance: nearDistance !== undefined ? nearDistance : state.fogSettings.nearDistance,
      farDistance: farDistance !== undefined ? farDistance : state.fogSettings.farDistance
    }
  })),
  
  setDebugMode: (enabled) => set({ debugMode: enabled }),
  
  setTunnelSpeed: (speed) => set({ tunnelSpeed: speed }),
  
  setDefaultTunnelSpeed: (speed) => set({ defaultTunnelSpeed: speed }),
  
  setFlightParams: (params) => set((state) => ({
    flightParams: { ...state.flightParams, ...params }
  })),
  
  setEnvironment: (params) => set((state) => ({
    environment: {
      ...state.environment,
      ...params,
      windDirection: params.windDirection || state.environment.windDirection
    }
  })),
  
  // NEW: Material setting actions
  setEmissiveColor: (color) => set((state) => ({
    materialSettings: { ...state.materialSettings, emissiveColor: color }
  })),
  
  setEmissiveIntensity: (intensity) => set((state) => ({
    materialSettings: { ...state.materialSettings, emissiveIntensity: intensity }
  })),
  
  setMetalness: (value) => set((state) => ({
    materialSettings: { ...state.materialSettings, metalness: value }
  })),
  
  setRoughness: (value) => set((state) => ({
    materialSettings: { ...state.materialSettings, roughness: value }
  })),
  
  setDepthTest: (enabled) => set((state) => ({
    materialSettings: { ...state.materialSettings, depthTest: enabled }
  })),
  
  setTransparent: (enabled) => set((state) => ({
    materialSettings: { ...state.materialSettings, transparent: enabled }
  })),
  
  setRenderPriority: (value) => set((state) => ({
    materialSettings: { ...state.materialSettings, renderPriority: value }
  })),
  
  // NEW: Lighting setting actions
  setLightIntensity: (value) => set((state) => ({
    lightingSettings: { ...state.lightingSettings, lightIntensity: value }
  })),
  
  setLightColor: (color) => set((state) => ({
    lightingSettings: { ...state.lightingSettings, lightColor: color }
  })),
  
  setAmbientIntensity: (value) => set((state) => ({
    lightingSettings: { ...state.lightingSettings, ambientIntensity: value }
  })),
  
  // NEW: Reset functions
  resetMaterialSettings: () => {
    const state = get();
    
    // Set default values based on model type
    if (state.modelType === 'bird') {
      set({
        materialSettings: {
          emissiveColor: "#b8b8ff",
          emissiveIntensity: 0.00,
          metalness: 0.00,
          roughness: 0.80,
          depthTest: false,
          transparent: true,
          renderPriority: 10
        }
      });
    } else if (state.modelType === 'anatomy') {
      set({
        materialSettings: {
          emissiveColor: "#b8b8ff",
          emissiveIntensity: 0.00,
          metalness: 0.00,
          roughness: 0.80,
          depthTest: false,
          transparent: true,
          renderPriority: 10
        }
      });
    } else {
      // Default for other models
      set({
        materialSettings: {
          emissiveColor: "#b8b8ff",
          emissiveIntensity: 0.00,
          metalness: 0.00,
          roughness: 0.80,
          depthTest: false,
          transparent: true,
          renderPriority: 10
        }
      });
    }
  },
  
  resetLightingSettings: () => set({
    lightingSettings: {
      lightIntensity: 1.0,
      lightColor: "#ffffff",
      ambientIntensity: 0.5
    }
  })
}));

// Expose the bird store to the global window object for backward compatibility
// This should eventually be removed once all direct window accesses are refactored
if (typeof window !== 'undefined') {
  // @ts-ignore - adding property to window
  window.__BIRD_STORE__ = useBirdStore;
  
  // For backward compatibility, sync the store with window globals
  const syncStoreToWindow = () => {
    const state = useBirdStore.getState();
    
    // Sync visibility
    (window as any).isBirdVisible = state.isBirdVisible;
    
    // Sync model type
    (window as any).modelType = state.modelType;
    
    // Sync model adjustments
    (window as any).modelAdjustments = { ...state.modelAdjustments };
    
    // Sync wind effects
    (window as any).windEffects = { ...state.windEffects };
    
    // Sync fog settings
    (window as any).fogSettings = { ...state.fogSettings };
    
    // Sync debug mode
    (window as any).debugMode = state.debugMode;
    
    // Sync tunnel speed
    (window as any).tunnelSpeed = state.tunnelSpeed;
    (window as any).defaultTunnelSpeed = state.defaultTunnelSpeed;
  };
  
  // Subscribe to state changes and update window globals
  useBirdStore.subscribe(syncStoreToWindow);
  
  // Initial sync
  syncStoreToWindow();
} 