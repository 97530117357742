import { useState } from 'react';
import { Boxes, ChevronDown } from 'lucide-react';
import * as Collapsible from '@radix-ui/react-collapsible';
import { Switch, Slider, Tabs, TabsList, TabsTrigger, TabsContent } from '../ui';
import { useTunnelStore } from '../../store/tunnelStore';

// Add a CSS class for section containers and sliders
const sectionContainerClass = "bg-muted/30 rounded-md p-3 border border-border";
const sliderActiveClass = "[&_[role=slider]]:h-4 [&_[role=slider]]:w-4 [&_.bg-primary]:bg-primary";

// Helper type for update functions
type DeformationUpdateFn = (property: string, value: number | boolean) => void;

// Define new types for deformation settings that we'll add to the store later
export interface DeformationSettings {
  wave: {
    enabled: boolean;
    amplitude: number;
    frequency: number;
    speed: number;
  };
  ripple: {
    enabled: boolean;
    strength: number;
    frequency: number;
    speed: number;
  };
  kaleidoscope: {
    enabled: boolean;
    segments: number;
    rotation: number;
  };
  trail: {
    enabled: boolean;
    length: number;
    opacity: number;
    decay: number;
  };
  twist: {
    enabled: boolean;
    strength: number;
    radius: number;
  };
  pulse: {
    enabled: boolean;
    frequency: number;
    amplitude: number;
  };
  fractal: {
    enabled: boolean;
    iterations: number;
    scale: number;
    rotation: number;
  };
  liquid: {
    enabled: boolean;
    viscosity: number;
    turbulence: number;
    speed: number;
  };
  prism: {
    enabled: boolean;
    refraction: number;
    dispersion: number;
    rotation: number;
  };
  spiral: {
    enabled: boolean;
    tightness: number;
    direction: number;
    speed: number;
  };
  vortex: {
    enabled: boolean;
    strength: number;
    radius: number;
    speed: number;
  };
  bloom: {
    enabled: boolean;
    intensity: number;
    threshold: number;
    radius: number;
    smoothing: number;
    kernelSize: number;
    blend: number;
    saturation: number;
    highlight: number;
  };
  toneMapping: {
    enabled: boolean;
    exposure: number;
  };
}

// Default settings for deformation effects
export const defaultDeformationSettings: DeformationSettings = {
  wave: {
    enabled: false,
    amplitude: 0.1,
    frequency: 5.0,
    speed: 1.0,
  },
  ripple: {
    enabled: false,
    strength: 0.3,
    frequency: 3.0,
    speed: 1.0,
  },
  kaleidoscope: {
    enabled: false,
    segments: 8,
    rotation: 0.0,
  },
  trail: {
    enabled: false,
    length: 0.5,
    opacity: 0.7,
    decay: 0.9,
  },
  twist: {
    enabled: false,
    strength: 0.5,
    radius: 1.0,
  },
  pulse: {
    enabled: false,
    frequency: 0.5,
    amplitude: 0.2,
  },
  fractal: {
    enabled: false,
    iterations: 3,
    scale: 1.5,
    rotation: 0.2,
  },
  liquid: {
    enabled: false,
    viscosity: 0.8,
    turbulence: 0.5,
    speed: 0.3,
  },
  prism: {
    enabled: false,
    refraction: 0.8,
    dispersion: 0.5,
    rotation: 0.1,
  },
  spiral: {
    enabled: false,
    tightness: 0.6,
    direction: 1.0,
    speed: 0.2,
  },
  vortex: {
    enabled: false,
    strength: 0.7,
    radius: 0.5,
    speed: 0.3,
  },
  bloom: {
    enabled: false,
    intensity: 3.0,
    threshold: 0.2,
    radius: 0.6,
    smoothing: 0.5,
    kernelSize: 5,
    blend: 0.5,
    saturation: 1.0,
    highlight: 1.0,
  },
  toneMapping: {
    enabled: false,
    exposure: 1.0,
  },
};

// Helper component for deformation controls
interface DeformationControlProps {
  title: string;
  effect: any;
  updateEffect: DeformationUpdateFn;
  controls: {
    name: string;
    label: string;
    min: number;
    max: number;
    step: number;
    defaultValue?: number;
  }[];
}

function DeformationControl({ title, effect, updateEffect, controls }: DeformationControlProps) {
  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <label className="text-sm font-medium">{title}</label>
        <Switch 
          checked={effect.enabled}
          onCheckedChange={(checked) => updateEffect('enabled', checked)}
        />
      </div>
      
      {effect.enabled && (
        <>
          {controls.map((control) => (
            <div className="space-y-2" key={control.name}>
              <div className="flex items-center justify-between">
                <label className="text-xs text-muted-foreground">{control.label}</label>
                <span className="text-xs text-muted-foreground">
                  {typeof effect[control.name] === 'number' 
                    ? effect[control.name].toFixed(2) 
                    : control.defaultValue?.toFixed(2) || '0.00'}
                </span>
              </div>
              <Slider
                value={[typeof effect[control.name] === 'number' ? effect[control.name] : (control.defaultValue || 0)]}
                min={control.min}
                max={control.max}
                step={control.step}
                onValueChange={(values) => updateEffect(control.name, values[0])}
                className={sliderActiveClass}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export function DeformationControls() {
  const [isDeformationOpen, setIsDeformationOpen] = useState(false);
  
  // Get deformation settings from store
  const { 
    deformation,
    setDeformation
  } = useTunnelStore();

  // Generic update function for any deformation effect
  const updateDeformationSettings = (
    effectName: keyof DeformationSettings, 
    property: string, 
    value: number | boolean
  ) => {
    setDeformation({
      ...deformation,
      [effectName]: {
        ...deformation[effectName],
        [property]: value
      }
    });
  };

  // Effect update functions
  const updateWaveSettings = (property: string, value: number | boolean) => {
    updateDeformationSettings('wave', property, value);
  };

  const updateRippleSettings = (property: string, value: number | boolean) => {
    updateDeformationSettings('ripple', property, value);
  };

  const updateKaleidoscopeSettings = (property: string, value: number | boolean) => {
    updateDeformationSettings('kaleidoscope', property, value);
  };

  const updateTrailSettings = (property: string, value: number | boolean) => {
    updateDeformationSettings('trail', property, value);
  };

  const updateTwistSettings = (property: string, value: number | boolean) => {
    updateDeformationSettings('twist', property, value);
  };

  const updatePulseSettings = (property: string, value: number | boolean) => {
    updateDeformationSettings('pulse', property, value);
  };

  const updateBloomSettings = (property: string, value: number | boolean) => {
    updateDeformationSettings('bloom', property, value);
  };

  return (
    <div>
      <div 
        className="flex items-center justify-between cursor-pointer py-2 px-1 hover:bg-muted/30 rounded text-foreground"
        onClick={() => setIsDeformationOpen(!isDeformationOpen)}
      >
        <div className="flex items-center gap-2">
          <Boxes className="h-4 w-4 text-muted-foreground" />
          <h3 className="text-sm font-medium">Deformations</h3>
        </div>
        <ChevronDown className={`w-4 h-4 transition-transform ${isDeformationOpen ? 'rotate-180' : ''}`} />
      </div>
      
      <Collapsible.Root open={isDeformationOpen}>
        <Collapsible.Content>
          <div className={`mt-2 space-y-4 ${sectionContainerClass}`}>
            <Tabs defaultValue="basic" className="w-full">
              <TabsList className="grid grid-cols-4 mb-4 h-auto gap-1">
                <TabsTrigger value="basic" className="text-xs py-1">Basic</TabsTrigger>
                <TabsTrigger value="advanced" className="text-xs py-1">Advanced</TabsTrigger>
                <TabsTrigger value="bloom" className="text-xs py-1">Bloom</TabsTrigger>
                <TabsTrigger value="experimental" className="text-xs py-1">Exp</TabsTrigger>
              </TabsList>
              
              {/* Basic tab */}
              <TabsContent value="basic" className="space-y-6">
                <DeformationControl
                  title="Wave"
                  effect={deformation.wave}
                  updateEffect={updateWaveSettings}
                  controls={[
                    { name: 'amplitude', label: 'Amplitude', min: 0, max: 1, step: 0.01 },
                    { name: 'frequency', label: 'Frequency', min: 0, max: 10, step: 0.1 },
                    { name: 'speed', label: 'Speed', min: 0, max: 5, step: 0.1 }
                  ]}
                />
                
                <DeformationControl
                  title="Ripple"
                  effect={deformation.ripple}
                  updateEffect={updateRippleSettings}
                  controls={[
                    { name: 'strength', label: 'Strength', min: 0, max: 1, step: 0.01 },
                    { name: 'frequency', label: 'Frequency', min: 0, max: 10, step: 0.1 },
                    { name: 'speed', label: 'Speed', min: 0, max: 5, step: 0.1 }
                  ]}
                />
              </TabsContent>
              
              {/* Advanced tab */}
              <TabsContent value="advanced" className="space-y-6">
                <DeformationControl
                  title="Kaleidoscope"
                  effect={deformation.kaleidoscope}
                  updateEffect={updateKaleidoscopeSettings}
                  controls={[
                    { name: 'segments', label: 'Segments', min: 3, max: 20, step: 1 },
                    { name: 'rotation', label: 'Rotation', min: 0, max: 1, step: 0.01 }
                  ]}
                />
                
                <DeformationControl
                  title="Trail"
                  effect={deformation.trail}
                  updateEffect={updateTrailSettings}
                  controls={[
                    { name: 'length', label: 'Length', min: 0, max: 1, step: 0.01 },
                    { name: 'opacity', label: 'Opacity', min: 0, max: 1, step: 0.01 },
                    { name: 'decay', label: 'Decay', min: 0, max: 1, step: 0.01 }
                  ]}
                />
                
                <DeformationControl
                  title="Twist"
                  effect={deformation.twist}
                  updateEffect={updateTwistSettings}
                  controls={[
                    { name: 'strength', label: 'Strength', min: 0, max: 2, step: 0.01 },
                    { name: 'radius', label: 'Radius', min: 0, max: 5, step: 0.1 }
                  ]}
                />
                
                <DeformationControl
                  title="Pulse"
                  effect={deformation.pulse}
                  updateEffect={updatePulseSettings}
                  controls={[
                    { name: 'frequency', label: 'Frequency', min: 0, max: 2, step: 0.01 },
                    { name: 'amplitude', label: 'Amplitude', min: 0, max: 1, step: 0.01 }
                  ]}
                />
              </TabsContent>
              
              {/* Bloom tab - new dedicated tab for bloom controls */}
              <TabsContent value="bloom" className="space-y-6">
                <DeformationControl
                  title="Bloom Effect"
                  effect={deformation.bloom}
                  updateEffect={updateBloomSettings}
                  controls={[
                    { name: 'intensity', label: 'Intensity', min: 0, max: 10, step: 0.1 },
                    { name: 'threshold', label: 'Threshold', min: 0, max: 1, step: 0.01 },
                    { name: 'radius', label: 'Radius', min: 0, max: 1, step: 0.01 },
                    { name: 'smoothing', label: 'Smoothing', min: 0, max: 1, step: 0.01 },
                    { name: 'kernelSize', label: 'Kernel Size', min: 1, max: 8, step: 1 },
                    { name: 'blend', label: 'Blend', min: 0, max: 1, step: 0.01 },
                    { name: 'saturation', label: 'Saturation', min: 0, max: 2, step: 0.05 },
                    { name: 'highlight', label: 'Highlight', min: 0, max: 2, step: 0.05 }
                  ]}
                />
              </TabsContent>
            </Tabs>
          </div>
        </Collapsible.Content>
      </Collapsible.Root>
    </div>
  );
} 