import React from 'react';
import { Text } from '@react-three/drei';
import { DebugPlaneProps, DebugPlanesProps, FogConfig } from '../../types/tunnel';
import { DISAPPEAR_POINT } from '../../utils/TunnelUtils';

/**
 * A debug plane used to visualize positions in the 3D space
 */
export function DebugPlane({ 
  position, 
  color = 'red', 
  opacity = 0.3,
  size = 40,
  label
}: DebugPlaneProps) {
  return (
    <group>
      <mesh position={[0, 0, position]} rotation={[0, 0, 0]}>
        <planeGeometry args={[size, size]} />
        <meshBasicMaterial color={color} transparent opacity={opacity} side={2} fog={false} />
      </mesh>
      {/* Grid helper to make the plane more visible */}
      <gridHelper 
        args={[size, 10, color, color]} 
        position={[0, 0, position]}
        rotation={[Math.PI/2, 0, 0]}
      />
      {/* Add label if provided */}
      {label && (
        <Text
          position={[0, size/2 - 1, position]}
          color={color}
          fontSize={0.8}
          anchorX="center"
          anchorY="middle"
        >
          {label} (z: {position.toFixed(1)})
        </Text>
      )}
    </group>
  );
}

/**
 * Collection of debug planes to visualize the tunnel structure
 */
export function DebugPlanes({ 
  disappearPoint,
  tunnelConfig,
  mandalaCount,
  layersPerMandala,
  mandalaSeparation,
  layerSeparation,
  fogConfig
}: DebugPlanesProps) {
  // Calculate the total number of layers for logging
  const totalLayers = layersPerMandala.reduce((sum, count) => sum + count, 0);
  
  // Log debug information
  console.log(`Debug Planes:
    - Disappear point: ${disappearPoint}
    - Tunnel config end: ${tunnelConfig}
    - Total mandalas: ${mandalaCount}
    - Total layers: ${totalLayers}
    - Mandala separation: ${mandalaSeparation}
    - Layer separation: ${layerSeparation}
    - Fog near: ${fogConfig?.actualFogNear}
    - Fog far: ${fogConfig?.actualFogFar}
  `);

  // Create an array of debug planes
  const planes = [
    // White plane at disappear point
    { position: DISAPPEAR_POINT, color: 'white', label: 'White Plane' },
    
    // End of tunnel plane
    { position: tunnelConfig, color: 'purple', label: 'Tunnel End' },
    
    // Add fog planes if fog is enabled
    ...(fogConfig?.isFogEnabled ? [
      { position: fogConfig.actualFogNear, color: 'blue', label: 'Fog Near' },
      { position: fogConfig.actualFogFar, color: 'cyan', label: 'Fog Far' }
    ] : [])
  ];

  return (
    <>
      {planes.map((plane, index) => (
        <DebugPlane 
          key={`debug-plane-${index}`}
          position={plane.position}
          color={plane.color}
          label={plane.label}
        />
      ))}
      
      {/* Add axes helper at the origin */}
      <axesHelper args={[5]} />
      
      {/* Add grid on the bottom */}
      <gridHelper args={[50, 50, 'gray', 'gray']} position={[0, -10, 0]} />
    </>
  );
} 