import React, { useState, useEffect } from 'react';
import { Button } from './ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle, CardFooter } from './ui/card';
import { scanMandalaStorage, ScanResults } from '../utils/mandalaScanner';
import { clearCache } from '../services/mandalaService';
import { AlertCircle, CheckCircle2, CircleAlert, Loader2 } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from './ui/alert';
import { Badge } from './ui/badge';

export const AdminMandalaScanner: React.FC = () => {
  const [isScanning, setIsScanning] = useState(false);
  const [results, setResults] = useState<ScanResults | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [lastScanTime, setLastScanTime] = useState<Date | null>(null);

  useEffect(() => {
    // Reset error when results change
    if (results) {
      setError(null);
    }
  }, [results]);

  const handleScan = async () => {
    try {
      setIsScanning(true);
      setError(null);
      
      // Run the scan
      const scanResults = await scanMandalaStorage();
      setResults(scanResults);
      
      // Clear the mandala service cache to ensure fresh data
      clearCache();
      
      // Update last scan time
      setLastScanTime(new Date());
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      console.error('Error running mandala scan:', err);
    } finally {
      setIsScanning(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-col sm:flex-row sm:justify-between gap-4 items-start">
        <Button
          onClick={handleScan}
          disabled={isScanning}
          className="min-w-[120px]"
        >
          {isScanning ? (
            <span className="flex items-center gap-2">
              <Loader2 className="h-4 w-4 animate-spin" />
              Scanning...
            </span>
          ) : (
            'Scan Now'
          )}
        </Button>

        {lastScanTime && (
          <div className="text-sm text-muted-foreground">
            Last scan: {lastScanTime.toLocaleString()}
          </div>
        )}
      </div>

      {error && (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      {results && results.errorDetails.length > 0 && (
        <Alert variant="destructive" className="mt-4">
          <CircleAlert className="h-4 w-4" />
          <AlertTitle>Scan Errors</AlertTitle>
          <AlertDescription>
            <ul className="list-disc pl-5 mt-2 space-y-1">
              {results.errorDetails.map((detail, idx) => (
                <li key={idx} className="text-sm">{detail}</li>
              ))}
            </ul>
          </AlertDescription>
        </Alert>
      )}

      {results && (
        <Card className="border-muted bg-card mt-6">
          <CardHeader className="pb-2">
            <CardTitle className="text-base">Scan Results</CardTitle>
            <CardDescription>
              Summary of the last scanning operation
            </CardDescription>
          </CardHeader>
          
          <CardContent>
            <div className="grid grid-cols-2 gap-x-6 gap-y-3">
              <div className="text-sm font-medium">Processed:</div>
              <div className="text-sm">
                <Badge variant="secondary" className="font-mono">
                  {results.processed}
                </Badge>
              </div>
              
              <div className="text-sm font-medium">Created:</div>
              <div className="text-sm">
                <Badge variant="outline" className="bg-green-500/10 border-green-500/30 text-green-600 font-mono">
                  {results.created}
                </Badge>
              </div>
              
              <div className="text-sm font-medium">Updated:</div>
              <div className="text-sm">
                <Badge variant="outline" className="bg-blue-500/10 border-blue-500/30 text-blue-600 font-mono">
                  {results.updated}
                </Badge>
              </div>
              
              <div className="text-sm font-medium">Deleted:</div>
              <div className="text-sm">
                <Badge variant="outline" className="bg-orange-500/10 border-orange-500/30 text-orange-600 font-mono">
                  {results.deleted}
                </Badge>
              </div>
              
              <div className="text-sm font-medium">Errors:</div>
              <div className="text-sm">
                <Badge variant="outline" className={`font-mono ${results.errors > 0 
                  ? "bg-red-500/10 border-red-500/30 text-red-600" 
                  : "bg-muted border-muted-foreground/20"}`}>
                  {results.errors}
                </Badge>
              </div>
              
              <div className="text-sm font-medium">Skipped:</div>
              <div className="text-sm">
                <Badge variant="outline" className="bg-muted border-muted-foreground/20 font-mono">
                  {results.skipped}
                </Badge>
              </div>
            </div>
          </CardContent>
          
          <CardFooter className="pt-0 pb-2">
            <div className="text-xs text-muted-foreground italic">
              {results.errors > 0 
                ? "Some issues were encountered during the scan" 
                : results.deleted > 0
                  ? "Scan completed successfully. Some mandalas were removed as they no longer exist in storage."
                  : "Scan completed successfully"}
            </div>
          </CardFooter>
        </Card>
      )}
    </div>
  );
}; 