import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "./toast"
import { useToast } from "./use-toast"

export function Toaster() {
  const { toasts } = useToast()

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        return (
          <Toast key={id} {...props}>
            <div className="grid">
              {/* Title hidden as per request */}
              {description && (
                <ToastDescription className="font-doto text-center" style={{ fontSize: '0.8rem' }}>
                  {description}
                </ToastDescription>
              )}
            </div>
            {action}
            <ToastClose />
          </Toast>
        )
      })}
      <ToastViewport className="flex flex-col items-center gap-2 p-4 bottom-0 left-1/2 -translate-x-1/2 fixed max-h-screen m-0 max-w-[80vw] w-auto" />
    </ToastProvider>
  )
} 