import { useEffect, useRef, RefObject } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { Vector3, Quaternion, MathUtils } from 'three';

// Constants for the camera position movement (arrow keys)
const CAMERA_STEP = 0.05; // Base step size
const MAX_SPEED = 0.15; // Increased maximum speed for more dramatic inertia
const ACCELERATION = 0.015; // Reduced for slower acceleration (more inertia)
const WATER_DAMPING = 0.985; // Increased for much less damping (movement continues longer)
const SPRING_STRENGTH = 0.001; // Reduced for much slower return to center
const RETURN_THRESHOLD = 0.01; // Distance to center when we snap back completely

// Constants for camera rotation (ZQSD keys)
const ROTATION_SPEED = 0.03; // Base rotation speed
const ROTATION_ACCELERATION = 0.03; // Reduced for heavier feel
const ROTATION_DAMPING = 0.975; // Increased for much less damping (rotation continues longer)
const MAX_VERTICAL_ANGLE = Math.PI / 3; // Maximum vertical rotation (60 degrees)

export function useKeyboardCamera(controlsRef?: RefObject<any>) {
  const { camera } = useThree();
  
  // Track active keys
  const activeKeys = useRef(new Set<string>());
  
  // Track the original center position
  const centerPosition = useRef(new Vector3());
  
  // Current velocity (for inertia)
  const velocity = useRef(new Vector3(0, 0, 0));
  
  // Current rotation velocity
  const rotationVelocity = useRef({ horizontal: 0, vertical: 0 });
  
  // Current rotation angles
  const rotationAngles = useRef({ horizontal: 0, vertical: 0 });
  
  // Initialize on first render
  useEffect(() => {
    // Safety check
    if (!controlsRef?.current) {
      console.warn('⚠️ No controls ref found for keyboard camera movement');
      return;
    }
    
    // Store the initial target position
    centerPosition.current.copy(controlsRef.current.target);
    
    // Set up key listeners
    const handleKeyDown = (event: KeyboardEvent) => {
      // Track all relevant keys
      const key = event.key.toLowerCase();
      
      // Only handle relevant keys to avoid capturing text input
      if (['arrowup', 'arrowdown', 'arrowleft', 'arrowright', 'z', 'q', 's', 'd'].includes(key)) {
        activeKeys.current.add(key);
        event.preventDefault();
      }
    };
    
    const handleKeyUp = (event: KeyboardEvent) => {
      // Remove key from active keys
      const key = event.key.toLowerCase();
      
      // Only handle relevant keys
      if (['arrowup', 'arrowdown', 'arrowleft', 'arrowright', 'z', 'q', 's', 'd'].includes(key)) {
        activeKeys.current.delete(key);
        event.preventDefault();
      }
    };
    
    // Add event listeners
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [controlsRef]);
  
  // Handle camera movement and rotation in the animation frame
  useFrame((_, delta) => {
    // Skip if we don't have controls
    if (!controlsRef?.current) return;
    
    const controls = controlsRef.current;
    const normalizedDelta = delta * 60; // Normalize for 60fps
    
    // POSITION MOVEMENT WITH ARROW KEYS
    
    // Target acceleration based on arrow key presses
    let targetVx = 0;
    let targetVy = 0;
    
    if (activeKeys.current.has('arrowright')) targetVx = CAMERA_STEP;
    if (activeKeys.current.has('arrowleft')) targetVx = -CAMERA_STEP;
    if (activeKeys.current.has('arrowup')) targetVy = CAMERA_STEP;
    if (activeKeys.current.has('arrowdown')) targetVy = -CAMERA_STEP;
    
    // Apply acceleration for position change (reduced for more inertia)
    velocity.current.x += (targetVx - velocity.current.x) * ACCELERATION * normalizedDelta;
    velocity.current.y += (targetVy - velocity.current.y) * ACCELERATION * normalizedDelta;
    
    // Apply reduced damping for position (longer inertia)
    velocity.current.multiplyScalar(WATER_DAMPING);
    
    // Clamp maximum speed
    if (velocity.current.length() > MAX_SPEED) {
      velocity.current.normalize().multiplyScalar(MAX_SPEED);
    }
    
    // Only apply movement if we have some velocity - using a much lower threshold for longer tailing effect
    if (velocity.current.length() > 0.0001) {
      // Apply movement to both target and camera
      const moveX = velocity.current.x * normalizedDelta;
      const moveY = velocity.current.y * normalizedDelta;
      
      controls.target.x += moveX;
      controls.target.y += moveY;
      camera.position.x += moveX;
      camera.position.y += moveY;
      
      // Force controls to update
      controls.update();
    }
    
    // ROTATION WITH ZQSD KEYS
    
    // Calculate target rotation velocity based on ZQSD key presses
    let targetHorizontalRot = 0;
    let targetVerticalRot = 0;
    
    if (activeKeys.current.has('q')) targetHorizontalRot = ROTATION_SPEED;
    if (activeKeys.current.has('d')) targetHorizontalRot = -ROTATION_SPEED;
    if (activeKeys.current.has('z')) targetVerticalRot = -ROTATION_SPEED;
    if (activeKeys.current.has('s')) targetVerticalRot = ROTATION_SPEED;
    
    // Apply acceleration for rotation (reduced for more inertia)
    rotationVelocity.current.horizontal += (targetHorizontalRot - rotationVelocity.current.horizontal) * ROTATION_ACCELERATION * normalizedDelta;
    rotationVelocity.current.vertical += (targetVerticalRot - rotationVelocity.current.vertical) * ROTATION_ACCELERATION * normalizedDelta;
    
    // Apply reduced damping for rotation (longer inertia)
    rotationVelocity.current.horizontal *= ROTATION_DAMPING;
    rotationVelocity.current.vertical *= ROTATION_DAMPING;
    
    // Apply rotation if we have velocity - using a much lower threshold for longer rotation feel
    if (Math.abs(rotationVelocity.current.horizontal) > 0.00001 || Math.abs(rotationVelocity.current.vertical) > 0.00001) {
      // Update rotation angles
      rotationAngles.current.horizontal += rotationVelocity.current.horizontal * normalizedDelta;
      rotationAngles.current.vertical += rotationVelocity.current.vertical * normalizedDelta;
      
      // Clamp vertical angle to prevent flipping
      rotationAngles.current.vertical = MathUtils.clamp(
        rotationAngles.current.vertical, 
        -MAX_VERTICAL_ANGLE, 
        MAX_VERTICAL_ANGLE
      );
      
      // Calculate the new camera position based on rotation around the target
      const distance = camera.position.distanceTo(controls.target);
      
      // Calculate position using spherical coordinates
      const phi = Math.PI/2 - rotationAngles.current.vertical; // vertical angle
      const theta = rotationAngles.current.horizontal; // horizontal angle
      
      const x = distance * Math.sin(phi) * Math.sin(theta);
      const y = distance * Math.cos(phi);
      const z = distance * Math.sin(phi) * Math.cos(theta);
      
      // Set camera position relative to target
      camera.position.x = controls.target.x + x;
      camera.position.y = controls.target.y + y;
      camera.position.z = controls.target.z + z;
      
      // Make sure camera looks at target
      camera.lookAt(controls.target);
      
      // Force controls to update
      controls.update();
    }
    
    // Return to center if no keys are pressed and we're far from center - much slower return
    if (activeKeys.current.size === 0 && 
        controls.target.distanceTo(centerPosition.current) > RETURN_THRESHOLD) {
      
      // Calculate return vector with much gentler spring effect
      const returnVec = new Vector3().subVectors(centerPosition.current, controls.target);
      const returnAmount = returnVec.multiplyScalar(SPRING_STRENGTH * normalizedDelta);
      
      // Apply return movement
      controls.target.add(returnAmount);
      camera.position.add(returnAmount);
      
      // Force controls to update
      controls.update();
      
      // Snap to center if very close
      if (controls.target.distanceTo(centerPosition.current) < RETURN_THRESHOLD) {
        controls.target.copy(centerPosition.current);
        velocity.current.set(0, 0, 0); // Reset velocity
        controls.update();
      }
    }
  });
  
  return null;
}

export default useKeyboardCamera; 