import { useRef, useMemo, useEffect } from 'react';
// Color might be needed for future color manipulations
// @ts-ignore
import { Mesh, Texture, MeshBasicMaterial, PlaneGeometry, DoubleSide, Color } from 'three';
import { useFrame } from '@react-three/fiber';
import { useTunnelStore } from '../store/tunnelStore';
import { Plane } from '@react-three/drei';

interface MandalaLayerProps {
  texture: Texture;
  initialPosition: number;
  speed: number;
  rotationSpeed: number;
  size?: number;
  respawnPoint: number;
  disappearPoint: number;
  opacity?: number;
  debug?: boolean;
  debugColor?: string;
}

interface LayerState {
  position: number;
  speed: number;
  opacity: number;
  scale: number;
  targetScale: number;
  speedMultiplier: number;
}

export function MandalaLayer({ 
  texture, 
  initialPosition,
  speed, 
  rotationSpeed, 
  size = 10,
  respawnPoint,
  disappearPoint,
  opacity = 1,
  debug = false,
  debugColor = 'white'
}: MandalaLayerProps) {
  const meshRef = useRef<Mesh>(null);
  const materialRef = useRef<MeshBasicMaterial>(null);
  
  // Keeping postProcessing reference for potential future use
  // @ts-ignore
  const { postProcessing } = useTunnelStore();
  
  // Create a state object for this layer
  const state = useRef<LayerState>({
    position: initialPosition,
    speed: speed,
    opacity: opacity,
    scale: 1.0, // Base scale
    targetScale: 1.0,
    speedMultiplier: 1.0 // Default speed multiplier
  }).current;

  // Update position when initialPosition changes
  useEffect(() => {
    state.position = initialPosition;
    if (meshRef.current) {
      meshRef.current.position.z = initialPosition;
    }
  }, [initialPosition]);

  // Memoize geometry and material properties
  const geometry = useMemo(() => new PlaneGeometry(size, size), [size]);
  
  const materialProps = useMemo(() => ({
    map: debug ? null : texture,  // Only use texture when not in debug mode
    color: debug ? debugColor : 'white',
    transparent: true,
    depthWrite: true,  // Enable depth writing for proper fog
    depthTest: true,   // Ensure depth testing is enabled
    side: DoubleSide,
    fog: true,         // Enable fog on mandala layers
    opacity: debug ? 0.15 : opacity,
    wireframe: debug
  }), [debug, texture, debugColor, opacity]);

  useFrame((_, delta) => {
    if (meshRef.current && materialRef.current) {
      const s = state;
      
      // Use the speed prop directly instead of state interpolation
      // Update position using speed directly from props
      s.position += speed * delta;
      meshRef.current.position.z = s.position;

      // Start fade out when approaching disappear point
      const FADE_DISTANCE = 1; // Distance over which to fade out
      const distanceToDisappear = disappearPoint - s.position;
      
      if (distanceToDisappear < FADE_DISTANCE && distanceToDisappear > 0) {
        // Linear fade out over FADE_DISTANCE
        s.opacity = opacity * (distanceToDisappear / FADE_DISTANCE);
      } else if (s.position >= disappearPoint) {
        // Reset to respawn point
        s.position = respawnPoint;
        meshRef.current.position.z = respawnPoint;
        s.opacity = opacity; // Reset opacity
      } else if (s.position < respawnPoint + FADE_DISTANCE) {
        // Fade in at respawn
        const fadeInProgress = (s.position - respawnPoint) / FADE_DISTANCE;
        s.opacity = opacity * fadeInProgress;
      } else {
        // Normal visibility
        s.opacity = opacity;
      }

      // Apply opacity
      materialRef.current.opacity = s.opacity;

      // Apply rotation directly from props
      meshRef.current.rotation.z += rotationSpeed * delta;
      
      // Set a static scale
      meshRef.current.scale.set(1.0, 1.0, 1.0);
    }
  });

  return (
    <mesh
      ref={meshRef}
      position={[0, 0, state.position]}
      geometry={geometry}
    >
      <meshBasicMaterial
        ref={materialRef}
        {...materialProps}
      />
    </mesh>
  );
} 