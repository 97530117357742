import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import { useMotionControl } from '../Tunnel';
import { useBirdStore } from './BirdStore';

// Define model paths - updated to include shark model
const MODEL_PATHS = {
  none: '', // No model path for "no model" option
  phoenix: '/Models/phoenix/scene.gltf',   // Path to phoenix model
  bird: '/Models/bird/scene.gltf',         // Path to bird model
  anatomy: '/Models/anatomy/scene.gltf',   // Path to anatomy model
  shark: '/Models/shark/scene.gltf'        // Path to shark model
};

// Let's verify the bird model path
console.log('Bird model path:', MODEL_PATHS.bird);

// Define model scales - adjusted for better consistency
const MODEL_SCALES = {
  none: 0, // No scale for "no model" option
  phoenix: 0.005,
  bird: 0.02,          // Adjusted to match UI scale of ~31.20
  anatomy: 0.04,     
  shark: 0.01          // Increased from 0.006 for better visibility
};

// Define model-specific rotations (in radians) to ensure consistent orientation
// Format: [rotationX, rotationY, rotationZ]
const MODEL_ROTATIONS = {
  none: [0, 0, 0], // Default rotation for "no model" option
  phoenix: [0, 0, 0],                     // Default orientation
  bird: [0, Math.PI * 1.5, 0],            // Rotate 270° around Y-axis to face correct direction
  anatomy: [0, Math.PI * 1.5, 0],         // Rotate 270° around Y-axis
  shark: [0, Math.PI * 1.5, 0]            // Rotate 270° around Y-axis to face toward tunnel
};

// Define model-specific positions
const MODEL_POSITIONS = {
  none: [0, 0, 0], // Default position for "no model" option
  phoenix: [0, 0, 0],                    // Default position
  bird: [0, 0.3, 3.1],                   // Exact position from UI (positive Z, not negative)
  anatomy: [0, -0.5, -3],                // Centered, moved closer to bottom, forward in view
  shark: [-0.9, 0.5, -0.4]               // Updated default position based on user preference
};

interface BirdModelProps {
  scale?: number;
  renderOrder?: number;
  onModelLoaded?: (scene: THREE.Group) => void;
}

export function BirdModel({ scale = 1, renderOrder, onModelLoaded }: BirdModelProps) {
  const birdRef = useRef<THREE.Group>(null);
  const [mixer, setMixer] = useState<THREE.AnimationMixer | null>(null);
  const hasLoggedModelLoad = useRef<boolean>(false);
  const previousModelType = useRef<string | null>(null);
  
  // Get the global playing state from the motion control store
  const { isPlaying } = useMotionControl();
  
  // Get bird state from store
  const tunnelSpeed = useBirdStore(state => state.tunnelSpeed);
  const modelType = useBirdStore(state => state.modelType);
  const modelAdjustments = useBirdStore(state => state.modelAdjustments);
  const initModelAdjustments = useBirdStore(state => state.initModelAdjustments);
  
  // Get material settings from store
  const materialSettings = useBirdStore(state => state.materialSettings);
  
  // Get lighting settings from store
  const lightingSettings = useBirdStore(state => state.lightingSettings);
  
  // Initialize model adjustments when model type changes or component mounts
  useEffect(() => {
    initModelAdjustments();
    
    // Reset logging flag when model type changes
    if (previousModelType.current !== modelType) {
      hasLoggedModelLoad.current = false;
      previousModelType.current = modelType;
    }
  }, [modelType, initModelAdjustments]);
  
  // Don't render anything if "none" is selected
  if (modelType === 'none') {
    return null;
  }
  
  // Load the GLTF model based on the selected model type
  const modelPath = MODEL_PATHS[modelType];
  
  // Apply model adjustments to scale and position
  const baseModelScale = MODEL_SCALES[modelType];
  const modelScale = baseModelScale * scale * modelAdjustments.scale;
  
  // Get base position from model type
  const basePosition = MODEL_POSITIONS[modelType];
  // Apply custom position adjustments from the store
  const modelPosition = [
    basePosition[0] + modelAdjustments.positionX,
    basePosition[1] + modelAdjustments.positionY,
    basePosition[2] + modelAdjustments.positionZ
  ];
  
  const modelRotation = MODEL_ROTATIONS[modelType];
  
  // Helper function to apply model-specific adjustments after loading
  const applyModelAdjustments = useCallback((scene: THREE.Group) => {
    // Apply model-specific adjustments based on model type
    if (modelType === 'shark') {
      // Remove material modifications for shark to show original texture
      console.log('Using original shark materials');
      // Apply material settings from store
      scene.traverse((object) => {
        if (object instanceof THREE.Mesh && object.material) {
          if (object.material instanceof THREE.MeshStandardMaterial) {
            // Apply material settings from store
            const hexColor = parseInt(materialSettings.emissiveColor.replace('#', '0x'), 16);
            object.material.emissive = new THREE.Color(hexColor);
            object.material.emissiveIntensity = materialSettings.emissiveIntensity;
            object.material.metalness = materialSettings.metalness;
            object.material.roughness = materialSettings.roughness;
            object.material.transparent = materialSettings.transparent;
            object.material.depthTest = materialSettings.depthTest;
            object.material.depthWrite = !materialSettings.transparent;
            object.material.needsUpdate = true;
          }
        }
      });
    } else if (modelType === 'anatomy') {
      // Enhance anatomy model materials to be more visible in the tunnel
      scene.traverse((object) => {
        if (object instanceof THREE.Mesh) {
          if (object.material && object.material instanceof THREE.MeshStandardMaterial) {
            // Apply material settings from store
            const hexColor = parseInt(materialSettings.emissiveColor.replace('#', '0x'), 16);
            object.material.emissive = new THREE.Color(hexColor);
            object.material.emissiveIntensity = materialSettings.emissiveIntensity;
            object.material.metalness = materialSettings.metalness;
            object.material.roughness = materialSettings.roughness;
            object.material.transparent = materialSettings.transparent;
            object.material.depthTest = materialSettings.depthTest;
            object.material.depthWrite = !materialSettings.transparent;
            object.material.needsUpdate = true;
          }
        }
      });
    } else if (modelType === 'bird') {
      // Bird-specific adjustments
      scene.traverse((object) => {
        if (object instanceof THREE.Mesh) {
          // Enhance bird materials for better visibility
          if (object.material && object.material instanceof THREE.MeshStandardMaterial) {
            // Apply material settings from store
            const hexColor = parseInt(materialSettings.emissiveColor.replace('#', '0x'), 16);
            object.material.emissive = new THREE.Color(hexColor);
            object.material.emissiveIntensity = materialSettings.emissiveIntensity;
            object.material.metalness = materialSettings.metalness;
            object.material.roughness = materialSettings.roughness;
            object.material.transparent = materialSettings.transparent;
            object.material.depthTest = materialSettings.depthTest;
            object.material.depthWrite = !materialSettings.transparent;
            object.material.needsUpdate = true;
          }
        }
      });
    } else {
      // Default material settings for other models
      scene.traverse((object) => {
        if (object instanceof THREE.Mesh && object.material) {
          if (object.material instanceof THREE.MeshStandardMaterial) {
            // Apply material settings from store
            const hexColor = parseInt(materialSettings.emissiveColor.replace('#', '0x'), 16);
            object.material.emissive = new THREE.Color(hexColor);
            object.material.emissiveIntensity = materialSettings.emissiveIntensity;
            object.material.metalness = materialSettings.metalness;
            object.material.roughness = materialSettings.roughness;
            object.material.transparent = materialSettings.transparent;
            object.material.depthTest = materialSettings.depthTest;
            object.material.depthWrite = !materialSettings.transparent;
            object.material.needsUpdate = true;
          }
        }
      });
    }

    return scene;
  }, [modelType, materialSettings]);
  
  // Load the GLTF model from the location
  const { scene, animations } = useGLTF(modelPath);
  
  // Notify parent when model is loaded
  useEffect(() => {
    if (scene && !hasLoggedModelLoad.current) {
      // Apply model-specific adjustments
      const adjustedScene = applyModelAdjustments(scene);
      
      // Log successful loading - only once per model load
      console.log(`${modelType} model loaded successfully from ${modelPath}`);
      hasLoggedModelLoad.current = true;
      
      if (onModelLoaded) {
        onModelLoaded(adjustedScene);
      }
    } else if (!scene) {
      console.error(`Failed to load model: ${modelType} from path: ${modelPath}`);
      hasLoggedModelLoad.current = false;
    }
  }, [scene, onModelLoaded, applyModelAdjustments, modelType, modelPath]);
  
  // For renderOrder useEffect, update to use materialSettings
  useEffect(() => {
    if (birdRef.current && renderOrder !== undefined) {
      // Set render order on the group
      birdRef.current.renderOrder = materialSettings.renderPriority;
      
      // Apply to all child meshes to ensure entire bird renders on top
      birdRef.current.traverse((child) => {
        if ((child as THREE.Mesh).isMesh) {
          const mesh = child as THREE.Mesh;
          mesh.renderOrder = materialSettings.renderPriority;
          
          // Force the bird to render on top by using the right combination of settings
          if (mesh.material) {
            // Handle both single materials and material arrays
            if (Array.isArray(mesh.material)) {
              mesh.material.forEach(mat => {
                // Apply settings directly from the store
                mat.depthTest = materialSettings.depthTest;
                mat.depthWrite = !materialSettings.transparent; // Only write to depth buffer if not transparent
                mat.transparent = materialSettings.transparent;
                mat.opacity = 1.0;      // Keep fully opaque
                mat.needsUpdate = true;
              });
            } else {
              // Apply settings directly from the store
              mesh.material.depthTest = materialSettings.depthTest;
              mesh.material.depthWrite = !materialSettings.transparent; // Only write to depth buffer if not transparent
              mesh.material.transparent = materialSettings.transparent;
              mesh.material.opacity = 1.0;      // Keep fully opaque
              mesh.material.needsUpdate = true;
            }
          }
          
          // Don't use custom layers - reset to default layer
          mesh.layers.set(0);
        }
      });
      
      // Reset to default layer
      birdRef.current.layers.set(0);
    }
  }, [renderOrder, modelType, materialSettings]);
  
  // Set up the model on load
  useEffect(() => {
    if (scene) {
      // Keep the original materials for the model
      scene.traverse((object) => {
        // Make everything visible
        object.visible = true;
        
        // Enhance materials slightly if needed
        if (object instanceof THREE.Mesh && object.material) {
          // Ensure materials are properly lit
          if (object.material instanceof THREE.MeshStandardMaterial) {
            object.material.emissiveIntensity = 0.5;
            object.material.needsUpdate = true;
          }
        }
      });
      
      // Create an animation mixer if there are animations
      if (animations && animations.length > 0) {
        const newMixer = new THREE.AnimationMixer(scene);
        
        // Slow down all animations to 0.25x speed
        newMixer.timeScale = 0.25;
        
        // Play all animations
        animations.forEach(clip => {
          console.log(`Playing animation: ${clip.name}, duration: ${clip.duration}`);
          const action = newMixer.clipAction(clip);
          action.play();
        });
        
        setMixer(newMixer);
      } else {
        console.log(`No animations found for ${modelType} model`);
      }
    }
    
    return () => {
      if (mixer) mixer.stopAllAction();
    };
  }, [scene, animations, modelType]);
  
  // Update animation mixer on each frame
  useEffect(() => {
    // Create a cleanup function for animation frame
    let animationFrameId: number;
    let lastTime = 0;
    
    // Animation loop function
    const animate = (time: number) => {
      animationFrameId = requestAnimationFrame(animate);
      
      // Calculate delta time in seconds
      const delta = (time - lastTime) / 1000;
      lastTime = time;
      
      // Only update the animation mixer when playing
      if (mixer && isPlaying && delta > 0 && delta < 0.1) { // Avoid huge deltas on tab switch
        mixer.update(delta * tunnelSpeed); // Scale animation with tunnel speed
      }
    };
    
    // Start animation loop
    animationFrameId = requestAnimationFrame(animate);
    
    // Clean up animation frame
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [mixer, isPlaying, tunnelSpeed]);
  
  return (
    <group ref={birdRef}>
      {/* Add point light with settings from the store */}
      <pointLight 
        position={[0, 0, -3]} 
        intensity={lightingSettings.lightIntensity}
        distance={10}
        decay={2}
        color={lightingSettings.lightColor}
      />
      
      {/* Add ambient light to provide base illumination */}
      <ambientLight 
        intensity={lightingSettings.ambientIntensity} 
        color={lightingSettings.lightColor}
      />
      
      {/* Render the model with proper orientation and scale */}
      <primitive 
        object={scene} 
        scale={[modelScale, modelScale, modelScale]} 
        position={modelPosition}
        rotation={modelRotation}
      />
    </group>
  );
}

// Preload only the bird model since it's the default
useGLTF.preload(MODEL_PATHS.bird); 