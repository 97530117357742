import { useState } from 'react';
import { Sparkles, ChevronDown } from 'lucide-react';
import * as Collapsible from '@radix-ui/react-collapsible';
import { Switch, Slider, Tabs, TabsList, TabsTrigger, TabsContent } from '../ui';
import { useTunnelStore } from '../../store/tunnelStore';
import { PostProcessingSettings } from '../../types/scene';

// Add a CSS class for section containers and sliders
const sectionContainerClass = "bg-muted/30 rounded-md p-3 border border-border";
const sliderActiveClass = "[&_[role=slider]]:h-4 [&_[role=slider]]:w-4 [&_.bg-primary]:bg-primary";

// Helper type for update functions
type EffectUpdateFn = (property: string, value: number | boolean) => void;

// Helper component for effect controls
interface EffectControlsProps {
  title: string;
  effect: any;
  updateEffect: EffectUpdateFn;
  controls: {
    name: string;
    label: string;
    min: number;
    max: number;
    step: number;
  }[];
}

function EffectControls({ title, effect, updateEffect, controls }: EffectControlsProps) {
  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <label className="text-sm font-medium">{title}</label>
        <Switch 
          checked={effect.enabled}
          onCheckedChange={(checked) => updateEffect('enabled', checked)}
        />
      </div>
      
      {effect.enabled && (
        <>
          {controls.map((control) => (
            <div className="space-y-2" key={control.name}>
              <div className="flex items-center justify-between">
                <label className="text-xs text-muted-foreground">{control.label}</label>
                <span className="text-xs text-muted-foreground">
                  {effect[control.name].toFixed(2)}
                </span>
              </div>
              <Slider
                value={[effect[control.name]]}
                min={control.min}
                max={control.max}
                step={control.step}
                onValueChange={(values) => updateEffect(control.name, values[0])}
                className={sliderActiveClass}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export function EffectsControls() {
  const [isEffectsOpen, setIsEffectsOpen] = useState(false);
  
  // Get post-processing settings from store
  const { 
    postProcessing, 
    setPostProcessing 
  } = useTunnelStore();

  // Generic update function for any effect
  const updateEffectSettings = (effectName: keyof PostProcessingSettings, property: string, value: number | boolean) => {
    setPostProcessing({
      ...postProcessing,
      [effectName]: {
        ...postProcessing[effectName],
        [property]: value
      }
    });
  };

  // Effect update functions
  const updateBloomSettings = (property: string, value: number | boolean) => {
    updateEffectSettings('bloom', property, value);
  };

  const updateColorCorrectionSettings = (property: string, value: number | boolean) => {
    updateEffectSettings('colorCorrection', property, value);
  };

  const updateNoiseSettings = (property: string, value: number | boolean) => {
    updateEffectSettings('noise', property, value);
  };

  const updateVignetteSettings = (property: string, value: number | boolean) => {
    updateEffectSettings('vignette', property, value);
  };

  const updateDepthOfFieldSettings = (property: string, value: number | boolean) => {
    updateEffectSettings('depthOfField', property, value);
  };

  const updateChromaticAberrationSettings = (property: string, value: number | boolean) => {
    updateEffectSettings('chromaticAberration', property, value);
  };

  const updateSepiaSettings = (property: string, value: number | boolean) => {
    updateEffectSettings('sepia', property, value);
  };

  const updatePixelationSettings = (property: string, value: number | boolean) => {
    updateEffectSettings('pixelation', property, value);
  };

  const updateToneMappingSettings = (property: string, value: number | boolean) => {
    updateEffectSettings('toneMapping', property, value);
  };

  return (
    <div>
      <div 
        className="flex items-center justify-between cursor-pointer py-2 px-1 hover:bg-muted/30 rounded text-foreground"
        onClick={() => setIsEffectsOpen(!isEffectsOpen)}
      >
        <div className="flex items-center gap-2">
          <Sparkles className="h-4 w-4 text-muted-foreground" />
          <h3 className="text-sm font-medium">Effects</h3>
        </div>
        <ChevronDown className={`w-4 h-4 transition-transform ${isEffectsOpen ? 'rotate-180' : ''}`} />
      </div>
      
      <Collapsible.Root open={isEffectsOpen}>
        <Collapsible.Content>
          <div className={`mt-2 space-y-4 ${sectionContainerClass}`}>
            <Tabs defaultValue="bloom" className="w-full">
              <TabsList className="grid grid-cols-4 mb-4 h-auto gap-1">
                <TabsTrigger value="bloom" className="text-xs py-1">Bloom</TabsTrigger>
                <TabsTrigger value="color" className="text-xs py-1">Color</TabsTrigger>
                <TabsTrigger value="distortion" className="text-xs py-1">Distortion</TabsTrigger>
                <TabsTrigger value="stylized" className="text-xs py-1">Stylized</TabsTrigger>
              </TabsList>
              
              {/* Bloom tab */}
              <TabsContent value="bloom" className="space-y-6">
                <EffectControls
                  title="Bloom"
                  effect={postProcessing.bloom}
                  updateEffect={updateBloomSettings}
                  controls={[
                    { name: 'strength', label: 'Strength', min: 0, max: 3, step: 0.01 },
                    { name: 'radius', label: 'Radius', min: 0.1, max: 1, step: 0.01 },
                    { name: 'threshold', label: 'Threshold', min: 0, max: 1, step: 0.01 }
                  ]}
                />
              </TabsContent>
              
              {/* Color tab */}
              <TabsContent value="color" className="space-y-6">
                <EffectControls
                  title="Color Correction"
                  effect={postProcessing.colorCorrection}
                  updateEffect={updateColorCorrectionSettings}
                  controls={[
                    { name: 'brightness', label: 'Brightness', min: 0, max: 2, step: 0.01 },
                    { name: 'contrast', label: 'Contrast', min: 0, max: 2, step: 0.01 },
                    { name: 'saturation', label: 'Saturation', min: 0, max: 2, step: 0.01 }
                  ]}
                />
                
                <EffectControls
                  title="Tone Mapping"
                  effect={postProcessing.toneMapping}
                  updateEffect={updateToneMappingSettings}
                  controls={[
                    { name: 'exposure', label: 'Exposure', min: 0.1, max: 2, step: 0.05 }
                  ]}
                />
                
                <EffectControls
                  title="Sepia"
                  effect={postProcessing.sepia}
                  updateEffect={updateSepiaSettings}
                  controls={[
                    { name: 'intensity', label: 'Intensity', min: 0, max: 1, step: 0.01 }
                  ]}
                />
              </TabsContent>
              
              {/* Distortion tab */}
              <TabsContent value="distortion" className="space-y-6">
                <EffectControls
                  title="Chromatic Aberration"
                  effect={postProcessing.chromaticAberration}
                  updateEffect={updateChromaticAberrationSettings}
                  controls={[
                    { name: 'offset', label: 'Offset', min: 0, max: 0.03, step: 0.001 }
                  ]}
                />
                
                <EffectControls
                  title="Noise"
                  effect={postProcessing.noise}
                  updateEffect={updateNoiseSettings}
                  controls={[
                    { name: 'opacity', label: 'Opacity', min: 0, max: 1, step: 0.01 }
                  ]}
                />
                
                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <label className="text-xs text-muted-foreground">Premultiply Noise</label>
                    <Switch 
                      checked={postProcessing.noise.premultiply}
                      onCheckedChange={(checked) => updateNoiseSettings('premultiply', checked)}
                      disabled={!postProcessing.noise.enabled}
                    />
                  </div>
                </div>
              </TabsContent>
              
              {/* Stylized tab */}
              <TabsContent value="stylized" className="space-y-6">
                <EffectControls
                  title="Vignette"
                  effect={postProcessing.vignette}
                  updateEffect={updateVignetteSettings}
                  controls={[
                    { name: 'darkness', label: 'Darkness', min: 0, max: 1.5, step: 0.01 },
                    { name: 'offset', label: 'Offset', min: 0, max: 1, step: 0.01 }
                  ]}
                />
                
                <EffectControls
                  title="Depth of Field"
                  effect={postProcessing.depthOfField}
                  updateEffect={updateDepthOfFieldSettings}
                  controls={[
                    { name: 'focusDistance', label: 'Focus Distance', min: 0, max: 1, step: 0.01 },
                    { name: 'focalLength', label: 'Focal Length', min: 0, max: 0.1, step: 0.001 },
                    { name: 'bokehScale', label: 'Bokeh Scale', min: 0, max: 10, step: 0.1 }
                  ]}
                />
                
                <EffectControls
                  title="Pixelation"
                  effect={postProcessing.pixelation}
                  updateEffect={updatePixelationSettings}
                  controls={[
                    { name: 'granularity', label: 'Granularity', min: 1, max: 50, step: 1 }
                  ]}
                />
              </TabsContent>
            </Tabs>
          </div>
        </Collapsible.Content>
      </Collapsible.Root>
    </div>
  );
} 