import { useEffect, useState } from 'react';

export function useFPS() {
  const [fps, setFPS] = useState(0);
  
  useEffect(() => {
    let frameCount = 0;
    let lastTime = performance.now();
    
    function measure() {
      const currentTime = performance.now();
      frameCount++;
      
      if (currentTime >= lastTime + 1000) {
        setFPS(Math.round(frameCount * 1000 / (currentTime - lastTime)));
        frameCount = 0;
        lastTime = currentTime;
      }
      
      requestAnimationFrame(measure);
    }
    
    const handle = requestAnimationFrame(measure);
    return () => cancelAnimationFrame(handle);
  }, []);
  
  return fps;
} 