import { useState, useCallback } from 'react';
import { Layers, Palette, RefreshCw, ChevronDown } from 'lucide-react';
import * as Collapsible from '@radix-ui/react-collapsible';
import { Input, Slider } from '../ui';
import { useTunnelStore } from '../../store/tunnelStore';

// Add a CSS class for section containers and sliders
const sectionContainerClass = "bg-muted/30 rounded-md p-3 border border-border";
const sliderActiveClass = "[&_[role=slider]]:h-4 [&_[role=slider]]:w-4 [&_.bg-primary]:bg-primary";

interface TunnelConfigurationProps {
  defaultBackgroundColor: string;
}

export function TunnelConfiguration({ defaultBackgroundColor }: TunnelConfigurationProps) {
  const [isTunnelOpen, setIsTunnelOpen] = useState(false);
  
  // Use selective subscriptions with Zustand selectors
  const layerSeparation = useTunnelStore(state => state.layerSeparation);
  const mandalaSeparation = useTunnelStore(state => state.mandalaSeparation);
  const backgroundColor = useTunnelStore(state => state.backgroundColor);
  
  // Get actions separately to avoid re-renders when other state changes
  const setLayerSeparation = useTunnelStore(state => state.setLayerSeparation);
  const setMandalaSeparation = useTunnelStore(state => state.setMandalaSeparation);
  const setBackgroundColor = useTunnelStore(state => state.setBackgroundColor);

  // Memoize the background color change handler to prevent unnecessary function creation
  const handleBackgroundColorChange = useCallback((color: string) => {
    // Update the background color in the store
    setBackgroundColor(color);
    // Note: The fog color is synced with backgroundColor through the FogControls component
  }, [setBackgroundColor]);

  return (
    <div>
      <div 
        className="flex items-center justify-between cursor-pointer py-2 px-1 hover:bg-muted/30 rounded text-foreground"
        onClick={() => setIsTunnelOpen(!isTunnelOpen)}
      >
        <div className="flex items-center gap-2">
          <Layers className="h-4 w-4 text-muted-foreground" />
          <h3 className="text-sm font-medium">Tunnel Configuration</h3>
        </div>
        <ChevronDown className={`w-4 h-4 transition-transform ${isTunnelOpen ? 'rotate-180' : ''}`} />
      </div>
      
      <Collapsible.Root open={isTunnelOpen}>
        <Collapsible.Content>
          <div className={`mt-2 space-y-6 ${sectionContainerClass}`}>
            {/* Background Color Picker */}
            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Palette className="h-4 w-4 text-muted-foreground" />
                  <label className="text-sm font-medium">Background Color</label>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => handleBackgroundColorChange(defaultBackgroundColor)}
                    className="text-muted-foreground/50 hover:text-muted-foreground/80 transition-colors"
                    title="Reset to default color"
                  >
                    <RefreshCw className="h-3 w-3" />
                  </button>
                  <div 
                    className="w-6 h-6 rounded border border-border" 
                    style={{ backgroundColor: backgroundColor }}
                  />
                </div>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="color"
                  value={backgroundColor}
                  onChange={(e) => handleBackgroundColorChange(e.target.value)}
                  className="w-10 h-8 cursor-pointer rounded"
                />
                <Input
                  type="text"
                  value={backgroundColor}
                  onChange={(e) => {
                    // Simple validation to ensure it's a valid hex color
                    const value = e.target.value;
                    if (value.startsWith('#') && (value.length === 4 || value.length === 7)) {
                      handleBackgroundColorChange(value);
                    }
                  }}
                  className="flex-1 h-8 font-mono text-xs"
                  placeholder="#120315"
                />
              </div>
              <div className="text-xs text-muted-foreground/50 italic px-1">
                Changing the background color also updates the fog color
              </div>
            </div>

            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <label className="text-sm font-medium">Layer Separation</label>
                </div>
                <span className="text-sm text-muted-foreground/70">{layerSeparation.toFixed(1)}</span>
              </div>
              <Slider
                value={[layerSeparation]}
                min={0.1}
                max={10}
                step={0.1}
                onValueChange={(value) => setLayerSeparation(value[0])}
                className={sliderActiveClass}
              />
            </div>
            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <label className="text-sm font-medium">Mandala Separation</label>
                </div>
                <span className="text-sm text-muted-foreground/70">{mandalaSeparation.toFixed(1)}</span>
              </div>
              <Slider
                value={[mandalaSeparation]}
                min={1}
                max={20}
                step={0.1}
                onValueChange={(value) => setMandalaSeparation(value[0])}
                className={sliderActiveClass}
              />
            </div>
          </div>
        </Collapsible.Content>
      </Collapsible.Root>
    </div>
  );
} 