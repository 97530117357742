import { create } from 'zustand';
import { CameraInfoState, MotionControlState } from '../../types/tunnel';

/**
 * Motion control store to share play/pause state across components
 * This is a simple store that only manages whether the tunnel is playing or paused
 */
export const useMotionControl = create<MotionControlState>((set) => ({
  isPlaying: true,
  setIsPlaying: (playing) => set({ isPlaying: playing }),
}));

/**
 * Camera information store to share camera position and rotation across components
 */
export const useCameraInfo = create<CameraInfoState>((set) => ({
  position: { x: 0, y: 0, z: 0 },
  rotation: { x: 0, y: 0, z: 0 },
  fov: 75,
  updateCameraInfo: (position, rotation, fov) => 
    set({ position, rotation, fov }),
}));

// Add window.debug type declaration
declare global {
  interface Window {
    debug?: {
      cameraInfo?: {
        position: { x: number; y: number; z: number };
        rotation: { x: number; y: number; z: number };
        fov: number;
      };
    };
  }
} 