import React, { useState, useEffect, useRef } from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { BirdController } from './BirdController';
import { BirdModel } from './BirdModel';
import { BirdEffects } from './BirdEffects';
import { BirdDebug } from './BirdDebug';
import { useBirdStore } from './BirdStore';
import { useMotionControl } from '../Tunnel';

export { useBirdStore } from './BirdStore';

interface BirdProps {
  position?: [number, number, number];
  scale?: number;
  renderOrder?: number;
}

export function Bird({ position = [0, 0, 0], scale = 1, renderOrder }: BirdProps) {
  const { isPlaying } = useMotionControl();
  const flightParams = useBirdStore(state => state.flightParams);
  const tunnelSpeed = useBirdStore(state => state.tunnelSpeed);
  const debugMode = useBirdStore(state => state.debugMode);
  const [modelScene, setModelScene] = useState<THREE.Group | null>(null);
  const isBirdVisible = useBirdStore(state => state.isBirdVisible);
  const modelType = useBirdStore(state => state.modelType);
  
  // Scene ref for effects
  const sceneRef = useRef<THREE.Group | null>(null);
  
  // Handle scene reference
  const onModelLoaded = (scene: THREE.Group) => {
    sceneRef.current = scene;
  };
  
  // Don't render anything if bird is not visible or model type is 'none'
  if (!isBirdVisible || modelType === 'none') return null;
  
  // Only show effects when in motion (isPlaying) or in debug mode
  const shouldShowEffects = isPlaying || debugMode || tunnelSpeed > 0.5;
  
  return (
    <BirdController fixedForwardDistance={4}>
      {/* Main bird model */}
      <BirdModel 
        scale={scale} 
        renderOrder={renderOrder}
        onModelLoaded={onModelLoaded}
      />
      
      {/* Visual effects */}
      {shouldShowEffects && sceneRef.current && (
        <BirdEffects scene={sceneRef.current} />
      )}
      
      {/* Debug visualization */}
      {debugMode && <BirdDebug />}
    </BirdController>
  );
}

// Make sure the bird component is only re-rendered when necessary
export default React.memo(Bird); 