import React, { useEffect, useState } from 'react';

export type PatternData = boolean[][];

interface PresetPatternIconProps {
  size?: number;
  color?: string;
  activeColor?: string;
  onClick?: () => void;
  isActive?: boolean;
  patternData?: PatternData;
  onPatternGenerated?: (pattern: PatternData) => void;
}

/**
 * Generates a random 9x9 pattern for a mandala preset icon
 */
export function generateRandomPattern(): PatternData {
  const gridSize = 9;
  const patternTypes = [
    'symmetrical',
    'checkerboard',
    'diagonal',
    'spiral',
    'dots',
    'grid',
    'rings',
    'random'
  ];

  const patternType = patternTypes[Math.floor(Math.random() * patternTypes.length)];
  const pattern: PatternData = Array(gridSize)
    .fill(null)
    .map(() => Array(gridSize).fill(false));
  
  // Define center once here to fix the linter error
  const center = Math.floor(gridSize / 2);

  switch (patternType) {
    case 'symmetrical':
      // Generate a quadrant and mirror it
      const quadSize = Math.ceil(gridSize / 2);
      for (let y = 0; y < quadSize; y++) {
        for (let x = 0; x < quadSize; x++) {
          const isFilled = Math.random() < 0.4;
          // Fill all quadrants symmetrically
          if (x < gridSize && y < gridSize) pattern[y][x] = isFilled;
          if (x < gridSize && gridSize - 1 - y < gridSize) pattern[gridSize - 1 - y][x] = isFilled;
          if (gridSize - 1 - x < gridSize && y < gridSize) pattern[y][gridSize - 1 - x] = isFilled;
          if (gridSize - 1 - x < gridSize && gridSize - 1 - y < gridSize) pattern[gridSize - 1 - y][gridSize - 1 - x] = isFilled;
        }
      }
      break;

    case 'checkerboard':
      // Create a checkerboard pattern
      for (let y = 0; y < gridSize; y++) {
        for (let x = 0; x < gridSize; x++) {
          pattern[y][x] = (x + y) % 2 === 0;
        }
      }
      break;

    case 'diagonal':
      // Create diagonal lines
      for (let y = 0; y < gridSize; y++) {
        for (let x = 0; x < gridSize; x++) {
          pattern[y][x] = (x + y) % 3 === 0;
        }
      }
      break;

    case 'spiral':
      // Create a spiral-like pattern
      for (let y = 0; y < gridSize; y++) {
        for (let x = 0; x < gridSize; x++) {
          const distance = Math.max(Math.abs(x - center), Math.abs(y - center));
          pattern[y][x] = distance % 2 === 0;
        }
      }
      break;

    case 'dots':
      // Create scattered dots
      for (let y = 0; y < gridSize; y++) {
        for (let x = 0; x < gridSize; x++) {
          pattern[y][x] = Math.random() < 0.25;
        }
      }
      break;

    case 'grid':
      // Create a grid pattern
      for (let y = 0; y < gridSize; y++) {
        for (let x = 0; x < gridSize; x++) {
          pattern[y][x] = x % 3 === 0 || y % 3 === 0;
        }
      }
      break;

    case 'rings':
      // Create concentric rings
      for (let y = 0; y < gridSize; y++) {
        for (let x = 0; x < gridSize; x++) {
          const distanceFromCenter = Math.sqrt(Math.pow(x - center, 2) + Math.pow(y - center, 2));
          pattern[y][x] = Math.floor(distanceFromCenter) % 2 === 0;
        }
      }
      break;

    case 'random':
    default:
      // Completely random pattern
      for (let y = 0; y < gridSize; y++) {
        for (let x = 0; x < gridSize; x++) {
          pattern[y][x] = Math.random() < 0.4;
        }
      }
      break;
  }

  return pattern;
}

export function PresetPatternIcon({
  size = 14,
  color = "white",
  activeColor = "blue",
  onClick,
  isActive = false,
  patternData,
  onPatternGenerated
}: PresetPatternIconProps) {
  const [pattern, setPattern] = useState<PatternData>(
    patternData || generateRandomPattern()
  );
  
  useEffect(() => {
    if (patternData) {
      setPattern(patternData);
    }
  }, [patternData]);
  
  useEffect(() => {
    if (!patternData && onPatternGenerated) {
      onPatternGenerated(pattern);
    }
  }, [pattern, patternData, onPatternGenerated]);
  
  const handleClick = () => {
    // Generate new pattern if none is provided
    if (!patternData) {
      const newPattern = generateRandomPattern();
      setPattern(newPattern);
      if (onPatternGenerated) {
        onPatternGenerated(newPattern);
      }
    }
    
    // Trigger click handler
    if (onClick) {
      onClick();
    }
  };
  
  return (
    <div 
      onClick={handleClick}
      className={`relative cursor-pointer hover:scale-110 transition-all duration-150 ease-in-out`}
      style={{ width: size, height: size }}
    >
      <svg 
        width={size} 
        height={size} 
        viewBox="0 0 9 9"
        className="transition-opacity duration-150 ease-out"
        style={{ opacity: isActive ? 1 : 0.3 }}
      >
        {pattern.map((row, y) => 
          row.map((cell, x) => 
            cell && (
              <rect 
                key={`${x}-${y}`} 
                x={x} 
                y={y} 
                width="1" 
                height="1" 
                fill={color}
              />
            )
          )
        )}
      </svg>
    </div>
  );
}

export default PresetPatternIcon; 