import React, { useState, useEffect } from 'react';
import { AdminMandalaScanner } from '../components/AdminMandalaScanner';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/tabs';
import { Separator } from '../components/ui/separator';
import { MandalaGrid } from '../components/MandalaGrid';
import { Button } from '../components/ui/button';
import { RefreshCw, ArrowLeft } from 'lucide-react';
import { clearCache } from '../services/mandalaService';
import { useNavigate } from 'react-router-dom';
import { Label } from '../components/ui/label';

// Create a local storage key for mandala quality
const MANDALA_QUALITY_KEY = 'ananta-mandala-quality';

export default function AdminPage() {
  const [refreshKey, setRefreshKey] = useState(0);
  const [quality, setQuality] = useState(() => {
    // Initialize from localStorage if available
    const savedQuality = localStorage.getItem(MANDALA_QUALITY_KEY);
    return savedQuality || 'hd'; // Default to HD if not set
  });
  const navigate = useNavigate();

  // Save quality preference to localStorage when it changes
  useEffect(() => {
    localStorage.setItem(MANDALA_QUALITY_KEY, quality);
    // Dispatch an event so other components can react to this change
    window.dispatchEvent(new CustomEvent('mandala-quality-change', { 
      detail: { quality } 
    }));
  }, [quality]);

  const handleRefresh = () => {
    clearCache();
    setRefreshKey(prev => prev + 1);
  };

  const handleBack = () => {
    navigate('/tunnel');
  };

  return (
    <div className="h-full min-h-screen bg-black text-white overflow-y-auto">
      <div className="container max-w-6xl mx-auto py-8 px-4 md:px-6 h-full">
        <div className="flex flex-col h-full">
          <div className="flex items-center justify-between mb-6 relative">
            <Button 
              variant="ghost" 
              size="sm"
              onClick={handleBack}
              className="h-8 text-muted-foreground"
            >
              <ArrowLeft className="h-4 w-4 mr-2" />
              Back
            </Button>
            
            <div className="absolute left-1/2 transform -translate-x-1/2">
              <img src="/Ananta-logo.svg" alt="Ananta" className="h-8 w-auto" />
            </div>
            
            <div className="w-[60px]">
              {/* Empty div to balance the layout */}
            </div>
          </div>
          <Separator className="mb-8" />

          <div className="flex-1 overflow-y-auto">
            <Tabs defaultValue="mandalas" className="mb-8">
              <TabsList className="mb-6 w-full sm:w-auto">
                <TabsTrigger value="mandalas">Mandala Management</TabsTrigger>
                <TabsTrigger value="tunnels">Tunnel Settings</TabsTrigger>
                <TabsTrigger value="system">System</TabsTrigger>
              </TabsList>

              <TabsContent value="mandalas" className="space-y-8">
                <Card className="border-border bg-card">
                  <CardHeader>
                    <CardTitle>Mandala Storage Scanner</CardTitle>
                    <CardDescription>
                      Scan and synchronize mandalas from Supabase storage
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <AdminMandalaScanner />
                  </CardContent>
                </Card>

                <div className="flex justify-between items-center mt-8 mb-4">
                  <h2 className="text-xl font-semibold tracking-tight">Mandala Library</h2>
                  <Button 
                    variant="outline" 
                    size="sm" 
                    onClick={handleRefresh}
                    className="h-9"
                  >
                    <RefreshCw className="h-4 w-4 mr-2" />
                    Refresh
                  </Button>
                </div>
                
                <div key={refreshKey}>
                  <MandalaGrid />
                </div>
              </TabsContent>

              <TabsContent value="tunnels">
                <Card className="border-border bg-card">
                  <CardHeader>
                    <CardTitle>Tunnel Management</CardTitle>
                    <CardDescription>
                      Configure and manage tunnel settings
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <p className="text-muted-foreground">
                      Tunnel configuration management features will be added here in a future update.
                    </p>
                  </CardContent>
                </Card>
              </TabsContent>

              <TabsContent value="system">
                <Card className="border-border bg-card">
                  <CardHeader>
                    <CardTitle>System Information</CardTitle>
                    <CardDescription>
                      View system statistics and settings
                    </CardDescription>
                  </CardHeader>
                  <CardContent className="space-y-6">
                    <div className="space-y-4">
                      <div>
                        <div className="space-y-0.5">
                          <Label htmlFor="mandala-quality">Mandala Quality</Label>
                          <p className="text-sm text-muted-foreground">
                            Choose between SD, HD, and UHD mandala layers. Higher quality offers better resolution but may impact performance.
                          </p>
                        </div>
                        <div className="mt-3">
                          <Tabs value={quality} onValueChange={setQuality} className="w-full">
                            <TabsList className="grid w-full grid-cols-3">
                              <TabsTrigger value="sd">SD</TabsTrigger>
                              <TabsTrigger value="hd">HD</TabsTrigger>
                              <TabsTrigger value="uhd">UHD</TabsTrigger>
                            </TabsList>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                    
                    <p className="text-muted-foreground">
                      Additional system statistics and configuration will be displayed here in a future update.
                    </p>
                  </CardContent>
                </Card>
              </TabsContent>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
} 