import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getAllMandalas } from '../services/mandalaService';

export function HomePage() {
  const [randomMandalaName, setRandomMandalaName] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function loadRandomMandala() {
      try {
        setLoading(true);
        
        // Get all available mandalas
        const mandalas = await getAllMandalas();
        
        if (!mandalas || mandalas.length === 0) {
          setError('No mandalas found');
          setLoading(false);
          return;
        }
        
        // Pick a random mandala
        const randomIndex = Math.floor(Math.random() * mandalas.length);
        const selectedMandala = mandalas[randomIndex];
        
        console.log(`Redirecting to mandala: ${selectedMandala.name}`);
        setRandomMandalaName(selectedMandala.name);
      } catch (error) {
        console.error('Error loading mandalas:', error);
        setError(error instanceof Error ? error.message : 'Failed to load mandalas');
        setLoading(false);
      }
    }

    loadRandomMandala();
  }, []);

  if (error) {
    return (
      <div className="fixed inset-0 w-full h-full bg-black flex items-center justify-center">
        <div className="text-white">Error: {error}</div>
      </div>
    );
  }

  if (loading && !randomMandalaName) {
    return (
      <div className="fixed inset-0 w-full h-full bg-black flex items-center justify-center">
        <div className="text-white text-opacity-50 font-doto">loading...</div>
      </div>
    );
  }

  // Redirect to the random mandala page
  return randomMandalaName ? <Navigate to={`/${randomMandalaName}`} replace /> : null;
} 