import * as React from "react"
import * as SwitchPrimitives from "@radix-ui/react-switch"
import { cn } from "../../lib/utils"

// Create a fallback switch component in case the Radix UI component fails to load
const FallbackSwitch = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    checked?: boolean;
    onCheckedChange?: (checked: boolean) => void;
    size?: "default" | "sm";
    disabled?: boolean;
  }
>(({ className, checked = false, onCheckedChange, size = "default", disabled, ...props }, ref) => {
  const [isChecked, setIsChecked] = React.useState(checked);
  
  React.useEffect(() => {
    setIsChecked(checked);
  }, [checked]);
  
  const handleClick = () => {
    if (disabled) return;
    const newValue = !isChecked;
    setIsChecked(newValue);
    onCheckedChange?.(newValue);
  };
  
  return (
    <div
      ref={ref}
      role="switch"
      aria-checked={isChecked}
      tabIndex={disabled ? -1 : 0}
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          handleClick();
        }
      }}
      className={cn(
        "peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50",
        isChecked ? "bg-blue-600" : "bg-gray-700",
        size === "default" ? "h-6 w-11" : "h-5 w-9",
        disabled && "opacity-50 cursor-not-allowed",
        className
      )}
      {...props}
    >
      <div
        className={cn(
          "pointer-events-none block rounded-full bg-white shadow-lg ring-0 transition-transform",
          isChecked ? "translate-x-5" : "translate-x-0",
          size === "default" ? "h-5 w-5" : "h-4 w-4"
        )}
      />
    </div>
  );
});
FallbackSwitch.displayName = "FallbackSwitch";

// Try to use the Radix UI Switch component, but fall back to our custom implementation if it fails
const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & {
    size?: "default" | "sm"
  }
>(({ className, size = "default", ...props }, ref) => {
  const [useRadix, setUseRadix] = React.useState(true);
  
  React.useEffect(() => {
    // Check if Radix UI components are available
    if (typeof SwitchPrimitives.Root !== 'function') {
      setUseRadix(false);
    }
  }, []);
  
  if (!useRadix) {
    return <FallbackSwitch ref={ref as any} size={size} className={className} {...props as any} />;
  }
  
  try {
    return (
      <SwitchPrimitives.Root
        className={cn(
          "peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-blue-600 data-[state=unchecked]:bg-gray-700",
          size === "default" ? "h-6 w-11" : "h-5 w-9",
          className
        )}
        {...props}
        ref={ref}
      >
        <SwitchPrimitives.Thumb
          className={cn(
            "pointer-events-none block rounded-full bg-white shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0",
            size === "default" ? "h-5 w-5" : "h-4 w-4"
          )}
        />
      </SwitchPrimitives.Root>
    );
  } catch (error) {
    console.error("Error rendering Radix UI Switch, falling back to custom implementation", error);
    return <FallbackSwitch ref={ref as any} size={size} className={className} {...props as any} />;
  }
});

Switch.displayName = "Switch";

export { Switch } 