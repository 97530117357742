import React, { useEffect } from 'react';
import { Text } from '@react-three/drei';
import * as THREE from 'three';
import { useBirdStore } from './BirdStore';

interface BirdDebugProps {
  enabled?: boolean;
}

export function BirdDebug({ enabled = false }: BirdDebugProps) {
  // Get from the bird store
  const debugMode = useBirdStore(state => state.debugMode);
  const setDebugMode = useBirdStore(state => state.setDebugMode);
  
  // Set up debug mode toggle with keyboard shortcut
  useEffect(() => {
    // Debug mode toggle keyboard shortcut: F9
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'F9') {
        const newDebugMode = !debugMode;
        setDebugMode(newDebugMode);
        
        // Dispatch event for other components
        const debugEvent = new CustomEvent('debugModeChange', { 
          detail: { enabled: newDebugMode } 
        });
        window.dispatchEvent(debugEvent);
        console.log(`Debug mode ${newDebugMode ? 'enabled' : 'disabled'}`);
        
        // Log debug information when debug mode is enabled
        if (newDebugMode) {
          console.log('Debug Info: Initial Camera Position at Z=-6');
          console.log('Debug Controls: F9 to toggle debug view');
        }
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [debugMode, setDebugMode]);
  
  // Don't render anything if debug mode is disabled and not explicitly enabled by props
  if (!debugMode && !enabled) return null;
  
  return (
    <>
      {/* Initial camera position indicator at Z=-6 */}
      <group position={[0, 0, -6]}>
        {/* Sphere to mark position */}
        <mesh>
          <sphereGeometry args={[0.2, 16, 16]} />
          <meshBasicMaterial color="red" wireframe={true} />
        </mesh>
        
        {/* Line pointing in the initial camera direction */}
        <mesh position={[0, 0, 0.5]}>
          <boxGeometry args={[0.05, 0.05, 1]} />
          <meshBasicMaterial color="blue" />
        </mesh>
        
        {/* Coordinate axes */}
        <group>
          {/* X axis (red) */}
          <mesh position={[0.5, 0, 0]} rotation={[0, 0, -Math.PI / 2]}>
            <cylinderGeometry args={[0.02, 0.02, 1]} />
            <meshBasicMaterial color="red" />
          </mesh>
          
          {/* Y axis (green) */}
          <mesh position={[0, 0.5, 0]}>
            <cylinderGeometry args={[0.02, 0.02, 1]} />
            <meshBasicMaterial color="green" />
          </mesh>
          
          {/* Z axis (blue) */}
          <mesh position={[0, 0, 0.5]} rotation={[Math.PI / 2, 0, 0]}>
            <cylinderGeometry args={[0.02, 0.02, 1]} />
            <meshBasicMaterial color="blue" />
          </mesh>
          
          {/* Debug text */}
          <Text
            position={[0, 0.7, 0]}
            fontSize={0.15}
            color="yellow"
            anchorX="center"
            anchorY="middle"
            outlineWidth={0.01}
            outlineColor="#000000"
          >
            Debug Mode
          </Text>
          
          {/* Controls info */}
          <Text
            position={[0, 0.5, 0]}
            fontSize={0.1}
            color="white"
            anchorX="center"
            anchorY="middle"
            outlineWidth={0.01}
            outlineColor="#000000"
          >
            F9: Toggle Debug
          </Text>
          
          {/* Arrow keys info */}
          <Text
            position={[0, 0.35, 0]}
            fontSize={0.1}
            color="white"
            anchorX="center"
            anchorY="middle"
            outlineWidth={0.01}
            outlineColor="#000000"
          >
            Arrows: Move Bird
          </Text>
          
          {/* Speed keys info */}
          <Text
            position={[0, 0.2, 0]}
            fontSize={0.1}
            color="white"
            anchorX="center"
            anchorY="middle"
            outlineWidth={0.01}
            outlineColor="#000000"
          >
            L/M: Speed -/+
          </Text>
        </group>
      </group>
    </>
  );
} 