import { MainControlPanel } from './ControlPanel/MainControlPanel';

interface ControlPanelWrapperProps {
  onResetCamera: () => void;
  setIsTunnelMandalaOverlayOpen?: (isOpen: boolean) => void;
  initialIsOpen?: boolean;
}

/**
 * The ControlPanel provides controls for all tunnel parameters.
 * This is a wrapper component that uses the refactored MainControlPanel.
 */
export function ControlPanelWrapper({ 
  onResetCamera, 
  setIsTunnelMandalaOverlayOpen,
  initialIsOpen 
}: ControlPanelWrapperProps) {
  return (
    <div className="absolute top-0 right-0 w-full h-full pointer-events-none">
      <MainControlPanel 
        onResetCamera={onResetCamera} 
        externalSetTunnelMandalaOverlay={setIsTunnelMandalaOverlayOpen}
        initialIsOpen={initialIsOpen}
      />
    </div>
  );
} 