import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { useBirdStore } from './BirdStore';

// Helper function for smooth interpolation
const lerp = (start: number, end: number, factor: number): number => {
  return start + (end - start) * factor;
};

interface BirdControllerProps {
  children: React.ReactNode;
  fixedForwardDistance?: number;
}

export function BirdController({ 
  children, 
  fixedForwardDistance = 4 
}: BirdControllerProps) {
  const birdRef = useRef<THREE.Group>(null);
  const { camera } = useThree();
  
  // Get state from bird store
  const flightParams = useBirdStore(state => state.flightParams);
  const tunnelSpeed = useBirdStore(state => state.tunnelSpeed);
  const setTunnelSpeed = useBirdStore(state => state.setTunnelSpeed);
  const windEffects = useBirdStore(state => state.windEffects);
  const debugMode = useBirdStore(state => state.debugMode);
  const setFlightParams = useBirdStore(state => state.setFlightParams);
  const modelType = useBirdStore(state => state.modelType);
  
  // Add keyboard control state
  const [keyState, setKeyState] = useState({
    up: false,
    down: false,
    left: false,
    right: false,
    l: false,  // For decreasing tunnel speed
    m: false,  // For increasing tunnel speed
  });
  
  // Target values for smooth transitions with separate components for each axis
  const [targetRotation, setTargetRotation] = useState({ 
    leftRight: 0,   // X-axis rotation for left/right controls
    upDown: 0       // Z-axis rotation for up/down controls
  });
  const [targetOffset, setTargetOffset] = useState({ x: 0, y: 0, z: 0 });
  
  // Speed control for the tunnel
  const [targetTunnelSpeed, setTargetTunnelSpeed] = useState(tunnelSpeed);

  // Add a state to track how long no keys have been pressed
  const [noKeysPressedTime, setNoKeysPressedTime] = useState(0);
  
  // Set initial bird position
  useEffect(() => {
    if (camera && birdRef.current) {
      // Get the camera direction
      const direction = new THREE.Vector3();
      camera.getWorldDirection(direction);
      
      // Position bird at the camera position with an offset in front
      birdRef.current.position.copy(camera.position);
      birdRef.current.position.add(direction.multiplyScalar(fixedForwardDistance));
      birdRef.current.position.y -= 1.0; // Apply base vertical offset
    }
  }, [camera, fixedForwardDistance]);

  // Set up keyboard listeners
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowUp':
          setKeyState(prev => ({ ...prev, up: true }));
          break;
        case 'ArrowDown':
          setKeyState(prev => ({ ...prev, down: true }));
          break;
        case 'ArrowLeft':
          setKeyState(prev => ({ ...prev, left: true }));
          break;
        case 'ArrowRight':
          setKeyState(prev => ({ ...prev, right: true }));
          break;
        case 'l':
        case 'L':
          setKeyState(prev => ({ ...prev, l: true }));
          break;
        case 'm':
        case 'M':
          setKeyState(prev => ({ ...prev, m: true }));
          break;
      }
    };
    
    const handleKeyUp = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowUp':
          setKeyState(prev => ({ ...prev, up: false }));
          break;
        case 'ArrowDown':
          setKeyState(prev => ({ ...prev, down: false }));
          break;
        case 'ArrowLeft':
          setKeyState(prev => ({ ...prev, left: false }));
          break;
        case 'ArrowRight':
          setKeyState(prev => ({ ...prev, right: false }));
          break;
        case 'l':
        case 'L':
          setKeyState(prev => ({ ...prev, l: false }));
          break;
        case 'm':
        case 'M':
          setKeyState(prev => ({ ...prev, m: false }));
          break;
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);
  
  // Create a perlin noise generator for natural turbulence
  const noiseGenerator = useMemo(() => {
    return {
      simplex: new THREE.Vector3(),
      get: function(x: number, y: number, z: number) {
        // Simple pseudo-noise function for turbulence
        return Math.sin(x * 0.1 + y * 0.2 + z * 0.3) * 
               Math.cos(x * 0.1 + y * 0.3 + z * 0.2) * 0.5;
      }
    };
  }, []);
  
  // Update bird position and rotation on each frame
  useFrame((state, delta) => {
    if (!birdRef.current) return;
    
    // Calculate target rotation and position offsets based on key states
    const newTargetRotation = { ...targetRotation };
    const newTargetOffset = { ...targetOffset };
    let newTargetTunnelSpeed = targetTunnelSpeed;
    
    // Track if any movement keys are pressed
    const anyMovementKeyPressed = keyState.up || keyState.down || keyState.left || keyState.right;
    
    // Update the no-keys-pressed timer
    if (anyMovementKeyPressed) {
      // Reset timer when keys are pressed
      setNoKeysPressedTime(0);
    } else {
      // Increment timer when no keys are pressed
      setNoKeysPressedTime(prev => prev + delta);
    }
    
    // Get current horizontal offset to use for auto-stabilizing behavior
    const currentHorizontalOffset = birdRef.current.userData.currentOffsetX || 0;
    // Get current vertical offset to use for tilt scaling
    const currentVerticalOffset = birdRef.current.userData.currentOffsetY || 0;
    
    // ===== ADVANCED FLIGHT PHYSICS =====
    
    // Calculate flight energy - decreases with extreme maneuvers, recovers slowly
    const currentEnergy = birdRef.current.userData.flightEnergy || 1.0;
    const energyDrain = (Math.abs(currentHorizontalOffset) * 0.1) + (Math.abs(currentVerticalOffset) * 0.1);
    const newEnergy = Math.max(0.3, Math.min(1.0, currentEnergy - (energyDrain * delta) + (0.05 * delta)));
    birdRef.current.userData.flightEnergy = newEnergy;
    
    // Apply energy factor to control response (tired birds respond slower)
    const energyFactor = 0.7 + (newEnergy * 0.3); // Between 0.7-1.0 based on energy
    
    // Altitude-based handling (easier turns at height)
    const altitudeFactor = 1.0 + (currentVerticalOffset > 0 ? currentVerticalOffset * 0.2 : 0);
    
    // Apply speed-dependent handling
    const speedFactor = 0.8 + (tunnelSpeed * 0.2); // Harder to maneuver at high speeds

    // Environment effects - apply wind when active
    let windEffect = { x: 0, y: 0 };
    if (windEffects.active) {
      // Generate realistic wind using noise
      const time = state.clock.elapsedTime;
      const windX = noiseGenerator.get(time * 0.2, 0, 0) * 0.2; // Using fixed windStrength
      const windY = noiseGenerator.get(0, time * 0.3, 0) * 0.2;
      
      // Apply wind strength based on global setting
      const windIntensity = windEffects.intensity || 0.3;
      
      windEffect = {
        x: windX * windIntensity,
        y: windY * windIntensity
      };
    }
    
    // === End of new flight physics variables ===
    
    // Reset targets when no keys are pressed
    if (!keyState.left && !keyState.right) {
      // Auto-stabilizing behavior - tilt in SAME direction as current position
      // This creates a "falling" effect toward center rather than a "correcting" effect
      
      // Apply stronger auto-stabilization based on how long no keys have been pressed
      // This creates a gradual return to neutral orientation
      const returnToNeutralFactor = Math.min(1, noKeysPressedTime * 0.2); // Increases over time, maxes at 1
      const autoStabilizeTilt = currentHorizontalOffset * (1.5 - returnToNeutralFactor); // Reduces tilt effect over time
      
      newTargetRotation.leftRight = autoStabilizeTilt; // Tilt in same direction as position
      
      // Auto-return to center position with gradually increasing force
      // When no keys are pressed for a while, return to center more strongly
      newTargetOffset.x = 0; // Target center position
    }
    
    if (!keyState.up && !keyState.down) {
      // For the anatomy model, we want a stronger return to level position
      if (modelType === 'anatomy') {
        // Stronger and faster return to level for anatomy model
        newTargetRotation.upDown = 0; // No Z-axis rotation
      } else {
        newTargetRotation.upDown = 0; // No Z-axis rotation
      }
    }
    
    if (!keyState.up && !keyState.down && !keyState.left && !keyState.right) {
      newTargetOffset.y = 0;
      newTargetOffset.z = 0;
    }
    
    // Apply key-specific changes to targets
    if (keyState.up) {
      // Calculate a position-based scaling factor (the further from center, the less tilt)
      const verticalPositionFactor = Math.max(0.4, 1 - Math.abs(currentVerticalOffset) * 0.4);
      // Apply energy and altitude factors to controls
      const combinedFactor = verticalPositionFactor * energyFactor * (1/speedFactor);
      
      // Model-specific tilt behavior
      if (modelType === 'anatomy') {
        // Inverted and more pronounced tilt for anatomy model
        newTargetRotation.upDown = -0.8 * combinedFactor; // Inverted and increased magnitude
      } else {
        // Normal tilt for other models
        newTargetRotation.upDown = 0.5 * combinedFactor; // Scale based on position and energy
      }
      
      // Secondary motion: diving provides a slight speed boost
      const heightBoost = currentVerticalOffset > 0 ? 0.05 : 0; // Small boost when high
      newTargetOffset.y = 1.5 + heightBoost; // Vertical movement upward
      
      // Higher altitudes give more energy recovery
      if (currentVerticalOffset > 0.5) {
        birdRef.current.userData.flightEnergy = Math.min(1.0, newEnergy + 0.01);
      }
    } else if (keyState.down) {
      // Calculate a position-based scaling factor (the further from center, the less tilt)
      const verticalPositionFactor = Math.max(0.4, 1 - Math.abs(currentVerticalOffset) * 0.4);
      // Apply energy and speed factors to controls
      const combinedFactor = verticalPositionFactor * energyFactor * (1/speedFactor);
      
      // Model-specific tilt behavior
      if (modelType === 'anatomy') {
        // Inverted and more pronounced tilt for anatomy model
        newTargetRotation.upDown = 0.8 * combinedFactor; // Inverted and increased magnitude
      } else {
        // Normal tilt for other models
        newTargetRotation.upDown = -0.5 * combinedFactor; // Scale based on position and energy
      }
      
      // Secondary motion: diving provides extra downward momentum
      const divingMomentum = currentVerticalOffset < -0.3 ? 0.2 : 0; // Extra momentum when already diving
      newTargetOffset.y = -0.7 - divingMomentum; // Vertical movement downward
      
      // Diving recovers energy slightly (riding the airflow)
      if (currentVerticalOffset < -0.3 && tunnelSpeed > 1.0) {
        birdRef.current.userData.flightEnergy = Math.min(1.0, newEnergy + 0.02);
      }
    }
    
    // Apply tunnel speed changes with L and M keys
    if (keyState.m) {
      // Increase tunnel speed
      newTargetTunnelSpeed = Math.min(4.0, targetTunnelSpeed + 0.2); // Max speed of 4.0x
    } else if (keyState.l) {
      // Decrease tunnel speed
      newTargetTunnelSpeed = Math.max(0.7, targetTunnelSpeed - 0.05); // Min speed of 0.7x
    }
    
    if (keyState.left) {
      // Calculate a position-based scaling factor (the further from center, the less barrel roll)
      const horizontalPositionFactor = Math.max(0.3, 1 - Math.abs(currentHorizontalOffset) * 0.5);
      // Apply energy and altitude factors
      const combinedFactor = horizontalPositionFactor * energyFactor * altitudeFactor;
      newTargetRotation.leftRight = 0.85 * combinedFactor; // Scale barrel roll based on position and energy
      
      // Secondary motion: banking turns slightly change height
      const bankingDip = currentHorizontalOffset < -0.5 ? -0.1 : 0; // Slight dip when turning sharply
      newTargetOffset.y += bankingDip;
      
      newTargetOffset.x = -1.2; // Increased lateral movement range
    } else if (keyState.right) {
      // Calculate a position-based scaling factor (the further from center, the less barrel roll)
      const horizontalPositionFactor = Math.max(0.3, 1 - Math.abs(currentHorizontalOffset) * 0.5);
      // Apply energy and altitude factors
      const combinedFactor = horizontalPositionFactor * energyFactor * altitudeFactor;
      newTargetRotation.leftRight = -0.85 * combinedFactor; // Scale barrel roll based on position and energy
      
      // Secondary motion: banking turns slightly change height
      const bankingDip = currentHorizontalOffset > 0.5 ? -0.1 : 0; // Slight dip when turning sharply
      newTargetOffset.y += bankingDip;
      
      newTargetOffset.x = 1.2; // Increased lateral movement range
    }
    
    // Update the target state
    setTargetRotation(newTargetRotation);
    setTargetOffset(newTargetOffset);
    setTargetTunnelSpeed(newTargetTunnelSpeed);
    
    // Get the camera's direction
    const direction = new THREE.Vector3();
    camera.getWorldDirection(direction);
    
    // Apply smooth tunnel speed changes with inertia
    const easeFactorSpeed = 0.05; // Increased from 0.03 for more noticeable changes
    let currentTunnelSpeed = lerp(tunnelSpeed, targetTunnelSpeed, easeFactorSpeed);
    
    // Ensure we don't have NaN or invalid values
    if (isNaN(currentTunnelSpeed) || !isFinite(currentTunnelSpeed)) {
      console.warn("Invalid tunnel speed detected, resetting to default value");
      currentTunnelSpeed = 1.0; // Reset to default
    }
    
    // Update the bird store with new speed
    setTunnelSpeed(currentTunnelSpeed);
    
    // Dispatch the speed event only when needed
    if (Math.abs((birdRef.current.userData.lastDispatchedSpeed || 0) - currentTunnelSpeed) > 0.01) {
      // Store the last dispatched speed to avoid redundant events
      birdRef.current.userData.lastDispatchedSpeed = currentTunnelSpeed;
      
      // Dispatch the event
      const speedEvent = new CustomEvent('tunnelSpeedChange', { 
        detail: { 
          speed: currentTunnelSpeed,
          isLiveTweaking: true
        } 
      });
      window.dispatchEvent(speedEvent);
    }
    
    // Position the bird in the view
    const bird = birdRef.current;
    
    // 1. Copy camera position
    bird.position.copy(camera.position);
    
    // 2. Move bird in front of the camera (fixed distance)
    bird.position.add(direction.clone().multiplyScalar(fixedForwardDistance));
    
    // 3. Apply base vertical offset
    bird.position.y -= 1.0;
    
    // 4. Apply smooth additional offsets based on key presses
    const easeFactorPosition = 0.002; // Adjusted for better auto-stabilizing behavior
    const easeFactorVertical = 0.001; // Reduced from 0.002 - doubled inertia for vertical movement
    
    // Apply lateral offset (left/right) with strong inertia
    const rightVector = new THREE.Vector3().crossVectors(direction, new THREE.Vector3(0, 1, 0)).normalize();
    
    // Apply current side position and add a centering force when no keys are pressed
    const lateralMovement = lerp(bird.userData.currentOffsetX || 0, targetOffset.x, easeFactorPosition);
    
    // Apply wind effects to lateral movement
    const windLateralEffect = windEffect.x * (1 - Math.abs(lateralMovement) * 0.5); // Wind affects less during active turning
    
    // When no keys pressed, add a centering force proportional to distance from center
    if (!keyState.left && !keyState.right && Math.abs(bird.userData.currentOffsetX || 0) > 0.05) {
      // Apply additional force toward center proportional to distance
      // The longer no keys are pressed, the stronger the centering force becomes
      const baseCenteringForce = 0.001; // Base centering force
      const timeScaledForce = baseCenteringForce * (1 + Math.min(3, noKeysPressedTime)); // Increases over time, up to 4x
      const centeringForce = -(bird.userData.currentOffsetX || 0) * timeScaledForce;
      
      bird.position.add(rightVector.clone().multiplyScalar(lateralMovement + centeringForce + windLateralEffect));
    } else {
      bird.position.add(rightVector.multiplyScalar(lateralMovement + windLateralEffect));
    }
    
    // Store the current offset for next frame
    bird.userData.currentOffsetX = lateralMovement;
    
    // Apply vertical offset (up/down) with heavy inertia - for any existing vertical offset
    const currentOffsetY = bird.userData.currentOffsetY || 0;
    let newOffsetY = lerp(currentOffsetY, targetOffset.y, easeFactorVertical);
    
    // Apply stronger vertical centering force when no keys pressed for a while
    if (!keyState.up && !keyState.down && Math.abs(currentOffsetY) > 0.05) {
      const baseVerticalForce = 0.0005; // Base vertical centering force
      const timeScaledVerticalForce = baseVerticalForce * (1 + Math.min(3, noKeysPressedTime)); // Increases over time
      const verticalCenteringForce = -currentOffsetY * timeScaledVerticalForce;
      newOffsetY += verticalCenteringForce;
    }
    
    // Apply wind effects to vertical movement
    const windVerticalEffect = windEffect.y * (1 - Math.abs(newOffsetY) * 0.5); // Wind affects less during active climbing/diving
    
    bird.position.y += newOffsetY + windVerticalEffect;
    
    // Store current offsets for next frame
    bird.userData.currentOffsetY = newOffsetY;
    
    // 5. Reset rotation and apply base yaw
    bird.rotation.set(0, 0, 0);
    
    // Extract yaw (y-axis rotation) from camera
    const cameraDirection = new THREE.Vector3();
    camera.getWorldDirection(cameraDirection);
    const yaw = Math.atan2(cameraDirection.x, cameraDirection.z);
    
    // Apply base orientation + camera yaw + 90 degrees to face forward
    const baseYaw = yaw + Math.PI/2;
    
    // 6. Apply smooth rotations with easing
    let easeFactorRotation = 0.03; // Reduced from 0.1 for much more inertia in barrel rolls
    
    // Model-specific tilt response speed
    let easeFactorZRotation;
    if (modelType === 'anatomy') {
      easeFactorZRotation = 0.03; // Higher value for faster, more responsive tilting for anatomy
    } else {
      easeFactorZRotation = 0.01; // Default value for other models
    }
    
    // Increase rotation smoothing when no keys pressed for a while
    // This makes the bird return to neutral posture more quickly
    if (!anyMovementKeyPressed) {
      const rotationReturnFactor = Math.min(5, 1 + noKeysPressedTime * 0.5); // Increases over time
      easeFactorRotation *= rotationReturnFactor;
      
      // Model-specific return to neutral speed
      if (modelType === 'anatomy') {
        // Faster return to neutral for anatomy model
        easeFactorZRotation *= rotationReturnFactor * 1.5;
      } else {
        easeFactorZRotation *= rotationReturnFactor;
      }
    }
    
    // Store and update current rotations for smooth transitions
    if (bird.userData.currentRotation === undefined) {
      bird.userData.currentRotation = { leftRight: 0, upDown: 0 };
    }
    
    // Lerp between current and target rotations
    bird.userData.currentRotation.leftRight = lerp(bird.userData.currentRotation.leftRight, targetRotation.leftRight, easeFactorRotation);
    bird.userData.currentRotation.upDown = lerp(bird.userData.currentRotation.upDown, targetRotation.upDown, easeFactorZRotation);
    
    // Apply the smoothed rotations
    bird.rotation.order = 'YXZ'; // Apply Y rotation first, then X, then Z for proper rotation
    bird.rotation.y = baseYaw; // Camera direction
    bird.rotation.x = bird.userData.currentRotation.leftRight; // X rotation from left/right controls
    bird.rotation.z = bird.userData.currentRotation.upDown; // Z rotation from up/down controls
    
    // 7. Add a gentle hovering motion - reduce when returning to center
    const hoverTime = state.clock.getElapsedTime();
    // Reduce hovering when returning to center for longer periods
    const hoverScale = Math.max(0.2, 1 - (noKeysPressedTime * 0.1)); // Scales down to 0.2 over time
    bird.position.y += Math.sin(hoverTime * 0.75) * 0.02 * hoverScale;
    
    // Update flight parameters for visual effects
    setFlightParams({
      speed: isNaN(tunnelSpeed) ? 1.0 : Math.max(0.1, tunnelSpeed),
      energy: isNaN(newEnergy) ? 1.0 : newEnergy,
      altitude: isNaN(newOffsetY) ? 0 : newOffsetY,
      bankAngle: isNaN(bird.userData.currentRotation?.leftRight) ? 0 : bird.userData.currentRotation?.leftRight || 0,
      pitchAngle: isNaN(bird.userData.currentRotation?.upDown) ? 0 : bird.userData.currentRotation?.upDown || 0
    });
    
    // Smoothly adjust speed toward default when no L/M keys are pressed for a while
    if (!keyState.l && !keyState.m && noKeysPressedTime > 2) {
      // After 2 seconds of no key presses, gradually return to default speed
      const defaultSpeed = 1.0;
      const speedDiff = defaultSpeed - targetTunnelSpeed;
      
      // Apply a small adjustment toward default speed (1.0)
      if (Math.abs(speedDiff) > 0.05) {
        const speedAdjustment = speedDiff * 0.01; // Very gentle adjustment
        newTargetTunnelSpeed = targetTunnelSpeed + speedAdjustment;
        setTargetTunnelSpeed(newTargetTunnelSpeed);
      }
    }
  });
  
  return (
    <group ref={birdRef}>
      {/* Simple lighting for the bird model */}
      <ambientLight intensity={0.6} />
      <directionalLight position={[0, 5, 0]} intensity={0.8} />
      
      {/* Render children (bird model and effects) */}
      {children}
      
      {/* Debug indicators - only visible when debug mode is enabled */}
      {debugMode && (
        <>
          {/* Sphere to indicate bird position */}
          <mesh>
            <sphereGeometry args={[0.1, 8, 8]} />
            <meshBasicMaterial color="yellow" wireframe={true} />
          </mesh>
        </>
      )}
    </group>
  );
} 