import React, { useEffect, RefObject } from 'react';
import { useKeyboardCamera } from '../hooks/useKeyboardCamera';

interface KeyboardCameraControllerProps {
  enabled?: boolean;
  controlsRef?: RefObject<any>;
}

// Component that uses the keyboard camera hook with an enabled flag
export function KeyboardCameraController({ 
  enabled = true, 
  controlsRef 
}: KeyboardCameraControllerProps) {
  // Remove all debug logs
  useEffect(() => {
    // Effect cleanup - no need to log unmounting
    return () => {};
  }, [enabled, controlsRef]);

  // No need to log rendering
  
  // Only use the hook if enabled
  return enabled ? useKeyboardCamera(controlsRef) : null;
}

export default KeyboardCameraController; 