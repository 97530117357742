import { supabase } from '../services/supabase';
import { Mandala } from '../services/mandalaService';

export interface ScanResults {
  processed: number;
  created: number;
  updated: number;
  deleted: number;
  errors: number;
  skipped: number;
  errorDetails: string[];
}

/**
 * Scans the mandalas storage bucket and populates/updates the mandalas table
 */
export async function scanMandalaStorage(): Promise<ScanResults> {
  const results: ScanResults = {
    processed: 0,
    created: 0,
    updated: 0,
    deleted: 0,
    errors: 0,
    skipped: 0,
    errorDetails: []
  };

  try {
    console.log("Starting mandala scan in 'ananta' bucket...");
    
    // Get all existing mandalas from the database first
    const { data: existingMandalas, error: dbError } = await supabase
      .from('mandalas')
      .select('name');

    if (dbError) {
      console.error("Error fetching existing mandalas:", dbError);
      throw new Error(`Failed to fetch existing mandalas: ${dbError.message}`);
    }

    // Create a Set of existing mandala names for efficient lookup
    const existingMandalaNames = new Set(existingMandalas?.map(m => m.name) || []);
    const foundMandalaNames = new Set<string>();

    // Check if we have a 'mandalas' folder - this seems to be the root folder for mandalas
    const { data: rootFiles, error: rootError } = await supabase
      .storage
      .from('ananta')
      .list('', {
        sortBy: { column: 'name', order: 'asc' }
      });

    if (rootError) {
      console.error("Error listing root items:", rootError);
      throw new Error(`Failed to list root items: ${rootError.message}`);
    }

    if (!rootFiles || rootFiles.length === 0) {
      console.log("No items found in the bucket");
      return results;
    }

    console.log(`Found ${rootFiles.length} items in the bucket root:`, rootFiles);

    // Look for the 'mandalas' folder
    const mandalasFolder = rootFiles.find(item => item.name === 'mandalas' && !item.name.includes('.'));
    
    if (mandalasFolder) {
      console.log("Found 'mandalas' folder, scanning subfolders...");
      
      // List all folders in the 'mandalas' directory
      const { data: mandalaFolders, error: mandalaFoldersError } = await supabase
        .storage
        .from('ananta')
        .list('mandalas', {
          sortBy: { column: 'name', order: 'asc' }
        });

      if (mandalaFoldersError) {
        console.error("Error listing mandala folders:", mandalaFoldersError);
        throw new Error(`Failed to list mandala folders: ${mandalaFoldersError.message}`);
      }

      if (!mandalaFolders || mandalaFolders.length === 0) {
        console.log("No mandala folders found in the 'mandalas' directory");
        return results;
      }

      console.log(`Found ${mandalaFolders.length} potential mandala folders:`, mandalaFolders.map(f => f.name));

      // Process each mandala folder
      for (const folder of mandalaFolders) {
        if (folder.name.includes('.')) {
          // Skip files
          console.log(`Skipping file in mandalas folder: ${folder.name}`);
          results.skipped++;
          continue;
        }
        
        console.log(`Processing mandala folder: ${folder.name}`);
        
        try {
          // The path to the mandala is 'mandalas/FolderName'
          const processingResult = await processMandalaFolder(`mandalas/${folder.name}`);
          
          results.processed++;
          
          if (processingResult === 'created') {
            results.created++;
          } else if (processingResult === 'updated') {
            results.updated++;
          } else if (processingResult === 'skipped') {
            console.log(`Folder ${folder.name} was skipped during processing`);
            results.skipped++;
          }

          foundMandalaNames.add(folder.name);
        } catch (err) {
          results.errors++;
          const errorMessage = err instanceof Error ? err.message : String(err);
          results.errorDetails.push(`Error processing ${folder.name}: ${errorMessage}`);
          console.error(`Error processing ${folder.name}:`, err);
        }
      }
    } else {
      console.log("No 'mandalas' folder found, checking for root-level mandala folders...");
      
      // Look for mandala folders directly in the root (backup approach)
      const potentialMandalaFolders = rootFiles.filter(item => !item.name.includes('.'));
      
      console.log(`Found ${potentialMandalaFolders.length} potential root-level mandala folders:`, potentialMandalaFolders.map(f => f.name));
      
      for (const folder of potentialMandalaFolders) {
        console.log(`Processing potential root-level mandala folder: ${folder.name}`);
        
        try {
          const processingResult = await processMandalaFolder(folder.name);
          
          results.processed++;
          
          if (processingResult === 'created') {
            results.created++;
          } else if (processingResult === 'updated') {
            results.updated++;
          } else if (processingResult === 'skipped') {
            console.log(`Folder ${folder.name} was skipped during processing`);
            results.skipped++;
          }

          foundMandalaNames.add(folder.name);
        } catch (err) {
          results.errors++;
          const errorMessage = err instanceof Error ? err.message : String(err);
          results.errorDetails.push(`Error processing ${folder.name}: ${errorMessage}`);
          console.error(`Error processing ${folder.name}:`, err);
        }
      }
    }

    // After processing all folders, check for mandalas that need to be deleted
    for (const mandalaName of existingMandalaNames) {
      if (!foundMandalaNames.has(mandalaName)) {
        console.log(`Deleting mandala '${mandalaName}' as it no longer exists in storage`);
        try {
          const { error: deleteError } = await supabase
            .from('mandalas')
            .delete()
            .eq('name', mandalaName);

          if (deleteError) {
            results.errors++;
            results.errorDetails.push(`Failed to delete ${mandalaName}: ${deleteError.message}`);
          } else {
            results.deleted++;
          }
        } catch (err) {
          results.errors++;
          const errorMessage = err instanceof Error ? err.message : String(err);
          results.errorDetails.push(`Error deleting ${mandalaName}: ${errorMessage}`);
        }
      }
    }

    return results;
  } catch (err) {
    const errorMessage = err instanceof Error ? err.message : String(err);
    results.errorDetails.push(`Scan failed: ${errorMessage}`);
    console.error('Scan failed:', err);
    return results;
  }
}

/**
 * Process a single mandala folder
 */
async function processMandalaFolder(folderPath: string): Promise<'created' | 'updated' | 'skipped'> {
  console.log(`Processing mandala folder path: ${folderPath}`);
  
  // Extract just the folder name for database records
  const folderName = folderPath.includes('/') ? folderPath.split('/').pop() || folderPath : folderPath;
  
  // List all items in the mandala folder
  const { data: folderItems, error: folderError } = await supabase
    .storage
    .from('ananta')
    .list(folderPath, {
      sortBy: { column: 'name', order: 'asc' }
    });

  if (folderError) {
    console.error(`Error listing items in ${folderPath}:`, folderError);
    throw new Error(`Failed to list items in ${folderPath}: ${folderError.message}`);
  }

  if (!folderItems || folderItems.length === 0) {
    console.log(`Folder ${folderPath} is empty, skipping`);
    return 'skipped';
  }

  console.log(`Found ${folderItems.length} items in ${folderPath}:`, folderItems);

  // Find preview image at the mandala folder level
  const previewFile = folderItems.find((item: any) => 
    !item.name.includes('/') && 
    (item.name === 'preview.png' || 
     item.name.startsWith('thumb.') || 
     item.name.includes('preview') || 
     item.name.includes('thumbnail'))
  );

  // Find SD, HD and UHD subfolders
  const sdFolder = folderItems.find((item: any) => item.name === 'sd' && !item.name.includes('.'));
  const hdFolder = folderItems.find((item: any) => item.name === 'hd' && !item.name.includes('.'));
  const uhdFolder = folderItems.find((item: any) => item.name === 'uhd' && !item.name.includes('.'));

  // Process each resolution folder
  let sdLayerUrls: string[] = [];
  let hdLayerUrls: string[] = [];
  let uhdLayerUrls: string[] = [];
  let uhdSize: number | undefined;

  if (sdFolder) {
    console.log(`Processing SD subfolder for ${folderName}`);
    const { urls: sdUrls } = await processResolutionFolder(`${folderPath}/sd`);
    sdLayerUrls = sdUrls;
  }

  if (hdFolder) {
    console.log(`Processing HD subfolder for ${folderName}`);
    const { urls: hdUrls } = await processResolutionFolder(`${folderPath}/hd`);
    hdLayerUrls = hdUrls;
  }

  if (uhdFolder) {
    console.log(`Processing UHD subfolder for ${folderName}`);
    const { urls: uhdUrls, size } = await processResolutionFolder(`${folderPath}/uhd`);
    uhdLayerUrls = uhdUrls;
    uhdSize = size;
  }

  // Determine which to use as the main layer_urls (prefer HD)
  const mainLayerUrls = hdLayerUrls.length > 0 ? hdLayerUrls : uhdLayerUrls.length > 0 ? uhdLayerUrls : sdLayerUrls;
  
  // Find preview URL - if no explicit preview, use the first available layer
  const previewUrl = previewFile 
    ? `https://${import.meta.env.VITE_SUPABASE_PROJECT_ID}.supabase.co/storage/v1/object/public/ananta/${folderPath}/${previewFile.name}`
    : mainLayerUrls.length > 0 ? mainLayerUrls[0] : '';

  // Determine layer count (use HD if available, otherwise UHD, finally SD)
  const layerCount = hdLayerUrls.length > 0 ? hdLayerUrls.length : uhdLayerUrls.length > 0 ? uhdLayerUrls.length : sdLayerUrls.length;

  // Determine image size based on the highest quality available
  let imageSize: number;
  if (uhdFolder && uhdSize) {
    imageSize = uhdSize; // Use the actual detected UHD image size
  } else if (hdFolder) {
    imageSize = 1920; // HD is always 1920px
  } else if (sdFolder) {
    imageSize = 960;  // SD is always 960px
  } else {
    imageSize = 1920; // Default to HD size if no folders found
  }
  
  // Extract potential tags from mandala name
  const tags = extractTags(folderName);

  // Create/update record in mandalas table
  const mandalaData: Partial<Mandala> = {
    name: folderName,
    layer_count: layerCount,
    image_size: imageSize,
    preview_url: previewUrl,
    layer_urls: mainLayerUrls,
    tags: tags,
    sd_layer_urls: sdLayerUrls,
    hd_layer_urls: hdLayerUrls,
    uhd_layer_urls: uhdLayerUrls
  };

  return await updateOrCreateMandala(folderName, mandalaData);
}

/**
 * Process a resolution folder (HD or UHD) and return URLs for all layers
 */
async function processResolutionFolder(folderPath: string): Promise<{ urls: string[], size?: number }> {
  console.log(`Processing resolution folder: ${folderPath}`);
  
  // List all files in the resolution folder
  const { data: files, error } = await supabase
    .storage
    .from('ananta')
    .list(folderPath, {
      sortBy: { column: 'name', order: 'asc' }
    });

  if (error) {
    console.error(`Error listing files in ${folderPath}:`, error);
    throw new Error(`Failed to list files in ${folderPath}: ${error.message}`);
  }

  if (!files || files.length === 0) {
    console.log(`Resolution folder ${folderPath} is empty`);
    return { urls: [] };
  }

  // Filter for image files
  const imageFiles = files.filter((file: any) => 
    file.name.endsWith('.png') || 
    file.name.endsWith('.jpg') || 
    file.name.endsWith('.jpeg') ||
    file.name.endsWith('.webp')
  );

  console.log(`Found ${imageFiles.length} image files in ${folderPath}`);

  // Sort by name (typically numbered)
  imageFiles.sort((a: any, b: any) => {
    // Extract numbers from filenames if present
    const numA = parseInt(a.name.replace(/\D/g, ''));
    const numB = parseInt(b.name.replace(/\D/g, ''));
    
    if (!isNaN(numA) && !isNaN(numB)) {
      return numA - numB;
    }
    
    // Fall back to string comparison
    return a.name.localeCompare(b.name);
  });

  // Generate layer URLs
  const layerUrls = imageFiles.map((file: any) => 
    `https://${import.meta.env.VITE_SUPABASE_PROJECT_ID}.supabase.co/storage/v1/object/public/ananta/${folderPath}/${file.name}`
  );

  // If this is a UHD folder, get the first image's dimensions
  let size: number | undefined;
  if (folderPath.endsWith('/uhd') && imageFiles.length > 0) {
    try {
      // Download the first image to check its dimensions
      const { data: imageData, error: downloadError } = await supabase
        .storage
        .from('ananta')
        .download(`${folderPath}/${imageFiles[0].name}`);

      if (downloadError) {
        console.error(`Error downloading image:`, downloadError);
      } else if (imageData) {
        // Create a blob URL and load it into an Image object
        const blob = new Blob([imageData]);
        const img = new Image();
        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
          img.src = URL.createObjectURL(blob);
        });
        size = img.width; // Square images, so width = height
        URL.revokeObjectURL(img.src);
      }
    } catch (err) {
      console.error(`Error checking image dimensions:`, err);
    }
  }

  return { urls: layerUrls, size };
}

/**
 * Create or update mandala record in database
 */
async function updateOrCreateMandala(mandalaName: string, mandalaData: Partial<Mandala>): Promise<'created' | 'updated'> {
  // Check if mandala already exists
  const { data: existingMandala, error: checkError } = await supabase
    .from('mandalas')
    .select('id')
    .eq('name', mandalaName)
    .single();

  if (checkError && checkError.code !== 'PGRST116') { // Not a 'no rows returned' error
    console.error(`Error checking for existing mandala:`, checkError);
    throw new Error(`Failed to check for existing mandala: ${checkError.message}`);
  }

  if (existingMandala) {
    console.log(`Updating existing mandala '${mandalaName}' with ID: ${existingMandala.id}`);
    // Update existing mandala
    const { error: updateError } = await supabase
      .from('mandalas')
      .update(mandalaData)
      .eq('id', existingMandala.id);

    if (updateError) {
      console.error(`Error updating mandala:`, updateError);
      throw new Error(`Failed to update mandala: ${updateError.message}`);
    }

    return 'updated';
  } else {
    console.log(`Creating new mandala record for '${mandalaName}'`);
    // Insert new mandala
    const { error: insertError } = await supabase
      .from('mandalas')
      .insert(mandalaData);

    if (insertError) {
      console.error(`Error inserting mandala:`, insertError);
      throw new Error(`Failed to insert mandala: ${insertError.message}`);
    }

    return 'created';
  }
}

/**
 * Extract potential tags from mandala name
 */
function extractTags(mandalaName: string): string[] {
  const tags: string[] = [];
  
  // Add the name itself as a tag
  tags.push(mandalaName.toLowerCase());
  
  // Split by common separators
  const parts = mandalaName.split(/[-_\s]+/);
  
  // Add meaningful parts as tags (skip very short ones)
  parts.forEach(part => {
    if (part.length > 2) {
      tags.push(part.toLowerCase());
    }
  });
  
  // Remove duplicates
  return [...new Set(tags)];
} 