import { TextureLoader, Texture, LinearFilter } from 'three';
import { useTunnelStore } from '../store/tunnelStore';
import { getMandalaById, getMandalasByNames } from '../services/mandalaService';

const DEBUG = false;
const BATCH_SIZE = 3;

const textureCache = new Map<string, Texture>();
const loadingPromises = new Map<string, Promise<Texture>>();

const debug = (message: string, ...args: any[]) => {
  if (DEBUG) console.log(`[TextureLoader] ${message}`, ...args);
};

// Add event listener to clear texture cache when quality changes
if (typeof window !== 'undefined') {
  window.addEventListener('mandala-quality-change', () => {
    debug('Quality setting changed, clearing texture cache');
    clearTextureCache();
  });
}

/**
 * Loads a single texture from URL
 */
export const loadTexture = async (url: string): Promise<Texture> => {
  // Check if texture is already cached
  if (textureCache.has(url)) {
    debug(`Using cached texture for ${url}`);
    return textureCache.get(url)!;
  }
  
  // Check if texture is already being loaded
  if (loadingPromises.has(url)) {
    debug(`Waiting for existing load promise for ${url}`);
    return loadingPromises.get(url)!;
  }

  debug(`Loading texture: ${url}`);
  
  // Create a new loading promise
  const loadPromise = new Promise<Texture>((resolve, reject) => {
    new TextureLoader().load(
      url,
      (texture) => {
        // Remove loading promise when done
        loadingPromises.delete(url);
        
        // Apply texture filtering
        texture.minFilter = LinearFilter;
        texture.magFilter = LinearFilter;
        
        // Cache the texture
        textureCache.set(url, texture);
        
        resolve(texture);
      },
      undefined, // onProgress callback not needed
      (error) => {
        loadingPromises.delete(url);
        console.error(`Error loading texture ${url}:`, error);
        reject(error);
      }
    );
  });
  
  // Store the loading promise
  loadingPromises.set(url, loadPromise);
  
  return loadPromise;
};

/**
 * Loads all textures for a mandala by name
 */
export const loadMandalaTextures = async (mandalaName: string): Promise<Texture[]> => {
  debug(`Loading textures for mandala: ${mandalaName}`);
  const store = useTunnelStore.getState();

  try {
    store.setLoading(true);
    store.setError(null);
    
    // Get mandala from the database
    const mandalas = await getMandalasByNames([mandalaName]);
    
    if (!mandalas || mandalas.length === 0) {
      throw new Error(`Mandala not found: ${mandalaName}`);
    }
    
    const mandala = mandalas[0];
    const layerUrls = mandala.layer_urls;
    
    debug(`Found ${layerUrls.length} layers for ${mandalaName}`);
    console.log(`📂 Loading ${layerUrls.length} textures for mandala: ${mandalaName}`);

    const textures: Texture[] = [];
    for (let i = 0; i < layerUrls.length; i += BATCH_SIZE) {
      const batch = layerUrls.slice(i, i + BATCH_SIZE);
      const batchTextures = await Promise.all(batch.map(loadTexture));
      textures.push(...batchTextures);
      debug(`Loaded batch ${Math.floor(i/BATCH_SIZE) + 1}/${Math.ceil(layerUrls.length/BATCH_SIZE)}`);
      console.log(`🔄 Loaded texture batch ${Math.floor(i/BATCH_SIZE) + 1}/${Math.ceil(layerUrls.length/BATCH_SIZE)} for ${mandalaName}`);
    }
    
    console.log(`✅ Successfully loaded ${textures.length} textures for mandala: ${mandalaName}`);
    return textures;
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : 'Failed to load textures';
    store.setError(errorMessage);
    console.error(`❌ Error loading textures for ${mandalaName}:`, error);
    throw error;
  } finally {
    store.setLoading(false);
  }
};

/**
 * Clears the texture cache
 */
export const clearTextureCache = (): void => {
  debug('Clearing texture cache');
  
  // Dispose all textures
  textureCache.forEach(texture => texture.dispose());
  
  // Clear cache
  textureCache.clear();
  loadingPromises.clear();
}; 