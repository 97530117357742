// React import is needed for JSX
// @ts-ignore
import React from 'react';
import { 
  EffectComposer, 
  Bloom, 
  Vignette, 
  ChromaticAberration, 
  Noise,
  DepthOfField,
  Sepia,
  Pixelation,
  ColorAverage,
  BrightnessContrast,
  ToneMapping
} from '@react-three/postprocessing';
import { BlendFunction, KernelSize } from 'postprocessing';
import { useTunnelStore } from '../store/tunnelStore';
// THREE import might be needed for future effects
// @ts-ignore
import * as THREE from 'three';

// Default values to prevent undefined errors
const defaultBloom = {
  enabled: false,
  strength: 5.0,
  radius: 0.6,
  threshold: 0.1,
  smoothing: 0.5,
  kernelSize: 5,
  blend: 0.5,
  saturation: 1.0,
  highlight: 1.0,
};

const defaultColorCorrection = {
  enabled: false,
  brightness: 1,
  contrast: 1,
  saturation: 1,
};

const defaultNoise = {
  enabled: false,
  opacity: 0.2,
  premultiply: false,
};

const defaultVignette = {
  enabled: false,
  darkness: 0.5,
  offset: 0.5,
};

const defaultDepthOfField = {
  enabled: false,
  focusDistance: 0,
  focalLength: 0.02,
  bokehScale: 2,
};

const defaultChromaticAberration = {
  enabled: false,
  offset: 0.005,
};

const defaultSepia = {
  enabled: false,
  intensity: 0.6,
};

const defaultPixelation = {
  enabled: false,
  granularity: 5,
};

const defaultToneMapping = {
  enabled: false,
  exposure: 1.0,
};

export function PostProcessingEffects() {
  const { postProcessing } = useTunnelStore();
  
  // Safely access postProcessing properties with defaults
  const safePostProcessing = postProcessing || { 
    bloom: defaultBloom, 
    colorCorrection: defaultColorCorrection,
    noise: defaultNoise,
    vignette: defaultVignette,
    depthOfField: defaultDepthOfField,
    chromaticAberration: defaultChromaticAberration,
    sepia: defaultSepia,
    pixelation: defaultPixelation,
    toneMapping: defaultToneMapping
  };
  
  // Safely extract effects with defaults
  const bloom = safePostProcessing.bloom || defaultBloom;
  const colorCorrection = safePostProcessing.colorCorrection || defaultColorCorrection;
  const noise = safePostProcessing.noise || defaultNoise;
  const vignette = safePostProcessing.vignette || defaultVignette;
  const depthOfField = safePostProcessing.depthOfField || defaultDepthOfField;
  const chromaticAberration = safePostProcessing.chromaticAberration || defaultChromaticAberration;
  const sepia = safePostProcessing.sepia || defaultSepia;
  const pixelation = safePostProcessing.pixelation || defaultPixelation;
  const toneMapping = safePostProcessing.toneMapping || defaultToneMapping;
  
  // Check if any effects are enabled
  const anyEffectsEnabled = bloom.enabled || 
                           colorCorrection.enabled || 
                           noise.enabled || 
                           vignette.enabled || 
                           depthOfField.enabled || 
                           chromaticAberration.enabled || 
                           sepia.enabled || 
                           pixelation.enabled ||
                           toneMapping.enabled;
  
  if (!anyEffectsEnabled) {
    return null;
  }
  
  // Prepare effects array
  const effects = [];
  
  // Add ChromaticAberration effect
  if (chromaticAberration.enabled) {
    effects.push(
      <ChromaticAberration
        key="chromatic"
        offset={new THREE.Vector2(chromaticAberration.offset, chromaticAberration.offset)}
        radialModulation={true}
        modulationOffset={0.5}
      />
    );
  }
  
  // Add Vignette effect
  if (vignette.enabled) {
    effects.push(
      <Vignette
        key="vignette"
        offset={vignette.offset}
        darkness={vignette.darkness}
        eskil={false}
        blendFunction={BlendFunction.NORMAL}
      />
    );
  }
  
  // Add Noise effect
  if (noise.enabled) {
    effects.push(
      <Noise
        key="noise"
        premultiply={noise.premultiply}
        opacity={noise.opacity}
        blendFunction={BlendFunction.OVERLAY}
      />
    );
  }
  
  // Add DepthOfField effect
  if (depthOfField.enabled) {
    effects.push(
      <DepthOfField
        key="depthOfField"
        focusDistance={depthOfField.focusDistance}
        focalLength={depthOfField.focalLength}
        bokehScale={depthOfField.bokehScale}
        height={480}
      />
    );
  }
  
  // Add Sepia effect
  if (sepia.enabled) {
    effects.push(
      <Sepia
        key="sepia"
        intensity={sepia.intensity}
        blendFunction={BlendFunction.NORMAL}
      />
    );
  }
  
  // Add Pixelation effect
  if (pixelation.enabled) {
    effects.push(
      <Pixelation
        key="pixelation"
        granularity={pixelation.granularity}
      />
    );
  }
  
  // Add ToneMapping effect
  if (toneMapping.enabled) {
    effects.push(
      <ToneMapping
        key="toneMapping"
        exposure={toneMapping.exposure}
      />
    );
  }
  
  // Add Bloom effect as the last effect in the chain
  if (bloom.enabled) {
    // Map kernel size value to KernelSize enum
    const kernelSizeMap = {
      1: KernelSize.VERY_SMALL,
      2: KernelSize.SMALL,
      3: KernelSize.MEDIUM,
      4: KernelSize.LARGE,
      5: KernelSize.VERY_LARGE,
      6: KernelSize.HUGE,
      7: KernelSize.VERY_LARGE, // Fallback to very large
      8: KernelSize.HUGE,       // Fallback to huge
    };
    
    // Get kernel size or default to MEDIUM
    const kernelSizeValue = (bloom as any).kernelSize || 5;
    const mappedKernelSize = kernelSizeMap[kernelSizeValue as keyof typeof kernelSizeMap] || KernelSize.MEDIUM;
    
    // Get blend value or default to ADD
    const blendValue = (bloom as any).blend || 0.5;
    const blendFunc = blendValue > 0.5 ? BlendFunction.ADD : BlendFunction.SCREEN;
    
    // Add additional effects to enhance bloom if saturation or highlight is modified
    if ((bloom as any).saturation !== undefined && (bloom as any).saturation !== 1.0) {
      effects.push(
        <ColorAverage 
          key="colorAverage" 
          blendFunction={BlendFunction.NORMAL} 
        />
      );
    }
    
    if ((bloom as any).highlight !== undefined && (bloom as any).highlight !== 1.0) {
      effects.push(
        <BrightnessContrast 
          key="brightnessContrast"
          brightness={(bloom as any).highlight - 1.0} 
          contrast={0}
        />
      );
    }
    
    effects.push(
      <Bloom 
        key="bloom"
        intensity={bloom.strength} 
        luminanceThreshold={bloom.threshold}
        luminanceSmoothing={(bloom as any).smoothing || 0.5}
        mipmapBlur
        kernelSize={mappedKernelSize}
        blendFunction={blendFunc}
      />
    );
  }
  
  return <EffectComposer>{effects}</EffectComposer>;
} 