import React, { useMemo } from 'react';
import { Texture } from 'three';
import { Suspense } from 'react';
import { MandalaLayer } from '../MandalaLayer';
import { MandalaGroupProps } from '../../types/tunnel';
import { TunnelUtils, DISAPPEAR_POINT, generateMandalaColor } from '../../utils/TunnelUtils';
import { useMotionControl } from './hooks';

/**
 * Component that renders a group of mandala layers for a single mandala
 */
export function MandalaGroup({ 
  textures, 
  groupIndex, 
  mandalaSeparation, 
  layerSeparation,
  speed,
  rotationSpeed,
  debug = false,
  debugColor = 'white',
  respawnPoint,
  previousLayerCounts
}: MandalaGroupProps) {
  const { isPlaying } = useMotionControl();
  
  // Apply speed only when playing, otherwise set to 0
  const effectiveSpeed = isPlaying ? speed : 0;
  const effectiveRotationSpeed = isPlaying ? rotationSpeed : 0;

  return (
    <>
      {textures.map((texture, layerIndex) => (
        <MandalaLayer
          key={`${texture.uuid}-${layerIndex}`}
          texture={texture}
          initialPosition={TunnelUtils.calculateLayerPosition(groupIndex, layerIndex, previousLayerCounts, mandalaSeparation, layerSeparation)}
          speed={effectiveSpeed}
          rotationSpeed={effectiveRotationSpeed}
          size={8}
          respawnPoint={respawnPoint}
          disappearPoint={DISAPPEAR_POINT}
          opacity={1}
          debug={debug}
          debugColor={debugColor}
        />
      ))}
    </>
  );
}

interface TunnelGeometryProps {
  textures: Texture[];
  mandalaConfig: any[];
  mandalaSeparation: number;
  layerSeparation: number;
  speed: number;
  rotationSpeed: number;
  debug: boolean;
}

/**
 * Component that organizes all mandalas and their layers in the tunnel
 */
export function TunnelGeometry({ 
  textures, 
  mandalaConfig, 
  mandalaSeparation, 
  layerSeparation, 
  speed, 
  rotationSpeed, 
  debug 
}: TunnelGeometryProps) {
  // Memoized tunnel length calculation
  const tunnelLength = useMemo(() => 
    TunnelUtils.calculateConfig(
      mandalaConfig.length,
      mandalaConfig.map(m => m.layerCount),
      mandalaSeparation,
      layerSeparation
    ),
    [mandalaConfig, mandalaSeparation, layerSeparation]
  );

  // Memoized organization of mandala textures into groups
  const mandalaGroups = useMemo(() => {
    // Get array of layer counts for each mandala
    const layerCounts = mandalaConfig.map(m => m.layerCount);
    
    return mandalaConfig.map((mandala, index) => {
      const startIndex = mandalaConfig
        .slice(0, index)
        .reduce((sum, m) => sum + m.layerCount, 0);
      
      return {
        textures: textures.slice(startIndex, startIndex + mandala.layerCount),
        previousLayerCounts: layerCounts // Pass the entire array of layer counts
      };
    });
  }, [mandalaConfig, textures]);

  return (
    <Suspense fallback={null}>
      {mandalaGroups.map(({ textures: mandalaTextures, previousLayerCounts }, groupIndex) => (
        <MandalaGroup
          key={groupIndex}
          textures={mandalaTextures}
          groupIndex={groupIndex}
          mandalaSeparation={mandalaSeparation}
          layerSeparation={layerSeparation}
          speed={speed} 
          rotationSpeed={rotationSpeed}
          debug={debug}
          debugColor={generateMandalaColor(groupIndex)}
          respawnPoint={tunnelLength}
          previousLayerCounts={previousLayerCounts}
        />
      ))}
    </Suspense>
  );
} 