import React, { useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { Suspense } from 'react';
import { SmoothOrbitControls } from '../SmoothOrbitControls';
import { Bird } from '../Bird';
import { DEFAULT_CAMERA_POSITION, DEBUG_CAMERA_POSITION } from '../../utils/TunnelUtils';
import KeyboardCameraController from '../KeyboardCameraController';
import { TunnelGeometry } from './TunnelGeometry';
import { TunnelEffects } from './TunnelEffects';
import { DebugPlanes } from './DebugComponents';
import { CameraReset, CameraInfo } from './CameraController';

interface TunnelSceneProps {
  canvasRef: React.RefObject<HTMLCanvasElement | null>;
  controlsRef: React.RefObject<any>;
  isResettingCamera: boolean;
  debug: boolean;
  mandalaConfig: any[];
  textures: any[];
  mandalaSeparation: number;
  layerSeparation: number;
  speed: number;
  rotationSpeed: number;
  fogEnabled: boolean;
  fogNear: number;
  fogFar: number;
  backgroundColor: string;
}

/**
 * Component that sets up the core Three.js scene for the tunnel
 */
export function TunnelScene({
  canvasRef,
  controlsRef,
  isResettingCamera,
  debug,
  mandalaConfig,
  textures,
  mandalaSeparation,
  layerSeparation,
  speed,
  rotationSpeed,
  fogEnabled,
  fogNear,
  fogFar,
  backgroundColor
}: TunnelSceneProps) {
  // Calculate tunnel length for debug planes
  const tunnelLength = mandalaConfig.length > 0 
    ? calculateTunnelLength(
        mandalaConfig.length,
        mandalaConfig.map(m => m.layerCount),
        mandalaSeparation,
        layerSeparation
      )
    : 0;

  return (
    <Canvas
      ref={canvasRef}
      camera={{
        fov: debug ? 35 : Number(import.meta.env.VITE_DEFAULT_FOV),
        near: 0.01,
        far: Number(import.meta.env.VITE_DEFAULT_FAR),
        position: debug ? DEBUG_CAMERA_POSITION : DEFAULT_CAMERA_POSITION,
      }}
      gl={{
        antialias: true,
        alpha: true,
        stencil: false,
        depth: true,
        powerPreference: "high-performance",
        preserveDrawingBuffer: true,
        precision: "highp",
      }}
    >
      {/* Scene effects and lighting */}
      <TunnelEffects 
        fogEnabled={fogEnabled}
        fogNear={fogNear}
        fogFar={fogFar}
        mandalaConfig={mandalaConfig}
        mandalaSeparation={mandalaSeparation}
        layerSeparation={layerSeparation}
        debug={debug}
        backgroundColor={backgroundColor}
      />
      
      {/* Add keyboard camera controller without debug logs */}
      {!debug && (
        <KeyboardCameraController 
          enabled={true} 
          controlsRef={controlsRef} 
        />
      )}
      
      {/* Debug visualization */}
      {debug && mandalaConfig.length > 0 && (
        <DebugPlanes 
          disappearPoint={0} // DISAPPEAR_POINT constant
          tunnelConfig={tunnelLength}
          mandalaCount={mandalaConfig.length}
          layersPerMandala={mandalaConfig.map(m => m.layerCount)}
          mandalaSeparation={mandalaSeparation}
          layerSeparation={layerSeparation}
          fogConfig={null} // This will be calculated in TunnelEffects
        />
      )}
      
      {/* Tunnel geometry - all mandala layers */}
      <TunnelGeometry 
        textures={textures}
        mandalaConfig={mandalaConfig}
        mandalaSeparation={mandalaSeparation}
        layerSeparation={layerSeparation}
        speed={speed}
        rotationSpeed={rotationSpeed}
        debug={debug}
      />

      {/* Camera controls */}
      <SmoothOrbitControls
        ref={controlsRef}
        minDistance={0}
        maxDistance={debug ? 1000 : 30}
      />
      <CameraReset isResetting={isResettingCamera} debug={debug} />
      <CameraInfo />
      
      {/* Bird model */}
      <group renderOrder={9999} position={[0, 0, 0]}>
        <Bird scale={0.25} renderOrder={10000} />
      </group>
    </Canvas>
  );
}

// Utility function to calculate tunnel length
function calculateTunnelLength(
  mandalaCount: number, 
  layersPerMandala: number[], 
  mandalaSeparation: number, 
  layerSeparation: number
): number {
  // Start at white plane (z=0)
  let position = 0;
  
  // First add initial mandala separation
  position -= mandalaSeparation;
  
  // For each mandala
  for (let i = 0; i < mandalaCount; i++) {
    // Add layer separations between layers of this mandala
    position -= (layersPerMandala[i] - 1) * layerSeparation;
    
    // Add separation for next mandala (if not last mandala)
    if (i < mandalaCount - 1) {
      position -= mandalaSeparation;
    }
  }
  
  return position;
} 