import { useRef } from 'react';
// useEffect might be needed for future cleanup or initialization
// @ts-ignore
import { useEffect } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
// Vector3 might be needed for future camera position calculations
// @ts-ignore
import { Vector3 } from 'three';
import { useTunnelStore } from '../store/tunnelStore';

const ease = (t: number) => t * t * (3 - 2 * t); // Smoothstep easing

export function useSmoothControls(enabled = true) {
  const { camera } = useThree();
  const targetDistance = useRef(5);
  const currentDistance = useRef(5);
  const isAnimating = useRef(false);
  const animationProgress = useRef(0);
  const { debug } = useTunnelStore();

  useFrame((_, delta) => {
    if (!debug && isAnimating.current) {
      animationProgress.current = Math.min(animationProgress.current + delta * 2, 1);
      const alpha = ease(animationProgress.current);

      // Calculate the current camera position
      const direction = camera.position.clone().normalize();
      const targetPos = direction.multiplyScalar(targetDistance.current);
      const currentPos = camera.position.clone().normalize().multiplyScalar(currentDistance.current);
      
      // Interpolate between current and target positions
      camera.position.lerpVectors(currentPos, targetPos, alpha);

      if (animationProgress.current === 1) {
        isAnimating.current = false;
      }
    }
  });

  // Function to handle zoom changes
  const handleZoom = (distance: number) => {
    if (!enabled || debug) return;

    targetDistance.current = Math.max(2, Math.min(10, distance));
    currentDistance.current = camera.position.length();
    isAnimating.current = true;
    animationProgress.current = 0;
  };

  return {
    handleZoom,
  };
} 