import React, { useRef, useEffect } from 'react';
import { Trail } from '@react-three/drei';
import * as THREE from 'three';
import { useBirdStore } from './BirdStore';

interface BirdEffectsProps {
  scene: THREE.Group | null;
}

export function BirdEffects({ scene }: BirdEffectsProps) {
  // This component now returns null to completely disable trail effects
  return null;
} 