// Utility functions for tunnel calculations and transformations

/**
 * Utility functions for tunnel geometry and positioning calculations
 */
export const TunnelUtils = {
  /**
   * Calculate the total tunnel length based on mandala configuration
   */
  calculateConfig: (mandalaCount: number, layersPerMandala: number[], mandalaSeparation: number, layerSeparation: number) => {
    // Start at white plane (z=0)
    let position = 0;
    
    // First add initial mandala separation
    position -= mandalaSeparation;
    
    // For each mandala
    for (let i = 0; i < mandalaCount; i++) {
      // Add layer separations between layers of this mandala
      position -= (layersPerMandala[i] - 1) * layerSeparation;
      
      // Add separation for next mandala (if not last mandala)
      if (i < mandalaCount - 1) {
        position -= mandalaSeparation;
      }
    }
    
    return position;
  },

  /**
   * Calculate the z-position for a specific layer in a mandala
   */
  calculateLayerPosition: (mandalaIndex: number, layerIndex: number, previousLayerCounts: number[], mandalaSeparation: number, layerSeparation: number) => {
    // Start at white plane (z=0)
    let position = 0;
    
    // First add initial mandala separation
    position -= mandalaSeparation;
    
    // Add positions for completed mandalas
    for (let i = 0; i < mandalaIndex; i++) {
      // Add layer separations between layers of this mandala
      position -= (previousLayerCounts[i] - 1) * layerSeparation;
      // Add separation for next mandala
      position -= mandalaSeparation;
    }
    
    // Finally, add layer separations for layers in current mandala
    position -= layerIndex * layerSeparation;
    
    return position;
  },

  /**
   * Convert hex color string to RGB values
   */
  hexToRgb: (hex: string) => {
    // Remove # if present
    hex = hex.replace(/^#/, '');
    
    // Parse hex to rgb
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    
    return { r: r / 255, g: g / 255, b: b / 255 };
  }
};

// Fixed points for layer behavior
export const DISAPPEAR_POINT = 0;  // Where layers start to fade out (at the white plane)
export const FADE_DISTANCE = 1;    // How much distance to take to fade out completely

// Fog configuration
export const FOG_COLOR = 0x120315;  // Use hex number instead of string for Three.js

// Camera positions
export const DEFAULT_CAMERA_POSITION = [0, 0, 2] as const;
export const DEBUG_CAMERA_POSITION = [-72.48, 6.46, -8.06] as const;

/**
 * Generate a color for mandala based on its index
 */
export const generateMandalaColor = (index: number): string => {
  const mandalaColors = ['#ff3366', '#4da6ff', '#66ff66', '#ffcc00', '#ff66ff'];
  return mandalaColors[index % mandalaColors.length];
}; 