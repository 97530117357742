import React, { useMemo } from 'react';
import { FogConfig } from '../../types/tunnel';
import { FOG_COLOR } from '../../utils/TunnelUtils';
import { PostProcessingEffects } from '../PostProcessingEffects';
import { useThree, extend } from '@react-three/fiber';
import * as THREE from 'three';
import { useBirdStore } from '../Bird/BirdStore';

interface TunnelFogProps {
  fogConfig: FogConfig;
  debug: boolean;
}

/**
 * Component that handles the tunnel fog effect
 */
export function TunnelFog({ fogConfig, debug }: TunnelFogProps) {
  // Only render fog if it's enabled and not in debug mode
  if (!fogConfig.isFogEnabled || debug) {
    return null;
  }

  return (
    <fog 
      attach="fog" 
      args={[FOG_COLOR, fogConfig.fogNear, fogConfig.fogFar]} 
    />
  );
}

interface TunnelLightingProps {
  debug?: boolean;
}

/**
 * Component that handles all lighting in the tunnel
 */
export function TunnelLighting({ debug = false }: TunnelLightingProps) {
  // Get lighting settings from BirdStore
  const lightingSettings = useBirdStore(state => state.lightingSettings);
  
  return (
    <>
      {/* Add ambient light for MeshStandardMaterial */}
      <ambientLight intensity={lightingSettings.ambientIntensity} />
      
      {/* Add point light at camera position for better illumination */}
      <pointLight 
        position={[0, 0, 2]} 
        intensity={lightingSettings.lightIntensity} 
        distance={50} 
        decay={2}
        color={lightingSettings.lightColor}
      />
      
      {/* Add additional light in debug mode */}
      {debug && (
        <directionalLight 
          position={[5, 5, 5]} 
          intensity={0.5} 
          castShadow 
        />
      )}
    </>
  );
}

interface TunnelEffectsProps {
  fogEnabled: boolean;
  fogNear: number;
  fogFar: number;
  mandalaConfig: any[];
  mandalaSeparation: number;
  layerSeparation: number;
  debug: boolean;
  backgroundColor: string;
}

/**
 * Main component that combines all visual effects for the tunnel
 */
export function TunnelEffects({
  fogEnabled,
  fogNear,
  fogFar,
  mandalaConfig,
  mandalaSeparation,
  layerSeparation,
  debug,
  backgroundColor
}: TunnelEffectsProps) {
  const { scene } = useThree();
  
  // Set background color
  useMemo(() => {
    if (backgroundColor) {
      scene.background = new THREE.Color(backgroundColor);
    }
  }, [backgroundColor, scene]);

  // Calculate fog configuration
  const fogConfig = useMemo(() => {
    // Always calculate fog positions in debug mode, even if fog is disabled
    if (!mandalaConfig.length) return null;

    // Calculate the tunnel length for proper fog positioning
    const tunnelLengthFn = (mandalaCount: number, layersPerMandala: number[], mandalaSep: number, layerSep: number) => {
      let position = 0;
      position -= mandalaSep;
      for (let i = 0; i < mandalaCount; i++) {
        position -= (layersPerMandala[i] - 1) * layerSep;
        if (i < mandalaCount - 1) position -= mandalaSep;
      }
      return position;
    };

    const tunnelLength = Math.abs(tunnelLengthFn(
      mandalaConfig.length,
      mandalaConfig.map(m => m.layerCount),
      mandalaSeparation,
      layerSeparation
    ));

    // Convert percentage to actual position
    // Note: fogNear and fogFar are negative percentages (-100 to 0)
    // Keep positions negative to match tunnel coordinate system
    const fogNearPos = -(tunnelLength * Math.abs(fogNear) / 100);
    const fogFarPos = -(tunnelLength * Math.abs(fogFar) / 100);

    // For Three.js fog, we need to use the absolute distances from camera
    return { 
      fogNear: Math.abs(fogNearPos), 
      fogFar: Math.abs(fogFarPos),
      // Store actual positions for debug planes
      actualFogNear: fogNearPos,
      actualFogFar: fogFarPos,
      // Store whether fog is actually enabled
      isFogEnabled: fogEnabled || debug // Always consider fog enabled in debug mode for visualization
    };
  }, [mandalaConfig, mandalaSeparation, layerSeparation, fogEnabled, fogNear, fogFar, debug]);

  return (
    <>
      <TunnelLighting debug={debug} />
      
      {/* Add fog when config is available */}
      {fogConfig && (
        <TunnelFog fogConfig={fogConfig} debug={debug} />
      )}
      
      {/* Add post-processing effects */}
      <PostProcessingEffects />
    </>
  );
} 