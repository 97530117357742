import { forwardRef } from 'react';
import { OrbitControls as DreiOrbitControls } from '@react-three/drei';
import { useSmoothControls } from '../hooks/useSmoothControls';
import { useTunnelStore } from '../store/tunnelStore';

interface SmoothOrbitControlsProps {
  enablePan?: boolean;
  enableZoom?: boolean;
  minDistance?: number;
  maxDistance?: number;
  rotateSpeed?: number;
  zoomSpeed?: number;
}

export const SmoothOrbitControls = forwardRef<any, SmoothOrbitControlsProps>(({
  enablePan = false,
  enableZoom = true,
  minDistance = 2,
  maxDistance = 10,
  rotateSpeed = 0.5,
  zoomSpeed = 1
}, ref) => {
  const { handleZoom } = useSmoothControls();
  const { debug } = useTunnelStore();

  return (
    <DreiOrbitControls
      ref={ref}
      enablePan={enablePan}
      enableZoom={enableZoom}
      minDistance={minDistance}
      maxDistance={maxDistance}
      rotateSpeed={rotateSpeed}
      zoomSpeed={zoomSpeed}
      onChange={(e) => {
        if (!debug) {
          const target = e?.target as { getDistance: () => number };
          if (target?.getDistance) {
            handleZoom(target.getDistance());
          }
        }
      }}
      enableDamping={!debug}
      dampingFactor={0.05}
    />
  );
}); 