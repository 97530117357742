import React, { useState } from 'react';
import { Button } from './ui/button';
import { saveMandalaConfig, deleteMandalaConfig, MandalaConfig } from '../services/supabase';
import { toast } from './ui/use-toast';
import { Save } from 'lucide-react';
import PresetPatternIcon, { PatternData, generateRandomPattern } from './PresetPatternIcon';
import { getMandalaConfigs } from '../services/supabase';

interface PresetPanelProps {
  mandalaName: string;
  onSavePreset: () => Promise<MandalaConfig>;
  onLoadPreset: (preset: MandalaConfig) => void;
  currentPresetId?: string;
  onSelectMandala?: (mandalaName: string) => void;
}

export function PresetPanel({ 
  mandalaName, 
  onSavePreset, 
  onLoadPreset,
  currentPresetId,
  onSelectMandala
}: PresetPanelProps) {
  const [presets, setPresets] = useState<MandalaConfig[]>([]);
  const [patternData, setPatternData] = useState<PatternData>(generateRandomPattern());
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(true);

  // Load presets for the current mandala
  React.useEffect(() => {
    const loadPresets = async () => {
      setLoading(true);
      try {
        // Load presets for all mandalas instead of just the current one
        const configs = await getMandalaConfigs('');
        setPresets(configs);
      } catch (error) {
        console.error('Error loading mandala presets:', error);
        toast({
          title: "Failed to load presets",
          description: "There was an error loading your saved presets",
          variant: "destructive"
        });
      } finally {
        setLoading(false);
      }
    };
    
    loadPresets();
  }, []); // Remove mandalaName dependency so it only loads once

  const handleSavePreset = async () => {
    if (isSaving || !mandalaName) return;
    
    setIsSaving(true);
    try {
      // Get the current configuration from parent component
      const config = await onSavePreset();
      
      // Add the pattern and auto-generate name
      const presetToSave: MandalaConfig = {
        ...config,
        mandala_name: mandalaName,
        name: `Preset ${new Date().toLocaleTimeString()}`,
        pattern_data: patternData,
      };
      
      // Save to database
      const id = await saveMandalaConfig(presetToSave);
      
      if (id) {
        // Regenerate a new pattern for next save
        setPatternData(generateRandomPattern());
        
        // Refresh presets list
        const updatedPresets = await getMandalaConfigs('');
        setPresets(updatedPresets);
        
        toast({
          title: "Preset saved",
          description: "Your mandala configuration has been saved",
        });
      } else {
        toast({
          title: "Failed to save preset",
          description: "There was an error saving your configuration",
          variant: "destructive"
        });
      }
    } catch (error) {
      console.error('Error saving preset:', error);
      toast({
        title: "Failed to save preset",
        description: "There was an error saving your configuration",
        variant: "destructive"
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeletePreset = async (preset: MandalaConfig) => {
    if (!preset.id) return;
    
    try {
      const success = await deleteMandalaConfig(preset.id);
      
      if (success) {
        // Refresh the list - use empty string to get all presets across mandalas
        const updatedPresets = await getMandalaConfigs('');
        setPresets(updatedPresets);
        
        toast({
          title: "Preset deleted",
          description: "Your mandala preset has been deleted",
        });
      } else {
        toast({
          title: "Failed to delete preset",
          description: "There was an error deleting your preset",
          variant: "destructive"
        });
      }
    } catch (error) {
      console.error('Error deleting preset:', error);
      toast({
        title: "Failed to delete preset",
        description: "There was an error deleting your preset",
        variant: "destructive"
      });
    }
  };

  const handlePresetSelect = (preset: MandalaConfig) => {
    if (preset.id === currentPresetId) {
      // Handle deleting when clicking active preset
      if (confirm('Delete this preset?')) {
        handleDeletePreset(preset);
      }
    } else {
      // Check if we need to navigate to a different mandala page
      if (onSelectMandala && preset.mandala_name !== mandalaName) {
        onSelectMandala(preset.mandala_name);
      }
      
      // Load the preset
      onLoadPreset(preset);
    }
  };

  return (
    <div className="flex items-center space-x-2">
      {loading ? (
        <div className="flex space-x-1">
          <div className="w-3 h-3 bg-white/20 rounded-sm animate-pulse"></div>
          <div className="w-3 h-3 bg-white/20 rounded-sm animate-pulse"></div>
        </div>
      ) : (
        <>
          {/* Preset icons */}
          <div className="flex space-x-3">
            {presets.map((preset) => (
              <div key={preset.id} className="relative group">
                <PresetPatternIcon
                  size={14}
                  isActive={preset.id === currentPresetId}
                  patternData={preset.pattern_data as PatternData}
                  onClick={() => handlePresetSelect(preset)}
                />
                {/* Add tooltip to show which mandala this preset belongs to */}
                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 px-2 py-1 bg-black/80 text-xs rounded opacity-0 group-hover:opacity-100 pointer-events-none whitespace-nowrap transition-opacity">
                  {preset.mandala_name}
                </div>
              </div>
            ))}
          </div>
          
          {/* Save button - only show when a mandala is selected */}
          {mandalaName && (
            <Button 
              variant="ghost" 
              size="icon" 
              className="w-5 h-5 p-0 rounded-sm"
              onClick={handleSavePreset}
              disabled={isSaving}
            >
              {isSaving ? (
                <span className="animate-spin">◌</span>
              ) : (
                <Save size={12} />
              )}
            </Button>
          )}
        </>
      )}
    </div>
  );
}

export default PresetPanel; 