import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { Suspense } from 'react';
import * as THREE from 'three';
import { OrbitControls } from '@react-three/drei';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { toast } from "../components/ui/use-toast";
import { Button } from "../components/ui/button";
import { Card, CardContent } from "../components/ui/card";
import { Badge } from "../components/ui/badge";
import { ChevronLeft, ChevronRight, ChevronDown, ChevronUp, Settings, Boxes, Box, Dice6, Layers, Save, Download, Eye, EyeOff, Sparkle, Power, Camera, Rotate3d, Copy, RefreshCw, Wand2, Camera as CameraIcon, Disc3, ArrowLeft, Play } from 'lucide-react';
import { PostProcessingEffects } from '../components/PostProcessingEffects';
import { getRandomQuote } from '../utils/quotes';
import PresetPanel from '@/components/PresetPanel';
import { MandalaConfig } from '@/services/supabase';
import { Progress } from "../components/ui/progress";
import { useTunnelStore } from '../store/tunnelStore';
import { useMotionControl } from '../components/Tunnel';
import { loadMandalaTextures } from '../utils/textureLoader';
import { getMandalasByNames, getAllMandalas, getMandalaQuality } from '../services/mandalaService';
import { TunnelScene } from '../components/Tunnel/TunnelScene';
import { TunnelMandalaOverlay } from '../components/Tunnel/TunnelMandalaOverlay';
import { MandalaInfo } from '../types/tunnel';
import { ControlPanelWrapper } from '../components/ControlPanelWrapper';
import { MandalaDeformationCard } from '../components/MandalaDeformationCard';
import { DeformationSettings } from '../components/ControlPanel/DeformationControls';
import { applyDeformationEffects, updateDeformationEffects } from '../shaders/MandalaShaders';
import Magic from '/public/Icons/Magic.svg';
import Capture from '/public/Icons/Capture.svg';

// Animation parameters - same as HomePage
const PULSE_SPEED = 0.4; // Speed of the pulsing
const MIN_LAYER_SEPARATION = 0.05; // Minimum separation when compressed
const MAX_LAYER_SEPARATION = 0.7; // Maximum separation when expanded - reduced for less depth movement

// Camera controller component with mouse orbit functionality
function CameraController({ targetCameraDistance, currentCameraDistanceRef }: { 
  targetCameraDistance?: number,
  currentCameraDistanceRef: React.MutableRefObject<number>
}) {
  const { camera } = useThree();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const initialPosition = useRef(new THREE.Vector3(0, 0, 12));
  const cameraDistance = useRef(12);
  const minDistance = 1;  // Reduced from 3 to 1 to allow getting closer
  const maxDistance = 50; // Increased from 20 to 50 to allow getting much further away
  
  // Store initial camera position on mount
  useEffect(() => {
    // Set initial camera position without x and y offsets (centered)
    camera.position.set(0, 0, 12);
    initialPosition.current.copy(camera.position);
    
    // Only initialize with targetCameraDistance if explicitly provided
    // but with minimal impact to allow manual control to take precedence
    if (targetCameraDistance !== undefined) {
      // Just set the initial value once, don't force it
      cameraDistance.current = targetCameraDistance;
      
      // Update the shared ref for use in savePreset
      if (currentCameraDistanceRef.current !== undefined) {
        currentCameraDistanceRef.current = targetCameraDistance;
      }
    } else {
      cameraDistance.current = initialPosition.current.z;
      
      // Initialize the shared ref
      if (currentCameraDistanceRef.current !== undefined) {
        currentCameraDistanceRef.current = cameraDistance.current;
      }
    }
  }, [camera, targetCameraDistance, currentCameraDistanceRef]);
  
  // Listen for force-camera-update events
  useEffect(() => {
    const handleForceUpdate = (event: CustomEvent<{distance: number}>) => {
      const { distance } = event.detail;
      console.log('Force updating camera to distance:', distance);
      
      // Update the ref
      cameraDistance.current = distance;
      
      // Update the shared ref
      if (currentCameraDistanceRef.current !== undefined) {
        currentCameraDistanceRef.current = distance;
      }
      
      // Update camera position immediately for immediate visual feedback
      camera.position.z = distance;
    };
    
    window.addEventListener('force-camera-update', handleForceUpdate as EventListener);
    
    return () => {
      window.removeEventListener('force-camera-update', handleForceUpdate as EventListener);
    };
  }, [camera, currentCameraDistanceRef]);
  
  // Setup mouse move listener
  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      // Normalize mouse position to range -1 to 1
      const x = (event.clientX / window.innerWidth) * 2 - 1;
      const y = (event.clientY / window.innerHeight) * 2 - 1;
      setMousePosition({ x, y });
    };
    
    window.addEventListener('mousemove', handleMouseMove);
    
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  // Add wheel listener for dolly/zoom
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      // Prevent default scrolling behavior
      event.preventDefault();
      
      // Large fixed step size for immediate feedback
      const zoomStep = 1.0; // Significantly increased for dramatic movement
      
      // Use fixed step size with no dependency on current distance
      const scrollDirection = Math.sign(event.deltaY);
      cameraDistance.current += scrollDirection * zoomStep;
      
      // Clamp to min/max distances
      cameraDistance.current = Math.max(minDistance, Math.min(maxDistance, cameraDistance.current));
      
      // Update the shared ref
      if (currentCameraDistanceRef.current !== undefined) {
        currentCameraDistanceRef.current = cameraDistance.current;
      }
    };
    
    // Add wheel event listener with passive: false to allow preventDefault
    window.addEventListener('wheel', handleWheel, { passive: false });
    
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);
  
  // Add keyboard controls for dolly
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Use W/S keys for dolly movement
      if (event.key === 'w' || event.key === 'W') {
        // Move forward (closer) - increased movement amount for better responsiveness
        cameraDistance.current = Math.max(minDistance, cameraDistance.current - 1.0);
      } else if (event.key === 's' || event.key === 'S') {
        // Move backward (farther) - increased movement amount for better responsiveness
        cameraDistance.current = Math.min(maxDistance, cameraDistance.current + 1.0);
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  
  // Camera movement based on mouse position
  useFrame(() => {
    // No longer use targetCameraDistance at all - let manual controls fully determine position
    // This removes any automatic constraints on camera movement
    
    // Calculate target position for orbiting
    // Use spherical coordinates for circular movement
    const theta = mousePosition.x * Math.PI * 0.025; // Horizontal angle
    const phi = Math.PI / 2 + mousePosition.y * Math.PI * 0.025; // Vertical angle
    
    // Calculate target position
    const targetX = cameraDistance.current * Math.sin(phi) * Math.sin(theta);
    const targetY = cameraDistance.current * Math.cos(phi);
    const targetZ = cameraDistance.current * Math.sin(phi) * Math.cos(theta);
    
    // Much more responsive camera movement, especially for depth
    camera.position.x += (targetX - camera.position.x) * 0.003; // Keep horizontal movement subtle
    camera.position.y += (targetY - camera.position.y) * 0.01;  // Keep vertical as is
    camera.position.z += (targetZ - camera.position.z) * 0.2;   // Greatly increased for immediate response
    
    // Always look at the center
    camera.lookAt(0, 0, 0);
  });
  
  return null;
}

interface MandalaLayerProps {
  texture: THREE.Texture;
  index: number;
  totalLayers: number;
  layerSeparation: React.RefObject<number>;
}

// Layer component for a single mandala layer - identical to HomePage
function MandalaLayer({ texture, index, totalLayers, layerSeparation }: MandalaLayerProps) {
  const meshRef = useRef<THREE.Mesh>(null);
  const { viewport, camera } = useThree();
  const { deformation } = useTunnelStore();
  const timeRef = useRef(0);
  
  // Calculate size to fit the viewport based on camera distance
  const cameraDistance = 10; // Match the distance we set for the camera
  
  // Calculate visible dimensions at this distance using a safe approach
  const perspectiveCamera = camera as THREE.PerspectiveCamera;
  const fovRadians = (perspectiveCamera.fov || 40) * (Math.PI / 180);
  const visibleHeight = 2 * Math.tan(fovRadians / 2) * cameraDistance;
  const visibleWidth = visibleHeight * (viewport.width / viewport.height);
  const size = Math.max(visibleHeight, visibleWidth);
  
  // Apply deformation effects when mesh is ready or deformation settings change
  useEffect(() => {
    if (meshRef.current) {
      // Check if any deformation effects are enabled
      const hasEnabledEffect = Object.values(deformation).some(effect => effect.enabled);
      
      if (hasEnabledEffect) {
        // Apply the deformation effects
        applyDeformationEffects(meshRef.current, texture, deformation);
      } else {
        // Reset to basic material if no effects are enabled
        meshRef.current.material = new THREE.MeshBasicMaterial({ 
          map: texture, 
          transparent: true, 
          side: THREE.DoubleSide,
          depthWrite: true
        });
      }
    }
  }, [
    texture,
    deformation // Use the entire deformation object instead of individual properties
  ]);
  
  // Animation function run on each frame
  useFrame((_, delta) => {
    if (meshRef.current) {
      // Update the time reference for animated effects
      timeRef.current += delta;
      
      // Calculate layer position based on current separation value
      const separation = layerSeparation.current || MIN_LAYER_SEPARATION;
      // Add a base offset to ensure even the first layer (index 0) moves
      meshRef.current.position.z = -(index + 1) * separation;
      
      // Check if any deformation effects are enabled
      const hasEnabledEffect = Object.values(deformation).some(effect => effect.enabled);
      
      if (hasEnabledEffect && meshRef.current.material instanceof THREE.ShaderMaterial) {
        // Update the deformation effects with the correct parameter order
        updateDeformationEffects(meshRef.current, timeRef.current, deformation);
      }
    }
  });

  // Make sure to watch for changes in the bloom effect parameters
  useEffect(() => {
    if (deformation.bloom.enabled && meshRef.current && meshRef.current.material instanceof THREE.ShaderMaterial) {
      const material = meshRef.current.material as THREE.ShaderMaterial;
      if (material.uniforms) {
        if (material.uniforms.intensity) material.uniforms.intensity.value = deformation.bloom.intensity;
        if (material.uniforms.threshold) material.uniforms.threshold.value = deformation.bloom.threshold;
        if (material.uniforms.radius) material.uniforms.radius.value = deformation.bloom.radius;
        if (material.uniforms.smoothing) material.uniforms.smoothing.value = (deformation.bloom as any).smoothing || 0.5;
        // Additional bloom parameters are handled via post-processing
      }
    }
  }, [
    deformation.bloom.enabled,
    deformation.bloom.intensity,
    deformation.bloom.threshold,
    deformation.bloom.radius,
    (deformation.bloom as any).smoothing,
    (deformation.bloom as any).kernelSize,
    (deformation.bloom as any).blend,
    (deformation.bloom as any).saturation,
    (deformation.bloom as any).highlight
  ]);

  return (
    <mesh ref={meshRef} position={[0, 0, -(index + 1) * MIN_LAYER_SEPARATION]}>
      <planeGeometry args={[size, size]} />
      <meshBasicMaterial 
        map={texture} 
        transparent={true} 
        side={THREE.DoubleSide} 
        depthWrite={true}
      />
    </mesh>
  );
}

interface HeartbeatTunnelProps {
  textures: THREE.Texture[];
  explosionRef?: React.RefObject<number>;
}

// HeartbeatTunnel component manages the pulsing animation
function HeartbeatTunnel({ textures, explosionRef }: HeartbeatTunnelProps) {
  const layerSeparationValue = useRef<number>(MIN_LAYER_SEPARATION);
  const heartbeatPhase = useRef<number>(0);
  const { scene } = useThree();
  
  // Set background color to #120315
  useEffect(() => {
    scene.background = new THREE.Color('#120315');
  }, [scene]);
  
  // Animation loop using useFrame
  useFrame(() => {
    // Normally animate with pulsing effect, but use explosionRef during transition
    if (explosionRef && explosionRef.current > MIN_LAYER_SEPARATION) {
      // During explosion animation, use the externally controlled value
      layerSeparationValue.current = explosionRef.current;
    } else {
      // Normal pulsing animation when not transitioning
      // Update the phase
      heartbeatPhase.current += PULSE_SPEED * 0.01;
      
      // Calculate separation with sine wave for pulsing effect
      // Map sine output (-1 to 1) to range between MIN and MAX layer separation
      const pulseValue = (Math.sin(heartbeatPhase.current) + 1) / 2; // Now 0 to 1
      layerSeparationValue.current = MIN_LAYER_SEPARATION + 
        pulseValue * (MAX_LAYER_SEPARATION - MIN_LAYER_SEPARATION);
    }
  });
  
  return (
    <>
      {textures.map((texture, index) => (
        <MandalaLayer
          key={`${texture.uuid}-${index}`}
          texture={texture}
          index={index}
          totalLayers={textures.length}
          layerSeparation={layerSeparationValue}
        />
      ))}
    </>
  );
}

// Fallback image for mandalas without previews
const FALLBACK_IMAGE = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGNpcmNsZSBjeD0iNTAiIGN5PSI1MCIgcj0iNDUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1vcGFjaXR5PSIwLjIiIHN0cm9rZS13aWR0aD0iMiIvPgogIDxwYXRoIGQ9Ik01MCAyNUw2Mi41IDQ1TDc1IDY1SDUwSDI1TDM3LjUgNDVMNTAgMjVaIiBzdHJva2U9IndoaXRlIiBzdHJva2Utb3BhY2l0eT0iMC4yIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==';

// Helper function to render mandala preview image
function MandalaPreviewImage({ mandala, className = '' }: { 
  mandala: any, 
  className?: string 
}) {
  const [imgSrc, setImgSrc] = useState<string | null>(mandala.preview_url || null);
  const [imgError, setImgError] = useState(false);
  
  const handleError = () => {
    setImgError(true);
  };
  
  if (!imgSrc || imgError) {
    return (
      <div className={`${className} flex items-center justify-center text-white/30`}>
        <img src={FALLBACK_IMAGE} alt="No preview" className="w-full h-full object-contain opacity-50" />
      </div>
    );
  }
  
  return (
    <img 
      src={imgSrc} 
      alt={mandala.name} 
      className={className}
      onError={handleError}
    />
  );
}

// Resolution selector component
function ResolutionSelector() {
  const [quality, setQuality] = useState<'sd' | 'hd' | 'uhd'>(getMandalaQuality());
  const [isOpen, setIsOpen] = useState(false);
  const { mandalaName } = useParams();
  const navigate = useNavigate();
  
  const handleQualityChange = (newQuality: 'sd' | 'hd' | 'uhd') => {
    if (newQuality === quality) return;
    
    // Store in localStorage
    localStorage.setItem('ananta-mandala-quality', newQuality);
    setQuality(newQuality);
    setIsOpen(false);
    
    // Clear texture cache and dispatch event
    window.dispatchEvent(new Event('mandala-quality-change'));
    
    // Force refresh current page to reload mandala with new quality
    if (mandalaName) {
      navigate(0);
    }
  };
  
  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-white px-3 py-1 font-doto"
        style={{ fontSize: '1rem' }}
      >
        {quality.toLowerCase()}
      </button>
      
      {isOpen && (
        <div className="absolute bottom-full mb-1 left-0 bg-black/70 backdrop-blur-md rounded p-1">
          <div className="flex flex-col gap-1">
            <button
              onClick={() => handleQualityChange('sd')}
              className={`px-3 py-1 rounded font-doto ${quality === 'sd' ? 'bg-white/20' : 'hover:bg-white/10'}`}
              style={{ fontSize: '1rem' }}
            >
              sd
            </button>
            <button
              onClick={() => handleQualityChange('hd')}
              className={`px-3 py-1 rounded font-doto ${quality === 'hd' ? 'bg-white/20' : 'hover:bg-white/10'}`}
              style={{ fontSize: '1rem' }}
            >
              hd
            </button>
            <button
              onClick={() => handleQualityChange('uhd')}
              className={`px-3 py-1 rounded font-doto ${quality === 'uhd' ? 'bg-white/20' : 'hover:bg-white/10'}`}
              style={{ fontSize: '1rem' }}
            >
              uhd
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

// Component for generating random patterns
function RandomPatternIcon({ size = 14, color = "white", onClick }: { 
  size?: number, 
  color?: string, 
  onClick?: () => void 
}) {
  const [pattern, setPattern] = useState<boolean[][]>([]);
  
  // Generate a new random pattern
  const generatePattern = () => {
    const gridSize = 9;
    const patternTypes = [
      'symmetrical', 
      'center', 
      'corners', 
      'cross', 
      'diamond', 
      'frame', 
      'windowpane',
      'plus',
      'alternating',
      'zigzag',
      'centralSymbol',
      'cornerAccents',
      'chessboard',
      'rotationalSymmetry',
      'binaryCode'
    ];
    
    const patternType = patternTypes[Math.floor(Math.random() * patternTypes.length)];
    const newPattern: boolean[][] = Array(gridSize)
      .fill(null)
      .map(() => Array(gridSize).fill(false));
    
    switch (patternType) {
      case 'symmetrical':
        // First generate a random quarter (top-left)
        const quarter: boolean[][] = Array(Math.ceil(gridSize/2))
          .fill(null)
          .map(() => Array(Math.ceil(gridSize/2)).fill(false));
        
        // Fill quarter with random values
        for (let y = 0; y < Math.ceil(gridSize/2); y++) {
          for (let x = 0; x < Math.ceil(gridSize/2); x++) {
            // More likely to be empty than filled (30% chance of being filled)
            quarter[y][x] = Math.random() < 0.3;
          }
        }
        
        // Now create full grid with symmetry
        for (let y = 0; y < gridSize; y++) {
          newPattern[y] = [];
          for (let x = 0; x < gridSize; x++) {
            // Map to quarter coordinates (with symmetry)
            const qx = x < Math.ceil(gridSize/2) ? x : gridSize - 1 - x;
            const qy = y < Math.ceil(gridSize/2) ? y : gridSize - 1 - y;
            
            // Copy from quarter
            newPattern[y][x] = quarter[qy][qx];
          }
        }
        break;
        
      case 'center':
        // Create a pattern with a focus on the center
        const centerX = Math.floor(gridSize/2);
        const centerY = Math.floor(gridSize/2);
        
        // Create center point
        newPattern[centerY][centerX] = true;
        
        // Randomly add some surrounding pixels with decreasing probability
        for (let y = 0; y < gridSize; y++) {
          for (let x = 0; x < gridSize; x++) {
            if (x === centerX && y === centerY) continue;
            
            const distance = Math.max(Math.abs(x - centerX), Math.abs(y - centerY));
            const probability = 0.8 / distance;
            
            if (Math.random() < probability) {
              newPattern[y][x] = true;
            }
          }
        }
        break;
        
      case 'corners':
        // Create a pattern with elements in the corners
        const cornerSize = 2;
        
        // Fill in corners
        for (let y = 0; y < cornerSize; y++) {
          for (let x = 0; x < cornerSize; x++) {
            // Top-left
            newPattern[y][x] = true;
            // Top-right
            newPattern[y][gridSize - 1 - x] = true;
            // Bottom-left
            newPattern[gridSize - 1 - y][x] = true;
            // Bottom-right
            newPattern[gridSize - 1 - y][gridSize - 1 - x] = true;
          }
        }
        
        // Add some random elements
        for (let y = cornerSize; y < gridSize - cornerSize; y++) {
          for (let x = cornerSize; x < gridSize - cornerSize; x++) {
            if (Math.random() < 0.2) {
              newPattern[y][x] = true;
            }
          }
        }
        break;
        
      case 'cross':
        // Create a cross pattern
        for (let i = 0; i < gridSize; i++) {
          // Horizontal line
          newPattern[Math.floor(gridSize/2)][i] = true;
          // Vertical line
          newPattern[i][Math.floor(gridSize/2)] = true;
        }
        
        // Add random elements
        for (let y = 0; y < gridSize; y++) {
          for (let x = 0; x < gridSize; x++) {
            // Skip the cross center
            if (y === Math.floor(gridSize/2) || x === Math.floor(gridSize/2)) continue;
            
            if (Math.random() < 0.15) {
              newPattern[y][x] = true;
            }
          }
        }
        break;
        
      case 'diamond':
        // Create a diamond pattern
        const diamondRadius = Math.floor(gridSize/2);
        
        for (let y = 0; y < gridSize; y++) {
          for (let x = 0; x < gridSize; x++) {
            const distanceX = Math.abs(x - diamondRadius);
            const distanceY = Math.abs(y - diamondRadius);
            
            if (distanceX + distanceY <= diamondRadius) {
              newPattern[y][x] = Math.random() < 0.7;
            } else {
              newPattern[y][x] = Math.random() < 0.1;
            }
          }
        }
        break;
        
      case 'frame':
        // Create a frame around the edge
        for (let y = 0; y < gridSize; y++) {
          for (let x = 0; x < gridSize; x++) {
            if (x === 0 || x === gridSize - 1 || y === 0 || y === gridSize - 1) {
              newPattern[y][x] = true;
            } else if (Math.random() < 0.15) {
              newPattern[y][x] = true;
            }
          }
        }
        break;
      
      case 'windowpane':
        // Create a windowpane pattern
        for (let y = 0; y < gridSize; y++) {
          for (let x = 0; x < gridSize; x++) {
            if (y % 2 === 0 || x % 2 === 0) {
              newPattern[y][x] = true;
            }
          }
        }
        break;
        
      case 'plus':
        // Create a plus signs pattern
        const middleIdx = Math.floor(gridSize/2);
        
        // Create 3x3 plus in center
        newPattern[middleIdx - 1][middleIdx] = true;
        newPattern[middleIdx][middleIdx - 1] = true;
        newPattern[middleIdx][middleIdx] = true;
        newPattern[middleIdx][middleIdx + 1] = true;
        newPattern[middleIdx + 1][middleIdx] = true;
        
        // Add corner plus signs
        const offset = 2;
        
        // Top-left plus
        newPattern[offset - 1][offset] = true;
        newPattern[offset][offset - 1] = true;
        newPattern[offset][offset] = true;
        newPattern[offset][offset + 1] = true;
        newPattern[offset + 1][offset] = true;
        
        // Top-right plus
        const tr = gridSize - 1 - offset;
        newPattern[offset - 1][tr] = true;
        newPattern[offset][tr - 1] = true;
        newPattern[offset][tr] = true;
        newPattern[offset][tr + 1] = true;
        newPattern[offset + 1][tr] = true;
        
        // Bottom-left plus
        const bl = gridSize - 1 - offset;
        newPattern[bl - 1][offset] = true;
        newPattern[bl][offset - 1] = true;
        newPattern[bl][offset] = true;
        newPattern[bl][offset + 1] = true;
        newPattern[bl + 1][offset] = true;
        
        // Bottom-right plus
        const br = gridSize - 1 - offset;
        newPattern[bl - 1][tr] = true;
        newPattern[bl][tr - 1] = true;
        newPattern[bl][tr] = true;
        newPattern[bl][tr + 1] = true;
        newPattern[bl + 1][tr] = true;
        break;
        
      case 'alternating':
        // Create alternating pattern
        for (let y = 0; y < gridSize; y++) {
          const rowStart = y % 2 === 0;
          for (let x = 0; x < gridSize; x++) {
            newPattern[y][x] = (x % 2 === 0) === rowStart;
          }
        }
        break;
        
      case 'zigzag':
        // Create zigzag pattern
        for (let y = 0; y < gridSize; y++) {
          for (let x = 0; x < gridSize; x++) {
            if ((x + y) % 3 === 0) {
              newPattern[y][x] = true;
            }
          }
        }
        break;
        
      case 'centralSymbol':
        // Create a central symbol with symmetry
        const cx = Math.floor(gridSize/2);
        const cy = Math.floor(gridSize/2);
        
        // Center dot
        newPattern[cy][cx] = true;
        
        // Surround with a pattern
        for (let i = 0; i < 4; i++) {
          // Different patterns based on direction
          switch (i) {
            case 0: // North
              newPattern[cy-1][cx] = true;
              if (Math.random() < 0.5) newPattern[cy-2][cx] = true;
              break;
            case 1: // East
              newPattern[cy][cx+1] = true;
              if (Math.random() < 0.5) newPattern[cy][cx+2] = true;
              break;
            case 2: // South
              newPattern[cy+1][cx] = true;
              if (Math.random() < 0.5) newPattern[cy+2][cx] = true;
              break;
            case 3: // West
              newPattern[cy][cx-1] = true;
              if (Math.random() < 0.5) newPattern[cy][cx-2] = true;
              break;
          }
        }
        
        // Maybe add diagonals
        if (Math.random() < 0.5) {
          newPattern[cy-1][cx-1] = true;
          newPattern[cy-1][cx+1] = true;
          newPattern[cy+1][cx-1] = true;
          newPattern[cy+1][cx+1] = true;
        }
        break;
        
      case 'cornerAccents':
        // Create corner accents with connecting lines
        
        // Top-left corner
        newPattern[0][0] = true;
        newPattern[0][1] = true;
        newPattern[1][0] = true;
        
        // Top-right corner
        newPattern[0][gridSize-1] = true;
        newPattern[0][gridSize-2] = true;
        newPattern[1][gridSize-1] = true;
        
        // Bottom-left corner
        newPattern[gridSize-1][0] = true;
        newPattern[gridSize-1][1] = true;
        newPattern[gridSize-2][0] = true;
        
        // Bottom-right corner
        newPattern[gridSize-1][gridSize-1] = true;
        newPattern[gridSize-1][gridSize-2] = true;
        newPattern[gridSize-2][gridSize-1] = true;
        
        // Add center element
        if (Math.random() < 0.7) {
          const c = Math.floor(gridSize/2);
          newPattern[c][c] = true;
          
          // Maybe connect center to corners
          if (Math.random() < 0.5) {
            // Draw lines from center to middle of each side
            for (let i = c; i < gridSize; i++) newPattern[c][i] = true; // Right
            for (let i = 0; i < c; i++) newPattern[c][i] = true; // Left
            for (let i = 0; i < c; i++) newPattern[i][c] = true; // Up
            for (let i = c; i < gridSize; i++) newPattern[i][c] = true; // Down
          }
        }
        break;
        
      case 'chessboard':
        // Create a chessboard pattern with inner squares
        for (let y = 0; y < gridSize; y++) {
          for (let x = 0; x < gridSize; x++) {
            if ((x + y) % 2 === 0) {
              // Create square patterns inside each cell
              if ((y % 4 < 2 && x % 4 < 2) || (y % 4 >= 2 && x % 4 >= 2)) {
                newPattern[y][x] = true;
              }
            }
          }
        }
        break;
        
      case 'rotationalSymmetry':
        // Create pattern with 4-way rotational symmetry
        
        // Generate random points in first quadrant
        const quadSize = Math.ceil(gridSize/2);
        const points = [];
        const pointCount = 3 + Math.floor(Math.random() * 4); // 3-6 points
        
        for (let i = 0; i < pointCount; i++) {
          const px = Math.floor(Math.random() * quadSize);
          const py = Math.floor(Math.random() * quadSize);
          points.push({x: px, y: py});
        }
        
        // Mirror points to all 4 quadrants with rotational symmetry
        for (const point of points) {
          // Original point in first quadrant
          const x1 = point.x;
          const y1 = point.y;
          
          // Mirror point in second quadrant
          const x2 = gridSize - 1 - y1;
          const y2 = x1;
          
          // Mirror point in third quadrant
          const x3 = gridSize - 1 - x1;
          const y3 = gridSize - 1 - y1;
          
          // Mirror point in fourth quadrant
          const x4 = y1;
          const y4 = gridSize - 1 - x1;
          
          // Set all points
          if (x1 >= 0 && x1 < gridSize && y1 >= 0 && y1 < gridSize) 
            newPattern[y1][x1] = true;
          if (x2 >= 0 && x2 < gridSize && y2 >= 0 && y2 < gridSize) 
            newPattern[y2][x2] = true;
          if (x3 >= 0 && x3 < gridSize && y3 >= 0 && y3 < gridSize) 
            newPattern[y3][x3] = true;
          if (x4 >= 0 && x4 < gridSize && y4 >= 0 && y4 < gridSize) 
            newPattern[y4][x4] = true;
        }
        break;
        
      case 'binaryCode':
        // Create a pattern resembling binary code
        for (let y = 0; y < gridSize; y++) {
          // Each row has different probabilities
          const rowProbability = 0.3 + (Math.random() * 0.4);
          
          for (let x = 0; x < gridSize; x++) {
            if (Math.random() < rowProbability) {
              newPattern[y][x] = true;
            }
          }
        }
        break;
        
      default:
        // Complete random pattern
        for (let y = 0; y < gridSize; y++) {
          for (let x = 0; x < gridSize; x++) {
            newPattern[y][x] = Math.random() < 0.4;
          }
        }
        break;
    }
    
    setPattern(newPattern);
  };
  
  // Generate initial pattern
  useEffect(() => {
    generatePattern();
  }, []);
  
  // Handle click event
  const handleClick = () => {
    generatePattern();
    if (onClick) onClick();
  };
  
  // Calculate cell size
  const cellSize = size / 9;
  
  return (
    <div 
      onClick={handleClick}
      className="flex items-center justify-center cursor-pointer transition-opacity duration-200 hover:opacity-100"
      style={{ width: size, height: size, opacity: 0.3 }}
    >
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <rect width={size} height={size} fill="transparent" />
        {pattern.map((row, y) => 
          row.map((cell, x) => 
            cell && (
              <rect 
                key={`${x}-${y}`}
                x={x * cellSize}
                y={y * cellSize}
                width={cellSize}
                height={cellSize}
                fill={color}
              />
            )
          )
        )}
      </svg>
    </div>
  );
}

// Component for preset pattern icon (5x5 grid)
function PresetPatternIcon({ size = 14, color = "white", onClick, isActive = false }: { 
  size?: number, 
  color?: string, 
  onClick?: () => void,
  isActive?: boolean
}) {
  // Fixed 5x5 grid pattern for preset
  const gridSize = 5;
  const checkerboardPattern: boolean[][] = Array(gridSize)
    .fill(null)
    .map((_, y) => Array(gridSize).fill(false).map((_, x) => (x + y) % 2 === 0));
  
  // Calculate cell size
  const cellSize = size / gridSize;
  
  return (
    <div 
      onClick={onClick}
      className="flex items-center justify-center cursor-pointer transition-all duration-200 hover:opacity-100"
      style={{ 
        width: size, 
        height: size, 
        opacity: isActive ? 1 : 0.3,
        position: 'relative'
      }}
    >
      <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
        <rect width={size} height={size} fill="transparent" />
        {checkerboardPattern.map((row, y) => 
          row.map((cell, x) => 
            cell && (
              <rect 
                key={`${x}-${y}`}
                x={x * cellSize}
                y={y * cellSize}
                width={cellSize}
                height={cellSize}
                fill={isActive ? "#6366f1" : color}
              />
            )
          )
        )}
      </svg>
      {isActive && (
        <div 
          className="absolute -bottom-1 -right-1 w-2 h-2 bg-indigo-500 rounded-full"
          style={{ backgroundColor: "#6366f1" }}
        />
      )}
    </div>
  );
}

export function MandalaPage() {
  const { mandalaName } = useParams<{ mandalaName: string }>();
  const [textures, setTextures] = useState<THREE.Texture[]>([]);
  const [loading, setLoading] = useState(true);
  const [opacity, setOpacity] = useState(0);
  const [changingMandala, setChangingMandala] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [allMandalas, setAllMandalas] = useState<any[]>([]);
  const [currentPresetId, setCurrentPresetId] = useState<string | undefined>(undefined);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const hasLoadedRef = useRef<boolean>(false);
  
  // Add state for saved preset
  const [hasPreset, setHasPreset] = useState(false);
  const [isPresetActive, setIsPresetActive] = useState(false);
  
  // Add state for camera dolly control
  const [targetCameraDistance, setTargetCameraDistance] = useState<number | undefined>(undefined);
  // Add ref for the camera dolly timeout
  const dollyClearTimeoutRef = useRef<number | undefined>(undefined);
  // Add ref to track current camera distance for presets
  const currentCameraDistanceRef = useRef<number>(12);
  
  // Add state for enter button 3D effect with inertia
  const [buttonRotation, setButtonRotation] = useState({ x: 0, y: 0 });
  const targetRotation = useRef({ x: 0, y: 0 });
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const animationFrameId = useRef<number | null>(null);
  
  // Get motion control state (for play/pause)
  const { isPlaying, setIsPlaying } = useMotionControl();
  
  // UI visibility state
  const [isUiVisible, setIsUiVisible] = useState(true);
  const [isControlsOpen, setIsControlsOpen] = useState(false); // State for control panel visibility
  // Add a toggle counter to force remounting of the control panel
  const [controlToggleCount, setControlToggleCount] = useState(0);
  
  // Tunnel mode state
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [tunnelOpacity, setTunnelOpacity] = useState(0);
  const layerSeparationRef = useRef<number>(MIN_LAYER_SEPARATION);
  const [tunnelMode, setTunnelMode] = useState<'fly' | 'glide' | 'walk' | 'swim' | null>(null);
  const [tunnelMandalas, setTunnelMandalas] = useState<MandalaInfo[]>([]);
  const [tunnelTextures, setTunnelTextures] = useState<THREE.Texture[]>([]);
  const controlsRef = useRef<any>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isResettingCamera, setIsResettingCamera] = useState(false);
  
  // Tunnel mandala overlay state
  const [isTunnelMandalaOverlayOpen, setIsTunnelMandalaOverlayOpen] = useState(false);
  
  // Get tunnel store values
  const {
    speed,
    rotationSpeed,
    layerSeparation,
    mandalaSeparation,
    debug,
    fogEnabled,
    fogNear,
    fogFar,
    postProcessing,
    backgroundColor,
    currentTunnel,
    textures: tunnelStoreTextures,
    setCurrentTunnel,
    setTextures: setTunnelStoreTextures,
    setSpeed,
    setRotationSpeed,
    setBackgroundColor,
  } = useTunnelStore();
  
  // Add reference to the mandala canvas
  const mandalaCanvasRef = useRef<HTMLCanvasElement | null>(null);
  
  // Add states for tunnel loading and progress
  const [tunnelLoading, setTunnelLoading] = useState(false);
  const [tunnelLoadingProgress, setTunnelLoadingProgress] = useState(0);
  const [randomQuote, setRandomQuote] = useState('');
  
  // Replace single activeEffect with a map of active effects
  const [activeEffects, setActiveEffects] = useState<Record<string, boolean>>({
    ripple: false,
    liquid: false,
    bloom: false
  });

  // Create a syncActiveEffects function at component level
  const syncActiveEffects = () => {
    const { deformation } = useTunnelStore.getState();
    
    // Create a dynamic map of all effects and their enabled status
    const effectStates = Object.fromEntries(
      Object.entries(deformation)
        .map(([key, value]) => [key, (value as any)?.enabled || false])
    );
    
    setActiveEffects(effectStates);
  };

  // Effect to sync activeEffects with deformation state on mount and when deformation changes
  useEffect(() => {
    // Sync on mount
    syncActiveEffects();
    
    // Subscribe to store changes
    const unsubscribe = useTunnelStore.subscribe((state) => {
      // Only update if deformation state changes
      syncActiveEffects();
    });
    
    // Clean up subscription
    return () => {
      unsubscribe();
    };
  }, []);

  // Toggle effect function - updated to isolate effects
  const toggleEffect = (effectName: keyof DeformationSettings, settings: Partial<Record<string, any>>) => {
    const { deformation, setDeformation } = useTunnelStore.getState();
    
    // Check if this effect is already active
    const isActive = deformation[effectName].enabled;
    
    // Create a deep copy of the current deformation state to avoid reference issues
    const newDeformation = JSON.parse(JSON.stringify(deformation));
    
    // Toggle this effect without affecting others
    // If it's being enabled, apply the provided settings, otherwise just toggle the enabled property
    if (!isActive) {
      // When enabling, apply all provided settings
      newDeformation[effectName] = {
        ...newDeformation[effectName],
        ...settings,
        enabled: true
      };
    } else {
      // When disabling, only change the enabled property
      newDeformation[effectName] = {
        ...newDeformation[effectName],
        enabled: false
      };
    }
    
    // Update the active effects UI state directly so we don't have to wait for the subscription
    setActiveEffects(prev => ({
      ...prev,
      [effectName]: !isActive
    }));
    
    // Update the store
    setDeformation(newDeformation);
    
    // Special handling for bloom effect - sync with post-processing
    if (effectName === 'bloom') {
      const { postProcessing, setPostProcessing } = useTunnelStore.getState();
      const newPostProcessing = { ...postProcessing };
      
      newPostProcessing.bloom = {
        ...newPostProcessing.bloom,
        enabled: !isActive,
        strength: !isActive ? 3.0 : 0.0,
        radius: 0.6,
        threshold: 0.2
      };
      
      setPostProcessing(newPostProcessing);
    }
  };
  
  // Add these state variables and refs near the other state declarations
  const camera = useRef(null);
  const orbitControls = useRef(null);
  const explodeValue = useRef(0);
  
  // Update the savePreset function to make it compatible with the PresetPanel
  const savePreset = async () => {
    if (!mandalaName) return null;
    
    // Get current state from the store
    const { deformation, postProcessing } = useTunnelStore.getState();
    
    // Log the camera distance being saved
    console.log('Saving camera distance in preset:', targetCameraDistance);
    
    // Also save to localStorage for backward compatibility
    try {
      localStorage.setItem(`ananta-preset-${mandalaName}`, JSON.stringify(deformation));
      setHasPreset(true);
      setIsPresetActive(true);
    } catch (error) {
      console.error('Error saving preset to localStorage:', error);
    }
    
    // Create a mandala config object with all current settings
    const config: MandalaConfig = {
      mandala_name: mandalaName,
      camera_settings: {
        distance: currentCameraDistanceRef.current, // Use our ref for current camera distance
        position: [0, 0, 0], // Default value
        target: [0, 0, 0],   // Default value
        rotation: [0, 0, 0], // Default value
        fov: 35              // Default value
      },
      effect_settings: {
        // Make sure to include the complete deformation object with all effects
        deformation: deformation,
        postProcessing: postProcessing,
        backgroundColor: backgroundColor,
        explode: explodeValue.current || 0,
        layerSeparation: layerSeparationRef.current
      },
      pattern_data: []
    };
    
    return config;
  };
  
  // Update the loadPreset function implementation
  const loadPreset = async (preset: MandalaConfig) => {
    try {
      // Fade to black first
      setOpacity(0);
      
      // Wait for the fade to complete before making any changes
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Extract and apply settings
      const { camera_settings, effect_settings } = preset;
      
      // Set preset ID
      setCurrentPresetId(preset.id!);
      
      // Update camera
      if (camera_settings && camera_settings.distance) {
        // Make sure we log the value to verify it's being read correctly
        console.log('Loading camera distance from preset:', camera_settings.distance);
        
        // Use setTargetCameraDistance to update the camera view
        setTargetCameraDistance(camera_settings.distance);
        
        // Directly trigger a camera update (useful for immediate feedback)
        window.dispatchEvent(new CustomEvent('force-camera-update', { 
          detail: { distance: camera_settings.distance } 
        }));
      }
      
      // Update effects
      if (effect_settings) {
        // Apply deformation settings from the preset
        if (effect_settings.deformation) {
          const { setDeformation } = useTunnelStore.getState();
          setDeformation(effect_settings.deformation);
        }
        
        // Apply post-processing settings
        if (effect_settings.postProcessing) {
          const { setPostProcessing } = useTunnelStore.getState();
          setPostProcessing(effect_settings.postProcessing);
        }
        
        // Apply background color
        if (effect_settings.backgroundColor) {
          const { setBackgroundColor } = useTunnelStore.getState();
          setBackgroundColor(effect_settings.backgroundColor);
        }
        
        // Apply layer separation
        if (effect_settings.layerSeparation !== undefined) {
          layerSeparationRef.current = effect_settings.layerSeparation;
        }
        
        // Sync the activeEffects state with the deformation settings
        syncActiveEffects();
      }
      
      // Fade back in
      setOpacity(1);
    } catch (error) {
      console.error("Error loading preset:", error);
      toast({
        title: "Error loading preset",
        description: "Failed to apply the preset settings",
        variant: "destructive"
      });
      
      // Ensure we fade back in even if there's an error
      setOpacity(1);
    }
  };
  
  // Toggle the dropdown and manage animation
  const toggleOverlay = (isOpen: boolean) => {
    if (isOpen) {
      // Pause the tunnel animation when opening the overlay
      if (isPlaying) {
        setIsPlaying(false);
      }
      
      setDropdownOpen(true);
      // Small delay to ensure DOM element exists before starting animation
      setTimeout(() => setIsOverlayVisible(true), 10);
    } else {
      setIsOverlayVisible(false);
      // Wait for animation to complete before removing from DOM
      setTimeout(() => {
        setDropdownOpen(false);
        // Resume tunnel animation when closing the overlay if it was playing before
        if (tunnelMode) {
          setIsPlaying(true);
        }
      }, 300);
    }
  };

  // Reset hasLoadedRef when mandalaName changes
  useEffect(() => {
    hasLoadedRef.current = false;
  }, [mandalaName]);
  
  // Load the specific mandala whenever mandalaName changes
  useEffect(() => {
    if (!mandalaName) {
      setError("No mandala name provided");
      setLoading(false);
      return;
    }
    
    loadMandala(mandalaName);
    
    // Also fetch all available mandalas for the dropdown
    getAllMandalas()
      .then(mandalas => {
        // Sort alphabetically by name
        const sortedMandalas = [...mandalas].sort((a, b) => 
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        );
        setAllMandalas(sortedMandalas);
      })
      .catch(err => console.error("Error loading all mandalas:", err));
      
    // Check for existing presets
    const existingPreset = localStorage.getItem(`ananta-preset-${mandalaName}`);
    setHasPreset(!!existingPreset);
    setIsPresetActive(false);
  }, [mandalaName]);
  
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        toggleOverlay(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  // Add escape key handler to close overlay
  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && dropdownOpen) {
        toggleOverlay(false);
      }
    };
    
    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [dropdownOpen]);
  
  // Fade in effect when textures are loaded
  useEffect(() => {
    if (textures.length > 0 && !loading) {
      // Begin fade-in animation
      setTimeout(() => {
        setOpacity(1);
        setChangingMandala(false);
      }, 100);
    }
  }, [textures, loading]);
  
  // Toggle UI visibility
  const toggleUiVisibility = () => {
    const newVisibility = !isUiVisible;
    setIsUiVisible(newVisibility);
    console.log(`UI visibility set to: ${newVisibility}`);
  };

  // Add keyboard shortcut for toggling UI visibility
  useEffect(() => {
    // Handler for keyboard shortcuts
    const handleKeyDown = (event: KeyboardEvent) => {
      // Toggle UI visibility with 'H' key (case insensitive)
      if (event.key === 'h' || event.key === 'H') {
        toggleUiVisibility();
      }
    };
    
    // Add event listener
    window.addEventListener('keydown', handleKeyDown);
    
    // Cleanup on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [toggleUiVisibility]); // Include toggleUiVisibility in dependencies
  
  async function loadMandala(name: string) {
    try {
      if (changingMandala) return;
      
      setChangingMandala(true);
      // Start fade out if we already have textures
      if (textures.length > 0) {
        setOpacity(0);
        // Wait for fade out to complete
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      
      setLoading(true);
      setError(null);
      
      // Verify mandala exists
      const mandalas = await getMandalasByNames([name]);
      
      if (!mandalas || mandalas.length === 0) {
        setError(`Mandala not found: ${name}`);
        setLoading(false);
        setChangingMandala(false);
        setOpacity(1); // Ensure we fade back in even if there's an error
        return;
      }
      
      console.log(`Loading mandala: ${name}`);
      
      // Load the textures for this mandala
      const mandalaTextures = await loadMandalaTextures(name);
      
      // Dispose of any existing textures to prevent memory leaks
      textures.forEach(texture => texture.dispose());
      
      setTextures(mandalaTextures);
      setLoading(false);
      
      // Wait a brief moment to ensure all rendering is complete
      await new Promise(resolve => setTimeout(resolve, 100));
      
      // Fade back in
      setOpacity(1);
      
      // Reset the changing flag after a delay to allow the fade-in to complete
      setTimeout(() => {
        setChangingMandala(false);
      }, 1000);
    } catch (error) {
      console.error('Error loading mandala:', error);
      setError(error instanceof Error ? error.message : 'Failed to load mandala');
      setLoading(false);
      setChangingMandala(false);
      setOpacity(1); // Ensure we fade back in even if there's an error
    }
  }
  
  // Clean up textures on unmount
  useEffect(() => {
    return () => {
      textures.forEach(texture => texture.dispose());
    };
  }, [textures]);

  // Function to navigate to a mandala when selected from dropdown
  const handleMandalaSelect = async (newMandalaName: string) => {
    // Only navigate if it's a different mandala
    if (newMandalaName !== mandalaName) {
      toggleOverlay(false);
      
      // Start fade out before navigation
      setOpacity(0);
      
      // Wait for fade out to complete before navigating
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Then navigate to the new mandala
      navigate(`/${newMandalaName}`);
    } else {
      toggleOverlay(false);
    }
  };

  // Load and prepare random mandalas for tunnel
  async function prepareRandomTunnel(mode: 'fly' | 'glide' | 'walk' | 'swim') {
    try {
      // Set loading state
      setTunnelLoading(true);
      setTunnelLoadingProgress(5); // Start at 5%
      
      // Get all mandalas
      const allMandalasData = await getAllMandalas();
      setTunnelLoadingProgress(15);
      
      if (!allMandalasData || allMandalasData.length === 0) {
        console.error('No mandalas found for tunnel');
        setTunnelLoading(false);
        return;
      }
      
      // Log sample mandala data for debugging
      console.log('Sample mandala data:', allMandalasData[0]);
      
      // Make sure we have the current mandala
      const currentMandala = allMandalasData.find(m => m.name === mandalaName);
      if (!currentMandala) {
        console.error('Current mandala not found in the list');
        setTunnelLoading(false);
        return;
      }
      
      setTunnelLoadingProgress(25);
      
      // Shuffle the remaining mandalas and pick 5
      const otherMandalas = allMandalasData.filter(m => m.name !== mandalaName);
      const shuffled = [...otherMandalas].sort(() => 0.5 - Math.random());
      const selectedRandomMandalas = shuffled.slice(0, 5);
      
      // Create mandala info objects starting with current mandala
      const mandalaInfos: MandalaInfo[] = [
        {
          name: currentMandala.name,
          layerCount: currentMandala.layer_count,
          preview_url: currentMandala.preview_url
        },
        ...selectedRandomMandalas.map(m => ({
          name: m.name,
          layerCount: m.layer_count,
          preview_url: m.preview_url
        }))
      ];
      
      setTunnelLoadingProgress(35);
      
      // Set tunnel mandalas in local state for the TunnelScene
      setTunnelMandalas(mandalaInfos);
      
      // Convert to proper Mandala type for the tunnel store
      // This is a workaround for the type system - Ananta uses MandalaInfo in some places and Mandala in others
      const tunnelMandalas = mandalaInfos.map(info => ({
        id: `mandala-${info.name}-${Date.now()}`,
        name: info.name, // Add name property to match what's expected in /create
        layerCount: info.layerCount, // Add layerCount property to match what's expected in /create
        preview_url: info.preview_url, // Pass the preview URL
        previewUrl: info.preview_url, // Also add as previewUrl for compatibility
        layers: [], // We don't need layers here as they're loaded from textures
        separation: 1.0
      }));
      
      setTunnelLoadingProgress(45);
      
      // Create a tunnel object
      const tunnelObject = {
        id: `tunnel-${Date.now()}`,
        name: `${mandalaName} ${mode}`,
        mandalas: tunnelMandalas,
        defaultSpeed: getSpeedForMode(mode),
        defaultRotation: getRotationForMode(mode)
      };
      
      // Set in store
      setCurrentTunnel(tunnelObject);
      
      setTunnelLoadingProgress(55);
      
      // For seamless transition - first use the already loaded textures for the current mandala
      // then load the remaining mandala textures
      const totalMandalaCount = selectedRandomMandalas.length;
      const progressPerMandala = 40 / totalMandalaCount; // 40% of progress bar reserved for texture loading
      
      const remainingTextures = [];
      for (let i = 0; i < selectedRandomMandalas.length; i++) {
        const textures = await loadMandalaTextures(selectedRandomMandalas[i].name);
        remainingTextures.push(...textures);
        // Update progress based on mandala loading progress
        setTunnelLoadingProgress(55 + ((i + 1) * progressPerMandala));
      }
      
      // Combine the existing textures with newly loaded ones
      const allTunnelTextures = [...textures, ...remainingTextures.flat()];
      
      // Set the textures
      setTunnelTextures(allTunnelTextures);
      setTunnelStoreTextures(allTunnelTextures);
      
      setTunnelLoadingProgress(95);
      
      // Set the speed and rotation based on the mode
      setSpeed(getSpeedForMode(mode));
      setRotationSpeed(getRotationForMode(mode)); // This will be 0 for all modes
      
      // Ensure tunnel is in play mode by default
      setIsPlaying(true);
      
      // Activate tunnel mode
      setTunnelMode(mode);
      
      setTunnelLoadingProgress(100);
      
      // Complete loading after a short delay to ensure smooth transition
      setTimeout(() => {
        setTunnelLoading(false);
      }, 300);
      
    } catch (error) {
      console.error('Error preparing tunnel:', error);
      setTunnelLoading(false);
    }
  }
  
  // Get speed based on movement mode
  function getSpeedForMode(mode: 'fly' | 'glide' | 'walk' | 'swim'): number {
    switch (mode) {
      case 'fly':
        return 0.4;
      case 'glide':
        return 0.2;
      case 'walk':
        return 0.1;
      case 'swim':
        return 0.15;
      default:
        return 0.2;
    }
  }
  
  // Get rotation speed based on movement mode
  function getRotationForMode(mode: 'fly' | 'glide' | 'walk' | 'swim'): number {
    // Return 0 rotation for all modes as requested
    return 0;
  }
  
  // Handle mode button click
  const handleModeClick = (mode: 'fly' | 'glide' | 'walk' | 'swim') => {
    if (tunnelMode === mode) {
      // Toggle off if already in this mode
      setTunnelMode(null);
    } else {
      // Start transition animation
      startTransitionToTunnel(mode);
    }
  };
  
  // Toggle controls visibility
  const toggleControls = () => {
    const newState = !isControlsOpen;
    setIsControlsOpen(newState);
    // Increment the toggle counter to force remounting
    setControlToggleCount(prev => prev + 1);
    console.log(`Controls toggled: ${newState ? 'open' : 'closed'}`);
  };
  
  // Capture and download screenshot with watermark
  const captureScreenshot = () => {
    // Determine which canvas to use based on current mode
    const canvas = tunnelMode ? canvasRef.current : mandalaCanvasRef.current;
    
    if (!canvas) {
      console.error("No canvas found for screenshot");
      toast({
        title: "Screenshot failed",
        description: "Could not capture screenshot",
        variant: "destructive"
      });
      return;
    }
    
    try {
      // Create a temporary canvas to add the watermark
      const tempCanvas = document.createElement('canvas');
      const tempCtx = tempCanvas.getContext('2d');
      if (!tempCtx) {
        throw new Error('Failed to get canvas context');
      }
      
      // Set the dimensions to match the original canvas
      tempCanvas.width = canvas.width;
      tempCanvas.height = canvas.height;
      
      // Draw the original canvas content to our temporary canvas
      tempCtx.drawImage(canvas, 0, 0);
      
      // Add watermark text
      tempCtx.font = "900 2rem 'Doto'";
      tempCtx.fillStyle = "rgba(255, 255, 255, 0.1)"; // 10% opacity white
      tempCtx.textAlign = "center";
      tempCtx.textBaseline = "bottom";
      
      // Position at bottom center with small padding
      tempCtx.fillText("ananta.quest", tempCanvas.width / 2, tempCanvas.height - 20);
      
      // Create a temporary link element
      const link = document.createElement('a');
      
      // Set download attributes
      const name = mandalaName || 'ananta-mandala';
      const mode = tunnelMode ? `-${tunnelMode}` : '';
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-').slice(0, 19);
      link.download = `${name}${mode}-${timestamp}.png`;
      
      // Convert the temporary canvas with watermark to data URL
      link.href = tempCanvas.toDataURL('image/png');
      
      // Append to body, click to download, then remove
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      toast({
        title: "Screenshot captured",
        description: "Your screenshot has been saved",
        variant: "default"
      });
      
      console.log('Screenshot captured and downloaded');
    } catch (error) {
      console.error('Error capturing screenshot:', error);
      toast({
        title: "Screenshot failed",
        description: "Error capturing screenshot",
        variant: "destructive"
      });
    }
  };
  
  // Start transition animation from mandala to tunnel
  const startTransitionToTunnel = async (mode: 'fly' | 'glide' | 'walk' | 'swim') => {
    // Don't start another transition if already transitioning
    if (isTransitioning) return;
    
    setIsTransitioning(true);
    
    // Start loading tunnel data in the background right away
    const tunnelLoadPromise = prepareRandomTunnel(mode);
    
    // Animation timing parameters
    const startTime = Date.now();
    const animationDuration = 2000; // 2 seconds for the complete animation
    const fadeOutDuration = 1000; // 1 second for the mandala fade out
    const fadeInDelay = 500; // Start tunnel fade in after 500ms
    const fadeInDuration = 1500; // 1.5 seconds for the tunnel fade in
    
    // Simple animation function without explosion effect
    const animateTransition = () => {
      const elapsed = Date.now() - startTime;
      
      // Fade out mandala
      const fadeOutProgress = Math.min(elapsed / fadeOutDuration, 1);
      const easedFadeOut = 1 - Math.pow(1 - fadeOutProgress, 2); // Ease-out
      setOpacity(Math.max(0, 1 - easedFadeOut));
      
      // Start fading in tunnel after delay with a longer fade duration
      if (elapsed > fadeInDelay && !tunnelLoading) {
        const tunnelFadeProgress = Math.min((elapsed - fadeInDelay) / fadeInDuration, 1);
        // Smooth ease-in
        const easedFadeIn = tunnelFadeProgress * tunnelFadeProgress;
        setTunnelOpacity(easedFadeIn);
      }
      
      // Continue animation if not complete or if tunnel is still loading
      if (elapsed < animationDuration || tunnelLoading) {
        requestAnimationFrame(animateTransition);
      } else {
        // Animation complete
        setIsTransitioning(false);
        setOpacity(0); // Ensure mandala is fully hidden
        setTunnelOpacity(1); // Ensure tunnel is fully visible
        
        // Make sure tunnel is in play mode
        setIsPlaying(true);
        
        // Keep UI visible but hide controls in tunnel mode by default
        setIsUiVisible(true);
        // Set controls to be initially hidden in tunnel mode
        setIsControlsOpen(false);
      }
    };
    
    // Start animation
    requestAnimationFrame(animateTransition);
    
    // Wait for the tunnel data to be loaded
    await tunnelLoadPromise;
  };
  
  // Exit tunnel mode
  const exitTunnelMode = () => {
    // Reset tunnel opacity
    setTunnelOpacity(0);
    
    // Restore mandala opacity
    setOpacity(1);
    
    // Clear tunnel mode
    setTunnelMode(null);
    
    // Reset play state
    setIsPlaying(false);
    
    // Close any open controls
    setIsControlsOpen(false);
    
    // Show UI controls when exiting tunnel mode
    setIsUiVisible(true);
  };

  // Reset camera handler 
  const handleResetCamera = () => {
    setIsResettingCamera(true);
    setTimeout(() => setIsResettingCamera(false), 100);
  };

  // Listen for changes in the tunnel store
  useEffect(() => {
    if (!tunnelMode) return;
    
    // Update local state when tunnel store changes
    if (currentTunnel && tunnelStoreTextures.length > 0) {
      // Update local mandalas state
      setTunnelMandalas(currentTunnel.mandalas.map(mandala => ({
        name: mandala.name || '',
        layerCount: mandala.layerCount || mandala.layers?.length || 0,
        preview_url: mandala.preview_url || ''
      })));

      // Update local textures state
      setTunnelTextures(tunnelStoreTextures);
      
      console.log('Updated tunnel from store:', currentTunnel.mandalas.length, 'mandalas,', tunnelStoreTextures.length, 'textures');
    }
  }, [currentTunnel, tunnelStoreTextures, tunnelMode]);

  // Add event listeners for screenshot keyboard shortcut and events
  useEffect(() => {
    // Listen for keyboard shortcut (P) for screenshots
    const handleKeyDown = (event: KeyboardEvent) => {
      // Take screenshot with 'P' key
      if (event.key === 'p' || event.key === 'P') {
        captureScreenshot();
      }
    };
    
    // Listen for both the screenshot event and keyboard shortcut
    window.addEventListener('take-screenshot', captureScreenshot);
    window.addEventListener('keydown', handleKeyDown);
    
    return () => {
      window.removeEventListener('take-screenshot', captureScreenshot);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [mandalaName, tunnelMode]);

  // State for hover effects
  const [hoveredEffects, setHoveredEffects] = useState({
    ripple: false,
    liquid: false,
    bloom: false
  });
  
  const handleEffectHover = (effect: string, isHovered: boolean) => {
    setHoveredEffects(prev => ({
      ...prev,
      [effect]: isHovered
    }));
  };

  // Add event handler for button rotation effect with reduced transformation
  const handleMouseMove = (e: React.MouseEvent) => {
    if (buttonRef.current) {
      const button = buttonRef.current;
      const rect = button.getBoundingClientRect();
      
      // Calculate center of the button
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      
      // Calculate distance from center (normalized from -1 to 1)
      const x = (e.clientX - centerX) / (rect.width / 2);
      const y = (e.clientY - centerY) / (rect.height / 2);
      
      // Apply significantly reduced rotation to target (now only 0.8 degrees max)
      targetRotation.current = {
        x: y * -0.8, // Reduced from -2 to -0.8 for minimal movement
        y: x * 0.8   // Reduced from 2 to 0.8 for minimal movement
      };
    }
  };
  
  // Handle animation with increased inertia
  useEffect(() => {
    // Animation function for smooth transitions
    const animateRotation = () => {
      // Linear interpolation with increased inertia (slower response)
      setButtonRotation(prev => ({
        x: prev.x + (targetRotation.current.x - prev.x) * 0.03, // Reduced from 0.1 to 0.03 for more inertia
        y: prev.y + (targetRotation.current.y - prev.y) * 0.03  // Reduced from 0.1 to 0.03 for more inertia
      }));
      
      // Continue animation
      animationFrameId.current = requestAnimationFrame(animateRotation);
    };
    
    // Start animation
    animationFrameId.current = requestAnimationFrame(animateRotation);
    
    // Cleanup function
    return () => {
      if (animationFrameId.current !== null) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, []);
  
  // Reset rotation when mouse leaves
  const handleMouseLeave = () => {
    targetRotation.current = { x: 0, y: 0 };
    setIsButtonHovered(false);
  };
  
  // Set hover state when mouse enters
  const handleMouseEnter = () => {
    setIsButtonHovered(true);
  };

  // Generate a random quote when starting to load the tunnel
  useEffect(() => {
    if (tunnelLoading) {
      setRandomQuote(getRandomQuote());
    }
  }, [tunnelLoading]);

  // Function to create a tunnel from the current mandala
  const createTunnelFromCurrentMandala = async () => {
    // Make sure we have a current mandala
    if (!mandalaName) {
      console.error('Cannot start tunnel - no mandala loaded');
      toast({
        title: 'Unable to start tunnel',
        description: 'No mandala is currently loaded',
        variant: 'destructive'
      });
      return;
    }
    
    // Set loading state
    setTunnelLoading(true);
    setTunnelLoadingProgress(5); // Start at 5%
    
    try {
      // Get all mandalas from the database
      const allMandalas = await getAllMandalas();
      setTunnelLoadingProgress(15);
      
      // Find the current mandala in the database
      const currentMandala = allMandalas.find(m => m.name === mandalaName);
      if (!currentMandala) {
        throw new Error(`Current mandala "${mandalaName}" not found in database`);
      }
      
      // Log mandala information
      console.group("🚀 Creating tunnel with the following mandala:");
      console.log(`🔹 Mandala: ${currentMandala.name}`);
      console.log(`- ${currentMandala.layer_count} layers`);
      if (currentMandala.layer_urls && currentMandala.layer_urls.length > 0) {
        console.log(`- Layer URLs (first 3 of ${currentMandala.layer_urls.length}):`);
        currentMandala.layer_urls.slice(0, 3).forEach((url, i) => {
          console.log(`  ${i + 1}. ${url}`);
        });
        if (currentMandala.layer_urls.length > 3) {
          console.log(`  ... and ${currentMandala.layer_urls.length - 3} more`);
        }
      }
      console.groupEnd();
      
      setTunnelLoadingProgress(25);
      
      // Navigate to tunnel page with current tunnel store settings
      navigate('/tunnel', { 
        state: { 
          mandalas: [currentMandala],
          initialConfig: {
            speed: speed,
            rotationSpeed: rotationSpeed
          }
        } 
      });
      
    } catch (error) {
      console.error('Error creating tunnel:', error);
      setTunnelLoading(false);
    }
  };

  if (loading && textures.length === 0) {
    return (
      <div className="fixed inset-0 w-full h-full bg-black flex items-center justify-center">
        <div className="text-white text-opacity-50 font-doto">loading...</div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="fixed inset-0 w-full h-full bg-black flex flex-col items-center justify-center gap-4">
        <div className="text-white text-opacity-80">Error: {error}</div>
        <Link to="/" className="px-6 py-3 backdrop-blur-md rounded-full text-white hover:opacity-80 transition-all duration-300">
          Return to Home
        </Link>
      </div>
    );
  }

  return (
    <div className="relative h-screen w-screen overflow-hidden" style={{ 
      backgroundColor: "#120315",
      height: "100vh", /* Use standard viewport height */
      maxHeight: "-webkit-fill-available", /* For iOS Safari */
      position: "fixed", /* Prevent scrolling */
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overscrollBehavior: "none", /* Prevent bounce/scroll chaining */
      touchAction: "none", /* Disable touch scrolling on mobile */
      overflow: "hidden" /* Explicit overflow control */
    }}>
      {/* Tunnel loading progress overlay */}
      {tunnelLoading && (
        <div className="fixed inset-0 z-50 bg-black/80 flex flex-col items-center justify-center gap-6 backdrop-blur-sm transition-all duration-300">
          <div className="text-white font-doto text-xl text-center max-w-md px-4">{randomQuote}</div>
          <div className="w-64 relative">
            <Progress value={tunnelLoadingProgress} className="h-2" />
          </div>
          <div className="text-white/70 text-sm font-doto">{Math.round(tunnelLoadingProgress)}%</div>
        </div>
      )}
      
      {/* Tunnel Mandala Overlay - for selecting and ordering mandalas in tunnel mode */}
      <TunnelMandalaOverlay 
        isOpen={isTunnelMandalaOverlayOpen}
        onClose={() => setIsTunnelMandalaOverlayOpen(false)}
        setLocalTunnelMandalas={setTunnelMandalas}
        setLocalTunnelTextures={setTunnelTextures}
      />
      
      {/* Single mandala viewer */}
      <div 
        className="w-full h-full" 
        style={{ 
          opacity: opacity, 
          transition: 'opacity 0.5s ease-in-out',
          display: tunnelMode && !isTransitioning ? 'none' : 'block'
        }}
      >
        <Canvas 
          camera={{ position: [0, 0, 12], fov: 35 }}
          gl={{ preserveDrawingBuffer: true }} // Needed for screenshots
          ref={mandalaCanvasRef}
        >
          <Suspense fallback={null}>
            <CameraController 
              targetCameraDistance={targetCameraDistance} 
              currentCameraDistanceRef={currentCameraDistanceRef} 
            />
            <HeartbeatTunnel 
              textures={textures} 
              explosionRef={layerSeparationRef}
            />
            <PostProcessingEffects />
          </Suspense>
        </Canvas>
      </div>
      
      {/* Tunnel Canvas - add preserveDrawingBuffer for screenshots */}
      <div 
        className="w-full h-full" 
        style={{ 
          opacity: tunnelOpacity, 
          transition: 'opacity 0.5s ease-in-out',
          display: tunnelMode || isTransitioning ? 'block' : 'none',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 5
        }}
      >
        {(tunnelMode || isTransitioning) && tunnelMandalas.length > 0 && tunnelTextures.length > 0 && (
          <TunnelScene
            canvasRef={canvasRef}
            controlsRef={controlsRef}
            isResettingCamera={isResettingCamera}
            debug={debug}
            mandalaConfig={tunnelMandalas}
            textures={tunnelTextures}
            mandalaSeparation={mandalaSeparation}
            layerSeparation={layerSeparation}
            speed={speed}
            rotationSpeed={rotationSpeed}
            fogEnabled={fogEnabled}
            fogNear={fogNear}
            fogFar={fogFar}
            backgroundColor={backgroundColor}
          />
        )}
      </div>
      
      {/* Controls section for tunnel mode */}
      {tunnelMode && !isTransitioning && isUiVisible && (
        <div className="fixed top-4 sm:top-10 right-4 sm:right-10 z-40 flex flex-col items-end">
          {/* Screenshot button */}
          <button
            className="transition-all duration-300 rounded-full p-2 flex items-center justify-center hover:opacity-100 mb-2"
            style={{ 
              opacity: 0.3, 
              transform: 'translateY(0)',
              backgroundColor: 'transparent'
            }}
            onClick={captureScreenshot}
            title="Take screenshot"
          >
            <img src="/Icons/Capture.svg" alt="Screenshot" width={24} height={24} className="filter invert" />
          </button>
          
          {/* Controls toggle button - Magic Wand */}
          <button
            className="transition-all duration-300 rounded-full p-2 flex items-center justify-center hover:opacity-100"
            style={{ 
              opacity: 0.3, 
              transform: 'translateY(0)',
              backgroundColor: 'transparent'
            }}
            onClick={toggleControls}
          >
            <img src="/Icons/Magic.svg" alt="Controls" width={24} height={24} />
          </button>
          
          {/* Small spacer */}
          <div className="h-2 sm:h-3"></div>
        </div>
      )}
      
      {/* Controls section - organized to have coin buttons and screenshot next to magic wand */}
      {!tunnelMode && isUiVisible && (
        <div className="fixed top-4 sm:top-10 right-4 sm:right-10 z-40 flex flex-row items-center gap-3">
          {/* Effect buttons */}
          <div className="flex flex-row items-center gap-2">
            {/* Ripple effect button */}
            <button 
              onClick={() => toggleEffect('ripple', {
                enabled: true,
                strength: 0.16,
                frequency: 4.60,
                speed: 0.10
              })}
              className="transition-all duration-300 rounded-full p-0.5 w-4 h-4 flex items-center justify-center"
              style={{ 
                opacity: activeEffects.ripple || hoveredEffects.ripple ? 1 : 
                  (Object.values(activeEffects).some(Boolean) ? 0.1 : 0.3)
              }}
              onMouseEnter={() => handleEffectHover('ripple', true)}
              onMouseLeave={() => handleEffectHover('ripple', false)}
              title="Ripple Effect"
            >
              <img 
                src={activeEffects.ripple || hoveredEffects.ripple ? '/Icons/coin-color.svg' : '/Icons/coin.svg'} 
                alt="Ripple Effect" 
                width={12}
                height={12}
                className="transition-all duration-300"
              />
            </button>
            
            {/* Liquid effect button */}
            <button 
              onClick={() => {
                // Check if we're toggling on or off
                const { deformation } = useTunnelStore.getState();
                const isTogglingOn = !deformation.liquid.enabled;

                // Toggle the effect
                toggleEffect('liquid', {
                  enabled: true,
                  viscosity: 1.40,
                  turbulence: 1.00,
                  speed: 0.05
                });
                
                // No longer automatically dolly when liquid effect is enabled
                // This makes dolly completely independent from effects
              }}
              className="transition-all duration-300 rounded-full p-0.5 w-4 h-4 flex items-center justify-center"
              style={{ 
                opacity: activeEffects.liquid || hoveredEffects.liquid ? 1 : 
                  (Object.values(activeEffects).some(Boolean) ? 0.1 : 0.3)
              }}
              onMouseEnter={() => handleEffectHover('liquid', true)}
              onMouseLeave={() => handleEffectHover('liquid', false)}
              title="Liquid Effect"
            >
              <img 
                src={activeEffects.liquid || hoveredEffects.liquid ? '/Icons/coin-color.svg' : '/Icons/coin.svg'} 
                alt="Liquid Effect" 
                width={12}
                height={12}
                className="transition-all duration-300"
              />
            </button>
            
            {/* Bloom effect button */}
            <button 
              onClick={() => toggleEffect('bloom', {
                enabled: true,
                intensity: 3.0,
                threshold: 0.2,
                radius: 0.6,
                smoothing: 0.5,
                kernelSize: 5,
                blend: 0.5,
                saturation: 1.0,
                highlight: 1.0
              })}
              className="transition-all duration-300 rounded-full p-0.5 w-4 h-4 flex items-center justify-center"
              style={{ 
                opacity: activeEffects.bloom || hoveredEffects.bloom ? 1 : 
                  (Object.values(activeEffects).some(Boolean) ? 0.1 : 0.3)
              }}
              onMouseEnter={() => handleEffectHover('bloom', true)}
              onMouseLeave={() => handleEffectHover('bloom', false)}
              title="Bloom Effect"
            >
              <img 
                src={activeEffects.bloom || hoveredEffects.bloom ? '/Icons/coin-color.svg' : '/Icons/coin.svg'} 
                alt="Bloom Effect" 
                width={12}
                height={12}
                className="transition-all duration-300"
              />
            </button>
          </div>
          
          {/* Screenshot button - Camera */}
          <button
            className="transition-all duration-300 rounded-full p-2 flex items-center justify-center hover:opacity-100"
            style={{ 
              opacity: 0.3, 
              transform: 'translateY(0)',
              backgroundColor: 'transparent'
            }}
            onClick={captureScreenshot}
            title="Take screenshot"
          >
            <img src="/Icons/Capture.svg" alt="Screenshot" width={24} height={24} className="filter invert" />
          </button>
          
          {/* Controls toggle button - Magic Wand */}
          <button
            className="transition-all duration-300 rounded-full p-2 flex items-center justify-center hover:opacity-100"
            style={{ 
              opacity: 0.3, 
              transform: 'translateY(0)',
              backgroundColor: 'transparent'
            }}
            onClick={toggleControls}
          >
            <img src="/Icons/Magic.svg" alt="Controls" width={24} height={24} />
          </button>
        </div>
      )}
      
      {/* Separate control panel with fixed positioning */}
      {isControlsOpen && !tunnelMode && isUiVisible && (
        <div className="fixed top-16 sm:top-24 right-4 sm:right-10 z-30 w-80">
          <MandalaDeformationCard 
            isVisible={true} 
            onClose={toggleControls} 
          />
        </div>
      )}
      
      {/* Tunnel control panel with fixed positioning */}
      {tunnelMode && !isTransitioning && isUiVisible && (
        <div 
          key={`control-panel-${controlToggleCount}`}
          className="fixed right-4 sm:right-10 top-16 sm:top-20 z-40"
          style={{ 
            width: '280px',
            maxWidth: 'calc(100vw - 32px)',
            transition: 'all 0.5s ease-in-out',
            transform: isControlsOpen 
              ? 'translateX(0)' 
              : 'translateX(100%)',
            opacity: isControlsOpen ? 1 : 0,
            pointerEvents: isControlsOpen ? 'auto' : 'none',
          }}
        >
          <ControlPanelWrapper 
            onResetCamera={handleResetCamera}
            setIsTunnelMandalaOverlayOpen={setIsTunnelMandalaOverlayOpen}
          />
        </div>
      )}
      
      {/* Vignette overlay */}
      <div 
        className="pointer-events-none fixed inset-0 z-10"
        style={{
          background: 'radial-gradient(circle, transparent 40%, rgba(0,0,0,0.8) 100%)',
          mixBlendMode: 'multiply',
        }}
      />
      
      {/* Ananta logo at top left with mandala button - hide in tunnel mode */}
      <div 
        className={`absolute top-4 sm:top-10 left-4 sm:left-10 z-20 transition-all duration-500 ease-in-out transform ${
          isUiVisible && !tunnelMode
            ? 'opacity-100 translate-y-0' 
            : 'opacity-0 -translate-y-10 pointer-events-none'
        }`}
      >
        <div className="flex flex-row items-center gap-5">
          {/* Random mandala button - moved before mandala name */}
          {!tunnelMode && (
            <button 
              onClick={async () => {
                try {
                  const mandalas = await getAllMandalas();
                  if (mandalas.length > 0) {
                    // Filter out current mandala
                    const otherMandalas = mandalas.filter(m => m.name !== mandalaName);
                    // Pick a random mandala
                    const randomMandala = otherMandalas[Math.floor(Math.random() * otherMandalas.length)];
                    // Navigate to the random mandala
                    handleMandalaSelect(randomMandala.name);
                  }
                } catch (error) {
                  console.error('Error loading mandalas:', error);
                }
              }}
              className="transition-all duration-300 bg-transparent border-0 p-0 hover:opacity-100 flex items-center justify-center"
              style={{ 
                opacity: 1,
                width: 14,
                height: 14,
                minWidth: 14,
                minHeight: 14
              }}
            >
              <RandomPatternIcon size={14} color="white" />
            </button>
          )}
          
          {/* Mandala name */}
          {mandalaName && (
            <div 
              className="cursor-pointer flex items-center" 
              onClick={() => toggleOverlay(true)}
              title="Click to select a different mandala"
            >
              <span 
                className="font-doto hover:font-doto-hover" 
                style={{ 
                  fontSize: 'clamp(1rem, 4vw, 1.2rem)',
                  color: 'white'
                }}
              >
                {mandalaName.toLowerCase()}
              </span>
            </div>
          )}
        </div>
      </div>
      
      {/* Back button in top left - only in tunnel mode */}
      <div 
        className={`absolute top-4 sm:top-10 left-4 sm:left-10 z-20 transition-all duration-500 ease-in-out transform ${
          isUiVisible && tunnelMode
            ? 'opacity-100 translate-y-0' 
            : 'opacity-0 -translate-y-10 pointer-events-none'
        }`}
      >
        <button 
          onClick={exitTunnelMode}
          className="transition-all duration-300 hover:opacity-80"
          style={{ opacity: 0.3 }}
        >
          <ArrowLeft className="w-6 h-6 text-white" />
        </button>
      </div>
      
      {/* Mandala overlay with centered grid - positioned at the end for proper stacking */}
      {dropdownOpen && allMandalas.length > 0 && (
        <div 
          className="fixed inset-0 bg-black/80 backdrop-blur-md z-[1000] transition-all duration-300 ease-in-out"
          style={{ 
            opacity: isOverlayVisible ? 1 : 0,
            transform: isOverlayVisible ? 'scale(1)' : 'scale(0.95)',
            pointerEvents: isOverlayVisible ? 'auto' : 'none'
          }}
        >
          {/* Close button */}
          <button 
            className="absolute top-10 right-10 text-white font-doto-hover hover:text-white/70 transition-colors z-[1010] bg-transparent border-0 cursor-pointer"
            style={{ fontSize: '1.2rem' }}
            onClick={() => toggleOverlay(false)}
          >
            X
          </button>
          
          <div className="absolute inset-0 flex items-center justify-center transition-all duration-300 ease-in-out"
            style={{ 
              opacity: isOverlayVisible ? 1 : 0,
              transform: isOverlayVisible ? 'translateY(0)' : 'translateY(8px)'
            }}>
            <div className="container max-w-6xl mx-auto">
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-6">
                {allMandalas.map((mandala) => (
                  <div 
                    key={mandala.name}
                    className={`cursor-pointer transition-all duration-200 flex flex-col items-center ${
                      mandala.name === mandalaName ? 'opacity-100' : 'opacity-75 hover:opacity-100'
                    }`}
                    onClick={() => handleMandalaSelect(mandala.name)}
                  >
                    <div className="w-16 h-16 rounded-full overflow-hidden bg-black/30 mb-2">
                      <MandalaPreviewImage
                        mandala={mandala}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <span className="text-white font-doto hover:font-doto-hover text-center" style={{ fontSize: '1.2rem' }}>
                      {mandala.name.toLowerCase()}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* Centered "enter" button */}
      <div 
        className={`fixed inset-0 z-20 flex items-center justify-center transition-all duration-500 ease-in-out transform pointer-events-none ${
          isUiVisible && !tunnelMode
            ? 'opacity-100 translate-y-0' 
            : 'opacity-0 translate-y-10 pointer-events-none'
        }`}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
      >
        {!tunnelMode && (
          <button 
            ref={buttonRef}
            onClick={() => handleModeClick('fly')}
            className="transition-all duration-300 rounded-full flex items-center justify-center pointer-events-auto hover:scale-110"
            style={{ 
              width: '3.5rem',
              height: '3.5rem',
              color: 'white',
              backgroundColor: isButtonHovered ? 'rgba(0, 0, 0, 0.18)' : 'rgba(0, 0, 0, 0.04)',
              backdropFilter: isButtonHovered ? 'blur(8px)' : 'blur(2px)',
              transform: `perspective(1000px) rotateX(${buttonRotation.x}deg) rotateY(${buttonRotation.y}deg)`,
              transition: 'all 0.3s ease-out'
            }}
          >
            <Play className="w-5 h-5" fill="currentColor" />
          </button>
        )}
      </div>
      
      {/* Eye toggle button */}
      <button 
        onClick={toggleUiVisibility}
        className="fixed bottom-4 sm:bottom-10 right-4 sm:right-10 z-50 transition-all duration-300 bg-transparent border-0 p-0 hover:opacity-100"
        style={{ 
          opacity: 0.3,
          filter: 'none'
        }}
      >
        <img 
          src={isUiVisible ? '/Icons/Eye-open.svg' : '/Icons/Eye-close.svg'} 
          alt={isUiVisible ? 'Hide UI' : 'Show UI'} 
          className="w-8 h-8" 
        />
      </button>
      
      {/* Bottom left controls - Resolution selector and presets */}
      <div className="absolute bottom-10 left-10 z-50 flex items-center space-x-4">
        {isUiVisible && !tunnelMode && (
          <>
            <ResolutionSelector />
            
            {/* Vertical line separator */}
            <div className="h-5 w-px bg-white/20"></div>
            
            {/* Presets panel */}
            <div style={{ pointerEvents: 'auto' }}>
              <PresetPanel 
                mandalaName={mandalaName || ''}
                onSavePreset={async () => {
                  const result = await savePreset();
                  if (!result) throw new Error("Failed to save preset");
                  return result;
                }}
                onLoadPreset={loadPreset}
                currentPresetId={currentPresetId}
                onSelectMandala={handleMandalaSelect}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
} 