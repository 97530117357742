import { supabase } from '../services/supabase';

export interface Mandala {
  id: string;
  name: string;
  layer_count: number;
  image_size: number;
  preview_url: string;
  layer_urls: string[];
  tags: string[];
  created_at: string;
  updated_at: string;
  sd_layer_urls?: string[];   // SD resolution layer URLs
  hd_layer_urls?: string[];   // HD resolution layer URLs
  uhd_layer_urls?: string[];  // UHD resolution layer URLs
}

// Cache for mandala data to reduce API calls
let mandalasCache: Mandala[] | null = null;
let lastFetchTime: number = 0;
const CACHE_TTL = 5 * 60 * 1000; // 5 minutes

// Mandala quality settings
const MANDALA_QUALITY_KEY = 'ananta-mandala-quality';

/**
 * Get the current mandala quality setting
 */
export function getMandalaQuality(): 'sd' | 'hd' | 'uhd' {
  const quality = localStorage.getItem(MANDALA_QUALITY_KEY);
  if (quality === 'uhd') return 'uhd';
  if (quality === 'sd') return 'sd';
  return 'hd'; // Default to HD if not set
}

/**
 * Fetch all mandalas from the database
 */
export async function getAllMandalas(forceRefresh = false): Promise<Mandala[]> {
  // Check if we have a valid cache
  const now = Date.now();
  if (!forceRefresh && mandalasCache && now - lastFetchTime < CACHE_TTL) {
    return mandalasCache;
  }
  
  // Fetch from Supabase
  const { data, error } = await supabase
    .from('mandalas')
    .select('*')
    .order('name', { ascending: true });
    
  if (error) {
    console.error('Error fetching mandalas:', error);
    throw error;
  }
  
  // Update cache
  mandalasCache = data as Mandala[];
  lastFetchTime = now;
  
  return mandalasCache;
}

/**
 * Get a mandala by ID
 */
export async function getMandalaById(id: string): Promise<Mandala | null> {
  // Try from cache first
  if (mandalasCache) {
    const cached = mandalasCache.find(m => m.id === id);
    if (cached) return cached;
  }
  
  // Fetch from Supabase
  const { data, error } = await supabase
    .from('mandalas')
    .select('*')
    .eq('id', id)
    .single();
    
  if (error) {
    if (error.code === 'PGRST116') { // No rows returned
      return null;
    }
    console.error(`Error fetching mandala with ID ${id}:`, error);
    throw error;
  }
  
  return data as Mandala;
}

/**
 * Get mandalas by name
 */
export async function getMandalasByNames(names: string[]): Promise<Mandala[]> {
  if (!names || names.length === 0) return [];
  
  // Try from cache first
  if (mandalasCache) {
    const cached = mandalasCache.filter(m => names.includes(m.name));
    if (cached.length === names.length) {
      return applyQualityToMandalas(cached);
    }
  }
  
  // Fetch from Supabase
  const { data, error } = await supabase
    .from('mandalas')
    .select('*')
    .in('name', names);
    
  if (error) {
    console.error(`Error fetching mandalas by names:`, error);
    throw error;
  }
  
  return applyQualityToMandalas(data as Mandala[]);
}

/**
 * Get mandalas with specific tags
 */
export async function getMandalasByTags(tags: string[]): Promise<Mandala[]> {
  if (!tags || tags.length === 0) return applyQualityToMandalas(await getAllMandalas());
  
  // Convert tags to lowercase for case-insensitive matching
  const normalizedTags = tags.map(tag => tag.toLowerCase());
  
  // Try from cache first
  if (mandalasCache) {
    return applyQualityToMandalas(
      mandalasCache.filter(mandala => 
        mandala.tags && normalizedTags.some(tag => mandala.tags.includes(tag))
      )
    );
  }
  
  // For Supabase, we'd need to use the containment operator for arrays
  // This is a simplified approach - for a more advanced implementation you might
  // want to consider using PostgreSQL's array operators with Supabase
  const allMandalas = await getAllMandalas();
  
  return applyQualityToMandalas(
    allMandalas.filter(mandala => 
      mandala.tags && normalizedTags.some(tag => mandala.tags.includes(tag))
    )
  );
}

/**
 * Apply the current quality setting to mandala layer URLs
 */
function applyQualityToMandalas(mandalas: Mandala[]): Mandala[] {
  const quality = getMandalaQuality();
  
  return mandalas.map(mandala => {
    const mandalaClone = { ...mandala };
    
    // If UHD is selected and UHD layers are available, use those
    if (quality === 'uhd' && mandala.uhd_layer_urls && mandala.uhd_layer_urls.length > 0) {
      mandalaClone.layer_urls = mandala.uhd_layer_urls;
    }
    // If HD is selected (or UHD not available) and HD layers are available, use those
    else if (quality === 'hd' && mandala.hd_layer_urls && mandala.hd_layer_urls.length > 0) {
      mandalaClone.layer_urls = mandala.hd_layer_urls;
    }
    // If SD is selected and SD layers are available, use those
    else if (quality === 'sd' && mandala.sd_layer_urls && mandala.sd_layer_urls.length > 0) {
      mandalaClone.layer_urls = mandala.sd_layer_urls;
    }
    // Otherwise keep the default layer_urls
    
    return mandalaClone;
  });
}

/**
 * Clear the mandalas cache
 */
export function clearCache(): void {
  mandalasCache = null;
  lastFetchTime = 0;
} 