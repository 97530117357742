import React, { useEffect, useState } from 'react';
import { getAllMandalas, Mandala } from '../services/mandalaService';
import { Card, CardContent, CardFooter } from './ui/card';
import { Loader } from './ui/loader';
import { Badge } from './ui/badge';
import { AlertCircle, ImageIcon, Layers } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from './ui/alert';

interface MandalaGridProps {
  onSelect?: (mandala: Mandala) => void;
  selectable?: boolean;
  selectedIds?: string[];
}

export function MandalaGrid({ onSelect, selectable = false, selectedIds = [] }: MandalaGridProps) {
  const [mandalas, setMandalas] = useState<Mandala[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadMandalas = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await getAllMandalas();
        setMandalas(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load mandalas');
        console.error('Error loading mandalas:', err);
      } finally {
        setLoading(false);
      }
    };

    loadMandalas();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="h-8 w-8 text-muted-foreground/50" text="loading..." />
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>Error loading mandalas</AlertTitle>
        <AlertDescription>
          <p>{error}</p>
          <p className="text-sm mt-2">Please try refreshing the page or contact support.</p>
        </AlertDescription>
      </Alert>
    );
  }

  if (mandalas.length === 0) {
    return (
      <Alert variant="default" className="bg-muted/50 text-foreground border-muted">
        <ImageIcon className="h-4 w-4" />
        <AlertTitle>No mandalas found</AlertTitle>
        <AlertDescription>
          Use the scanner above to import mandalas from Supabase storage.
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
      {mandalas.map((mandala) => (
        <Card
          key={mandala.id}
          className={`
            overflow-hidden transition-all duration-200 relative bg-card border-border
            ${selectable ? 'cursor-pointer hover:ring-2 hover:ring-primary/50' : ''}
            ${selectedIds.includes(mandala.id) ? 'ring-2 ring-primary' : ''}
          `}
          onClick={() => selectable && onSelect && onSelect(mandala)}
        >
          <CardContent className="p-0">
            <div className="aspect-square relative bg-muted/30">
              {mandala.preview_url ? (
                <img
                  src={mandala.preview_url}
                  alt={mandala.name}
                  className="w-full h-full object-contain"
                  loading="lazy"
                  onError={(e) => {
                    // Fallback if image fails to load
                    e.currentTarget.src = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgZmlsbD0iIzEwMTAxMCIvPjx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBmb250LXNpemU9IjE0IiB0ZXh0LWFuY2hvcj0ibWlkZGxlIiBmaWxsPSIjNjY2NjY2IiBkeT0iLjNlbSI+Tm8gaW1hZ2U8L3RleHQ+PC9zdmc+';
                  }}
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center bg-muted/50">
                  <ImageIcon className="h-6 w-6 text-muted-foreground/40" />
                </div>
              )}
            </div>
          </CardContent>
          
          <CardFooter className="p-2 bg-card/95 border-t border-border flex flex-col items-start gap-1">
            <div className="text-sm font-doto hover:font-doto-hover truncate w-full">{mandala.name}</div>
            <div className="flex items-center gap-2">
              <Badge 
                variant="secondary" 
                className="text-xs px-1.5 h-5 font-normal"
              >
                <Layers className="h-3 w-3 mr-1" />
                {mandala.layer_count}
              </Badge>
              <Badge 
                variant="outline" 
                className="text-xs px-1.5 h-5 font-normal text-muted-foreground border-muted-foreground/30"
              >
                {mandala.image_size}px
              </Badge>
            </div>
          </CardFooter>
        </Card>
      ))}
    </div>
  );
} 