import { Radio } from 'lucide-react';

interface RadioPlayerProps {
  streamUrl?: string; // Made optional since we'll use a fixed URL
}

export function RadioPlayer({ streamUrl }: RadioPlayerProps) {
  // Direct link to the web-based player
  const radioUrl = "http://217.154.9.72/public/eoc_radio";
  
  const openRadioPlayer = () => {
    window.open(radioUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="fixed bottom-10 left-10 z-50">
      <button 
        onClick={openRadioPlayer}
        className="w-10 h-10 flex items-center justify-center bg-black/80 backdrop-blur-md text-white rounded-full hover:bg-black/90 transition-colors shadow-lg"
        aria-label="Open Radio Player"
        title="Listen to EOC Radio"
      >
        <Radio size={18} />
      </button>
    </div>
  );
} 