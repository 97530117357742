export const quotes = [
  "Save all your energies and time for breaking the wall your mind has built around you.",
  "I cannot but see you as myself. It is in the very nature of love to see no difference.",
  "Know yourself to be the changeless witness of the changeful mind.",
  "Shift your attention from words to silence and you will hear.",
  "Instead of searching for what you do not have, find out what it is that you have never lost.",
  "It is not what you do, but what you stop doing that matters.",
  "Having never left the house you are looking for the way home",
  "You will receive everything you need when you stop asking for what you do not need",
  "Past and future are in the mind only - I am now."
];

/**
 * Returns a random quote from the list
 */
export function getRandomQuote(): string {
  const randomIndex = Math.floor(Math.random() * quotes.length);
  return quotes[randomIndex];
} 