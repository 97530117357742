import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Texture } from 'three';
import { PostProcessingSettings, Tunnel } from '../types/scene';
import { DeformationSettings, defaultDeformationSettings } from '../components/ControlPanel/DeformationControls';

// Default values for tunnel configuration
export const DEFAULT_SPEED = 0.2;
export const DEFAULT_ROTATION_SPEED = 0.25;
export const DEFAULT_LAYER_SEPARATION = 2;
export const DEFAULT_MANDALA_SEPARATION = 5;
export const DEFAULT_BACKGROUND_COLOR = '#120315';

interface TunnelState {
  // Current tunnel configuration
  currentTunnel: Tunnel | null;
  textures: Texture[];
  isLoading: boolean;
  loadingProgress: number;
  error: string | null;
  
  // Runtime state
  speed: number;
  rotationSpeed: number;
  layerSeparation: number;
  mandalaSeparation: number;
  postProcessing: PostProcessingSettings;
  deformation: DeformationSettings;
  quality: 'low' | 'medium' | 'high';
  debug: boolean;
  fogEnabled: boolean;
  fogNear: number;
  fogFar: number;
  backgroundColor: string;
  
  // Actions
  setCurrentTunnel: (tunnel: Tunnel) => void;
  setTextures: (textures: Texture[]) => void;
  setLoading: (isLoading: boolean) => void;
  setError: (error: string | null) => void;
  setSpeed: (newSpeed: number) => void;
  setRotationSpeed: (newRotationSpeed: number) => void;
  setLayerSeparation: (newSeparation: number) => void;
  setMandalaSeparation: (newSeparation: number) => void;
  setPostProcessing: (settings: PostProcessingSettings) => void;
  setDeformation: (settings: DeformationSettings) => void;
  setQuality: (quality: 'low' | 'medium' | 'high') => void;
  setDebug: (enabled: boolean) => void;
  setFogEnabled: (enabled: boolean) => void;
  setFogNear: (near: number) => void;
  setFogFar: (far: number) => void;
  setBackgroundColor: (color: string) => void;
  reset: () => void;
}

const defaultPostProcessing: PostProcessingSettings = {
  bloom: {
    enabled: false,
    strength: 3.0,
    radius: 0.4,
    threshold: 0.4,
  },
  colorCorrection: {
    enabled: false,
    brightness: 1,
    contrast: 1,
    saturation: 1,
  },
  noise: {
    enabled: false,
    opacity: 0.2,
    premultiply: false,
  },
  vignette: {
    enabled: false,
    darkness: 0.5,
    offset: 0.5,
  },
  depthOfField: {
    enabled: false,
    focusDistance: 0,
    focalLength: 0.02,
    bokehScale: 2,
  },
  chromaticAberration: {
    enabled: false,
    offset: 0.005,
  },
  sepia: {
    enabled: false,
    intensity: 0.6,
  },
  pixelation: {
    enabled: false,
    granularity: 5,
  },
  toneMapping: {
    enabled: false,
    exposure: 1.0,
  },
};

export const useTunnelStore = create<TunnelState>()(
  devtools(
    (set) => ({
      // Initial state
      currentTunnel: null,
      textures: [],
      isLoading: false,
      loadingProgress: 0,
      error: null,
      
      speed: DEFAULT_SPEED,
      rotationSpeed: DEFAULT_ROTATION_SPEED,
      layerSeparation: DEFAULT_LAYER_SEPARATION,
      mandalaSeparation: DEFAULT_MANDALA_SEPARATION,
      postProcessing: defaultPostProcessing,
      deformation: defaultDeformationSettings,
      quality: 'high',
      debug: false,
      fogEnabled: false,
      fogNear: 10,
      fogFar: 50,
      backgroundColor: DEFAULT_BACKGROUND_COLOR,
      
      // Actions
      setCurrentTunnel: (tunnel) => set({ currentTunnel: tunnel }),
      setTextures: (textures) => set({ textures }),
      setLoading: (isLoading) => set({ isLoading }),
      setError: (error) => set({ error }),
      setSpeed: (newSpeed) => set({ speed: newSpeed }),
      setRotationSpeed: (newRotationSpeed) => set({ rotationSpeed: newRotationSpeed }),
      setLayerSeparation: (newSeparation) => set({ layerSeparation: newSeparation }),
      setMandalaSeparation: (newSeparation) => set({ mandalaSeparation: newSeparation }),
      setPostProcessing: (settings) => set({ postProcessing: settings }),
      setDeformation: (settings) => set({ deformation: settings }),
      setQuality: (quality) => set({ quality }),
      setDebug: (enabled) => set({ debug: enabled }),
      setFogEnabled: (enabled) => set({ fogEnabled: enabled }),
      setFogNear: (near) => set({ fogNear: near }),
      setFogFar: (far) => set({ fogFar: far }),
      setBackgroundColor: (color) => set({ backgroundColor: color }),
      
      reset: () => set({
        speed: DEFAULT_SPEED,
        rotationSpeed: DEFAULT_ROTATION_SPEED,
        layerSeparation: DEFAULT_LAYER_SEPARATION,
        mandalaSeparation: DEFAULT_MANDALA_SEPARATION,
        postProcessing: defaultPostProcessing,
        deformation: defaultDeformationSettings,
        fogEnabled: false,
        fogNear: 10,
        fogFar: 50,
        backgroundColor: DEFAULT_BACKGROUND_COLOR
      })
    }),
    { name: 'TunnelStore' }
  )
);

// Expose the tunnel store to the global window object for access from event handlers
if (typeof window !== 'undefined') {
  // @ts-ignore - adding property to window
  window.__TUNNEL_STORE__ = useTunnelStore;
} 