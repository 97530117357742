import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import { Loader } from '../ui/loader';
import { ControlPanelWrapper } from '../ControlPanelWrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTunnelStore } from '../../store/tunnelStore';
import { loadMandalaTextures } from '../../utils/textureLoader';
import { MandalaInfo } from '../../types/tunnel';
import { TunnelScene } from './TunnelScene';
import { getRandomQuote } from '../../utils/quotes';
import { Mandala } from '../../services/mandalaService';

/**
 * Main TunnelViewer component that manages the tunnel experience
 */
export function TunnelViewer() {
  const [isResettingCamera, setIsResettingCamera] = useState(false);
  const [mandalaConfig, setMandalaConfig] = useState<MandalaInfo[]>([]);
  const [randomQuote, setRandomQuote] = useState('');
  const controlsRef = useRef<any>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const previousTunnelIdRef = useRef<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  
  const {
    textures,
    isLoading,
    error,
    speed,
    rotationSpeed,
    layerSeparation,
    mandalaSeparation,
    debug,
    fogEnabled,
    fogNear,
    fogFar,
    postProcessing,
    currentTunnel,
    setCurrentTunnel,
    setLoading,
    setError,
    setTextures,
    setSpeed,
    setRotationSpeed,
    backgroundColor,
  } = useTunnelStore();

  // Set initial speeds from navigation state
  useEffect(() => {
    const initialConfig = location.state?.initialConfig;
    if (initialConfig) {
      setSpeed(initialConfig.speed);
      setRotationSpeed(initialConfig.rotationSpeed);
    }
  }, [location.state, setSpeed, setRotationSpeed]);

  // Set a random quote when the component mounts
  useEffect(() => {
    setRandomQuote(getRandomQuote());
  }, []);

  // Load data effect
  useEffect(() => {
    let mounted = true;
    const controller = new AbortController();

    async function loadAllData() {
      if (!mounted) return;
      
      // Check if we have mandalas in the navigation state
      const selectedMandalas = location.state?.mandalas;
      if (!selectedMandalas) {
        navigate('/create', { replace: true });
        return;
      }

      try {
        setLoading(true);
        setMandalaConfig(selectedMandalas);
        
        // Create and set the current tunnel in the global store
        const tunnelObject = {
          id: `tunnel-${Date.now()}`,
          name: selectedMandalas.map((m: any) => m.name).join(' '),
          mandalas: selectedMandalas,
          defaultSpeed: speed,
          defaultRotation: rotationSpeed
        };
        
        console.log("Setting currentTunnel:", tunnelObject);
        setCurrentTunnel(tunnelObject);
        
        const allTextures = await Promise.all(
          selectedMandalas.map((mandala: MandalaInfo) => loadMandalaTextures(mandala.name))
        );
        
        // Log image URLs for each mandala
        console.group("🔄 Tunnel created with the following mandala images:");
        selectedMandalas.forEach((mandala: MandalaInfo & Partial<Mandala>, index: number) => {
          console.group(`🔹 Mandala ${index + 1}: ${mandala.name}`);
          // Access layer_urls from the mandala object, which may come from the Mandala interface
          const layerUrls = (mandala as any).layer_urls;
          if (layerUrls && layerUrls.length > 0) {
            console.log(`  - ${layerUrls.length} layers:`);
            layerUrls.slice(0, 3).forEach((url: string, i: number) => {
              console.log(`    ${i + 1}. ${url}`);
            });
            if (layerUrls.length > 3) {
              console.log(`    ... and ${layerUrls.length - 3} more layers`);
            }
          } else {
            console.log(`  - No layer URLs available in the mandala object`);
          }
          console.groupEnd();
        });
        
        if (mounted) {
          setTextures(allTextures.flat());
          
          // Log texture information
          console.log(`📊 Loaded ${allTextures.flat().length} textures for the tunnel`);
          
          // Log texture details for debugging
          if (allTextures.flat().length > 0) {
            console.group("📷 Texture Details:");
            console.log(`- First Texture UUID: ${allTextures.flat()[0].uuid}`);
            console.log(`- Texture Sizes: ${allTextures.flat().map(t => `${t.image.width}x${t.image.height}`).slice(0, 3).join(", ")}${allTextures.flat().length > 3 ? ", ..." : ""}`);
            console.groupEnd();
          }
        }
        console.groupEnd();
      } catch (err) {
        if (mounted) {
          setError(err instanceof Error ? err.message : 'Failed to load data');
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }

    loadAllData();

    return () => {
      mounted = false;
      controller.abort();
      textures.forEach(texture => texture.dispose());
      setTextures([]);
    };
  }, [location.state, navigate, setCurrentTunnel, setError, setLoading, setTextures]);

  // Watch for changes to currentTunnel and update mandalaConfig
  useEffect(() => {
    // Skip if no tunnel or if it's the same tunnel as before
    if (!currentTunnel?.mandalas || currentTunnel.mandalas.length === 0) {
      return;
    }
    
    // Check if this is the same tunnel we already processed
    if (previousTunnelIdRef.current === currentTunnel.id) {
      return;
    }
    
    // Update our ref to mark this tunnel as processed
    previousTunnelIdRef.current = currentTunnel.id;
    
    // Convert mandalas to the expected MandalaInfo format if needed
    const mandalaInfos = currentTunnel.mandalas.map((mandala: any) => {
      // If it's already in the right format, return as is
      if ('name' in mandala && 'layerCount' in mandala) {
        return mandala as MandalaInfo;
      }
      
      // Otherwise, adapt to MandalaInfo format
      return {
        name: mandala.name || mandala.id,
        layerCount: mandala.layers?.length || 0,
        // Add any other required properties of MandalaInfo
      } as MandalaInfo;
    });
    
    // Update the mandala config
    setMandalaConfig(mandalaInfos);
    
    // We need to reload textures for the new mandalas
    const loadTexturesForNewTunnel = async () => {
      try {
        setLoading(true);
        const allTextures = await Promise.all(
          mandalaInfos.map((mandala) => loadMandalaTextures(mandala.name))
        );
        setTextures(allTextures.flat());
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load textures');
      } finally {
        setLoading(false);
      }
    };
    
    // Always reload textures when tunnel changes
    loadTexturesForNewTunnel();
  }, [currentTunnel, setError, setLoading, setTextures]);

  // Handle screenshot capture
  useEffect(() => {
    const captureScreenshot = () => {
      if (!canvasRef.current) return;
      
      try {
        // Get the canvas element
        const canvas = canvasRef.current;
        
        // Create a temporary link element
        const link = document.createElement('a');
        
        // Set download attributes
        const tunnelName = currentTunnel?.name || 'ananta-tunnel';
        const timestamp = new Date().toISOString().replace(/[:.]/g, '-').slice(0, 19);
        link.download = `${tunnelName}-${timestamp}.png`;
        
        // Convert canvas to data URL and set as href
        link.href = canvas.toDataURL('image/png');
        
        // Append to body, click to download, then remove
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        console.log('Screenshot captured and downloaded');
      } catch (error) {
        console.error('Error capturing screenshot:', error);
      }
    };
    
    // Listen for the screenshot event
    window.addEventListener('take-screenshot', captureScreenshot);
    
    return () => {
      window.removeEventListener('take-screenshot', captureScreenshot);
    };
  }, [currentTunnel]);

  const resetCamera = useCallback(() => {
    if (controlsRef.current) {
      controlsRef.current.reset(); // Use OrbitControls built-in reset
      setIsResettingCamera(true);
      setTimeout(() => {
        setIsResettingCamera(false);
      }, 400); // Match animation duration
    }
  }, []);

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen bg-black text-white">
        <p className="text-red-400">{error}</p>
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <div className="w-full h-full relative">
        {/* The 3D scene */}
        <TunnelScene 
          canvasRef={canvasRef}
          controlsRef={controlsRef}
          isResettingCamera={isResettingCamera}
          debug={debug}
          mandalaConfig={mandalaConfig}
          textures={textures}
          mandalaSeparation={mandalaSeparation}
          layerSeparation={layerSeparation}
          speed={speed}
          rotationSpeed={rotationSpeed}
          fogEnabled={fogEnabled}
          fogNear={fogNear}
          fogFar={fogFar}
          backgroundColor={backgroundColor}
        />
        
        {/* Place ControlPanelWrapper directly on the scene */}
        <ControlPanelWrapper onResetCamera={resetCamera} />
        
        {isLoading && (
          <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/80 z-10">
            <Loader text="loading..." />
            <p className="text-white text-center mt-8 max-w-md px-4 font-doto text-lg italic">
              {randomQuote}
            </p>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
} 