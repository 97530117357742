import { useState, useEffect } from 'react';
import { Cloud, ChevronDown } from 'lucide-react';
import * as Collapsible from '@radix-ui/react-collapsible';
import { Switch, Slider } from '../ui';
import { useTunnelStore } from '../../store/tunnelStore';

// Add a CSS class for section containers and sliders
const sectionContainerClass = "bg-muted/30 rounded-md p-3 border border-border";
const sliderActiveClass = "[&_[role=slider]]:h-4 [&_[role=slider]]:w-4 [&_.bg-primary]:bg-primary";

export function FogControls() {
  const [isFogOpen, setIsFogOpen] = useState(false);
  
  // Get fog state from store
  const { 
    fogEnabled, 
    fogNear, 
    fogFar,
    backgroundColor, 
    setFogEnabled, 
    setFogNear, 
    setFogFar
  } = useTunnelStore();

  // Sync fog color with background color via a custom event (handled in scene)
  useEffect(() => {
    if (backgroundColor && fogEnabled) {
      // Dispatch an event to update fog color in the scene
      const fogColorUpdateEvent = new CustomEvent('fogColorUpdate', {
        detail: { color: backgroundColor }
      });
      window.dispatchEvent(fogColorUpdateEvent);
    }
  }, [backgroundColor, fogEnabled]);

  return (
    <div>
      <div 
        className="flex items-center justify-between cursor-pointer py-2 px-1 hover:bg-muted/30 rounded text-foreground"
        onClick={() => setIsFogOpen(!isFogOpen)}
      >
        <div className="flex items-center gap-2">
          <Cloud className="h-4 w-4 text-muted-foreground" />
          <h3 className="text-sm font-medium">Fog</h3>
        </div>
        <ChevronDown className={`w-4 h-4 transition-transform ${isFogOpen ? 'rotate-180' : ''}`} />
      </div>
      
      <Collapsible.Root open={isFogOpen}>
        <Collapsible.Content>
          <div className={`mt-2 space-y-6 ${sectionContainerClass}`}>
            {/* Fog Enabled Toggle */}
            <div className="flex items-center justify-between">
              <label className="text-sm font-medium">Enable Fog</label>
              <Switch 
                checked={fogEnabled}
                onCheckedChange={setFogEnabled}
              />
            </div>
            
            {fogEnabled && (
              <>
                {/* Fog Near Slider */}
                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <label className="text-sm font-medium">Near</label>
                    <span className="text-sm text-muted-foreground/70">{fogNear.toFixed(1)}</span>
                  </div>
                  <Slider
                    value={[fogNear]}
                    min={1}
                    max={100}
                    step={0.1}
                    onValueChange={(values) => setFogNear(values[0])}
                    className={sliderActiveClass}
                  />
                </div>
                
                {/* Fog Far Slider */}
                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <label className="text-sm font-medium">Far</label>
                    <span className="text-sm text-muted-foreground/70">{fogFar.toFixed(1)}</span>
                  </div>
                  <Slider
                    value={[fogFar]}
                    min={1}
                    max={500}
                    step={1}
                    onValueChange={(values) => setFogFar(values[0])}
                    className={sliderActiveClass}
                  />
                </div>
                
                <div className="text-xs text-muted-foreground/50 italic px-1">
                  Fog color is synced with the background color
                </div>
              </>
            )}
          </div>
        </Collapsible.Content>
      </Collapsible.Root>
    </div>
  );
} 