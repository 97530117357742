import { createClient } from '@supabase/supabase-js';
import { MandalaInfo } from '../types/tunnel';
import { Mandala as MandalaService } from './mandalaService';
import { Mandala as SceneMandala, PostProcessingSettings } from '../types/scene';

const SUPABASE_URL = `https://${import.meta.env.VITE_SUPABASE_PROJECT_ID}.supabase.co`;
const SUPABASE_ANON_KEY = import.meta.env.VITE_SUPABASE_ANON_KEY;
const BUCKET_NAME = import.meta.env.VITE_STORAGE_BUCKET_NAME;

// Cache configurations
const CONFIG_CACHE_TIME = 5 * 60 * 1000; // 5 minutes
const configCache = new Map<string, { data: any; timestamp: number; }>();

console.log('Initializing Supabase with:', {
  url: SUPABASE_URL,
  bucket: BUCKET_NAME,
  // Don't log the key for security
});

export const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY, {
  auth: {
    autoRefreshToken: false,
    persistSession: false,
    detectSessionInUrl: false
  }
});

// Test Supabase connection
export async function testSupabaseConnection(): Promise<{ success: boolean; message: string }> {
  try {
    console.log('Testing Supabase connection...');
    console.log('Supabase URL:', SUPABASE_URL);
    
    // Try to fetch a single row from tunnel_configs to test connection
    const { data, error } = await supabase
      .from('tunnel_configs')
      .select('id')
      .limit(1);
    
    if (error) {
      console.error('Supabase connection test failed:', error);
      return { 
        success: false, 
        message: `Connection failed: ${error.message} (Code: ${error.code})` 
      };
    }
    
    return { 
      success: true, 
      message: `Connection successful. Found ${data?.length || 0} records.` 
    };
  } catch (e) {
    console.error('Exception during Supabase connection test:', e);
    return { 
      success: false, 
      message: `Exception: ${e instanceof Error ? e.message : String(e)}` 
    };
  }
}

// Check if environment variables are properly set
export function checkEnvironmentVariables(): { valid: boolean; issues: string[] } {
  const issues: string[] = [];
  
  // Check Supabase project ID
  if (!import.meta.env.VITE_SUPABASE_PROJECT_ID) {
    issues.push('VITE_SUPABASE_PROJECT_ID is missing');
  } else if (import.meta.env.VITE_SUPABASE_PROJECT_ID.length < 5) {
    issues.push('VITE_SUPABASE_PROJECT_ID appears to be invalid (too short)');
  }
  
  // Check Supabase anon key
  if (!import.meta.env.VITE_SUPABASE_ANON_KEY) {
    issues.push('VITE_SUPABASE_ANON_KEY is missing');
  } else if (!import.meta.env.VITE_SUPABASE_ANON_KEY.startsWith('eyJ')) {
    issues.push('VITE_SUPABASE_ANON_KEY appears to be invalid (should start with "eyJ")');
  }
  
  // Check storage bucket name
  if (!import.meta.env.VITE_STORAGE_BUCKET_NAME) {
    issues.push('VITE_STORAGE_BUCKET_NAME is missing');
  }
  
  return {
    valid: issues.length === 0,
    issues
  };
}

interface MandalaFolder {
  name: string;
  layerCount: number;
  previewUrl?: string | null;
}

// Interface for tunnel configurations
export interface TunnelConfig {
  id?: string;
  name: string;
  mandalas: string[] | { name: string; layerCount: number }[] | MandalaInfo[] | MandalaService[] | SceneMandala[]; // Array of mandala names or objects
  speed: number;
  rotationSpeed: number;
  layerSeparation?: number; // Made optional
  mandalaSeparation?: number; // Made optional
  created_at?: string;
  updated_at?: string;
  published?: boolean;
  // Add missing properties
  fogEnabled?: boolean;
  fogNear?: number;
  fogFar?: number;
  postProcessing?: PostProcessingSettings; // Properly typed now
  backgroundColor?: string;
  model_type?: string; // Added for 3D model type selection
}

// Add types and functions for mandala configs

// Type for mandala config to save
export interface MandalaConfig {
  id?: string;
  mandala_name: string;
  name?: string;
  camera_settings: {
    distance?: number;
    position?: [number, number, number];
    target?: [number, number, number];
    rotation?: [number, number, number];
    fov?: number;
  };
  effect_settings: {
    deformation?: any; // Contains all deformation effects and their settings
    postProcessing?: any; // Contains all post-processing settings
    backgroundColor?: string;
    explode?: number;
    layerSeparation?: number;
  };
  pattern_data: any;
  created_at?: string;
  updated_at?: string;
  user_id?: string;
  published?: boolean;
}

/**
 * Save a mandala configuration to the database
 */
export async function saveMandalaConfig(config: MandalaConfig): Promise<string | null> {
  try {
    const { data, error } = await supabase
      .from('mandala_configs')
      .insert(config)
      .select('id')
      .single();
    
    if (error) {
      console.error('Error saving mandala config:', error);
      return null;
    }
    
    return data.id;
  } catch (error) {
    console.error('Exception saving mandala config:', error);
    return null;
  }
}

/**
 * Get all mandala configurations for a specific mandala
 * If mandalaName is empty, returns configs for all mandalas
 */
export async function getMandalaConfigs(mandalaName: string): Promise<MandalaConfig[]> {
  try {
    let query = supabase
      .from('mandala_configs')
      .select('*')
      .order('created_at', { ascending: false });
    
    // Only filter by mandala_name if a specific name is provided
    if (mandalaName) {
      query = query.eq('mandala_name', mandalaName);
    }
    
    const { data, error } = await query;
    
    if (error) {
      console.error('Error getting mandala configs:', error);
      return [];
    }
    
    return data || [];
  } catch (error) {
    console.error('Exception getting mandala configs:', error);
    return [];
  }
}

/**
 * Get a specific mandala configuration by ID
 */
export async function getMandalaConfigById(id: string): Promise<MandalaConfig | null> {
  try {
    const { data, error } = await supabase
      .from('mandala_configs')
      .select('*')
      .eq('id', id)
      .single();
    
    if (error) {
      console.error('Error getting mandala config by ID:', error);
      return null;
    }
    
    return data;
  } catch (error) {
    console.error('Exception getting mandala config by ID:', error);
    return null;
  }
}

/**
 * Delete a mandala configuration
 */
export async function deleteMandalaConfig(id: string): Promise<boolean> {
  try {
    const { error } = await supabase
      .from('mandala_configs')
      .delete()
      .eq('id', id);
    
    if (error) {
      console.error('Error deleting mandala config:', error);
      return false;
    }
    
    return true;
  } catch (error) {
    console.error('Exception deleting mandala config:', error);
    return false;
  }
}

function getCachedData<T>(key: string): T | null {
  const cached = configCache.get(key);
  if (cached && Date.now() - cached.timestamp < CONFIG_CACHE_TIME) {
    return cached.data as T;
  }
  return null;
}

function setCachedData(key: string, data: any) {
  configCache.set(key, { data, timestamp: Date.now() });
}

// Save a tunnel configuration to Supabase
export async function saveTunnelConfig(config: any): Promise<TunnelConfig> {
  // Clear cache for tunnel configs
  configCache.delete('tunnelConfigs');
  
  // Convert camelCase to snake_case for database columns
  const dbConfig = {
    ...config,
    rotation_speed: config.rotationSpeed,
    layer_separation: config.layerSeparation,
    mandala_separation: config.mandalaSeparation,
    fog_enabled: config.fogEnabled,
    fog_near: config.fogNear,
    fog_far: config.fogFar,
    post_processing: config.postProcessing,
    background_color: config.backgroundColor,
    model_type: config.model_type, // Already snake_case format
    
    // Remove camelCase fields that have snake_case equivalents
    rotationSpeed: undefined,
    layerSeparation: undefined,
    mandalaSeparation: undefined,
    fogEnabled: undefined,
    fogNear: undefined,
    fogFar: undefined,
    postProcessing: undefined,
    backgroundColor: undefined
  };
  
  // If we have an ID, we're updating an existing config
  if (config.id) {
    // Clear the specific config cache
    configCache.delete(`tunnelConfig_${config.id}`);
    
    const { data, error } = await supabase
      .from('tunnel_configs')
      .update(dbConfig)
      .eq('id', config.id)
      .select()
      .single();
    
    if (error) {
      console.error('Error updating tunnel config:', error);
      throw error;
    }
    
    // Convert snake_case to camelCase for the return value
    const result: TunnelConfig = {
      id: data.id,
      name: data.name,
      mandalas: data.mandalas,
      speed: data.speed,
      rotationSpeed: data.rotation_speed,
      layerSeparation: data.layer_separation,
      mandalaSeparation: data.mandala_separation,
      created_at: data.created_at,
      updated_at: data.updated_at,
      published: data.published,
      fogEnabled: data.fog_enabled,
      fogNear: data.fog_near,
      fogFar: data.fog_far,
      postProcessing: data.post_processing,
      backgroundColor: data.background_color,
      model_type: data.model_type
    };
    
    return result;
  } else {
    // Insert new config with snake_case properties
    const { data, error } = await supabase
      .from('tunnel_configs')
      .insert(dbConfig)
      .select()
      .single();
    
    if (error) {
      console.error('Error saving tunnel config:', error);
      throw error;
    }
    
    // Convert snake_case to camelCase for the return value
    const result: TunnelConfig = {
      id: data.id,
      name: data.name,
      mandalas: data.mandalas,
      speed: data.speed,
      rotationSpeed: data.rotation_speed,
      layerSeparation: data.layer_separation,
      mandalaSeparation: data.mandala_separation,
      created_at: data.created_at,
      updated_at: data.updated_at,
      published: data.published,
      fogEnabled: data.fog_enabled,
      fogNear: data.fog_near,
      fogFar: data.fog_far,
      postProcessing: data.post_processing,
      backgroundColor: data.background_color,
      model_type: data.model_type
    };
    
    return result;
  }
}

// Load all tunnel configurations from Supabase
export async function loadTunnelConfigs(): Promise<TunnelConfig[]> {
  const cacheKey = 'tunnelConfigs';
  const cached = getCachedData<TunnelConfig[]>(cacheKey);
  if (cached) return cached;
  
  const { data, error } = await supabase
    .from('tunnel_configs')
    .select('*')
    .order('created_at', { ascending: false });
  
  if (error) {
    console.error('Error loading tunnel configs:', error);
    throw error;
  }
  
  // Convert snake_case to camelCase for all returned configurations
  const configs: TunnelConfig[] = (data || []).map(item => ({
    id: item.id,
    name: item.name, 
    mandalas: item.mandalas,
    speed: item.speed,
    rotationSpeed: item.rotation_speed,
    layerSeparation: item.layer_separation,
    mandalaSeparation: item.mandala_separation,
    created_at: item.created_at,
    updated_at: item.updated_at,
    published: item.published,
    fogEnabled: item.fog_enabled,
    fogNear: item.fog_near,
    fogFar: item.fog_far,
    postProcessing: item.post_processing,
    backgroundColor: item.background_color,
    model_type: item.model_type
  }));
  
  setCachedData(cacheKey, configs);
  return configs;
}

// Load a specific tunnel configuration by ID
export async function loadTunnelConfigById(id: string): Promise<TunnelConfig> {
  const cacheKey = `tunnelConfig_${id}`;
  const cached = getCachedData<TunnelConfig>(cacheKey);
  if (cached) return cached;
  
  const { data, error } = await supabase
    .from('tunnel_configs')
    .select('*')
    .eq('id', id)
    .single();
  
  if (error) {
    console.error(`Error loading tunnel config with ID ${id}:`, error);
    throw error;
  }
  
  // Convert snake_case to camelCase for the returned configuration
  const config: TunnelConfig = {
    id: data.id,
    name: data.name,
    mandalas: data.mandalas,
    speed: data.speed,
    rotationSpeed: data.rotation_speed,
    layerSeparation: data.layer_separation,
    mandalaSeparation: data.mandala_separation,
    created_at: data.created_at,
    updated_at: data.updated_at,
    published: data.published,
    fogEnabled: data.fog_enabled,
    fogNear: data.fog_near,
    fogFar: data.fog_far,
    postProcessing: data.post_processing,
    backgroundColor: data.background_color,
    model_type: data.model_type
  };
  
  setCachedData(cacheKey, config);
  return config;
}

// Delete a tunnel configuration by ID
export async function deleteTunnelConfig(id: string): Promise<void> {
  // Clear cache for tunnel configs and the specific config
  configCache.delete('tunnelConfigs');
  configCache.delete(`tunnelConfig_${id}`);
  
  const { error } = await supabase
    .from('tunnel_configs')
    .delete()
    .eq('id', id);
  
  if (error) {
    console.error(`Error deleting tunnel config with ID ${id}:`, error);
    throw error;
  }
}

export async function listMandalaFolders(): Promise<MandalaFolder[]> {
  const cached = getCachedData<MandalaFolder[]>('mandalaFolders');
  if (cached) return cached;

  const { data, error } = await supabase
    .storage
    .from(BUCKET_NAME)
    .list('mandalas', {
      limit: 100,
      sortBy: { column: 'name', order: 'asc' }
    });

  if (error) throw error;
  if (!data) throw new Error('No data returned from storage list');

  const folders = data
    .filter(item => !item.name.includes('.'))
    .map(folder => ({ name: folder.name, layerCount: 0 }));

  setCachedData('mandalaFolders', folders);
  return folders;
}

export async function listMandalaLayers(mandalaName: string): Promise<string[]> {
  const cacheKey = `mandalaLayers_${mandalaName}`;
  const cached = getCachedData<string[]>(cacheKey);
  if (cached) return cached;

  const { data, error } = await supabase
    .storage
    .from(BUCKET_NAME)
    .list(`mandalas/${mandalaName}`, {
      limit: 100,
      sortBy: { column: 'name', order: 'asc' }
    });

  if (error) throw error;
  if (!data) throw new Error(`No data returned for mandala ${mandalaName}`);

  const layers = data
    .filter(item => item.name.endsWith('.png') && item.name !== 'preview.png')
    .sort((a, b) => {
      const aNum = parseInt(a.name.split('-')[1]);
      const bNum = parseInt(b.name.split('-')[1]);
      return aNum - bNum;
    })
    .map(file => `${SUPABASE_URL}/storage/v1/object/public/${BUCKET_NAME}/mandalas/${mandalaName}/${file.name}`);

  setCachedData(cacheKey, layers);
  return layers;
}

export async function getMandalaConfig(): Promise<MandalaFolder[]> {
  const cached = getCachedData<MandalaFolder[]>('mandalaConfig');
  if (cached) {
    console.log('Using cached mandala config');
    return cached;
  }

  console.log('Fetching mandala configuration...');
  try {
    const mandalas = await listMandalaFolders();
    console.log(`Found ${mandalas.length} mandala folders:`, mandalas.map(m => m.name));
    
    const mandalaPromises = mandalas.map(async mandala => {
      try {
        console.log(`Processing mandala: ${mandala.name}`);
        const layers = await listMandalaLayers(mandala.name);
        console.log(`Found ${layers.length} layers for ${mandala.name}`);
        
        // Get preview URL with retry logic
        let previewUrl = null;
        try {
          previewUrl = await getMandalaPreview(mandala.name);
          console.log(`Preview URL for ${mandala.name}:`, previewUrl);
        } catch (previewError) {
          console.error(`Error getting preview for ${mandala.name}:`, previewError);
        }
        
        return { 
          ...mandala, 
          layerCount: layers.length, 
          previewUrl 
        };
      } catch (error) {
        console.error(`Error processing mandala ${mandala.name}:`, error);
        // Return the mandala with default values rather than failing the whole operation
        return { 
          ...mandala, 
          layerCount: 0, 
          previewUrl: null 
        };
      }
    });

    const config = await Promise.all(mandalaPromises);
    console.log('Final mandala config:', config);
    
    // Only cache if we have valid data
    if (config.length > 0) {
      setCachedData('mandalaConfig', config);
    }
    
    return config;
  } catch (error) {
    console.error('Error in getMandalaConfig:', error);
    throw error;
  }
}

// Clear all cache
export const clearCache = () => {
  console.log('Clearing all cache');
  configCache.clear();
};

// Clear only mandala-related cache
export const clearMandalaCache = () => {
  console.log('Clearing mandala-related cache');
  
  // Get all keys
  const keys = Array.from(configCache.keys());
  
  // Filter and delete mandala-related keys
  keys.forEach(key => {
    if (
      key === 'mandalaConfig' || 
      key === 'mandalaFolders' || 
      key.startsWith('mandalaLayers_') || 
      key.startsWith('mandalaPreview_')
    ) {
      console.log(`Clearing cache for: ${key}`);
      configCache.delete(key);
    }
  });
  
  // Force a reload of the preview images by adding a timestamp to the URL
  console.log('Cache cleared, next load will fetch fresh data');
};

export async function getMandalaPreview(mandalaName: string): Promise<string | null> {
  const cacheKey = `mandalaPreview_${mandalaName}`;
  const cached = getCachedData<string | null>(cacheKey);
  if (cached !== null && cached !== undefined) {
    console.log(`Using cached preview for ${mandalaName}:`, cached);
    return cached;
  }

  console.log(`Fetching preview for ${mandalaName}...`);

  try {
    // First, check if the mandala folder exists
    const { data: folderData, error: folderError } = await supabase
      .storage
      .from(BUCKET_NAME)
      .list('mandalas', {
        limit: 100,
        search: mandalaName
      });

    if (folderError) {
      console.error(`Error checking if mandala folder exists for ${mandalaName}:`, folderError);
      throw folderError;
    }

    if (!folderData || !folderData.some(item => item.name === mandalaName)) {
      console.error(`Mandala folder ${mandalaName} does not exist`);
      setCachedData(cacheKey, null);
      return null;
    }

    // Check if preview.png exists
    const { data: previewData, error: previewError } = await supabase
      .storage
      .from(BUCKET_NAME)
      .list(`mandalas/${mandalaName}`, {
        limit: 100
      });

    if (previewError) {
      console.error(`Error listing files in ${mandalaName}:`, previewError);
      throw previewError;
    }

    console.log(`Files in ${mandalaName} folder:`, previewData);

    // Add a timestamp to prevent caching issues
    const timestamp = new Date().getTime();

    // If preview.png exists, return its URL
    const previewFile = previewData?.find(item => item.name === 'preview.png');
    if (previewFile) {
      const previewUrl = `${SUPABASE_URL}/storage/v1/object/public/${BUCKET_NAME}/mandalas/${mandalaName}/preview.png?t=${timestamp}`;
      console.log(`Found preview.png for ${mandalaName}: ${previewUrl}`);
      
      // Verify the URL is accessible
      try {
        const response = await fetch(previewUrl, { method: 'HEAD' });
        if (!response.ok) {
          console.warn(`Preview URL exists but returns ${response.status}: ${previewUrl}`);
        } else {
          console.log(`Preview URL is accessible: ${previewUrl}`);
          setCachedData(cacheKey, previewUrl);
          return previewUrl;
        }
      } catch (e) {
        console.warn(`Error testing preview URL: ${e}`);
        // Continue to fallback instead of returning null
      }
    }

    console.log(`No valid preview.png found for ${mandalaName}, looking for first layer...`);

    // If no preview.png or it's not accessible, get the first layer as fallback
    if (!previewData || previewData.length === 0) {
      console.log(`No files found for ${mandalaName}`);
      setCachedData(cacheKey, null);
      return null;
    }

    // Find PNG files that are not preview.png
    const pngFiles = previewData.filter(item => 
      item.name.endsWith('.png') && 
      item.name !== 'preview.png'
    );
    
    console.log(`PNG files in ${mandalaName} (excluding preview.png):`, pngFiles);

    if (pngFiles.length === 0) {
      console.log(`No PNG files found for ${mandalaName}`);
      setCachedData(cacheKey, null);
      return null;
    }

    // Sort files to find the first layer
    const sortedFiles = pngFiles.sort((a, b) => {
      // Try to extract numbers from filenames for sorting
      let aNum = 0;
      let bNum = 0;
      
      try {
        if (a.name.includes('-')) {
          const aParts = a.name.split('-');
          if (aParts.length > 1) {
            // Extract number from "layer-1.png" format
            aNum = parseInt(aParts[1].split('.')[0] || '0');
          }
        }
        if (b.name.includes('-')) {
          const bParts = b.name.split('-');
          if (bParts.length > 1) {
            // Extract number from "layer-1.png" format
            bNum = parseInt(bParts[1].split('.')[0] || '0');
          }
        }
      } catch (e) {
        console.warn(`Error parsing layer numbers: ${e}`);
      }
      
      return aNum - bNum;
    });

    const firstLayer = sortedFiles[0];
    const firstLayerUrl = `${SUPABASE_URL}/storage/v1/object/public/${BUCKET_NAME}/mandalas/${mandalaName}/${firstLayer.name}?t=${timestamp}`;
    console.log(`Using first layer for ${mandalaName} preview: ${firstLayerUrl}`);
    
    // Verify the URL is accessible
    try {
      const response = await fetch(firstLayerUrl, { method: 'HEAD' });
      if (!response.ok) {
        console.warn(`Layer URL exists but returns ${response.status}: ${firstLayerUrl}`);
        setCachedData(cacheKey, null);
        return null;
      } else {
        console.log(`Layer URL is accessible: ${firstLayerUrl}`);
        setCachedData(cacheKey, firstLayerUrl);
        return firstLayerUrl;
      }
    } catch (e) {
      console.warn(`Error testing layer URL: ${e}`);
      setCachedData(cacheKey, null);
      return null;
    }
  } catch (error) {
    console.error(`Error getting preview for ${mandalaName}:`, error);
    setCachedData(cacheKey, null);
    return null;
  }
}

// Get mandalas by their names
export async function getMandalasByNames(names: string[] | { name: string; layerCount: number }[] | MandalaInfo[] | MandalaService[] | SceneMandala[]): Promise<any[]> {
  try {
    if (!names || names.length === 0) {
      console.error('getMandalasByNames called with empty or null names array');
      return [];
    }

    console.log('Getting mandalas for names:', names);
    
    // Handle all possible formats
    const mandalaNames = names.map(item => {
      // Handle stringified JSON objects
      if (typeof item === 'string' && item.startsWith('{') && item.includes('"name"')) {
        try {
          const parsed = JSON.parse(item);
          return parsed.name;
        } catch (e) {
          console.error('Failed to parse mandala JSON string:', item, e);
          // Continue with the original string
        }
      }
      
      if (typeof item === 'string') return item;
      // Handle SceneMandala which doesn't have a name property
      if ('id' in item && 'layers' in item && 'separation' in item) {
        return item.id; // Use id instead of name for SceneMandala
      }
      return item.name;
    });
    
    console.log('Extracted mandala names after parsing:', mandalaNames);
    
    const allMandalas = await getMandalaConfig();
    console.log('All available mandalas:', allMandalas.map(m => m.name));
    
    // Filter to only include mandalas whose names are in the provided list
    const foundMandalas = allMandalas.filter(mandala => {
      const found = mandalaNames.includes(mandala.name);
      if (!found) {
        // Also try case-insensitive comparison
        const lowerCaseName = mandala.name.toLowerCase();
        const lowerCaseMatch = mandalaNames.some(name => 
          typeof name === 'string' && name.toLowerCase() === lowerCaseName
        );
        
        if (lowerCaseMatch) {
          console.log(`Found mandala with case-insensitive match: ${mandala.name}`);
          return true;
        }
      }
      return found;
    });
    
    console.log(`Found ${foundMandalas.length} mandalas out of ${mandalaNames.length} requested`);
    
    if (foundMandalas.length === 0) {
      console.error('No matching mandalas found. Available mandalas:', allMandalas.map(m => m.name));
    }
    
    return foundMandalas;
  } catch (error) {
    console.error('Error in getMandalasByNames:', error);
    throw error;
  }
}

// Load only published tunnel configurations from Supabase
export async function loadPublishedTunnelConfigs(): Promise<TunnelConfig[]> {
  const cacheKey = 'publishedTunnelConfigs';
  const cached = getCachedData<TunnelConfig[]>(cacheKey);
  if (cached) return cached;
  
  const { data, error } = await supabase
    .from('tunnel_configs')
    .select('*')
    .eq('published', true)
    .order('created_at', { ascending: false });
  
  if (error) {
    console.error('Error loading published tunnel configs:', error);
    throw error;
  }
  
  // Convert snake_case to camelCase for all returned configurations
  const configs: TunnelConfig[] = (data || []).map(item => ({
    id: item.id,
    name: item.name, 
    mandalas: item.mandalas,
    speed: item.speed,
    rotationSpeed: item.rotation_speed,
    layerSeparation: item.layer_separation,
    mandalaSeparation: item.mandala_separation,
    created_at: item.created_at,
    updated_at: item.updated_at,
    published: item.published,
    fogEnabled: item.fog_enabled,
    fogNear: item.fog_near,
    fogFar: item.fog_far,
    postProcessing: item.post_processing,
    backgroundColor: item.background_color,
    model_type: item.model_type
  }));
  
  setCachedData(cacheKey, configs);
  return configs;
} 