import { cn } from "../../lib/utils"

export function Loader({ className, text }: { className?: string; text?: string }) {
  return (
    <div className={cn("flex flex-col items-center justify-center gap-4", className)}>
      <div className="relative">
        {/* Outer ring */}
        <div className="absolute inset-0 rounded-full border-2 border-white/20" />
        {/* Spinning inner ring */}
        <div className="h-12 w-12 rounded-full border-2 border-transparent border-t-white animate-spin" />
      </div>
      
      {text && (
        <div className="text-white font-doto text-xl">{text}</div>
      )}
    </div>
  )
} 