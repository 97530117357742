import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { TunnelPage } from './pages/TunnelPage';
import { CreatePage } from './pages/CreatePage';
import AdminPage from './pages/AdminPage';
import { MandalaPage } from './pages/MandalaPage';
import { ThemeProvider } from './components/theme-provider';
import { RadioPlayer } from './components/RadioPlayer';
import { Toaster } from './components/ui/toaster';

// Component to conditionally render RadioPlayer based on route
function RadioPlayerWrapper() {
  const location = useLocation();
  
  // Check if we're on a mandala page (any path that's not one of the main routes)
  const isMandalaPage = !['/tunnel', '/create', '/admin', '/'].includes(location.pathname);
  
  // Only render RadioPlayer if not on a mandala page
  if (isMandalaPage) return null;
  
  return <RadioPlayer />;
}

// Main App content with routing
function AppContent() {
  return (
    <div className="w-screen h-screen bg-background text-foreground overflow-hidden">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/tunnel" element={<TunnelPage />} />
        <Route path="/create" element={<CreatePage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/:mandalaName" element={<MandalaPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <RadioPlayerWrapper />
      <Toaster />
    </div>
  );
}

function App() {
  return (
    <ThemeProvider defaultTheme="dark">
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  );
}

export default App;
